import React, { Component } from "react";
import {
  _bidChildElement,
  _getMyLastBidForTheChildElement,
  _PaymentMethod
} from "../../../../../api";
import swal from "sweetalert2";

export default class ChildElementBidForModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      childElementId: this.props.id,
      ProjectId: this.props.projectId,
      Bid: 0,
      Currency: "",
      MyLastBid: "",
      ProjectCurrency: "",
      message: null,
      warningMessage: "",
      phone: "",
      LanguageId: localStorage.getItem("LanguageId"),
      TeklifVermekIcinText: "",
      SonTeklifText: "",
      TeklifVerText: "",
      TeklifVerButton: "",
      PlaceHolderText: "",
      Valor: "",
      PayMethods:[]
    };
    this.handleChange = this.handleChange.bind(this);
    this.bidChildElement = this.bidChildElement.bind(this);
    this.handleChangeCurrency = this.handleChangeCurrency.bind(this);
    this.handleChangeValor = this.handleChangeValor.bind(this);
    this.PaymentMethod = this.PaymentMethod.bind(this);
  }
  handleChange(e) {
    let change = {};
    change[e.target.name] = parseFloat(e.target.value);
    this.setState(change);
    // console.log(e.target.name + ": " + e.target.value);
  }
  handleChangeCurrency(e) {
    this.setState({ Currency: e.target.value });
  }

  handleChangeValor(e) {
    this.setState({ Valor: e.target.value });
  }
  bidChildElement() {
    // if (this.state.Currency === null && this.state.Currency === "") {
    //   if (this.state.LanguageId === "1") {
    //     swal("Lütfen Kur seçiniz");
    //   } else {
    //     swal("Please Select Currency");
    //   }
    // }
    if (this.state.Bid === 0 || parseFloat(this.state.Bid) < 0) {
      if (this.state.LanguageId === "1") {
        swal("Teklifiniz 0'dan büyük olmalıdır.");
      } else {
        swal("Your bid must be greater than '0'");
      }
    } else {
      const obj = {
        ProjectAltElementId: this.state.childElementId,
        MemberId: localStorage.getItem("UserId"),
        LanguageId: localStorage.getItem("LanguageId"),
        CompanyId: localStorage.getItem("CompanyId"),
        Bid: this.state.Bid,
        ProjectId: this.state.ProjectId,
        Currency: this.state.Currency,
        Valor:this.state.Valor
      };
      _bidChildElement(obj).then(response => {
        swal(response.data.message);
        this.getlastBidForTheChildElement();
      });
    }
  }
  // warningMessageFunc(){
  //   if(localStorage.getItem("LanguageId" === "1")){
  // this.setState({warningMessage:"Henüz Teklif Vermediniz"})
  //   }
  //   else{
  // this.setState({})
  //   }
  // }
  componentDidMount() {
    if (this.state.LanguageId === "1") {
      this.setState({
        TeklifVermekIcinText: "Teklif Vermek İçin",
        SonTeklifText: "Son Teklifiniz",
        TeklifVerText: "Teklif Ver",
        TeklifVerButton: "Teklif Ver",
        PlaceHolderText: "100.48 => 100 ₺ 48 Krş olarak değerlendirilecektir."
      });
    } else {
      this.setState({
        TeklifVermekIcinText: "To Bid",
        SonTeklifText: "You Last Bid",
        TeklifVerText: "Give Offer",
        TeklifVerButton: "Give Offer",
        PlaceHolderText: "100.48 => 100 ₺ 48 will be evaluated as Krs."
      });
    }

    this.getlastBidForTheChildElement();
    this.PaymentMethod();
  }

  getlastBidForTheChildElement() {
    _getMyLastBidForTheChildElement(
      this.state.childElementId,
      localStorage.getItem("UserId"),
      localStorage.getItem("LanguageId")
    ).then(response => {
      this.setState({
        MyLastBid: response.data.data.lastBid,
        ProjectCurrency: response.data.data.projectCurrency,
        message: response.data.message,
        Currency: response.data.data.projectCurrency,
        Valor: response.data.data.valor
      });
     // console.log(response.data.data);
    });
  }

  PaymentMethod(){
    _PaymentMethod(this.state.LanguageId).then(res=>{
      const optionsData = res.data.data.map(d => ({
        "value" : d.zterm,
        "label" : d.text1
      }))
    this.setState({PayMethods:optionsData});
    })
  }

  render() {
    let paymentOptions=this.state.PayMethods.map(v=>(
      <option value={v.value}>{v.label}</option>
    ));
    return (
      <div>
        <fieldset>
          <legend>{this.state.TeklifVermekIcinText}</legend>
          <div className="form-group">
            <label
              className="col-md-3 control-label"
              htmlFor="example-text-input"
            >
              {this.state.SonTeklifText}:
            </label>
            <label
              className="col-md-3 control-label"
              htmlFor="example-text-input"
            >
              {this.state.MyLastBid !== null &&
              this.state.MyLastBid !== undefined
                ? this.state.MyLastBid
                : this.state.message}
            </label>
          </div>
          <div className="form-group">
            <label
              className="col-md-3 control-label"
              htmlFor="example-text-input"
            >
              {this.state.TeklifVerText}
            </label>
            <div className="col-md-6">
              <input
                type="number"
                className="form-control"
                name="Bid"
                id="Bid"
                onChange={this.handleChange}
                value={this.state.value}
                placeholder={this.state.PlaceHolderText}
              />
            </div>
            <div className="col-md-3">
              <select
                className="form-control"
                name="Currency"
                id="Currency"
                onChange={this.handleChangeCurrency}
                value={this.state.value}
              >
                <option value={this.state.ProjectCurrency}>
                  {this.state.ProjectCurrency}
                </option>
                <option
                  hidden={this.state.ProjectCurrency === "TRY" ? true : false}
                  value="TRY"
                >
                  TRY
                </option>
                <option
                  hidden={this.state.ProjectCurrency === "USD" ? true : false}
                  value="USD"
                >
                  USD
                </option>
                <option
                  hidden={this.state.ProjectCurrency === "EUR" ? true : false}
                  value="EUR"
                >
                  EUR
                </option>
              </select>
            </div>
            <div className="col-md-3">
              <select
                className="form-control"
                name="Valor"
                id="Valor"
                onChange={this.handleChangeValor}
                value={this.state.Valor}
              >
                {paymentOptions}
              </select>
            </div>
            <div className="col-md-3">
              <button
                className="btn btn-warning"
                onClick={this.bidChildElement}
              >
                {this.state.TeklifVerButton}
              </button>
            </div>
          </div>
        </fieldset>
      </div>
    );
  }
}
