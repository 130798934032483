import { createStore } from 'redux';
import reducers from './redux/reducers';
import reduxThunk from "redux-thunk";
import { applyMiddleware } from "redux";

export default createStore(
  reducers,
  {
    auth: {
      authenticated: localStorage.getItem("Token"),
      User: {
        CompanyId: localStorage.getItem("CompanyId"),
        UserId: localStorage.getItem("UserId"),
        Username: localStorage.getItem("Username"),
        Email: localStorage.getItem("Email"),
        authenticated: localStorage.getItem("Token")
      }
    }
  }, //initial state
  applyMiddleware(reduxThunk)
);