import React, { Component } from "react";
import { MDBDataTable } from "mdbreact";
import {
  _getGeneralInformationForProject,
  _getExportPdf,
  _mainSet,
  _syncStatus
} from "../../../../api";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import swal from "sweetalert2";
import { Modal } from "react-bootstrap";
import ProjectClone from "./projectClone";
export default class GeneralInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projectId: this.props.id,
      projectType: this.props.projectType,
      projectGeneralInformation: [],
      isBidProcedureType: 1,
      isLiveProjectType: 3,
      message: "",
      sapModalShow:false,
      sapBtnStatus:true,
      modalSapBtnStatus:true,
      LanguageId: localStorage.getItem("LanguageId"),
      tableData:[],
      ConstantValuesBidProcDetail: {
        StartDateText: "",
        EndDateText: "",
        ProjectTypeText: "",
        CurrencyText: "",
        SpesificationText: "",
        SpesificationbuttonText: "",
        UpdateLabelText: "",
        UpdateButtonText: "",
        ProjectCloneButtonText: "",
        ProjectSapSyncButtonText: "",
        GoToReportButtonText:"",
        syncStatusBtnText:"",
        tableProjectNameText:"",
        tableProjectStatusText:"",
        TablesyncStatusBtnText:""
      },
      IsDateVisible : false
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.ConstantValuesForBidDetail = this.ConstantValuesForBidDetail.bind(this);
    this.SyncStatus=this.SyncStatus.bind(this);
  }
  componentDidMount() {
    this.getGeneralInformationForProject();
    this.ConstantValuesForBidDetail();
  }
  ConstantValuesForBidDetail() {
    this.state.LanguageId === "1"
      ? this.setState({
          ConstantValuesBidProcDetail: {
            StartDateText: "Başlangıç Tarihi",
            EndDateText: "Bitiş Tarihi",
            ProjectTypeText: "İhale Türü",
            CurrencyText: "Kur",
            SpesificationText: "Şartname",
            SpesificationbuttonText: "İndir",
            UpdateLabelText: "Güncelle",
            UpdateButtonText: "Güncelle",
            GoToReportButtonText:"Rapora Git",
            ProjectCloneButtonText: "İhaleyi Klonla",
            ProjectSapSyncButtonText:"Sap",
            syncStatusBtnText:"Senkronize Et",
            tableProjectNameText:"İhale",
            tableProjectStatusText:"Durum Bilgisi",
            TablesyncStatusBtnText:"İşlem"
          }
        })
      : this.setState({
          ConstantValuesBidProcDetail: {
            StartDateText: "Start Date",
            EndDateText: "End Date",
            ProjectTypeText: "Tender Type",
            CurrencyText: "Currency",
            SpesificationText: "Specification",
            SpesificationbuttonText: "Download",
            UpdateLabelText: "Update",
            UpdateButtonText: "Update",
            ProjectCloneButtonText: "Clone Tender",
            GoToReportButtonText:"Go To Report",
            ProjectSapSyncButtonText:"Sap",
            syncStatusBtnText:"Sap Sync",
            tableProjectNameText:"Project",
            tableProjectStatusText:"Status Information",
            TablesyncStatusBtnText:"Process"
          }
        });
  }
  getGeneralInformationForProject() {
    const id = this.state.projectId;
    _getGeneralInformationForProject(id).then(response => {
      this.setState({
        projectGeneralInformation:
          response.data.data === undefined ? null : response.data.data,
        message: response.data.message
      });
      this.setState({IsDateVisible:true});
      this.SyncStatus(id);
      localStorage.setItem("pId",id)
    });
  }
  handleShow = e => {
    if(e.target.name=="clonbtn")
    {
      this.setState({ sapModalShow: false });
      this.setState({ modalShow: true });
    }
    else{
      this.setState({ modalShow: false });
      this.setState({ sapModalShow: true });
    }
   
  };
  handleClose = ()=> {
  this.setState({ modalShow: false });
  this.setState({ sapModalShow: false }); 
  };
  downloadBase64File(pdf) {
    if (pdf !== null && pdf !== undefined) {
      const linkSource = `data:application/pdf;base64,${pdf}`;
      const downloadLink = document.createElement("a");
      const fileName = "sartname.pdf";

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    } else {
      localStorage.getItem("LanguageId") === "1"
        ? swal("Sistemde ihalenin teknik şartnamesi mevcut değildir.")
        : swal(
            "Technical specifications of the tender are not available on the site."
          );
    }
  }
  MainSet = e => {
   
    this.setState({modalSapBtnStatus:true});
    var obje={
      ProjectName:"",
      SyncStatus:"",
      ProjecId:  localStorage.getItem("pId")
    }
    _mainSet(obje).then(res=>{
      if(res.data.status==false)
      {
        swal("Hata");
      }
      else
      {
        swal(res.data.message);
      }
    });
  }
  SyncStatus(projectId)
  {
    _syncStatus(1,projectId).then(response => {
      this.setState({tableData:response.data.data});
      this.setState({sapBtnStatus:response.data.data[0].sapBtnStatus});
      this.setState({modalSapBtnStatus:response.data.data[0].modalSapBtnStatus});
    });
  }
  render() {
    const data = {
      columns: [
        {
          label: "label",
          field: "detail",
          width: 150
        },
        {
          label: "label",
          field: "malzemeTanim",
          width: 270
        }
      ],
      rows: {
                detail:"",
                malzemeTanim:"tanım"
              }
           
    };
    return (
      <div className="col-md-6">
        <div className="form-group">
          <label
            className="col-md-3 control-label"
            htmlFor="example-text-input"
          >
            {this.state.ConstantValuesBidProcDetail.StartDateText}
          </label>
          {
            this.state.IsDateVisible?
          <div className="col-md-9">
            <label
              className="col-md-8 control-label"
              type="date"
              id="example-text-input"
            >
              {this.state.projectGeneralInformation !== null &&
              this.state.projectGeneralInformation !== undefined ? (
                <Moment format="YYYY-MM-DD HH:mm">
                  {this.state.projectGeneralInformation.startDate}
                </Moment>
              ) : localStorage.getItem("LanguageId") === "1" ? (
                <span>Bilgiye şu an ulaşılamıyor</span>
              ) : (
                <span>Information is currently unavailable</span>
              )}
            </label>
          </div>:""
          }
        </div>
        <div className="form-group">
          <label
            className="col-md-3 control-label"
            htmlFor="example-text-input"
          >
            {this.state.ConstantValuesBidProcDetail.EndDateText}
          </label>
          {
            this.state.IsDateVisible?
          <div className="col-md-9">
            <label
              className="col-md-8 control-label"
              type="date"
              id="example-text-input"
            >
              {this.state.projectGeneralInformation !== null &&
              this.state.projectGeneralInformation !== undefined ? (
                <Moment format="YYYY-MM-DD HH:mm">
                  {this.state.projectGeneralInformation.endDate}
                </Moment>
              ) : localStorage.getItem("LanguageId") === "1" ? (
                <span>Bilgiye şu an ulaşılamıyor</span>
              ) : (
                <span>Information is currently unavailable</span>
              )}
            </label>
          </div>:""
          }
        </div>
        <div className="form-group">
          <label className="col-md-3 control-label" htmlFor="example-select">
           {this.state.ConstantValuesBidProcDetail.ProjectTypeText}
          </label>
          <div className="col-md-9">
            <label
              className="col-md-3 control-label"
              type="date"
              id="example-text-input"
            >
              {this.state.projectGeneralInformation.projectType}
            </label>
          </div>
        </div>
        <div className="form-group">
          <label className="col-md-3 control-label" htmlFor="example-select">
            Kur
          </label>
          <div className="col-md-9">
            <label
              className="col-md-3 control-label"
              type="date"
              id="example-text-input"
            >
              {this.state.projectGeneralInformation.currency}
            </label>
          </div>
        </div>
        <div className="form-group">
          <label className="col-md-3 control-label" htmlFor="example-select">
            {this.state.ConstantValuesBidProcDetail.SpesificationText}
          </label>
          <div className="col-md-9">
            <label
              className="col-md-3 control-label"
              type="date"
              id="example-text-input"
            >
              <button
                type="button"
                onClick={e =>
                  this.downloadBase64File(
                    this.state.projectGeneralInformation.teknikSartname
                  )
                }
                className="btn btn-warning"
              >
                {this.state.ConstantValuesBidProcDetail.SpesificationbuttonText}
              </button>
            </label>
          </div>
        </div>
        <div className="form-group">
          <label className="col-md-3 control-label" htmlFor="example-select">
            {this.state.ConstantValuesBidProcDetail.UpdateLabelText}
          </label>
          <div className="col-md-9">
            <label
              className="col-md-3 control-label"
              type="date"
              id="example-text-input"
            >
              <Link
                to={`/project-update/${this.state.projectId}/projectType/${this.state.projectType}`}
                className="btn btn-warning"
              >
                {this.state.ConstantValuesBidProcDetail.UpdateButtonText}
              </Link>
            </label>
          </div>
        </div>
        <div className="form-group">
          {/* <label className="col-md-3 control-label" htmlFor="example-select">
            Rapora Git
          </label> */}
          <div className="col-md-9">
            <label
              className="col-md-3 control-label"
              type="date"
              id="example-text-input"
            >
              {this.state.projectGeneralInformation.isBidProcedure === true ? (
                <Link
                  to={`/go-to-bid-proc-project-reports/${this.state.projectId}/projectType/${this.state.isBidProcedureType}`}
                  key="key"
                  className="btn btn-warning"
                >
                  {this.state.ConstantValuesBidProcDetail.GoToReportButtonText}
                </Link>
              ) : (
                <Link
                  to={`/go-to-live-project-reports/${this.state.projectId}/projectType/${this.state.isLiveProjectType}`}
                  key="key"
                  className="btn btn-warning"
                >
                  {this.state.ConstantValuesBidProcDetail.GoToReportButtonText}
                </Link>
              )}
            </label>
          </div>
        </div>
        <div className="from-group">
          <div className="col-md-9">
            <label
              className="col-md-3 control-label"
              type="date"
              id="example-text-input"
            >
              <button
                type="button"
                className="btn btn-warning"
                name="clonbtn"
                onClick={this.handleShow}
                key={this.state.projectId}
                // disabled={true}
              >
                {this.state.ConstantValuesBidProcDetail.ProjectCloneButtonText}
              </button>
            </label>
          </div>
        </div>
        <div className="from-group">
          <div className="col-md-9">
            <label
              className="col-md-3 control-label"
              type="date"
              id="example-text-input"
            >
              <button
                type="button"
                className="btn btn-warning"
                onClick={this.handleShow}
                key={this.state.projectId}
                 disabled={this.state.sapBtnStatus}
              >
                 {this.state.ConstantValuesBidProcDetail.ProjectSapSyncButtonText} 
              </button>
            </label>
          </div>
        </div>
        <Modal show={this.state.modalShow} onHide={this.handleClose} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>İhale Klonlama</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ProjectClone
              projectId={this.state.projectId}
              projectType={this.state.projectType}
            />
          </Modal.Body>
          <Modal.Footer>
            <button
              className="ml-auto"
              onClick={this.handleClose}
              variant="link"
              name="closebtn"
              type="button"
            >
              kapat
            </button>
          </Modal.Footer>
        </Modal>
        <Modal show={this.state.sapModalShow} onHide={this.handleClose} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Sap</Modal.Title>
          </Modal.Header>
          <Modal.Body>
             <table
              id="example-datatable"
              className="table table-vcenter table-condensed table-bordered dataTable no-footer"
              role="grid"
              aria-describedby="example-datatable_info"
            > 
              <thead>
                <tr role="row">
                  
                  <th className="sorting">{this.state.ConstantValuesBidProcDetail.tableProjectNameText}</th>
                  <th className="text-center sorting_disabled">{this.state.ConstantValuesBidProcDetail.tableProjectStatusText}</th>
                  <th className="text-center sorting_disabled">{this.state.ConstantValuesBidProcDetail.TablesyncStatusBtnText}</th>
                </tr>
              </thead>
              <tbody>

              {this.state.tableData.map(( listValue, index ) => {
          return (
            <tr key={index} className="even">
              <td>{listValue.projectName}</td>
              <td>{listValue.syncStatus}</td>
              <td><button
                          type="button"
                          className="btn  btn-success"
                          onClick={this.MainSet}
                          disabled={this.state.modalSapBtnStatus}
                        >
                          {this.state.ConstantValuesBidProcDetail.syncStatusBtnText}
                        </button></td>
            </tr>
          );
        })}

                
              </tbody>
            </table> 
        
          </Modal.Body>
          <Modal.Footer>
            <button
              className="ml-auto"
              onClick={this.handleClose}
              variant="link"
            >
              kapat
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
