import React, { Component } from "react";
import swal from "sweetalert2";
import { _getProjectInformation, _updatProjectInf,_ProjectBuyGroups,_accountList } from "../../../../api";
//import DateTimeRangeContainer from "react-advanced-datetimerange-picker";
import moment from "moment";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import Moment from "react-moment";
import { Modal } from "react-bootstrap";
import ChildElementBidListForReport from "../reports/childElementBidListForReport/childElementBidListForReport";
import UpdateTimeModal from "./updateTimeModal";

export default class UpdateProejct extends Component {
  constructor(props) {
    super(props);
    let now = new Date();
    let start = moment(
      new Date(now.getFullYear(), now.getMonth(), now.getDate(), 1, 0, 0, 0)
    );
    let end = moment(start)
      .add(1, "days")
      .subtract(1, "seconds");
    this.state = {
      projectId: this.props.match.params.projectId,
      projectTypeId: this.props.match.params.projectTypeId,
      location: this.props.location,
      start: start,
      end: end,
      minDate: new Date(),
      TeknikSartname_TR: "",
      TeknikSartname_EN: "",
      ProjectInformation: [],
      credentials: {
        // TeknikSartname_TR: "",
        // TeknikSartname_EN: "",
        LanguageId: localStorage.getItem("LanguageId"),
        Name_TR: "",
        Name_EN: "",
        ProjectTypeId: "",
        StartDate: new Date(),
        EndDate: new Date(),
        MinTeklif: "",
        MaxTeklif: "",
        LongDescription_TR: "",
        // MTeknikSartname_TR: "",
        // MTeknikSartname_EN: "",
        LongDescription_EN: "",
        CurrencyTypeId: "",
        ProjectBuyGroupEkgrp:1,
        MaterialGroup:""
      },
      StartDate: "",
      EndDate: "",
      unionDate: "",
      moment: moment(),
      projectBuyGroupsOptions:[],
      accountOptions:[],
      AvailibilityUpdateText: "",
      isProjectStart: false,
      ConstantValuesUpdateProject: {
        HeadText: "",
        TenderGeneralInformationText: "",
        StartDateText: "",
        EndDateText: "",
        ProjectTypeText: "",
        CurrencyText: "",
        ProjectDetailInfHead: "",
        TukceText: "",
        IngizliceText: "",
        NameText: "",
        NamePlaceHolder: "",
        LongDescriptionText: "",
        LongDescriptionPlaceHolder: "",
        SpecificationsText: "",
        SelectedText: "",
        BidProcedureText: "",
        LiveAuctionText: "",
        CreateButtontext: "",
        DownloadButtonText:"",
        UpdateButtonText:"",
        ExtendTimeText:"",
        CloseButtonText:"",
        ModalHeadText:"" ,
        ProjectBuyGroupstext:"",
        accountText:""
      }
    };
    // this.handleSubmitTR = this.handleSubmitTR.bind(this);
    // this.handleSubmitEN = this.handleSubmitEN.bind(this);
    this.fileInputEN = React.createRef();
    this.fileInputTR = React.createRef();
    this.onChangeTR = this.onChangeTR.bind(this);
    this.onChangeEN = this.onChangeEN.bind(this);
    this.applyCallback = this.applyCallback.bind(this);
    this.onStartDateChange = this.onStartDateChange.bind(this);
    this.onEndDateChange = this.onEndDateChange.bind(this);
    this.handleChangeDateStart = this.handleChangeDateStart.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.ConstantValuesUpdatePage = this.ConstantValuesUpdatePage.bind(this);
    this.ProjectBuyGroups = this.ProjectBuyGroups.bind(this);
    this.AccountList=this.AccountList.bind(this);
  }
  ConstantValuesUpdatePage() {
    this.state.credentials.LanguageId === "1"
      ? this.setState({
        ConstantValuesUpdateProject: {
            HeadText: "İhale Güncelle",
            TenderGeneralInformationText: "İhale Genel Bilgiler",
            StartDateText: "Başlangıç Tarihi",
            EndDateText: "Bitiş Tarihi",
            ProjectTypeText: "İhale Türü",
            CurrencyText: "Kur",
            ProjectDetailInfHead: "İhale Detay Bilgiler",
            TukceText: "Türkçe",
            IngizliceText: "İngilizce",
            NameText: "İsim",
            NamePlaceHolder: "İhale Adı",
            LongDescriptionText: "Uzun Açıklama",
            LongDescriptionPlaceHolder: "Uzun Açıklama Giriniz",
            SpecificationsText: "Teknik Şartname",
            SelectedText: "Seçiniz",
            BidProcedureText: "Teklif Usülü",
            LiveAuctionText: "Online İhale",
            CreateButtontext: "Güncelle",
            DownloadButtonText:"İndir",
            UpdateButtonText:"Güncelle",
            ExtendTimeText:"Süreyi Uzat",
            CloseButtonText:"Kapat",
            ModalHeadText:"İhale Süresinin Uzatılması",
            ProjectBuyGroupstext:"Satın Alma Grubu",
            accountText:"Mal Grubu"
          }
        })
      : this.setState({
        ConstantValuesUpdateProject: {
            HeadText: "Update Tender",
            TenderGeneralInformationText: "Tender General Description",
            StartDateText: "Start Date",
            EndDateText: "End Date",
            ProjectTypeText: "Tender Type",
            CurrencyText: "Currency",
            ProjectDetailInfHead: "Tender Detail Information",
            TukceText: "Turkish",
            IngizliceText: "English",
            NameText: "Name",
            NamePlaceHolder: "Enter Name",
            LongDescriptionText: "Long Description",
            LongDescriptionPlaceHolder: "Enter Long Description",
            SpecificationsText: "Technical Spesification",
            SelectedText: "Select",
            BidProcedureText: "Bid Procedure",
            LiveAuctionText: "Online Tender",
            CreateButtontext: "Update",
            DownloadButtonText:"Download",
            UpdateButtonText:"Update",
            ExtendTimeText:"Extend Time",
            CloseButtonText:"Close",
            ModalHeadText:"Extension of the Tender Period",
            ProjectBuyGroupstext:"Buy Group",
            accountText:"Material Group"
          }
        });
  }
  componentDidMount() {
    localStorage.LanguageId === "1"
      ? // ? swal("Teknik Şartnameleri 'PDF' formatında yükleyiniz.")
        swal.fire(
          "Teknik Şartnameleri PDF formatında yükleyiniz.",
          "Teşekkür eder iyi günler dileriz",
          "success"
        )
      : swal.fire(
          "Technical Specifications Please install the 'PDF' format.",
          "Thank you good day",
          "success"
        );
    this.state.credentials.LanguageId === "1"
      ? this.setState({
          AvailibilityUpdateText:
            "Güncelleme işlemi sadece ihale Başlamadan yapılabilir."
        })
      : this.setState({
          AvailibilityUpdateText:
            "The update can only be done before the tender starts."
        });
    this.projectInformation();
    this.ConstantValuesUpdatePage();
    this.ProjectBuyGroups();
    this.AccountList();
  }
  applyCallback(startDate, endDate) {
    this.setState({
      start: startDate,
      end: endDate
    });
  }
  onChangeTR(event) {
    this.setState(prevState => ({
      credentials: {
        ...prevState.credentials,
        [event.id]: this.fileInputTR.current.files[0].name
      }
    }));
    //this.fileInputTR.current.files[0].name
  }

  onChangeImage(e) {
    var sourceFile = e.target.files[0];
    var labelId = e.target.id;
    var file64 = "";
    new Promise((resolve, reject) => {
      const reader = new FileReader(e);
      reader.onloadend = () => {
        file64 = btoa(reader.result);
        this.setState({
          [labelId]: file64
        });
        console.log(labelId, ":", file64);
      };
      reader.readAsBinaryString(sourceFile);
    });
  }

  onChangeEN(event) {
    event.preventDefault();
    this.setState(prevState => ({
      credentials: {
        ...prevState.credentials,
        [event.id]: event.value
      }
    }));
  }
  onInputChange = value => {
    this.setState(prevState => ({
      credentials: {
        ...prevState.credentials,
        [value.id]: value.value
      }
    }));
  };
  onStartDateChange(e) {
    this.setState({ strDate: e });
  }
  onEndDateChange(e) {
    this.setState({ enDate: e });
  }
  onCliks = value => {
    const obj = {
      ProjectId: this.state.projectId,
      TeknikSartname_TR: this.state.TeknikSartname_TR,
      TeknikSartname_EN: this.state.TeknikSartname_EN,
      LanguageId: localStorage.getItem("LanguageId"),
      Name_TR: this.state.unionDate + " " + this.state.credentials.Name_TR,
      Name_EN: this.state.unionDate + " " + this.state.credentials.Name_EN,
      ProjectTypeId: this.state.credentials.ProjectTypeId,
      StartDate: this.state.strDate,
      EndDate: this.state.enDate,
      MinTeklif: this.state.credentials.MinTeklif,
      MaxTeklif: this.state.credentials.MaxTeklif,
      LongDescription_TR: this.state.credentials.LongDescription_TR,
      LongDescription_EN: this.state.credentials.LongDescription_EN,
      CurrencyTypeId: this.state.credentials.CurrencyTypeId,
      ProjectBuyGroup:this.state.credentials.ProjectBuyGroupEkgrp,
      MaterialGroup:this.state.credentials.MaterialGroup
    };

    _updatProjectInf(obj).then(response => {
      swal(response.data.message);
      this.projectInformation();
    });
  };

  projectInformation() {
    _getProjectInformation(
      this.state.projectId,
      localStorage.getItem("LanguageId")
    ).then(response => {
      this.setState({
        projectInformation: response.data.data,
        TeknikSartname_TR: response.data.data.teknikSartname_TR,
        TeknikSartname_EN: response.data.data.teknikSartname_EN,
        strDate: response.data.data.startDate,
        enDate: response.data.data.endDate,
        isProjectStart: response.data.data.isProjectStart,
        credentials: {
          LanguageId: localStorage.getItem("LanguageId"),
          Name_TR: response.data.data.name_TR,
          Name_EN: response.data.data.name_EN,
          ProjectTypeId: response.data.data.projectTypeId,
          StartDate: response.data.data.startDate,
          EndDate: response.data.data.endDate,
          MinTeklif: "",
          MaxTeklif: "",
          LongDescription_TR: response.data.data.longDescription_TR,
          // MTeknikSartname_TR: "",
          // MTeknikSartname_EN: "",
          LongDescription_EN: response.data.data.longDescription_EN,
          CurrencyTypeId: response.data.data.currencyTypeId,
           ProjectBuyGroupEkgrp:response.data.data.projectBuyGroup,
           MaterialGroup:response.data.data.MaterialGroup
        },
        message: response.data.message,
        unionDate: response.data.data.onEkDate
      });
      console.log(response.data.data);
    });
  }

  handleChangeDateStart = date => {
    this.setState({
      strDate: date._d
    });
    console.log(date._d);
  };
  handleChangeDateEnd = date => {
    this.setState({
      enDate: date._d
    });
    console.log(date._d);
  };
  validation = currentDate => {
    return currentDate.isAfter(moment(new Date() - 1));
  };

  downloadBase64File(pdf, lang) {
    if (pdf !== null && pdf !== undefined && pdf !== "") {
      const linkSource = `data:application/pdf;base64,${pdf}`;
      const downloadLink = document.createElement("a");
      const fileName = "sartname.pdf";

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    } else {
      localStorage.getItem("LanguageId") === "1"
        ? swal(`Sistemde ihalenin ${lang} teknik şartnamesi mevcut değildir.`)
        : swal(
            `Technical specifications of the tender are not available ${lang} on the site.`
          );
    }
  }
  handleShow = e => {
    this.setState({ modalShow: true, childElementId: e.target.id });
  };
  handleClose = () => {
    this.setState({ modalShow: false });
  };
    //--------------naci olut-------------------

    AccountList()
    {
      _accountList(1).then(res=>{     
        console.log("gelen "+res);
        const optionsData = res.data.data.map(d => ({
          "value" : d.matkl,
          "label" : d.matkl
        }))
        this.setState({accountOptions:optionsData});
      })
    }
    ProjectBuyGroups()
    { 
        _ProjectBuyGroups(1).then(res=>{
          const optionsData = res.data.data.map(d => ({
            "value" : d.ekgrp,
            "label" : d.eknam
          }))
          this.setState({projectBuyGroupsOptions:optionsData});
        })
    }
    //-----------------naci olut-----------------
  render() {
    let projectBuyGroupsOptions=this.state.projectBuyGroupsOptions.map(v=>(
      <option value={v.value}>{v.label}</option>
    ));
    let accountOptions = this.state.accountOptions.map(v => (
      <option value={v.value}>{v.label}</option>
    ));
    return (
      <div>
        <div className="row">
          <div className="col-md-12">
            <div className="block">
              <div className="block-title">
                <div className="block-options pull-right"></div>
                <h2>
                  <strong>{this.state.ConstantValuesUpdateProject.HeadText}</strong>
                </h2>
              </div>

              <form
                className="form-horizontal form-bordered"
                onSubmit={this.handleSubmit}
              >
                <fieldset>
                  <legend>{this.state.ConstantValuesUpdateProject.TenderGeneralInformationText}</legend>
                  <div className="col-md-6">
                    {this.state.isProjectStart === true ? (
                      <div className="form-group">
                        <div className="alert alert-danger">
                          {this.state.AvailibilityUpdateText}
                        </div>
                      </div>
                    ) : (
                      <div></div>
                    )}
                    <div className="form-group">
                      <label
                        className="col-md-3 control-label"
                        htmlFor="example-text-input"
                      >
                        {this.state.ConstantValuesUpdateProject.StartDateText}
                      </label>

                      <div className="col-md-9">
                        {this.state.ProjectInformation.projectTypeId === 1 ? (
                          <Moment format="YYYY-MM-DD">
                            {this.state.credentials.startDate}
                          </Moment>
                        ) : (
                          <Moment format="YYYY-MM-DD HH:mm">
                            {this.state.credentials.StartDate}
                          </Moment>
                        )}
                        <Datetime
                          selected={this.state.credentials.startDate}
                          className="form-control"
                          value={this.state.startDate}
                          placeholder={this.state.ProjectInformation.startDate}
                          onChange={this.handleChangeDateStart}
                          isValidDate={this.validation}
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        className="col-md-3 control-label"
                        htmlFor="example-text-input"
                      >
                        {this.state.ConstantValuesUpdateProject.EndDateText}
                      </label>
                      <div className="col-md-9">
                        {this.state.ProjectInformation.projectTypeId === 1 ? (
                          <Moment format="YYYY-MM-DD">
                            {this.state.credentials.EndDate}
                          </Moment>
                        ) : (
                          <Moment format="YYYY-MM-DD HH:mm">
                            {this.state.credentials.EndDate}
                          </Moment>
                        )}
                        <Datetime
                          selected={this.state.credentials.EndDate}
                          className="form-control"
                          placeholder="Date"
                          onChange={this.handleChangeDateEnd}
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        className="col-md-3 control-label"
                        htmlFor="example-select"
                      >
                       {this.state.ConstantValuesUpdateProject.ProjectTypeText}
                      </label>
                      <div className="col-md-9">
                        <select
                          id="ProjectTypeId"
                          name="ProjectTypeId"
                          className="form-control"
                          size="1"
                          onChange={event => this.onInputChange(event.target)}
                          value={this.state.credentials.ProjectTypeId}
                          required
                        >
                          <option value="1">{this.state.ConstantValuesUpdateProject.SelectedText}</option>
                        <option value="1">{this.state.ConstantValuesUpdateProject.BidProcedureText}</option>
                        <option value="3">{this.state.ConstantValuesUpdateProject.LiveAuctionText}</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label
                        className="col-md-3 control-label"
                        htmlFor="example-select"
                      >
                        {this.state.ConstantValuesUpdateProject.CurrencyText}
                      </label>
                      <div className="col-md-9">
                        <select
                          id="CurrencyTypeId"
                          name="CurrencyTypeId"
                          className="form-control"
                          size="1"
                          onChange={event => this.onInputChange(event.target)}
                          value={this.state.credentials.CurrencyTypeId}
                          required
                        >
                          <option value="1">{this.state.ConstantValuesUpdateProject.SelectedText}</option>
                          <option value="1">TRY</option>
                          <option value="2">EUR</option>
                          <option value="3">USD</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        className="col-md-3 control-label"
                        htmlFor="example-select"
                      >
                        {this.state.ConstantValuesUpdateProject.ProjectBuyGroupstext}
                      </label>
                      <div className="col-md-9">
                        <select
                          id="ProjectBuyGroupEkgrp"
                          name="ProjectBuyGroupEkgrp"
                          className="form-control"
                          size="1"
                          onChange={event => this.onInputChange(event.target)}
                          value={this.state.credentials.ProjectBuyGroupEkgrp}
                          required
                        >
                          <option value="-1">{this.state.ConstantValuesUpdateProject.SelectedText}</option>
                          {projectBuyGroupsOptions}
                        </select>
                      </div>
                    </div>
                    {/* <div className="form-group">
                      <label
                        className="col-md-3 control-label"
                        htmlFor="example-select"
                      >
                        {this.state.ConstantValuesUpdateProject.accountText}
                      </label>
                      <div className="col-md-9">
                        <select
                          id="MaterialGroup"
                          name="MaterialGroup"
                          className="form-control"
                          size="1"
                          onChange={event => this.onInputChange(event.target)}
                          value={this.state.credentials.MaterialGroup}
                          required
                        >
                          <option value="-1">{this.state.ConstantValuesUpdateProject.SelectedText}</option>
                          {accountOptions}
                        </select>
                      </div>
                    </div> */}
                  </div>
                </fieldset>

                <fieldset>
                        <legend>{this.state.ConstantValuesUpdateProject.ProjectDetailInfHead}</legend>
                  <div className="col-md-6">
                    <fieldset>
                      <legend>{this.state.ConstantValuesUpdateProject.TukceText}</legend>
                      <div className="form-group">
                        <label
                          className="col-md-3 control-label"
                          htmlFor="example-text-input"
                        >
                          {this.state.ConstantValuesUpdateProject.NameText}
                        </label>
                        <div className="col-md-9">
                          <label>{this.state.unionDate}</label>
                          <input
                            type="text"
                            id="Name_TR"
                            name="Name_TR"
                            className="form-control"
                            placeholder="Text"
                            onChange={event => this.onInputChange(event.target)}
                            value={this.state.credentials.Name_TR}
                            required
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label
                          className="col-md-3 control-label"
                          htmlFor="example-text-input"
                        >
                          {this.state.ConstantValuesUpdateProject.LongDescriptionText}
                        </label>
                        <div className="col-md-9">
                          <textarea
                            type="text"
                            id="LongDescription_TR"
                            name="LongDescription_TR"
                            className="form-control"
                            placeholder="Text"
                            onChange={event => this.onInputChange(event.target)}
                            value={this.state.credentials.LongDescription_TR}
                            required
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label
                          className="col-md-3 control-label"
                          htmlFor="example-text-input"
                        >
                          {this.state.ConstantValuesUpdateProject.SpecificationsText}
                        </label>
                        <div className="col-md-9">
                          <button
                            type="button"
                            onClick={e =>
                              this.downloadBase64File(
                                this.state.TeknikSartname_TR,
                                "TR"
                              )
                            }
                            className="btn btn-warning"
                          >
                            {this.state.ConstantValuesUpdateProject.DownloadButtonText}
                          </button>
                          <input
                            name="TeknikSartname_TR"
                            className="d-none"
                            id="TeknikSartname_TR"
                            onChange={e => this.onChangeImage(e)}
                            type="file"
                            accept=".pdf, .jpg, .jpeg, .png, .doc, .xlsl"
                            ref={this.fileInputTR}
                            value={this.state.credentials.TeknikSartname_TR}
                          />
                        </div>
                      </div>
                    </fieldset>
                  </div>
                  <div className="col-md-6">
                    <fieldset>
                          <legend>{this.state.ConstantValuesUpdateProject.IngizliceText}</legend>
                      <div className="form-group">
                        <label
                          className="col-md-3 control-label"
                          htmlFor="example-text-input"
                        >
                          {this.state.ConstantValuesUpdateProject.NameText}
                        </label>
                        <div className="col-md-9">
                          <label>{this.state.unionDate}</label>
                          <input
                            type="text"
                            id="Name_EN"
                            name="Name_EN"
                            className="form-control"
                            placeholder="Text"
                            onChange={event => this.onInputChange(event.target)}
                            value={this.state.credentials.Name_EN}
                            required
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label
                          className="col-md-3 control-label"
                          htmlFor="example-text-input"
                        >
                          {this.state.ConstantValuesUpdateProject.LongDescriptionText}
                        </label>
                        <div className="col-md-9">
                          <textarea
                            type="text"
                            id="LongDescription_EN"
                            name="LongDescription_EN"
                            className="form-control"
                            placeholder="Text"
                            onChange={event => this.onInputChange(event.target)}
                            value={this.state.credentials.LongDescription_EN}
                            required
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label
                          className="col-md-3 control-label"
                          htmlFor="example-text-input"
                        >
                          {this.state.ConstantValuesUpdateProject.SpecificationsText}
                        </label>
                        <div className="col-md-9">
                          <button
                            type="button"
                            onClick={e =>
                              this.downloadBase64File(
                                this.state.TeknikSartname_EN,
                                "EN"
                              )
                            }
                            className="btn btn-warning"
                          >
                            {this.state.ConstantValuesUpdateProject.DownloadButtonText}
                          </button>
                          <input
                            name="TeknikSartname_EN"
                            className="d-none"
                            id="TeknikSartname_EN"
                            onChange={e => this.onChangeImage(e)}
                            type="file"
                            ref={this.fileInputEN}
                            accept=".pdf, .jpg, .jpeg, .png, .doc, .xlsl"
                            value={this.state.credentials.TeknikSartname_EN}
                          />
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </fieldset>

                <div className="form-group form-actions">
                  <div className="col-md-9 col-md-offset-9">
                    <button
                      type="button"
                      className="btn  btn-warning"
                      onClick={e => this.onCliks(e)}
                      disabled={this.state.isProjectStart}
                    >
                      <i className="fa fa-plus"></i> {this.state.ConstantValuesUpdateProject.UpdateButtonText}
                    </button>
                    <button
                      type="button"
                      className="btn  btn-warning"
                      onClick={this.handleShow}
                      key={this.state.projectId}
                    >
                      <i className="fa fa-plus"></i> {this.state.ConstantValuesUpdateProject.ExtendTimeText}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <Modal show={this.state.modalShow} onHide={this.handleClose} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>{this.state.ConstantValuesUpdateProject.ModalHeadText}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <UpdateTimeModal
              projectId={this.state.projectId}
              endDate={this.state.credentials.EndDate}
              name = {this.state.credentials.Name_TR}
            />
          </Modal.Body>
          <Modal.Footer>
            <button
              className="ml-auto"
              onClick={this.handleClose}
              variant="link"
            >
              {this.state.ConstantValuesUpdateProject.CloseButtonText}
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
