import React, { Component } from 'react';
import { connect } from 'react-redux';

export default ChildComponent => {
  class ComposedComponent extends Component {
    // Our component just got rendered
    componentDidMount() {
      this.shouldNavigateAway();
    }

    // Our component just got updated
    componentDidUpdate() {
      this.shouldNavigateAway();
    }

    shouldNavigateAway() {
      if (!localStorage.getItem("Token")) {
        this.props.history.push('/login');
      } else {
        // _getTokenCheck()
        //   .then((resp) => {
        //   }, () => {
        //     this.props.history.push('/');
        //   })
       }
    }

    render() {
      return <ChildComponent {...this.props} />;
    }
  }

  function mapStateToProps(state) {
    return { auth: state.auth.User.authenticated };
  }

  return connect(mapStateToProps)(ComposedComponent);
};