import React, { Component } from "react";
import {
  _getUserInformationForManager,
  _managerUpdateUser
} from "../../../api";
import swal from "sweetalert2";
import { Link } from "react-router-dom";

export default class ManagerMemberUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      TCKN: "",
      TCKNHolder: "",
      Username: "",
      UsernameHolder: "",
      Email: "",
      EmailHolder: "",
      Password: "",
      PasswordHolder: "",
      TelNumber: "",
      TelNumberHolder: "",
      Name: "",
      NameHolder:"",
      MemberId: this.props.match.params.id,
      MemberType: this.props.match.params.memberTypeId, //1 ise izocam Kullanıcısı, 2 ise firma kullanıcısı
      LanguageId: localStorage.getItem("LanguageId"),
      IzocamUserText: "",
      CompanyUserText: "",

    };
    this.placeholderTexts = this.placeholderTexts.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onUpdateProfile = this.onUpdateProfile.bind(this);
  }

  componentDidMount() {
    this.placeholderTexts(); //Dile Göre Place holder textleri güncelliyoruz.
    this.getUserInformationForManager();


  }

  placeholderTexts() {
    /*
      Bu fonksiyon ile birlikte Component sayfaya yüklenirken
       localStorage'da yer alan LanguageId'ye göre PlaceHolderlarımızı güncelliyoruz.
      */
    if (this.state.LanguageId === "1") {
      this.setState({
        TCKNHolder: "TC Kimlik Numarası",
        UsernameHolder: "Kullanıcı Adı",
        EmailHolder: "Email",
        TelNumberHolder: "Telefon Numarası",
        PasswordHolder: "Şifre",
        IzocamUserText: "İzocam Kullanıcıları",
        CompanyUserText: "Firma Kullanıcıları",
        NameHolder:"Ad Soyad"
      });
    } else {
      this.setState({
        TCKNHolder: "Identity Number",
        UsernameHolder: "Username",
        EmailHolder: "Email",
        TelNumberHolder: "Phone Number",
        PasswordHolder: "Password",
        IzocamUserText: "Izocam Users",
        CompanyUserText: "Company Users",
        NameHolder:"Fullname"
      });
    }
  }

  getUserInformationForManager() {
    _getUserInformationForManager(
      this.state.MemberId,
      this.state.LanguageId
    ).then(response => {
      console.log(response.data.data);
      this.setState({
        TCKN: response.data.data.tckn,
        Username: response.data.data.username,
        Password: response.data.data.password,
        Email: response.data.data.email,
        TelNumber: response.data.data.telNumber,
        Name: response.data.data.name
      });
    });
  }
  handleChange(e) {
    let change = {};
    change[e.target.name] = e.target.value;
    this.setState(change);
    //console.log(e.target.name + ": " + e.target.value);
  }
  onUpdateProfile() {
    const obj = {
      Username: this.state.Username,
      Password: this.state.Password,
      Email: this.state.Email,
      TelNumber: this.state.TelNumber,
      Name: this.state.Name,
      MemberId: this.state.MemberId,
      TCKN: this.state.TCKN
    };
    //console.log(obj);

    _managerUpdateUser(obj, this.state.LanguageId).then(response => {
      swal(response.data.message);
      //window.location.href=`/izocam-user-update/${this.state.MemberId}/memberType/${this.state.MemberType}`;
    });
  }
  render() {
    return (
      <div className="row">
        <div className="col-md-12">
          <div className="block">
            <div className="block-title">
              <div className="block-options pull-right"></div>
              {this.state.MemberType === "1" ? (
                <Link to="/izocam-user" className="btn btn-info">
                  {this.state.IzocamUserText}
                </Link>
              ) : (
                <Link to="/company-user" className="btn btn-info">
                  {this.state.CompanyUserText}
                </Link>
              )}
            </div>

            <form
              className="form-horizontal form-bordered"
              name="loginForm"
              onSubmit={this.onCreateCompany}
            >
              <fieldset>
                <legend>
                  <strong>
                    <p className="btn btn-success">{this.state.Name}</p>
                  </strong>
                </legend>
                <div className="col-md-6">
                  <div className="form-group">
                    <label
                      className="col-md-3 control-label"
                      htmlFor="example-text-input"
                    >
                      {this.state.TCKNHolder}
                    </label>
                    <div className="col-md-9">
                      <input
                        type="text"
                        id="TCKN"
                        name="TCKN"
                        className="form-control"
                        placeholder={
                          this.state.TCKN !== ""
                            ? this.state.TCKN
                            : this.state.TCKNHolder
                        }
                        onChange={this.handleChange}
                        value={this.state.value}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      className="col-md-3 control-label"
                      htmlFor="example-text-input"
                    >
                      {this.state.NameHolder}
                    </label>
                    <div className="col-md-9">
                      <input
                        type="text"
                        id="Name"
                        name="Name"
                        className="form-control"
                        placeholder={
                          this.state.Name !== ""
                            ? this.state.Name
                            : this.state.NameHolder
                        }
                        onChange={this.handleChange}
                        value={this.state.value}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      className="col-md-3 control-label"
                      htmlFor="example-select"
                    >
                      {this.state.UsernameHolder}
                    </label>
                    <div className="col-md-9">
                      <input
                        type="text"
                        id="Username"
                        name="Username"
                        className="form-control"
                        placeholder={
                          this.state.Username !== ""
                            ? this.state.Username
                            : this.state.UsernameHolder
                        }
                        onChange={this.handleChange}
                        value={this.state.value}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      className="col-md-3 control-label"
                      htmlFor="example-text-input"
                    >
                      {this.state.TelNumberHolder}
                    </label>
                    <div className="col-md-9">
                      <input
                        type="text"
                        id="TelNumber"
                        name="TelNumber"
                        className="form-control"
                        placeholder={
                          this.state.TelNumber !== ""
                            ? this.state.TelNumber
                            : this.state.TelNumberHolder
                        }
                        onChange={this.handleChange}
                        value={this.state.value}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      className="col-md-3 control-label"
                      htmlFor="example-select"
                    >
                      {this.state.PasswordHolder}
                    </label>
                    <div className="col-md-9">
                      <input
                        type="text"
                        id="Password"
                        name="Password"
                        className="form-control"
                        placeholder={
                          this.state.Password !== ""
                            ? this.state.Password
                            : this.state.PasswordHolder
                        }
                        onChange={this.handleChange}
                        value={this.state.value}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      className="col-md-3 control-label"
                      htmlFor="example-select"
                    >
                      {this.state.EmailHolder}
                    </label>
                    <div className="col-md-9">
                      <input
                        type="text"
                        id="Email"
                        name="Email"
                        className="form-control"
                        placeholder={
                          this.state.Email !== ""
                            ? this.state.Email
                            : this.state.EmailHolder
                        }
                        onChange={this.handleChange}
                        value={this.state.value}
                      />
                    </div>
                  </div>
                </div>
              </fieldset>
              <div className="form-group form-actions">
                <div className="col-md-9 col-md-offset-9">
                  <button
                    type="button"
                    className="btn  btn-warning"
                    id="createCompany"
                    onClick={this.onUpdateProfile}
                  >
                    <i className="fa fa-plus"></i> Güncelle
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
