import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../redux/actions/index";
import { Link } from "react-router-dom";
// import { NavDropdown } from "react-bootstrap";
import avatar from "../../styles/img/no-avatar.png";
import $ from "jquery";
import { _updateCurrencyRates } from "../../api";
import swal from "sweetalert2";
class NavigationBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pagelength: "",
      logOutText:"",
      CurrencyRateVisible:false,
      CurrencyUpdateText:"",
      CurrencyRateUpdateSuccessMessage:"",
      CurrencyRateUpdateErrorMessage :""
    };
    this.logOut = this.logOut.bind(this);
    this.companyTypeSet = this.companyTypeSet.bind(this);
    this.updateExcange = this.updateExcange.bind(this);
  }
  componentDidMount() {
    this.updateExcange();
    this.companyTypeSet();
    this.getWindowWidth();
    if(localStorage.getItem("LanguageId") === "1")
    {
      this.setState({
        logOutText:"Çıkış",
      CurrencyUpdateText:"Kurları Güncelle",
      CurrencyRateUpdateSuccessMessage:"Güncelleme Başarılı",
      CurrencyRateUpdateErrorMessage :"Hata Oluştu,Daha Sonra Tekrar Deneyiniz."

    })


    }
    else{
      this.setState({
        logOutText:"Log Out",
      CurrencyUpdateText:"Update Exchanges",
      CurrencyRateUpdateSuccessMessage:"Update Successful",
      CurrencyRateUpdateErrorMessage :"An error occurred, try again later."
    })
    }

    console.log("Görülebilirlik",this.state.CurrencyRateVisible)

    setInterval(() => {
      this.updateExchange();
    }, 4 * 60 * 60 * 1000); // 4 saat = 4 * 60 dakika * 60 saniye * 1000 milisaniye
  }

  updateExcange(){
    _updateCurrencyRates().then(response => {
      response.data.status === true ? swal(this.state.CurrencyRateUpdateSuccessMessage):swal(this.state.CurrencyRateUpdateErrorMessage);
    })
    .catch(error => {
      swal(this.state.CurrencyRateUpdateErrorMessage);
      console.log(error);
    });
  }

  companyTypeSet(){
    if(localStorage.getItem("CompanyId") === 1)
    console.log(localStorage.getItem("CompanyId"))
    {
this.setState({CurrencyRateVisible:true})
    }
  }

  sidebarToggle = e => {
    var page = document.getElementById("page-container");
    document.getElementById("test").blur();
    //  e.preventDefault();
    // document
    //   .getElementById("page-container")
    //   .classList.toggle("sidebar-visible-lg");
    // if(this.state.pagelength >991){
    //   e.preventDefault( );
    //   document
    //     .getElementById("page-container")
    //     .classList.toggle("sidebar-visible-lg");
    // }
    // else{
    //   document
    //   .getElementById("page-container")
    //   .classList.toggle('sidebar-visible-xs');

    //                   // if (page.hasClass('sidebar-visible-xs')) {
    //                   //     handleSidebar('close-sidebar-alt');
    //                   // }
    // }
    var a = "toggle-sidebar";
    if (a === "toggle-sidebar") {
      if (this.state.pagelength > 991) {
        // Toggle main sidebar in large screens (> 991px)

        page.classList.toggle("sidebar-visible-lg");
        if (page.getElementsByClassName("sidebar-mini")) {
          page.classList.toggle("sidebar-visible-lg-mini");
        }

        if (page.getElementsByClassName("sidebar-visible-lg")) {
          var pagelength = this.getWindowWidth();
          if (pagelength > 991) {
            // Close alternative sidebar in large screens (> 991px)
            page.classList.remove("sidebar-alt-visible-lg");
          } else {
            // Close alternative sidebar in small screens (< 992px)
            page.classList.remove("sidebar-alt-visible-xs");
          }
        }
      } else {
        // Toggle main sidebar in small screens (< 992px)
        page.classList.toggle("sidebar-visible-xs");
        if (page.getElementsByClassName("sidebar-visible-xs")) {
          if (pagelength > 991) {
            // Close alternative sidebar in large screens (> 991px)
            page.classList.remove("sidebar-alt-visible-lg");
          } else {
            // Close alternative sidebar in small screens (< 992px)
            page.classList.remove("sidebar-alt-visible-xs");
          }
        }
      }
    }
  };

  logOut() {
    localStorage.clear();
    window.location.replace("http://satinalma.izocam.com.tr/");
  }
  getWindowWidth() {
    return (
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth
    );
  }
  dropToggle = e => {
    e.preventDefault();
    document.getElementById("dropdown").classList.toggle("open");
    // document.getElementById("side-bar").style.width = "0";
    // document.getElementById("page-container").style.marginLeft = "0";
    // document.getElementById("sidebar").style.width = "0";
    // document.getElementById("page-container").style.marginLeft = "0%";
  };

  onLogout = () => {
    this.props._signout();
  };
  render() {
    return (
      <header className="navbar navbar-default">
        <ul className="nav navbar-nav-custom">
          <li>
            <a id="test" onClick={this.sidebarToggle}>
              <i className="fa fa-bars fa-fw" />
            </a>
          </li>
        </ul>

        {/* {this.state.CurrencyRateVisible === true ?
          <div style={{visible:`${this.state.CurrencyRateVisible}`}}>
            <button onClick={this.updateExcange} className="btn btn-success">
            <span class="glyphicon glyphicon" ></span> {this.state.CurrencyUpdateText}
            </button>
        </div> : null } */}
        <ul className="nav navbar-nav-custom pull-right">
          <li>
            <Link onClick={this.logOut}  to ="/login" key="login" className="">
            <span class="glyphicon glyphicon-log-out"></span> {this.state.logOutText}
            </Link>
          </li>
          {/* <li className="dropdown" id="dropdown">
            <button
              onClick={this.dropToggle}
              className="dropdown-toggle"
              data-toggle="dropdown"
            >
              <i className="fa fa-angle-down mr-4" />
            </button>
            <ul className="dropdown-menu dropdown-custom dropdown-menu-right">
              <li>

                <Link onClick={this.logOut} to="/login" key="login">
                  <i className="fa fa-ban fa-fw pull-right" /> Çıkış
                </Link>
              </li>
            </ul>
          </li> */}
        </ul>
        {/* <ul>
          <li className="dropdown" id="dropdown">
        <div className="btn-group">
  <button type="button" className="btn btn-danger dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
    Action
  </button>
  <div className="dropdown-menu">
    <a className="dropdown-item" href="#">Action</a>
  </div>
</div>
</li>
</ul> */}
      </header>
    );
  }
}

export default connect(
  null,
  actions
)(NavigationBar);
