import React, { Component } from "react";
import { _createCompany } from "../../../api";
import swal from "sweetalert2";

export default class AddCompany extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Name: "",
      CompanyTitle: "",
      PostCode: "",
      Address: "",
      CompanySAPCode: "",
      TelNumber: "",
      TaxPlace: "",
      TaxNo: "",
      AuthorizedPersonName: "",
      AuthorizedPersonNumber: "",
      AuthorizedPersonEmail: "",
      Password: "",
      Username: "",
      Status: "",
      LanguageId: localStorage.getItem("LanguageId"),
      ConstantValues: {
        HeadCreateCompanyText: "",
        CompanyName: "",
        CompanyNamePlaceHolder: "",
        Telephone: "",
        TelephonePlaceHolder: "",
        StatusText: "",
        TitleText: "",
        TitlePlaceHolder: "",
        AddressText: "",
        AddressPlaceHolder: "",
        CompanyCodeText: "",
        CompanyCodePlaceHolder: "",
        TaxPlaceText: "",
        TaxPlacePlaceHolder: "",
        TaxNoText: "",
        TaxNoPlaceHolder: "",
        HeadManagerInformation: "",
        ManagerNameText: "",
        ManagerNamePlaceHolder: "",
        ManagerTelText: "",
        ManagerTelPlaceHolder: "",
        ManagerEmailText: "",
        ManagerEmailPlaceHolder: "",
        ManagerUsernameText: "",
        ManagerUsernamePlaceHolder: "",
        ManagerPasswordText: "",
        ManagerPasswordPlaceHolder: "",
        ManagerAgainPasswordText: "",
        ManagerAgainPasswordPlaceHolder: "",
        CreateButtonText: "",
        CompanyGeneralInformationText: "",
        SelectedText: "",
        PostCodeText: "",
        ActiveText: "",
        PasiveText: "",
        PostCodePlaceHolder:""
      }
    };
    this.handleChange = this.handleChange.bind(this);
    this.onCreateCompany = this.onCreateCompany.bind(this);
    this.ConstantValuesThisPage = this.ConstantValuesThisPage.bind(this);
  }

  componentDidMount(){
    this.ConstantValuesThisPage();
  }
  ConstantValuesThisPage() {
    this.state.LanguageId === "1"
      ? this.setState({
          ConstantValues: {
            HeadCreateCompanyText: "Firma Ekleme",
            CompanyName: "Firma Adı",
            CompanyNamePlaceHolder: "Firma Adı Giriniz",
            Telephone: "Telefon",
            TelephonePlaceHolder: "Telefon Numarası Giriniz",
            StatusText: "Durum",
            TitleText: "Ticari Ünvan",
            TitlePlaceHolder: "Firma Ticari Ünvan Giriniz",
            AddressText: "Firma Adresi",
            AddressPlaceHolder: "Adresi Giriniz",
            CompanyCodeText: "Firma Kodu",
            CompanyCodePlaceHolder: "Tedarikçi SAP Kodunu Giriniz",
            TaxPlaceText: "Vergi Dairesi",
            TaxPlacePlaceHolder: "Vergi Dairesi Giriniz",
            TaxNoText: "Vergi Numarası",
            TaxNoPlaceHolder: "Vergi Numarası Giriniz",
            HeadManagerInformation: "Yönetici Genel Bilgiler",
            ManagerNameText: "Ad Soyad",
            ManagerNamePlaceHolder: "Ad ve Soyadı Giriniz",
            ManagerTelText: "Telefon",
            ManagerTelPlaceHolder: "Yetkili Telefon Numarası Giriniz",
            ManagerEmailText: "Email",
            ManagerEmailPlaceHolder: "Yetkili Email Giriniz",
            ManagerUsernameText: "Kullanıcı Adı",
            ManagerUsernamePlaceHolder: "Kullanıcı Adı Giriniz",
            ManagerPasswordText: "Şifre",
            ManagerPasswordPlaceHolder: "Şifre",
            ManagerAgainPasswordText: "Şifre Tekrar",
            ManagerAgainPasswordPlaceHolder: "Şifre Tekrar",
            CreateButtonText: "Ekle",
            CompanyGeneralInformationText: "Firma Genel Bilgiler",
            SelectedText: "Seçiniz",
            PostCodeText: "Posta Kodu",
            ActiveText: "Aktif",
            PasiveText: "Pasif",
            PostCodePlaceHolder:"Posta Kodu Giriniz"
          }
        })
      : this.setState({
          ConstantValues: {
            HeadCreateCompanyText: "Create Company",
            CompanyName: "Company Name",
            CompanyNamePlaceHolder: "Enter Company Name",
            Telephone: "Telephone",
            TelephonePlaceHolder: "Enter Telephone",
            StatusText: "Status",
            TitleText: "Company Title",
            TitlePlaceHolder: "Enter Company Title",
            AddressText: "Company Address",
            AddressPlaceHolder: "Enter Address",
            CompanyCodeText: "Company Code",
            CompanyCodePlaceHolder: "Enter Company SAP Code",
            TaxPlaceText: "Tax Place",
            TaxPlacePlaceHolder: "Enter Tax Place",
            TaxNoText: "Tax Number",
            TaxNoPlaceHolder: "Enter Tax Number",
            HeadManagerInformation: "Manager General Information",
            ManagerNameText: "Full Name",
            ManagerNamePlaceHolder: "Enter Full Name",
            ManagerTelText: "Telephone Number",
            ManagerTelPlaceHolder: "Enter Manager Telephone Number",
            ManagerEmailText: "Email",
            ManagerEmailPlaceHolder: "Enter Manager Email",
            ManagerUsernameText: "Username",
            ManagerUsernamePlaceHolder: "example : name.surname",
            ManagerPasswordText: "Password",
            ManagerPasswordPlaceHolder: "Enter Manager Password",
            ManagerAgainPasswordText: "Password Again",
            ManagerAgainPasswordPlaceHolder: "Enter Password Again",
            CreateButtonText: "Create",
            CompanyGeneralInformationText: "Company General Information",
            SelectedText: "Select",
            PostCodeText: "Post Code",
            ActiveText: "Active",
            PasiveText: "Pasive",
            PostCodePlaceHolder:"Enter Post Code"
          }
        });
  }

  handleChange(e) {
    let change = {};
    change[e.target.name] = e.target.value;
    this.setState(change);
    //console.log(e.target.name + ": " + e.target.value);
  }

  onCreateCompany() {
    if (
      this.state.Name !== "" &&
      this.state.AuthorizedPersonEmail !== "" &&
      this.state.AuthorizedPersonName !== "" &&
      this.state.Password !== "" &&
      this.state.Username !== ""
    ) {
      const obj = {
        Name: this.state.Name,
        CompanyTitle: this.state.CompanyTitle,
        PostCode: this.state.PostCode,
        Address: this.state.Address,
        CompanySAPCode: this.state.CompanySAPCode,
        TelNumber: this.state.TelNumber,
        TaxPlace: this.state.TaxPlace,
        TaxNo: this.state.TaxNo,
        AuthorizedPersonName: this.state.AuthorizedPersonName,
        AuthorizedPersonNumber: this.state.AuthorizedPersonNumber,
        AuthorizedPersonEmail: this.state.AuthorizedPersonEmail,
        Password: this.state.Password,
        Username: this.state.Username,
        Status: this.state.Status,
        LanguageId: this.state.LanguageId
      };
      _createCompany(obj).then(response => {
        swal(response.data.message);
        this.props.history.push("/companies");
        // swal(response.data.data.message);
      });
    } else {
      this.state.LanguageId === "1"
        ? swal("Lütfen Bilgileri Eksiksiz Giriniz.")
        : swal("Please enter the information without missing");
    }
  }

  render() {
    return (
      <div className="row">
        <div className="col-md-12">
          <div className="block">
            <div className="block-title">
              <div className="block-options pull-right"></div>
              <h2>
                <strong>
                  {this.state.ConstantValues.HeadCreateCompanyText}
                </strong>{" "}
              </h2>
            </div>

            <form
              className="form-horizontal form-bordered"
              name="loginForm"
              onSubmit={this.onCreateCompany}
            >
              <fieldset>
                <legend>
                  {this.state.ConstantValues.CompanyGeneralInformationText}
                </legend>
                <div className="col-md-6">
                  <div className="form-group">
                    <label
                      className="col-md-3 control-label"
                      htmlFor="example-text-input"
                    >
                      {this.state.ConstantValues.CompanyName} *
                    </label>
                    <div className="col-md-9">
                      <input
                        type="text"
                        id="Name"
                        name="Name"
                        className="form-control"
                        placeholder={
                          this.state.ConstantValues.CompanyNamePlaceHolder
                        }
                        onChange={this.handleChange}
                        value={this.state.value}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      className="col-md-3 control-label"
                      htmlFor="example-text-input"
                    >
                      {this.state.ConstantValues.Telephone}
                    </label>
                    <div className="col-md-9">
                      <input
                        type="number"
                        id="TelNumber"
                        name="TelNumber"
                        className="form-control"
                        placeholder={
                          this.state.ConstantValues.TelephonePlaceHolder
                        }
                        onChange={this.handleChange}
                        value={this.state.value}
                      />
                    </div>
                  </div>

                  <div className="form-group">
                    <label
                      className="col-md-3 control-label"
                      htmlFor="example-select"
                    >
                      {this.state.ConstantValues.StatusText}
                    </label>
                    <div className="col-md-9">
                      <select
                        id="Status"
                        name="Status"
                        className="form-control"
                        size="1"
                        onChange={this.handleChange}
                        value={this.state.value}
                      >
                        <option>
                          {this.state.ConstantValues.SelectedText}
                        </option>
                        <option value="1">{this.state.ConstantValues.ActiveText}</option>
                        <option value="0">{this.state.ConstantValues.PasiveText}</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label
                      className="col-md-3 control-label"
                      htmlFor="example-text-input"
                    >
                      {this.state.ConstantValues.TitleText}
                    </label>
                    <div className="col-md-9">
                      <input
                        type="text"
                        id="CompanyTitle"
                        name="CompanyTitle"
                        className="form-control"
                        placeholder={this.state.ConstantValues.TitlePlaceHolder}
                        onChange={this.handleChange}
                        value={this.state.value}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      className="col-md-3 control-label"
                      htmlFor="example-text-input"
                    >
                      {this.state.ConstantValues.AddressText}
                    </label>
                    <div className="col-md-9">
                      <input
                        type="text"
                        id="Address"
                        name="Address"
                        className="form-control"
                        placeholder={
                          this.state.ConstantValues.AddressPlaceHolder
                        }
                        onChange={this.handleChange}
                        value={this.state.value}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      className="col-md-3 control-label"
                      htmlFor="example-text-input"
                    >
                      {this.state.ConstantValues.PostCodeText}
                    </label>
                    <div className="col-md-9">
                      <input
                        type="text"
                        id="PostCode"
                        name="PostCode"
                        className="form-control"
                        placeholder={this.state.ConstantValues.PostCodePlaceHolder}
                        onChange={this.handleChange}
                        value={this.state.value}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      className="col-md-3 control-label"
                      htmlFor="example-text-input"
                    >
                      {this.state.ConstantValues.CompanyCodeText}
                    </label>
                    <div className="col-md-9">
                      <input
                        type="text"
                        id="CompanySAPCode"
                        name="CompanySAPCode"
                        className="form-control"
                        placeholder={this.state.ConstantValues.CompanyCodePlaceHolder}
                        onChange={this.handleChange}
                        value={this.state.value}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      className="col-md-3 control-label"
                      htmlFor="example-text-input"
                    >
                      {this.state.ConstantValues.TaxPlaceText}
                    </label>
                    <div className="col-md-9">
                      <input
                        type="text"
                        id="TaxPlace"
                        name="TaxPlace"
                        className="form-control"
                        placeholder={this.state.ConstantValues.TaxPlacePlaceHolder}
                        onChange={this.handleChange}
                        value={this.state.value}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      className="col-md-3 control-label"
                      htmlFor="example-text-input"
                    >
                     {this.state.ConstantValues.TaxNoText}
                    </label>
                    <div className="col-md-9">
                      <input
                        type="text"
                        id="TaxNo"
                        name="TaxNo"
                        className="form-control"
                        placeholder={this.state.ConstantValues.TaxNoPlaceHolder}
                        onChange={this.handleChange}
                        value={this.state.value}
                      />
                    </div>
                  </div>
                </div>
              </fieldset>

              <fieldset>
                <legend>{this.state.ConstantValues.HeadManagerInformation}</legend>
                <div className="col-md-6">
                  <div className="form-group">
                    <label
                      className="col-md-3 control-label"
                      htmlFor="example-text-input"
                    >
                     {this.state.ConstantValues.ManagerNameText} *
                    </label>
                    <div className="col-md-9">
                      <input
                        type="text"
                        id="example-text-input"
                        name="AuthorizedPersonName"
                        className="form-control"
                        placeholder={this.state.ConstantValues.ManagerNamePlaceHolder}
                        onChange={this.handleChange}
                        value={this.state.value}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      className="col-md-3 control-label"
                      htmlFor="example-text-input"
                    >
                      {this.state.ConstantValues.ManagerTelText} *
                    </label>
                    <div className="col-md-9">
                      <input
                        type="number"
                        id="AuthorizedPersonNumber"
                        name="AuthorizedPersonNumber"
                        className="form-control"
                        placeholder={this.state.ConstantValues.ManagerTelPlaceHolder}
                        onChange={this.handleChange}
                        value={this.state.value}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      className="col-md-3 control-label"
                      htmlFor="example-text-input"
                    >
                      {this.state.ConstantValues.ManagerEmailText}l *
                    </label>
                    <div className="col-md-9">
                      <input
                        type="text"
                        id="AuthorizedPersonEmail"
                        name="AuthorizedPersonEmail"
                        className="form-control"
                        placeholder={this.state.ConstantValues.ManagerEmailPlaceHolder}
                        onChange={this.handleChange}
                        value={this.state.value}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label
                      className="col-md-3 control-label"
                      htmlFor="example-text-input"
                    >
                     {this.state.ConstantValues.ManagerUsernameText} *
                    </label>
                    <div className="col-md-9">
                      <input
                        type="text"
                        id="Username"
                        name="Username"
                        className="form-control"
                        placeholder={this.state.ConstantValues.ManagerUsernamePlaceHolder}
                        onChange={this.handleChange}
                        value={this.state.value}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      className="col-md-3 control-label"
                      htmlFor="example-text-input"
                    >
                      {this.state.ConstantValues.ManagerPasswordText} *
                    </label>
                    <div className="col-md-9">
                      <input
                        type="text"
                        id="Password"
                        name="Password"
                        className="form-control"
                        placeholder={this.state.ConstantValues.ManagerPasswordPlaceHolder}
                        onChange={this.handleChange}
                        value={this.state.value}
                      />
                    </div>
                  </div>

                  <div className="form-group">
                    <label
                      className="col-md-3 control-label"
                      htmlFor="example-text-input"
                    >
                      {this.state.ConstantValues.ManagerAgainPasswordText} *
                    </label>
                    <div className="col-md-9">
                      <input
                        type="text"
                        id="Password"
                        name="Password"
                        className="form-control"
                        placeholder={this.state.ConstantValues.ManagerAgainPasswordPlaceHolder}
                      />
                    </div>
                  </div>
                </div>
              </fieldset>

              <div className="form-group form-actions">
                <div className="col-md-9 col-md-offset-9">
                  <button
                    type="button"
                    className="btn btn-warning"
                    id="createCompany"
                    onClick={this.onCreateCompany}
                  >
                    <i className="fa fa-plus"></i> {this.state.ConstantValues.CreateButtonText}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
