import React, { Component } from "react";
import { _getCompanyInformation, _updateCompanyWithById } from "../../../api";
import swal from "sweetalert2";

export default class UpdateCompany extends Component {
  constructor(props) {
    super(props);
    this.state = {
      CompanyId: this.props.match.params.id,
      Name: "",
      NameHolder: "",
      CompanyTitle: "",
      CompanyTitleHolder: "",
      PostCode: "",
      PostCodeHolder: "",
      Address: "",
      AddressHolder: "",
      CompanySAPCode: "",
      CompanySAPCodeHolder: "",
      TelNumber: "",
      TelNumberHolder: "",
      TaxPlace: "",
      TaxPlaceHolder: "",
      TaxNo: "",
      TaxNoHolder: "",
      Status: "",
      StatusHolder: "",
      LanguageId: localStorage.getItem("LanguageId"),
      UpdateText: "",
      HeadText: "",
      TaxPlaceText: "",
      GeneralInformation: "",
      StatusText: "",
      ActiveText: "",
      PassiveText: "",
      SelectText:"",
      AuthorizedPersonName:"",
      AuthorizedPersonEmail:"",
      AuthorizedPersonNameText:"",
      AuthorizedPersonEmailText:"",
      AuthorizationPersonNumber:"",
      AuthorizationPersonNumberText:""
    };
    this.handleChange = this.handleChange.bind(this);
    this.updateCompanyFunc = this.updateCompanyFunc.bind(this);
  }

  componentDidMount() {
   // console.log(this.state.CompanyId);
    this.getCompanyInformation();
    this.placeholderTextFunc();
  }

  placeholderTextFunc() {
    this.state.LanguageId === "1"
      ? this.setState({
          NameHolder: "Firma Adı",
          CompanyTitleHolder: "Firma Unvan",
          PostCodeHolder: "Posta Kodu",
          AddressHolder: "Adres",
          CompanySAPCodeHolder: "SAP Kodu",
          TelNumberHolder: "Tel No",
          TaxPlaceHolder: "Vergi Dairesi",
          TaxNoHolder: "Vergi Numarası",
          UpdateText: "Güncelle",
          HeadText: "Firma Güncelleme",
          TaxPlaceText: "Vergi Dairesi",
          GeneralInformation: "Genel Bilgiler",
          StatusText: "Durum",
          ActiveText: "Aktif",
          PassiveText: "Pasif",
          SelectText:"Seçiniz",
          AuthorizedPersonNameText :"Yetkili Kişi",
          AuthorizedPersonEmailText : "Yetkili Email",
          AuthorizationPersonNumberText:"Yetkili Tel No"
        })
      : this.setState({
          NameHolder: "Company Name",
          CompanyTitleHolder: "Company Title",
          PostCodeHolder: "Post Code",
          AddressHolder: "Address",
          CompanySAPCodeHolder: "SAP Code",
          TelNumberHolder: "Tel Number",
          TaxPlaceHolder: "Tax Place",
          TaxNoHolder: "Tax No",
          UpdateText: "Update",
          HeadText: "Update Company",
          TaxPlaceText: "Tax Place",
          GeneralInformation: "General Information",
          StatusText: "Status",
          ActiveText: "Active",
          PassiveText: "Passive",
          SelectText:"Select",
          AuthorizedPersonNameText :"Authorized Person Name",
          AuthorizedPersonEmailText : "Authorized Person Email",
          AuthorizationPersonNumberText:"Authorized Person No"
        });
  }

  getCompanyInformation() {
    _getCompanyInformation(this.state.CompanyId, this.state.LanguageId).then(
      response => {
        this.setState({
          Name: response.data.data.name,
          CompanyTitle: response.data.data.companyTitle,
          PostCode: response.data.data.postCode,
          Address: response.data.data.address,
          CompanySAPCode: response.data.data.companySAPCode,
          TelNumber: response.data.data.telNumber,
          TaxPlace: response.data.data.taxPlace,
          TaxNo: response.data.data.taxNo,
          Status: response.data.data.status,
          AuthorizationPersonNumber:response.data.data.authorizationPersonNumber,
          AuthorizedPersonName : response.data.data.authorizationPersonName,
          AuthorizedPersonEmail:response.data.data.authorizationPersonEmail
        });
        console.log(response.data)
      }
    );
  }
  handleChange(e) {
    let change = {};
    change[e.target.name] = e.target.value;
    this.setState(change);
    //console.log(e.target.name + ": " + e.target.value);
  }
  updateCompanyFunc() {
    const obj = {
      Id: this.state.CompanyId,
      Name: this.state.Name,
      CompanyTitle: this.state.CompanyTitle,
      PostCode: this.state.PostCode,
      Address: this.state.Address,
      CompanySAPCode: this.state.CompanySAPCode,
      TelNumber: this.state.TelNumber,
      TaxPlace: this.state.TaxPlace,
      TaxNo: this.state.TaxNo,
      Status: this.state.Status,
      LanguageId: this.state.LanguageId,
      AuthorizationPersonNumber:this.state.AuthorizationPersonNumber,
      AuthorizationPersonEmail:this.state.AuthorizedPersonEmail,
      AuthorizationPersonName:this.state.AuthorizedPersonName
    };
    //console.log(obj);
    _updateCompanyWithById(obj).then(response => {
      swal(response.data.message);
    });
  }
  render() {
    return (
      <div id="page-content">
        <div className="page-container">
          <div className="row">
            <div className="col-md-12">
              <div className="block">
                <div className="block-title">
                  <div className="block-options pull-right"></div>
                  <h2>
                    <strong>{this.state.HeadText}</strong>{" "}
                  </h2>
                </div>
                <form
                  className="form-horizontal form-bordered"
                  name="loginForm"
                  onSubmit={this.onCreateCompany}
                >
                  <fieldset>
                    <legend>{this.state.GeneralInformation}</legend>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label
                          className="col-md-3 control-label"
                          htmlFor="example-text-input"
                        >
                          {this.state.NameHolder}
                        </label>
                        <div className="col-md-9">
                          <input
                            type="text"
                            id="Name"
                            name="Name"
                            className="form-control"
                            placeholder={
                              this.state.Name !== "" &&
                              this.state.Name !== undefined
                                ? this.state.Name
                                : this.state.NameHolder
                            }
                            onChange={this.handleChange}
                            value={this.state.value}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label
                          className="col-md-3 control-label"
                          htmlFor="example-text-input"
                        >
                          {this.state.TelNumberHolder}
                        </label>
                        <div className="col-md-9">
                          <input
                            type="number"
                            id="TelNumber"
                            name="TelNumber"
                            className="form-control"
                            placeholder={
                              this.state.TelNumber !== "" &&
                              this.state.TelNumber !== undefined
                                ? this.state.TelNumber
                                : this.state.TelNumberHolder
                            }
                            onChange={this.handleChange}
                            value={this.state.value}
                          />
                        </div>
                      </div>

                      <div className="form-group">
                        <label
                          className="col-md-3 control-label"
                          htmlFor="example-select"
                        >
                          {this.state.StatusText}
                        </label>
                        <div className="col-md-9">
                          <select
                            id="Status"
                            name="Status"
                            className="form-control"
                            size="1"
                            onChange={this.handleChange}
                            value={this.state.value}
                          >
                            <option value="True">{this.state.SelectText}</option>
                            <option value="True">{this.state.ActiveText}</option>
                            <option value="False">{this.state.PassiveText}</option>
                          </select>
                        </div>
                      </div>
                      <div className="form-group">
                        <label
                          className="col-md-3 control-label"
                          htmlFor="example-text-input"
                        >
                          {this.state.AuthorizationPersonNumberText}
                        </label>
                        <div className="col-md-9">
                          <input
                            type="number"
                            id="AuthorizationPersonNumber"
                            name="AuthorizationPersonNumber"
                            className="form-control"
                            placeholder={
                              this.state.AuthorizationPersonNumber !== "" &&
                              this.state.AuthorizationPersonNumber !== undefined
                                ? this.state.AuthorizationPersonNumber
                                : this.state.AuthorizationPersonNumberText
                            }
                            onChange={this.handleChange}
                            value={this.state.value}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label
                          className="col-md-3 control-label"
                          htmlFor="example-text-input"
                        >
                          {this.state.AuthorizedPersonNameText}
                        </label>
                        <div className="col-md-9">
                          <input
                            type="text"
                            id="AuthorizedPersonName"
                            name="AuthorizedPersonName"
                            className="form-control"
                            placeholder={
                              this.state.AuthorizedPersonName !== "" &&
                              this.state.AuthorizedPersonName !== undefined
                                ? this.state.AuthorizedPersonName
                                : this.state.AuthorizedPersonNameText
                            }
                            onChange={this.handleChange}
                            value={this.state.value}
                          />
                        </div>
                      </div>

                      <div className="form-group">
                        <label
                          className="col-md-3 control-label"
                          htmlFor="example-text-input"
                        >
                          {this.state.AuthorizedPersonEmailText}
                        </label>
                        <div className="col-md-9">
                          <input
                            type="text"
                            id="AuthorizedPersonEmail"
                            name="AuthorizedPersonEmail"
                            className="form-control"
                            placeholder={
                              this.state.AuthorizedPersonEmail !== "" &&
                              this.state.AuthorizedPersonEmail !== undefined
                                ? this.state.AuthorizedPersonEmail
                                : this.state.AuthorizedPersonEmailText
                            }
                            onChange={this.handleChange}
                            value={this.state.value}
                          />
                        </div>
                      </div>

                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label
                          className="col-md-3 control-label"
                          htmlFor="example-text-input"
                        >
                          {this.state.CompanyTitleHolder}
                        </label>
                        <div className="col-md-9">
                          <input
                            type="text"
                            id="CompanyTitle"
                            name="CompanyTitle"
                            className="form-control"
                            placeholder={
                              this.state.CompanyTitle !== "" &&
                              this.state.CompanyTitle !== undefined
                                ? this.state.CompanyTitle
                                : this.state.CompanyTitleHolder
                            }
                            onChange={this.handleChange}
                            value={this.state.value}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label
                          className="col-md-3 control-label"
                          htmlFor="example-text-input"
                        >
                          {this.state.AddressHolder}
                        </label>
                        <div className="col-md-9">
                          <input
                            type="text"
                            id="Address"
                            name="Address"
                            className="form-control"
                            placeholder={
                              this.state.Address !== "" &&
                              this.state.Address !== undefined
                                ? this.state.Address
                                : this.state.AddressHolder
                            }
                            onChange={this.handleChange}
                            value={this.state.value}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label
                          className="col-md-3 control-label"
                          htmlFor="example-text-input"
                        >
                          {this.state.PostCodeHolder}
                        </label>
                        <div className="col-md-9">
                          <input
                            type="number"
                            id="PostCode"
                            name="PostCode"
                            className="form-control"
                            placeholder={
                              this.state.PostCode !== "" &&
                              this.state.PostCode !== undefined
                                ? this.state.PostCode
                                : this.state.PostCodeHolder
                            }
                            onChange={this.handleChange}
                            value={this.state.value}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label
                          className="col-md-3 control-label"
                          htmlFor="example-text-input"
                        >
                          {this.state.CompanySAPCodeHolder}
                        </label>
                        <div className="col-md-9">
                          <input
                            type="text"
                            id="CompanySAPCode"
                            name="CompanySAPCode"
                            className="form-control"
                            placeholder={
                              this.state.CompanySAPCode !== "" &&
                              this.state.CompanySAPCode !== undefined
                                ? this.state.CompanySAPCode
                                : this.state.CompanySAPCodeHolder
                            }
                            onChange={this.handleChange}
                            value={this.state.value}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label
                          className="col-md-3 control-label"
                          htmlFor="example-text-input"
                        >
                          {this.state.TaxPlaceText}
                        </label>
                        <div className="col-md-9">
                          <input
                            type="text"
                            id="TaxPlace"
                            name="TaxPlace"
                            className="form-control"
                            placeholder={
                              this.state.TaxPlace !== "" &&
                              this.state.TaxPlace !== undefined
                                ? this.state.TaxPlace
                                : this.state.TaxPlaceHolder
                            }
                            onChange={this.handleChange}
                            value={this.state.value}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label
                          className="col-md-3 control-label"
                          htmlFor="example-text-input"
                        >
                          {this.state.TaxNoHolder}
                        </label>
                        <div className="col-md-9">
                          <input
                            type="text"
                            id="TaxNo"
                            name="TaxNo"
                            className="form-control"
                            placeholder={
                              this.state.TaxNo !== "" &&
                              this.state.TaxNo !== undefined
                                ? this.state.TaxNo
                                : this.state.TaxNoHolder
                            }
                            onChange={this.handleChange}
                            value={this.state.value}
                          />
                        </div>
                      </div>
                    </div>
                  </fieldset>

                  <div className="form-group form-actions">
                    <div className="col-md-9 col-md-offset-9">
                      <button
                        type="button"
                        className="btn  btn-warning"
                        id="updateCompany"
                        onClick={this.updateCompanyFunc}
                      >
                        <i className="fa fa-plus"></i> {this.state.UpdateText}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
