import React, { Component } from "react";
import swal from "sweetalert2";
import { _createProject,_ProjectBuyGroups,
  _accountList } from "../../../../api";
import "moment/locale/tr";
//import DateTimeRangeContainer from "react-advanced-datetimerange-picker";
import moment from "moment";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
export default class CreateProject extends Component {
  constructor(props) {
    super(props);
    let now = new Date();
    let start = moment(
      new Date(now.getFullYear(), now.getMonth(), now.getDate(), 1, 0, 0, 0)
    );
    let end = moment(start)
      .add(1, "days")
      .subtract(1, "seconds");
    this.state = {
      location: this.props.location,
      start: start,
      end: end,
      minDate: new Date(),
      TeknikSartname_TR: "",
      TeknikSartname_EN: "",
      currencyText:"",
      credentials: {
        // TeknikSartname_TR: "",
        // TeknikSartname_EN: "",
        LanguageId: localStorage.getItem("LanguageId"),
        Name_TR: "",
        Name_EN: "",
        ProjectTypeId: "",
        StartDate: new Date(),
        EndDate: new Date(),
        MinTeklif: "",
        MaxTeklif: "",
        LongDescription_TR: "",
        // MTeknikSartname_TR: "",
        // MTeknikSartname_EN: "",
        LongDescription_EN: "",
        CurrencyTypeId: 1,
        ProjectBuyGroup:"110",
        MaterialGroup:""
      },
      StartDate: "",
      EndDate: "",
      unionDate: "",
      moment: moment(),
      projectBuyGroupsOptions:[],
      accountOptions:[],
      ConstantValuesCraeteProject: {
        HeadText: "",
        TenderGeneralInformationText: "",
        StartDateText: "",
        EndDateText: "",
        ProjectTypeText: "",
        CurrencyText: "",
        ProjectDetailInfHead: "",
        TukceText: "",
        IngizliceText: "",
        NameText: "",
        NamePlaceHolder: "",
        LongDescriptionText: "",
        LongDescriptionPlaceHolder: "",
        SpecificationsText: "",
        SelectedText: "",
        BidProcedureText: "",
        LiveAuctionText: "",
        CreateButtontext: "",
        ProjectBuyGroupstext:"",
        accountText:""
      }
    };
    // this.handleSubmitTR = this.handleSubmitTR.bind(this);
    // this.handleSubmitEN = this.handleSubmitEN.bind(this);
    this.fileInputEN = React.createRef();
    this.fileInputTR = React.createRef();
    this.onChangeTR = this.onChangeTR.bind(this);
    this.onChangeEN = this.onChangeEN.bind(this);
    this.applyCallback = this.applyCallback.bind(this);
    this.onStartDateChange = this.onStartDateChange.bind(this);
    this.onEndDateChange = this.onEndDateChange.bind(this);
    this.handleChangeDateStart = this.handleChangeDateStart.bind(this);
    this.ConstantValuesCreatePage = this.ConstantValuesCreatePage.bind(this);
    this.ProjectBuyGroups = this.ProjectBuyGroups.bind(this);
    this.AccountList=this.AccountList.bind(this);
  }

  ConstantValuesCreatePage() {
    this.state.credentials.LanguageId === "1"
      ? this.setState({
          ConstantValuesCraeteProject: {
            HeadText: "İhale Oluştur",
            TenderGeneralInformationText: "İhale Genel Bilgiler",
            StartDateText: "Başlangıç Tarihi",
            EndDateText: "Bitiş Tarihi",
            ProjectTypeText: "İhale Türü",
            CurrencyText: "Kur",
            ProjectDetailInfHead: "İhale Detay Bilgiler",
            TukceText: "Türkçe",
            IngizliceText: "İngilizce",
            NameText: "İsim",
            NamePlaceHolder: "İhale Adı",
            LongDescriptionText: "Uzun Açıklama",
            LongDescriptionPlaceHolder: "Uzun Açıklama Giriniz",
            SpecificationsText: "Teknik Şartname",
            SelectedText: "Seçiniz",
            BidProcedureText: "Teklif Usülü",
            LiveAuctionText: "Online İhale",
            CreateButtontext: "Oluştur",
            ProjectBuyGroupstext:"Satın Alma Grubu",
            accountText:"Mal Grubu"
          }
        })
      : this.setState({
          ConstantValuesCraeteProject: {
            HeadText: "Create Tender",
            TenderGeneralInformationText: "Tender General Description",
            StartDateText: "Start Date",
            EndDateText: "End Date",
            ProjectTypeText: "Tender Type",
            CurrencyText: "Currency",
            ProjectDetailInfHead: "Tender Detail Information",
            TukceText: "Turkish",
            IngizliceText: "English",
            NameText: "Name",
            NamePlaceHolder: "Enter Name",
            LongDescriptionText: "Long Description",
            LongDescriptionPlaceHolder: "Enter Long Description",
            SpecificationsText: "Technical Spesification",
            SelectedText: "Select",
            BidProcedureText: "Bid Procedure",
            LiveAuctionText: "Online Tender",
            CreateButtontext: "Create",
            ProjectBuyGroupstext:"Buy Group",
            accountText:"Material Group"
          }
        });
  }

  formatDate() {
    var x = new Date();
    var dd = x.getDate();
    var mm = x.getMonth() + 1;
    var yy = x.getFullYear();
    var unionDate = yy + "-" + mm + "-" + dd;
    //console.log(unionDate);
    this.setState({ unionDate: unionDate });
  }
  componentDidMount() {
    localStorage.LanguageId === "1"
      ? // ? swal("Teknik Şartnameleri 'PDF' formatında yükleyiniz.")
        swal.fire(
          "Teknik Şartnameleri PDF formatında yükleyiniz.",
          "Teşekkür eder iyi günler dileriz",
          "success"
        )
      : swal.fire(
          "Technical Specifications Please install the 'PDF' format.",
          "Thank you good day",
          "success"
        );

    this.formatDate();
    this.ConstantValuesCreatePage();
    this.ProjectBuyGroups();
    this.AccountList();
  }
  applyCallback(startDate, endDate) {
    this.setState({
      start: startDate,
      end: endDate
    });
  }
  onChangeTR(event) {
    this.setState(prevState => ({
      credentials: {
        ...prevState.credentials,
        [event.id]: this.fileInputTR.current.files[0].name
      }
    }));
    //this.fileInputTR.current.files[0].name
  }

  onChangeImage(e) {
    var sourceFile = e.target.files[0];
    var labelId = e.target.id;
    var file64 = "";
    new Promise((resolve, reject) => {
      const reader = new FileReader(e);
      reader.onloadend = () => {
        file64 = btoa(reader.result);
        this.setState({
          [labelId]: file64
        });
       // console.log(labelId, ":", file64);
      };
      reader.readAsBinaryString(sourceFile);
    });
  }

  onChangeEN(event) {
    event.preventDefault();
    this.setState(prevState => ({
      credentials: {
        ...prevState.credentials,
        [event.id]: event.value
      }
    }));
  }
  onInputChange = value => {
    this.setState(prevState => ({
      credentials: {
        ...prevState.credentials,
        [value.id]: value.value
      }
    }));
    if (value.id === "Name_TR") {
      this.setState(prevState => ({
        credentials: {
          ...prevState.credentials,
          Name_EN: value.value
        }
      }));
    }
    if (value.id === "LongDescription_TR") {
      this.setState(prevState => ({
        credentials: {
          ...prevState.credentials,
          LongDescription_EN: value.value
        }
      }));
    }
  };
  onStartDateChange(e) {
    this.setState({ strDate: e });
  }
  onEndDateChange(e) {
    this.setState({ enDate: e });
  }
  onCliks = value => {
    // value.preventDefault();
    // _createProject(this.state.credentials, (response) => {
    //   swal(response.data.message);
    // });
    // console.log(this.state.credentials.TeknikSartname_EN);
    //  var encodedStringTr = btoa(this.state.credentials.TeknikSartname_TR);
    //  var encodedStringEn = btoa(this.state.credentials.TeknikSartname_EN);
    //  const fileTr = encodedStringTr;
    //  const fileEn = encodedStringEn;
    const obj = {
      TeknikSartname_TR: this.state.TeknikSartname_TR,
      TeknikSartname_EN: this.state.TeknikSartname_EN,
      LanguageId: localStorage.getItem("LanguageId"),
      Name_TR: this.state.unionDate + " " + this.state.credentials.Name_TR,
      Name_EN: this.state.unionDate + " " + this.state.credentials.Name_EN,
      ProjectTypeId: this.state.credentials.ProjectTypeId,
      StartDate: this.state.strDate,
      EndDate: this.state.enDate,
      MinTeklif: this.state.credentials.MinTeklif,
      MaxTeklif: this.state.credentials.MaxTeklif,
      LongDescription_TR: this.state.credentials.LongDescription_TR,
      LongDescription_EN: this.state.credentials.LongDescription_EN,
      CurrencyTypeId: this.state.credentials.CurrencyTypeId,
      MemberId:localStorage.getItem("UserId"),
      ProjectBuyGroupEkgrp:this.state.credentials.ProjectBuyGroup,
      MaterialGroup:this.state.credentials.MaterialGroup
    };
  //  console.log(obj);
    // console.log(this.state.start);
    // console.log(this.state.start._d.getDate());
    // console.log(this.state.start._d);
    // console.log(this.state.end._d.toDateString());

    _createProject(obj).then(response => {
      swal(response.data.message);
      if(response.data.status){
        this.props.history.push(
          `/create-project-alt-element/${response.data.data.projectId}/projectType/${response.data.data.projectTypeId}`
        );
      }
    });
  };
  handleChangeDateStart = date => {
    this.setState({
      strDate: date._d
    });
    //console.log(date._d);
  };
  handleChangeDateEnd = date => {
    this.setState({
      enDate: date._d
    });
  };
  validation = currentDate => {
    var d = new Date();
    return currentDate.isAfter(d.setDate(d.getDate() - 1));
    //currentDate.isAfter(addDays(new Date(),1))
  };
  //--------------naci olut-------------------

  AccountList()
  {
    _accountList(1).then(res=>{     
      console.log("gelen "+res);
      const optionsData = res.data.data.map(d => ({
        "value" : d.matkl,
        "label" : d.matkl
      }))
      this.setState({accountOptions:optionsData});
    })
  }
  
  ProjectBuyGroups()
  { 
      _ProjectBuyGroups(1).then(res=>{
        const optionsData = res.data.data.map(d => ({
          "value" : d.ekgrp,
          "label" : d.eknam
        }))
        this.setState({projectBuyGroupsOptions:optionsData});
      })
  }
  //-----------------naci olut-----------------
  render() {
    let projectBuyGroupsOptions=this.state.projectBuyGroupsOptions.map(v=>(
      <option value={v.value}>{v.label}</option>
    ));
    let accountOptions = this.state.accountOptions.map(v => (
      <option value={v.value}>{v.label}</option>
    ));
    return (
      <div>
        <div className="row">
          <div className="col-md-12">
            <div className="block">
              <div className="block-title">
                <div className="block-options pull-right"></div>
                <h2>
                  <strong>{this.state.ConstantValuesCraeteProject.HeadText}</strong>
                </h2>
              </div>

              <form
                className="form-horizontal form-bordered"
                onSubmit={this.handleSubmit}
              >
                <fieldset>
                  <legend>{this.state.ConstantValuesCraeteProject.TenderGeneralInformationText}</legend>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label
                        className="col-md-3 control-label"
                        htmlFor="example-text-input"
                      >
                       {this.state.ConstantValuesCraeteProject.StartDateText}
                      </label>

                      <div className="col-md-9">
                        {/* <DateTimeRangeContainer
                          value={this.state.value}
                          ranges={ranges}
                          start={this.state.start}
                          end={this.state.end}
                          local={local}
                          minDate={now}
                          maxDate={maxDate}
                          applyCallback={this.applyCallback}
                          className="form-control"
                          required
                        >
                          <FormControl
                            id="formControlsTextB"
                            type="text"
                            label="Text"
                            placeholder="entry date"
                            className="form-control-input"
                            value={
                              this.state.credentials.StartDate +
                              "-" +
                              this.state.credentials.EndDate
                            }
                          />
                        </DateTimeRangeContainer> */}
                        {/* <input
                          type="date"
                          id="StartDate"
                          name="StartDate"
                          className="form-control"
                          placeholder="Date"
                          onChange={event => this.onInputChange(event.target)}
                          value={this.state.credentials.StartDate}
                        /> */}
                        <Datetime
                          selected={this.state.date}
                          className="form-control"
                          placeholder="Date"
                          onChange={this.handleChangeDateStart}
                          isValidDate={this.validation}
                          timeFormat={"HH:mm:ss"}
                          locale="tr"
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        className="col-md-3 control-label"
                        htmlFor="example-text-input"
                      >
                        {this.state.ConstantValuesCraeteProject.EndDateText}
                      </label>
                      <div className="col-md-9">
                        {/* <input
                          type="datetimepicker"
                          id="EndDate"
                          name="EndDate"
                          className="form-control"
                          placeholder="Date"
                          onChange={event => this.onInputChange(event.target)}
                          value={this.state.credentials.StartDate}
                        /> */}
                        <Datetime
                          selected={this.state.date}
                          className="form-control"
                          placeholder="Date"
                          onChange={this.handleChangeDateEnd}
                          isValidDate={this.validation}
                          timeFormat={"HH:mm:ss"}
                          locale="tr"
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        className="col-md-3 control-label"
                        htmlFor="example-select"
                      >
                        {this.state.ConstantValuesCraeteProject.ProjectTypeText}
                      </label>
                      <div className="col-md-9">
                        <select
                          id="ProjectTypeId"
                          name="ProjectTypeId"
                          className="form-control"
                          size="1"
                          onChange={event => this.onInputChange(event.target)}
                          value={this.state.credentials.ProjectTypeId}
                          required
                        >
                          <option value="0">{this.state.ConstantValuesCraeteProject.SelectedText}</option>
                          <option value="1">{this.state.ConstantValuesCraeteProject.BidProcedureText}</option>
                          <option value="3">{this.state.ConstantValuesCraeteProject.LiveAuctionText}</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    {/* <div className="form-group">
                      <label
                        className="col-md-3 control-label"
                        htmlFor="example-text-input"
                      >
                        Min teklif
                      </label>
                      <div className="col-md-9">
                        <input
                          type="text"
                          id="MinTeklif"
                          name="MinTeklif"
                          className="form-control"
                          placeholder="Text"
                          onChange={event => this.onInputChange(event.target)}
                          value={this.state.credentials.MinTeklif}
                          required
                        />
                      </div>
                    </div> */}
                    {/* <div className="form-group">
                      <label
                        className="col-md-3 control-label"
                        htmlFor="example-text-input"
                      >
                        Max Teklif
                      </label>
                      <div className="col-md-9">
                        <input
                          type="text"
                          id="MaxTeklif"
                          name="MaxTeklif"
                          className="form-control"
                          placeholder="Text"
                          onChange={event => this.onInputChange(event.target)}
                          value={this.state.credentials.MaxTeklif}
                          required
                        />
                      </div>
                    </div> */}
                    <div className="form-group">
                      <label
                        className="col-md-3 control-label"
                        htmlFor="example-select"
                      >
                        {this.state.ConstantValuesCraeteProject.CurrencyText}
                      </label>
                      <div className="col-md-9">
                        <select
                          id="CurrencyTypeId"
                          name="CurrencyTypeId"
                          className="form-control"
                          size="1"
                          onChange={event => this.onInputChange(event.target)}
                          value={this.state.credentials.CurrencyTypeId}
                          required
                        >
                          <option value="">{this.state.ConstantValuesCraeteProject.SelectedText}</option>
                          <option value="1">TRY</option>
                          <option value="2">EUR</option>
                          <option value="3">USD</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        className="col-md-3 control-label"
                        htmlFor="example-select"
                      >
                        {this.state.ConstantValuesCraeteProject.ProjectBuyGroupstext}
                      </label>
                      <div className="col-md-9">
                        <select
                          id="ProjectBuyGroup"
                          name="ProjectBuyGroup"
                          className="form-control"
                          size="1"
                          onChange={event => this.onInputChange(event.target)}
                          value={this.state.credentials.ProjectBuyGroup}
                          required
                        >
                          <option value="0">{this.state.ConstantValuesCraeteProject.SelectedText}</option>
                          {projectBuyGroupsOptions}
                        </select>
                      </div>
                    </div>
                    {/* <div className="form-group">
                      <label
                        className="col-md-3 control-label"
                        htmlFor="example-select"
                      >
                        {this.state.ConstantValuesCraeteProject.accountText}
                      </label>
                      <div className="col-md-9">
                        <select
                          id="MaterialGroup"
                          name="MaterialGroup"
                          className="form-control"
                          size="1"
                          onChange={event => this.onInputChange(event.target)}
                          value={this.state.credentials.MaterialGroup}
                          required
                        >
                          <option value="-1">{this.state.ConstantValuesCraeteProject.SelectedText}</option>
                          {accountOptions}
                        </select>
                      </div>
                    </div> */}
                  </div>
                </fieldset>

                <fieldset>
                  <legend>{this.state.ConstantValuesCraeteProject.ProjectDetailInfHead}</legend>
                  <div className="col-md-6">
                    <fieldset>
                      <legend>{this.state.ConstantValuesCraeteProject.TukceText}</legend>
                      <div className="form-group">
                        <label
                          className="col-md-3 control-label"
                          htmlFor="example-text-input"
                        >
                          {this.state.ConstantValuesCraeteProject.NameText}
                        </label>
                        <div className="col-md-9">
                          <label>{this.state.unionDate}</label>
                          <input
                            type="text"
                            id="Name_TR"
                            name="Name_TR"
                            className="form-control"
                            placeholder={this.state.ConstantValuesCraeteProject.NamePlaceHolder}
                            onChange={event => this.onInputChange(event.target)}
                            value={this.state.credentials.Name_TR}
                            required
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label
                          className="col-md-3 control-label"
                          htmlFor="example-text-input"
                        >
                      {this.state.ConstantValuesCraeteProject.LongDescriptionText}
                        </label>
                        <div className="col-md-9">
                          <textarea
                            type="text"
                            id="LongDescription_TR"
                            name="LongDescription_TR"
                            className="form-control"
                            placeholder={this.state.ConstantValuesCraeteProject.LongDescriptionPlaceHolder}
                            onChange={event => this.onInputChange(event.target)}
                            value={this.state.credentials.LongDescription_TR}
                            required
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label
                          className="col-md-3 control-label"
                          htmlFor="example-text-input"
                        >
                         {this.state.ConstantValuesCraeteProject.SpecificationsText}
                        </label>
                        <div className="col-md-9">
                          <input
                            name="TeknikSartname_TR"
                            className="d-none"
                            id="TeknikSartname_TR"
                            onChange={e => this.onChangeImage(e)}
                            type="file"
                            accept=".pdf, .jpg, .jpeg, .png, .doc, .xlsl"
                            ref={this.fileInputTR}
                            value={this.state.credentials.TeknikSartname_TR}
                          />
                        </div>
                      </div>
                    </fieldset>
                  </div>
                  <div className="col-md-6">
                    <fieldset>
                      <legend>{this.state.ConstantValuesCraeteProject.IngizliceText}</legend>
                      <div className="form-group">
                        <label
                          className="col-md-3 control-label"
                          htmlFor="example-text-input"
                        >
                          {this.state.ConstantValuesCraeteProject.NameText}
                        </label>
                        <div className="col-md-9">
                          <label>{this.state.unionDate}</label>
                          <input
                            type="text"
                            id="Name_EN"
                            name="Name_EN"
                            className="form-control"
                            placeholder={this.state.credentials.Name_TR}
                            onChange={event => this.onInputChange(event.target)}
                            value={this.state.credentials.Name_EN}
                            required
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label
                          className="col-md-3 control-label"
                          htmlFor="example-text-input"
                        >
                          {this.state.ConstantValuesCraeteProject.LongDescriptionText}
                        </label>
                        <div className="col-md-9">
                          <textarea
                            type="text"
                            id="LongDescription_EN"
                            name="LongDescription_EN"
                            className="form-control"
                            placeholder={
                              this.state.credentials.LongDescription_TR
                            }
                            onChange={event => this.onInputChange(event.target)}
                            value={this.state.credentials.LongDescription_EN}
                            required
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label
                          className="col-md-3 control-label"
                          htmlFor="example-text-input"
                        >
                          {this.state.ConstantValuesCraeteProject.SpecificationsText}
                        </label>
                        <div className="col-md-9">
                          <input
                            name="TeknikSartname_EN"
                            className="d-none"
                            id="TeknikSartname_EN"
                            onChange={e => this.onChangeImage(e)}
                            type="file"
                            ref={this.fileInputEN}
                            accept=".pdf, .jpg, .jpeg, .png, .doc, .xlsl"
                            value={this.state.credentials.TeknikSartname_EN}
                          />
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </fieldset>

                <div className="form-group form-actions">
                  <div className="col-md-9 col-md-offset-9">
                    <button
                      type="button"
                      className="btn  btn-warning"
                      onClick={e => this.onCliks(e)}
                    >
                      <i className="fa fa-plus"></i>{this.state.ConstantValuesCraeteProject.CreateButtontext}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
