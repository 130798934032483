import React, { Component } from "react";
import {
  _getChildelementListForProjectWithById,
  _getIsProjectStart,
  _deleteChildElement
} from "../../../../api";
import { MDBDataTable } from "mdbreact";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import swal from "sweetalert2";
export default class ChildElementListIzocam extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projectId: this.props.id,
      projectType:this.props.projectType,
      childElementListForProject: [],
      LanguageId: localStorage.getItem("LanguageId"),
      message: "",
      ConstantValueChildElementList: {
        HeadText: "",
        UpdateTextDT: "",
        UpdateButtonTextDT: "",
        MalzemeTanimtextDT: "",
        MiktarTextDT: "",
        BirimTextDT: "",
        TerminTextDT: "",
        TeslimSekliTextDT: "",
        OdemeSekliTextDT: "",
        NonChildElementErrorMessage: "",
        ValorTextDT: "",
        DeleteTextDT: "",
        DeletedErrorMessage1: "",
        DeletedErrorMessage2: "",
        SilButtonText: "",
        VazgecButtonText: "",
        PeinhText:""
      },
      IsProjectStart: false
    };
    this.ConstantValueForChildElementList = this.ConstantValueForChildElementList.bind(
      this
    );
    this.DeleteProjectChildElement = this.DeleteProjectChildElement.bind(this);
    this.isProjectstartStatus = this.isProjectstartStatus.bind(this);
  }
  componentDidMount() {
    this.ConstantValueForChildElementList();
    this.getChildelementListForProjectWithById();
    this.isProjectstartStatus();
  }
  DeleteProjectChildElement(ex) {
    if (!this.state.IsProjectStart) {
      swal({
        title: "",
        text: this.state.ConstantValueChildElementList.DeletedErrorMessage1,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: this.state.ConstantValueChildElementList.SilButtonText,
        cancelButtonText: this.state.ConstantValueChildElementList.VazgecButtonText,
        closeOnConfirm: false,
        closeOnCancel: false,
        allowOutsideClick: false
      }).then(result => {
        if (result.value) {
          _deleteChildElement(
            this.state.projectId,
            ex,
            this.state.projectType,
            this.state.IsProjectStart,
            this.state.LanguageId
          ).then(response => {
            swal(response.data.message);
   this.getChildelementListForProjectWithById();
          });
        }
      });
    } else {
      swal({
        title: "",
        text: this.state.ConstantValueChildElementList.DeletedErrorMessage2,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: this.state.ConstantValueChildElementList.SilButtonText,
        cancelButtonText: this.state.ConstantValueChildElementList.VazgecButtonText,
        closeOnConfirm: false,
        closeOnCancel: false,
        allowOutsideClick: false
      }).then(result => {
        if (result.value) {
          _deleteChildElement(
            this.state.projectId,
            ex,
            this.state.projectType,
            this.state.IsProjectStart,
            this.state.LanguageId
          ).then(response => {
            swal(response.data.message);
            this.getChildelementListForProjectWithById();
          });
        }
      });
    }
  }
  ConstantValueForChildElementList() {
    this.state.LanguageId === "1"
      ? this.setState({
          ConstantValueChildElementList: {
            HeadText: "Alt Element Listesi",
            UpdateTextDT: "Güncelle",
            UpdateButtonTextDT: "Güncelle",
            MalzemeTanimtextDT: "Malzeme Tanımı",
            MiktarTextDT: "Miktar",
            BirimTextDT: "Birim",
            TerminTextDT: "Termin",
            TeslimSekliTextDT: "Teslim Şekli",
            OdemeSekliTextDT: "Ödeme Şekli",
            NonChildElementErrorMessage: "Alt Element Bilgisi Bulunmamaktadır.",
            ValorTextDT: "Valör",
            DeleteTextDT: "Sil",
            DeletedErrorMessage1: "Silmek İstediğinize Emin Misiniz?",
            DeletedErrorMessage2:
              "İhale başlamıştır, eğer silerseniz firmaların tüm teklifleride silinecektir. Kabul Ediyor musunuz?",
            SilButtonText: "Sil",
            VazgecButtonText: "Vazgeç",
            PeinhText:"Miktar Birimi"
          }
        })
      : this.setState({
          ConstantValueChildElementList: {
            HeadText: "Sub Element List",
            UpdateTextDT: "Update",
            UpdateButtonTextDT: "Update",
            MalzemeTanimtextDT: "Material Definition",
            MiktarTextDT: "Quantity",
            BirimTextDT: "Unit",
            TerminTextDT: "Termin",
            TeslimSekliTextDT: "Delivery Method",
            OdemeSekliTextDT: "Pay Method",
            NonChildElementErrorMessage: "There is no Sub Element Information.",
            ValorTextDT: "Valor",
            DeleteTextDT: "Delete",
            DeletedErrorMessage1: "Are you sure this item deleted?",
            DeletedErrorMessage2:
              "The tender has started. If you delete all the offers of the companies will be deleted. Do you accept?",
            SilButtonText: "Delete",
            VazgecButtonText: "Cancel",
            PeinhText:"Quantity Unit"
          }
        });
  }

  getChildelementListForProjectWithById() {
    const id = this.state.projectId;
    _getChildelementListForProjectWithById(id, this.state.LanguageId).then(
      response => {
        //console.log(response.data.data);
        this.setState({
          childElementListForProject:
            response.data.data === undefined ? null : response.data.data,
          message: response.data.message
        });
      }
    );
 
  }

  isProjectstartStatus(){
    _getIsProjectStart(this.state.projectId).then(response => {
      this.setState({ IsProjectStart: response.data });
      //console.log("Proje Başladı mı?",response.data)
    });
  }

  render() {
    // var textStyle={
    //   text:"center"
    // };
    const dataChilList = {
      columns: [
        {
          label: this.state.ConstantValueChildElementList.UpdateTextDT,
          field: "update",
          sort: "asc",
          width: 150
        },
        {
          label: this.state.ConstantValueChildElementList.DeleteTextDT,
          field: "update",
          sort: "asc",
          width: 150
        },
        {
          label: this.state.ConstantValueChildElementList.MalzemeTanimtextDT,
          field: "malzemeTanim",
          sort: "asc",
          width: 150
        },
        {
          label: this.state.ConstantValueChildElementList.MiktarTextDT,
          field: "miktar",
          sort: "asc",
          width: 270
        },
        {
          label: this.state.ConstantValueChildElementList.PeinhText,
          field: "peinh",
          sort: "asc",
          width: 270
        },
        {
          label: this.state.ConstantValueChildElementList.BirimTextDT,
          field: "birim",
          sort: "asc",
          width: 200
        },
        {
          label: this.state.ConstantValueChildElementList.TerminTextDT,
          field: "termin",
          sort: "asc",
          width: 200
        },
        {
          label: this.state.ConstantValueChildElementList.TeslimSekliTextDT,
          field: "deliveryMethod",
          sort: "asc",
          width: 100
        },
        {
          label: this.state.ConstantValueChildElementList.OdemeSekliTextDT,
          field: "payMethod",
          sort: "asc",
          width: 100
        }
        // {
        //   label: this.state.ConstantValueChildElementList.ValorTextDT,
        //   field: "valor",
        //   sort: "asc",
        //   width: 100
        // }
      ],
      rows:
        this.state.childElementListForProject !== null &&
        this.state.childElementListForProject !== undefined ? (
          this.state.childElementListForProject.map(data => {
            return {
              update: (
                <Link
                  to={`/edit-childElement/${data.projectAltElementId}`}
                  key={data.projectAltElementId}
                  className="btn btn-warning"
                >
                  {this.state.ConstantValueChildElementList.UpdateButtonTextDT}
                </Link>
              ),
              delete: (
                <Button
                  className="btn btn-danger"
                  id={data.projectAltElementId}
                  onClick={() =>
                    this.DeleteProjectChildElement(data.projectAltElementId)
                  }
                >
                  {this.state.ConstantValueChildElementList.DeleteTextDT}
                </Button>
              ),
              malzemeTanim: data.malzemeTanim,
              miktar: data.miktar,
              peinh: data.peinh === null ||data.peinh === undefined ? "Belirtilmemiş": data.peinh,
              birim: data.birim,
              termin: <Moment format="YYYY-MM-DD HH:mm">{data.termin}</Moment>,
              deliveryMethod: data.deliveryMethod,
              payMethod: data.payMethod
              //valor: data.valor
            };
          })
        ) : (
          <p className="alert alert-danger">
            {this.state.NonChildElementErrorMessage}
          </p>
        )
    };
    return (
      <div className="col-md-12">
        <div className="block full">
          <div className="block-title">
            <h2>
              <strong>
                {this.state.ConstantValueChildElementList.HeadText}
              </strong>{" "}
            </h2>
          </div>
          <div className="table-responsive">
            <div
              id="example-datatable_wrapper"
              className="dataTables_wrapper form-inline no-footer"
            >
              {this.state.message === null ? (
                <MDBDataTable striped hover data={dataChilList} />
              ) : (
                <p className="alert alert-danger">
                  {this.state.NonChildElementErrorMessage}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
