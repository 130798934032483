import React, { Component } from "react";
import CreateComp from "./childElementCreateComp/createComp";


export default class CreateChildElement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projectId: this.props.match.params.id,
      projectType:this.props.match.params.projectTypeId,
    };
  }
  render() {
    return (
      <div id="page-content">
        <CreateComp id={this.state.projectId} projectType={this.state.projectType} />
      </div>
    );
  }
}
