import React, { Component } from "react";
import { Link } from "react-router-dom";
import { _getLiveProjectListForIzocam } from "../../../../api";
import { MDBDataTable } from "mdbreact";
import Moment from "react-moment";
export default class LiveProjectListForIzocam extends Component {
  constructor(props) {
    super(props);
    this.state = {
      liveProjectList: [],
      message: "",
      LanguageId: localStorage.getItem("LanguageId"),
      projectTypeId: 3,
      ProjectStatus: "1",
      ConstantValuesOnlineList: {
        ProjectStatusLabelText: "",
        ProjectStatusContiunesText: "",
        ProjectStatusCompletedText: "",
        ProjectStatusAllText: "",
        ProjectHeaderText: "",
        GoToDetailTextDT: "",
        GoToDetailButtonTextDT: "",
        InviteTextDT: "",
        InviteButtonTextDT: "",
        CreateChildElementTextDT: "",
        CreateChildElementButtonTextDT: "",
        TenderNameTextDT: "",
        TenderStatusTextDT: "",
        TenderStartDateDT: "",
        TenderEndDateDT: "",
        LiveListErrorMessage: "",
        Tamamlanmis: "",
        DevamEdiyor: "",
        CreatedUserText: "",
        CreaterUserNullText: ""
      }
    };

    // Proje Status 1=> Devam Ediyor, 2=> Tamamlanmış, 3=> Tümü
    this.statuChange = this.statuChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.ConstantValuesOnlineListPage = this.ConstantValuesOnlineListPage.bind(
      this
    );
  }
  handleChange(e) {
    let change = {};
    change[e.target.name] = e.target.value;
    this.setState(change);
    //console.log(e.target.name + ": " + e.target.value);
    _getLiveProjectListForIzocam(this.state.LanguageId, e.target.value).then(
      response => {
        this.setState({
          liveProjectList: response.data.data,
          message: response.data.message
        });
      }
    );
  }
  componentDidMount() {
    this.getBidProcedureProjectListForIzocam();
    this.ConstantValuesOnlineListPage();
  }
  ConstantValuesOnlineListPage() {
    this.state.LanguageId === "1"
      ? this.setState({
          ConstantValuesOnlineList: {
            ProjectStatusLabelText: "Ihale Durumu",
            ProjectStatusContiunesText: "Devam Ediyor",
            ProjectStatusCompletedText: "Tamamlanmış",
            ProjectStatusAllText: "Tümü",
            ProjectHeaderText: "İhaleler",
            GoToDetailTextDT: "Ayrıntıya Git",
            GoToDetailButtonTextDT: "Ayrıntıya Git",
            InviteTextDT: "Davet Et",
            InviteButtonTextDT: "Davet",
            CreateChildElementTextDT: "Alt Element Ekle",
            CreateChildElementButtonTextDT: "Alt Element Ekle",
            TenderNameTextDT: "İhale Adı",
            TenderStatusTextDT: "Durum",
            TenderStartDateDT: "Başlangıç Tarihi",
            TenderEndDateDT: "Bitiş Tarihi",
            LiveListErrorMessage:
              "Bu Duruma Ait İhale Bulunamamıştır. Lütfen İhale Durumuny Değiştiriniz",
            Tamamlanmis: "Tamamlanmış",
            DevamEdiyor: "Devam Ediyor",
            CreatedUserText: "Oluşturan",
            CreaterUserNullText: "Belirtilmemiş"
          }
        })
      : this.setState({
          ConstantValuesOnlineList: {
            ProjectStatusLabelText: "Tender Status",
            ProjectStatusContiunesText: "Contiunes",
            ProjectStatusCompletedText: "Completed",
            ProjectStatusAllText: "All",
            ProjectHeaderText: "Tenders",
            GoToDetailTextDT: "Go To Detail",
            GoToDetailButtonTextDT: "Go To Detail",
            InviteTextDT: "Invite",
            InviteButtonTextDT: "Invite",
            CreateChildElementTextDT: "Create Sub Element",
            CreateChildElementButtonTextDT: "Create Sub Element",
            TenderNameTextDT: "Tender Name",
            TenderStatusTextDT: "Status",
            TenderStartDateDT: "Start Date",
            TenderEndDateDT: "End Date",
            LiveListErrorMessage:
              "There is no tender for this situation. Please Change Tender Status",
            Tamamlanmis: "Completed",
            DevamEdiyor: "Contiunes",
            CreatedUserText: "Creater",
            CreaterUserNullText: "Unspecified"
          }
        });
  }
  getBidProcedureProjectListForIzocam() {
    _getLiveProjectListForIzocam(
      this.state.LanguageId,
      this.state.ProjectStatus
    ).then(response => {
      this.setState({
        liveProjectList: response.data.data,
        message: response.data.message
      });
      console.log(response.data.data);
    });
  }
  statuChange() {}
  render() {
    const data = {
      columns: [
        {
          label: this.state.ConstantValuesOnlineList.GoToDetailTextDT,
          field: "detail",
          sort: "asc",
          width: 150
        },
        {
          label: this.state.ConstantValuesOnlineList.InviteTextDT,
          field: "invite",
          sort: "asc",
          width: 270
        },
        {
          label: this.state.ConstantValuesOnlineList.CreateChildElementTextDT,
          field: "childElement",
          sort: "asc",
          width: 200
        },
        {
          label: this.state.ConstantValuesOnlineList.TenderNameTextDT,
          field: "name",
          sort: "asc",
          width: 200
        },
        {
          label: this.state.ConstantValuesOnlineList.TenderStartDateDT,
          field: "startDate",
          sort: "asc",
          width: 100
        },
        {
          label: this.state.ConstantValuesOnlineList.TenderEndDateDT,
          field: "endDate",
          sort: "asc",
          width: 100
        },
        {
          label: "İhale no",
          field: "projectNumber",
          sort: "asc",
          width: 100
        },
        {
          label: "Sap No",
          field: "sapNumber",
          sort: "asc",
          width: 100
        },
        {
          label: this.state.ConstantValuesOnlineList.TenderStatusTextDT,
          field: "status",
          sort: "asc",
          width: 100
        },
        {
          label: this.state.ConstantValuesOnlineList.CreatedUserText,
          field: "createdUser",
          sort: "asc",
          width: 100
        }
      ],
      rows:
        this.state.liveProjectList !== null &&
        this.state.liveProjectList !== undefined &&
        this.state.liveProjectList.length > 0
          ? this.state.liveProjectList.map(data => {
              return {
                detail: (
                  <Link
                    className="btn  btn-warning"
                    to={`/bid-procedure-project-detail/${data.projectId}/projectType/${this.state.projectTypeId}`}
                    key="id"
                  >
                    {this.state.ConstantValuesOnlineList.GoToDetailButtonTextDT}
                  </Link>
                ),
                invite: (
                  <Link
                    className="btn  btn-warning"
                    to={`project-invitation/${data.projectId}/projectType/${this.state.projectTypeId}`}
                    key={data.id}
                  >
                    {this.state.ConstantValuesOnlineList.InviteButtonTextDT}
                  </Link>
                ),
                childElement: (
                  <Link
                    className="btn  btn-warning"
                    to={`create-project-alt-element/${data.projectId}/projectType/${this.state.projectTypeId}`}
                    key={data.id}
                  >
                    {
                      this.state.ConstantValuesOnlineList
                        .CreateChildElementButtonTextDT
                    }
                  </Link>
                ),
                name: data.name,
                startDate: (
                  <Moment format="YYYY-MM-DD HH:mm">{data.startDate}</Moment>
                ),
                endDate: (
                  <Moment format="YYYY-MM-DD HH:mm">{data.endDate}</Moment>
                ),
                projectNumber : data.projectNumber,
                sapNumber:data.sapNumber,
                status:
                  data.status === true
                    ? this.state.ConstantValuesOnlineList.Tamamlanmis
                    : this.state.ConstantValuesOnlineList.DevamEdiyor,
                createdUser:
                  data.createdUser === "" ||
                  data.createdUser === null ||
                  data.createdUser === undefined
                    ? this.state.ConstantValuesOnlineList.CreaterUserNullText
                    : data.createdUser
              };
            })
          : this.state.ConstantValuesOnlineList.LiveListErrorMessage
    };

    return (
      <div className="row">
        <div className="col-md-12">
          <div className="block full">
            <div className="form-group">
              <div className="col-md-3">
                <label
                  className="col-md-3 control-label"
                  htmlFor="example-text-input"
                >
                  {this.state.ConstantValuesOnlineList.ProjectStatusLabelText}
                </label>
              </div>
              <div className="col-md-9">
                <select
                  className="form-form-control-lg"
                  id="ProjectStatus"
                  name="ProjectStatus"
                  onChange={this.handleChange}
                  value={this.state.value}
                >
                  <option value="1">
                    {
                      this.state.ConstantValuesOnlineList
                        .ProjectStatusContiunesText
                    }
                  </option>
                  <option value="2">
                    {
                      this.state.ConstantValuesOnlineList
                        .ProjectStatusCompletedText
                    }
                  </option>
                  <option value="3">
                    {this.state.ConstantValuesOnlineList.ProjectStatusAllText}
                  </option>
                </select>
              </div>
            </div>
            <div className="block-title">
              <h2>
                <strong>
                  {this.state.ConstantValuesOnlineList.ProjectHeaderText}
                </strong>
              </h2>
            </div>

            <div className="table-responsive">
              {this.state.message === null ? (
                <MDBDataTable striped hover data={data} />
              ) : (
                <p>{this.state.message}</p>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
