import React, { Component } from "react";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import swal from "sweetalert2";
import { _ProjectClone } from "../../../../api";

export default class ProjectClone extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ProjectId: this.props.projectId,
      LanguageId: localStorage.getItem("LanguageId"),
      ProjectType: this.props.projectType,
      StartDate: 0,
      EndDate: 0,
      Termin: 0,
      unionDate: "",
      isChecked: false,
      MemberId:localStorage.getItem("UserId")
    };
    this.handleChangeDateStart = this.handleChangeDateStart.bind(this);
    this.handleChangeEndStart = this.handleChangeEndStart.bind(this);
    this.handleChangeTermin = this.handleChangeTermin.bind(this);
    this.ClonedProject = this.ClonedProject.bind(this);
    this.validationStart = this.validationStart.bind(this);
    this.validationEnd = this.validationEnd.bind(this);
    this.handleChecked = this.handleChecked.bind(this);
  }

  componentDidMount() {
    //console.log(this.state.ProjectType);
    this.formatDate();
  }
  formatDate() {
    var x = new Date();
    var dd = x.getDate();
    var mm = x.getMonth() + 1;
    var yy = x.getFullYear();
    var unionDate = yy + "-" + mm + "-" + dd;
    this.setState({ unionDate: unionDate });
  }
  handleChangeDateStart = date => {
    this.setState({
      StartDate: date._d
    });
  };
  handleChangeEndStart = date => {
    this.setState({
      EndDate: date._d
    });
  };
  handleChangeTermin = date => {
    this.setState({
      Termin: date._d
    });
  };
  ClonedProject() {
    if (
      this.state.StartDate !== 0 &&
      this.state.EndDate !== 0 &&
      this.state.Termin !== 0
    ) {
      const obj = {
        ProjectId: this.state.ProjectId,
        LanguageId: this.state.LanguageId,
        StartDate: this.state.StartDate,
        EndDate: this.state.EndDate,
        UnionDate: this.state.unionDate,
        Termin: this.state.Termin,
        IsChecked:this.state.isChecked,
        MemberId:this.state.MemberId
      };
      _ProjectClone(obj).then(response => {
        swal(response.data.message);

        window.location.href = `/bid-procedure-project-detail/${response.data.projectId}/projectType/${this.state.ProjectType}`;
      });
    } else {
      this.state.LanguageId === "1"
        ? swal("Lütfen Tarih Aralıklarını Doğru Giriniz")
        : swal("Please Enter Date Ranges Correctly");
    }
  }
  validationStart = currentDate => {
    var d = new Date();
    return currentDate.isAfter(d.setDate(d.getDate() - 1));
    //currentDate.isAfter(addDays(new Date(),1))
  };
  validationEnd = currentDate => {
    var d = new Date();
    return currentDate.isAfter(d.setDate(d.getDate() - 1));
    //currentDate.isAfter(addDays(new Date(),1))
  };

  handleChecked () {
    this.setState({isChecked: !this.state.isChecked});
  }
  render() {
    return (
      <div>
        <fieldset>
          <legend>Tarih Aralığı Seçiniz</legend>

          <div className="form-group">
            <div className="col-md-3">
              <label className="control-label" htmlFor="example-text-input">
                İhale başlangıç Tarihi
              </label>
            </div>
            <div className="col-md-9">
              <Datetime
                className="form-control"
                value={this.state.StartDate}
                onChange={this.handleChangeDateStart}
                isValidDate={this.validationStart}
              />
            </div>
          </div>
          <div className="form-group" style={{ marginTop: "5px" }}>
            <div className="col-md-3">
              <label className="control-label">İhale Bitiş Tarihi</label>
            </div>
            <div className="col-md-9">
              <Datetime
                className="form-control"
                value={this.state.EndDate}
                onChange={this.handleChangeEndStart}
                isValidDate={this.validationEnd}
              />
            </div>
          </div>
          <div className="form-group" style={{ marginTop: "5px" }}>
            <div className="col-md-3">
              <label className="control-label">Alt Element Termin</label>
            </div>
            <div className="col-md-9">
              <Datetime
                className="form-control"
                value={this.state.Termin}
                onChange={this.handleChangeTermin}
                isValidDate={this.validationStart}
              />
            </div>
          </div>
          <div className="form-group" style={{ marginTop: "20px",paddingTop:"10px" }}>
            <div className="col-md-3">
              <label className="control-label">
                Davetli Firmaları Dahil Et
              </label>
            </div>
            <div className="col-md-9">
              <input type="checkbox" onChange={this.handleChecked} className="btn-group-sm"/>
            </div>
          </div>
          <div className="form-group">
            <div className="col-md-3">
              <button className="btn btn-warning" onClick={this.ClonedProject}>
                Klonla
              </button>
            </div>
          </div>
        </fieldset>
      </div>
    );
  }
}
