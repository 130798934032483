import React, { Component } from "react";
import {
  _getProjectName,
  _createChildElement,
  _getChildelementListForProjectWithById,
  _PaymentMethod,
  _ProductionAres,
  _Materials
} from "../../../../../api";
import swal from "sweetalert2";
import { Link } from "react-router-dom";
// import ChildElementList from "../../projectChildElementList/ChildElementList";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { MDBDataTable } from "mdbreact";
import Moment from "react-moment";
import Select from 'react-select';
export default class CreateComp extends Component {
   
  constructor(props) {
    super(props);
    this.state = {
      projectId: this.props.id,
      projectType: this.props.projectType,
      ProjectName: "",
      MalzemeTanim_TR: "",
      MalzemeTanim_EN: "",
      DeliveryMethod_TR: "",
      DeliveryMethod_EN: "",
      PayMethod_TR: "",
      PayMethod_EN: "",
      Valor_TR: "",
      Valor_EN: "",
      Miktar: 1,
      Termin: "",
      Birim_TR: "",
      Birim_EN: "",
      Birim: "",
      productionAres: 0,
      material: "",
      PaymentCode:"",
      MaterialUnitCode:"",
      Birim:"",
      materias: [],
      paymentOptions: [],
      productionAresOptions: [],
      materialOptions: [],
      LanguageId: localStorage.getItem("LanguageId"),
      date: new Date(),
      productionAres:"",
      Peinh:"",
      ConstantValueOfCreateSub: {
        HeadText: "",
        BackButtonText: "",
        UpdateButtonText: "",
        MiktarText: "",
        MiktarPlaceHolder: "",
        BirimText: "",
        BirimSelectText: "",
        TerminText: "",
        DetailHeadText: "",
        TurkceText: "",
        IngilizceText: "",
        MalzemeTanimText: "",
        TeslimSekliText: "",
        OdemeSekliText: "",
        ValorText: "",
        CreateButtonText: "",
        ListHeadText: "",
        UpdateTextDT: "",
        ListCountZeroErrorMessage:"",
        UretimYeri:"",
        Malzeme:"",
        PeinhPlaceHolder:"",
        PeinhText:"",
      }
    };

    this.handleChange = this.handleChange.bind(this);
    this.createChildElement = this.createChildElement.bind(this);
    // this.getComponent = this.getComponent.bind(this);
    this.handleChangeDateStart = this.handleChangeDateStart.bind(this);
    this.ConstantValueOfCreateSubComponent = this.ConstantValueOfCreateSubComponent.bind(this);
    this.productionAres=this.productionAres.bind(this);
    this.paymentMethod=this.paymentMethod.bind(this);
    this.materials=this.materials.bind(this);
  }
  
  ConstantValueOfCreateSubComponent() {
    this.state.LanguageId === "1"
      ? this.setState({
          ConstantValueOfCreateSub: {
            HeadText: "Alt Element Oluşturma",
            BackButtonText: "Geri",
            UpdateButtonText: "Güncelle",
            MiktarText: "Miktar",
            MiktarPlaceHolder: "Miktar Giriniz",
            BirimText: "Birim",
            BirimSelectText: "Seçiniz",
            TerminText: "Termin",
            DetailHeadText: "Alt Element Detay Bilgiler",
            TurkceText: "Türkçe",
            IngilizceText: "İngilizce",
            MalzemeTanimText: "Malzeme Tanım",
            TeslimSekliText: "Teslim Şekli",
            OdemeSekliText: "Ödeme Şekli",
            ValorText: "Valör",
            CreateButtonText: "Ekle",
            ListHeadText: "Alt Element Listesi",
            ListCountZeroErrorMessage:"Listelenecek Alt Element Bilgisi Bulunamamıştır",
            UretimYeri:"Üretim Yeri",
            Malzeme:"Malzeme",
            PeinhPlaceHolder:"Miktar Birimi",
            PeinhText:"Miktar Birimi",
          }
        })
      : this.setState({
          ConstantValueOfCreateSub: {
            HeadText: "Creating a child element",
            BackButtonText: "Back",
            UpdateButtonText: "Update",
            MiktarText: "Quantity",
            MiktarPlaceHolder: "Enter Quantity",
            BirimText: "Unit",
            BirimSelectText: "Select",
            TerminText: "Termin",
            DetailHeadText: "Child Element Detail Information",
            TurkceText: "Turkish",
            IngilizceText: "English",
            MalzemeTanimText: "Material Defination",
            TeslimSekliText: "Delievery Method",
            OdemeSekliText: "Pay Method",
            ValorText: "Valor",
            CreateButtonText: "Crate",
            ListHeadText: "Child Element List",
            ListCountZeroErrorMessage:"No Sub-Element Information To Be Listed Found",
            UretimYeri:"Production Area",
            Malzeme:"Material",
            PeinhPlaceHolder:"Quantity Unit",
            PeinhText:"Quantity Unit",
          }
        });
  }

  handleChange(e) {
    let change = {};
    change[e.target.name] = e.target.value;
    this.setState(change);
   // console.log(e.target.name + ": " + e.target.value);

    if (e.target.name === "MalzemeTanim_TR") {
      this.setState({ MalzemeTanim_EN: e.target.value });
     
      
    }
    if (e.target.name === "DeliveryMethod_TR") {
      var index = e.nativeEvent.target.selectedIndex;
      this.setState({ DeliveryMethod_EN: e.nativeEvent.target[index].text });
      this.setState({ DeliveryMethod_EN:  e.target.value});
    }
    if (e.target.name === "PayMethod_TR") {
      var index = e.nativeEvent.target.selectedIndex;
      this.setState({PaymentCode: e.target.value});
      this.setState({ PayMethod_TR: e.nativeEvent.target[index].text });
      this.setState({ PayMethod_EN: e.nativeEvent.target[index].text });
      this.setState({ PayMethod_EN: e.target.value });
    }
    if (e.target.name === "Valor_TR") {
      var index = e.nativeEvent.target.selectedIndex;
      this.setState({ Valor_TR:  e.nativeEvent.target[index].text });
      this.setState({ Valor_EN:  e.target.value});
     
    }
    if(e.target.name=="material")
    {
      this.setState({material:e.target.value})
      var index = e.nativeEvent.target.selectedIndex;
      this.setState({MalzemeTanim_TR: e.nativeEvent.target[index].text});
       this.setState({MalzemeTanim_EN: e.nativeEvent.target[index].text});  
      this.setState({ Birim:this.state.materias.find(x=>x.key==e.target.value).value });
    }
    if(e.target.name=="productionAres")
    {
      // this.setState({ DeliveryMethod_TR: e.target.value });
      // this.setState({ DeliveryMethod_EN: e.target.value });
      this.setState({productionAres:e.target.value})
       this.materials(e.target.value)
    }
    
  }
  onChange = date => this.setState({ date });
  componentDidMount() {
    this.paymentMethod();
    this.productionAres();
    this.getProjectName();
    this.getChildelementListForProjectWithById();
    this.ConstantValueOfCreateSubComponent(); 
  }
  getProjectName() {
    const id = this.state.projectId;
    _getProjectName(id).then(response => {
      this.setState({
        ProjectName:
          response.data.data === undefined ? null : response.data.data
      });
    });
  }
 
  createChildElement(event) {
    const obj = {
      ProjectId: this.state.projectId,
      MalzemeTanim_TR: this.state.MalzemeTanim_TR,
      MalzemeTanim_EN: this.state.MalzemeTanim_EN,
      DeliveryMethod_TR: this.state.DeliveryMethod_TR,
      DeliveryMethod_EN: this.state.DeliveryMethod_EN,
      PayMethod_TR: this.state.PayMethod_TR,
      PayMethod_EN: this.state.PayMethod_TR,
      Valor_TR: '',
      Valor_EN: '',
      Miktar: this.state.Miktar,
      Termin: this.state.date,
      Birim_TR: this.state.Birim,
      Birim_EN: this.state.Birim,
      LanguageId: this.state.LanguageId,
      MaterialId :this.state.material,
      MaterialUnitCode :this.state.MaterialUnitCode,
      PaymentCode :this.state.PaymentCode,
      ProductAreaCode :this.state.productionAres,
      Peinh:this.state.Peinh
    };
    console.log('model = '+JSON.stringify(obj));
    _createChildElement(obj).then(response => {
      swal(response.data.message);
      document.getElementById("MalzemeTanim_TR").value = "";
      document.getElementById("MalzemeTanim_EN").value = "";
      this.getChildelementListForProjectWithById();
    });
  //  console.log(obj);
  }
  validation = currentDate => {
    var d = new Date();
    return currentDate.isAfter(d.setDate(d.getDate() - 1));
    //currentDate.isAfter(addDays(new Date(),1))
  };

  // getComponent() {
  //   return <ChildElementList id={this.state.projectId} />;
  // }
  handleChangeDateStart = date => {
    this.setState({
      date: date._d
    });
    //console.log(date._d);
  };

  getChildelementListForProjectWithById() {
    const id = this.state.projectId;
    _getChildelementListForProjectWithById(id, this.state.LanguageId).then(
      response => {
        //console.log(response.data.data);
        this.setState({
          childElementListForProject:
            response.data.data === undefined ? null : response.data.data,
          message: response.data.message
        });
      }
    );
  }
  /* -------------naci olut---------------------      */
  paymentMethod()
  {
    _PaymentMethod(this.state.LanguageId).then(res=>{
      const optionsData = res.data.data.map(d => ({
        "value" : d.zterm,
        "label" : d.text1
      }))
    this.setState({paymentOptions:optionsData});
    })
  }
  productionAres()
  {
    _ProductionAres(this.state.LanguageId).then(res=>{     
      const optionsData = res.data.data.map(d => ({
        "value" : d.name,
        "label" : d.name
      }))
      this.setState({productionAresOptions:optionsData});
    })
  }
  handleChangeMaterial = (selectedOption) => {
    this.setState({material: selectedOption.value });
    this.setState({MalzemeTanim_TR: selectedOption.label});
       this.setState({MalzemeTanim_EN: selectedOption.label});  
      this.setState({ Birim:this.state.materias.find(x=>x.key==selectedOption.value).value });
    console.log(`Option selected:`, selectedOption);
  }
  materials(productionAreCode)
  { 
    this.setState({materialOptions:[]});
    if(productionAreCode !="Birimsiz")
    {
      _Materials(this.state.LanguageId,productionAreCode).then(res=>{
        console.log(res);
        const optionsData = res.data.data.map(d =>
        ({
          "value" : d.id,
          "label" : d.name
        }))
        let materialsOptions=optionsData.map(v=>(
          // <option value={v.value}>{v.label}</option>
          {value:v.value,label:v.label}
        ));
        this.setState({materialOptions:materialsOptions});
        const materialData = res.data.data.map(d => ({
          "key" : d.id,
          "value" : d.meins
        }));
        this.setState({materias:materialData});
      })
    }
   
  }
    /* -------------naci olut---------------------      */
  render() {
   
    let paymentOptions=this.state.paymentOptions.map(v=>(
      <option value={v.value}>{v.label}</option>
    ));
    let productionAresOptions = this.state.productionAresOptions.map(v => (
      <option value={v.value}>{v.label}</option>
    ));
    
    
    const dataChilList = {
      columns: [
        {
          label: this.state.ConstantValueOfCreateSub.UpdateTextDT,
          field: "update",
          sort: "asc",
          width: 150
        },
        {
          label: this.state.ConstantValueOfCreateSub.MalzemeTanimText,
          field: "malzemeTanim",
          sort: "asc",
          width: 150
        },
        {
          label: this.state.ConstantValueOfCreateSub.MiktarText,
          field: "miktar",
          sort: "asc",
          width: 270
        },
        {
          label: this.state.ConstantValueOfCreateSub.PeinhText,
          field: "peinh",
          sort: "asc",
          width: 270
        },
        {
          label: this.state.ConstantValueOfCreateSub.BirimText,
          field: "birim",
          sort: "asc",
          width: 200
        },
        {
          label: this.state.ConstantValueOfCreateSub.TerminText,
          field: "termin",
          sort: "asc",
          width: 200
        },
        {
          label: this.state.ConstantValueOfCreateSub.TeslimSekliText,
          field: "deliveryMethod",
          sort: "asc",
          width: 100
        },
        {
          label: this.state.ConstantValueOfCreateSub.OdemeSekliText,
          field: "payMethod",
          sort: "asc",
          width: 100
        }
        // {
        //   label: this.state.ConstantValueOfCreateSub.ValorText,
        //   field: "valor",
        //   sort: "asc",
        //   width: 100
        // }
      ],
      rows:
        this.state.childElementListForProject !== null &&
        this.state.childElementListForProject !== undefined
          ? this.state.childElementListForProject.map(data => {
              return {
                update: (
                  <Link
                    to={`/edit-childElement/${data.projectAltElementId}`}
                    key={data.projectAltElementId}
                    className="btn btn-warning"
                  >
                    {this.state.ConstantValueOfCreateSub.UpdateButtonText}
                  </Link>
                ),
                malzemeTanim: data.malzemeTanim,
                miktar: data.miktar,
                peinh: data.peinh === null ||data.peinh === undefined ? "Belirtilmemiş": data.peinh,
                birim: data.birim,
                termin: (
                  <Moment format="YYYY-MM-DD HH:mm">{data.termin}</Moment>
                ),
                deliveryMethod: data.deliveryMethod,
                payMethod: data.payMethod
                //valor: data.valor
              };
            })
          : this.state.ConstantValueOfCreateSub.ListCountZeroErrorMessage
    };
    const { selectedOption } = this.state;
    return (
      <div className="page-container">
        <div className="row">
          <div className="col-md-12">
            <div className="block">
              <div className="block-title">
                <h2>
                  <strong>{this.state.ConstantValueOfCreateSub.HeadText}</strong>
                </h2>
                <ul className="sidebar-nav ml-3" style={{ width: "10%" }}>
                  <li className="nav-item d-block">
                    {this.state.projectType === "1" ? (
                      <Link
                        to="/project-list-for-izocam"
                        className="btn btn-info"
                      >
                       {this.state.ConstantValueOfCreateSub.BackButtonText}
                      </Link>
                    ) : (
                      <Link
                        to="/live-project-list-for-izocam"
                        className="btn btn-info"
                      >
                        {this.state.ConstantValueOfCreateSub.BackButtonText}
                      </Link>
                    )}
                  </li>
                  <li className="nav-item d-block  mt-2">
                    <Link
                      className="btn btn-info"
                      to={`/project-update/${this.state.projectId}/projectType/${this.state.projectType}`}
                    >
                     {this.state.ConstantValueOfCreateSub.UpdateButtonText}
                    </Link>
                  </li>
                </ul>
                <form className="form-horizontal form-bordered needs-validation">
                  <fieldset>
                    <legend className="ml-3">
                      <b>{this.state.ProjectName}</b>
                    </legend>
                    <div className="col-md-8">
                    <div className="form-group">
                        <label
                          className="col-md-3 control-label"
                          htmlFor="example-text-input"
                        >
                          {this.state.ConstantValueOfCreateSub.UretimYeri}
                        </label>
                        <div className="col-md-9">
                        <select
                              id="productionAres"
                              name="productionAres"
                              className="form-control"
                              placeholder="Üretim Yerleri"
                              onChange={this.handleChange }
                              value={this.state.value}
                              required
                             
                            >
                              <option value="Birimsiz" selected>
                                {this.state.ConstantValueOfCreateSub.BirimSelectText}
                              </option>
                              {productionAresOptions}
                            </select>
                        </div>
                      </div>
                    <div className="form-group">
                        <label
                          className="col-md-3 control-label"
                          htmlFor="example-text-input"
                        >
                          {this.state.ConstantValueOfCreateSub.Malzeme}
                        </label>
                        <div className="col-md-9">
                        <Select
                        id="material"
                        name="material"
                        className="form-control"
                        value={selectedOption}
                        onChange={this.handleChangeMaterial}
                        options={this.state.materialOptions}
      />
                        {/* <select
                              id="material"
                              name="material"
                              className="form-control"
                              placeholder="Malzemeler"
                              onChange={this.handleChange }
                              value={this.state.value}
                              required
                            > 
                             <option value="Birimsiz" selected>
                                {this.state.ConstantValueOfCreateSub.BirimSelectText}
                              </option>
                               {this.state.materialOptions}
                            </select> */}
                        </div>
                      </div>
                      <div className="form-group">
                        <label
                          className="col-md-3 control-label"
                          htmlFor="example-text-input"
                        >
                          {this.state.ConstantValueOfCreateSub.MiktarText}
                        </label>
                        <div className="col-md-9">
                          <input
                            type="number"
                            id="Miktar"
                            name="Miktar"
                            className="form-control"
                            placeholder={this.state.ConstantValueOfCreateSub.MiktarPlaceHolder}
                            onChange={this.handleChange}
                            value={this.state.value}
                            required
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label
                          className="col-md-3 control-label"
                          htmlFor="example-text-input"
                        >
                          {this.state.ConstantValueOfCreateSub.PeinhText}
                        </label>
                        <div className="col-md-9">
                          <input
                            type="number"
                            id="Peinh"
                            name="Peinh"
                            className="form-control"
                            min={1}
                            placeholder={this.state.ConstantValueOfCreateSub.PeinhPlaceHolder}
                            onChange={this.handleChange}
                            value={this.state.value}
                            required
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label
                          className="col-md-3 control-label"
                          htmlFor="example-text-input"
                        >
                          {this.state.ConstantValueOfCreateSub.BirimText}
                        </label>
                        <div className="col-md-9">
                          {/* <input
                          type="text"
                          id="Birim"
                          name="Birim"
                          className="form-control"
                          placeholder="Birim"
                          onChange={this.handleChange}
                          value={this.state.value}
                          required
                        /> */}
                          <select
                            id="Birim"
                            name="Birim"
                            className="form-control"
                            placeholder="Birim"
                            onChange={this.handleChange}
                            value={this.state.Birim}
                            required
                          >
                            <option value="Birimsiz" selected>
                              {this.state.ConstantValueOfCreateSub.BirimSelectText}
                            </option>
                            <option value="M3">M3</option>
                            <option value="GR">GR</option>
                            <option value="M2">M2</option>
                            <option value="L">L</option>
                            <option value="PAK">PAK</option>
                            <option value="TKM">TKM</option>
                            <option value="ADT">ADT</option>
                            <option value="KG">KG</option>
                            <option value="M">M</option>
                          </select>
                        </div>
                      </div>
                      <div className="form-group">
                        <label
                          className="col-md-3 control-label"
                          htmlFor="example-text-input"
                        >
                          {this.state.ConstantValueOfCreateSub.TerminText}
                        </label>
                        <div className="col-md-9">
                          {/* <input
                          type="date"
                          id="Termin"
                          name="Termin"
                          className="form-control file"
                          placeholder="Termin"
                          onChange={this.handleChange}
                          value={this.state.value}
                          required
                        /> */}
                          <Datetime
                            id="Termin"
                            name="Termin"
                            selected={this.state.date}
                            className="form-control"
                            placeholder="Date"
                            timeFormat={"HH:mm:ss"}
                            onChange={this.handleChangeDateStart}
                            isValidDate={this.validation}
                          />
                        </div>
                      </div>
                    </div>
                  </fieldset>

                  <fieldset>
                    <legend className="ml-3">
                      <b>{this.state.ConstantValueOfCreateSub.DetailHeadText}</b>
                    </legend>
                    <div className="col-md-6">
                      <fieldset>
                      <legend>{this.state.ConstantValueOfCreateSub.TurkceText}</legend>
                        <div className="form-group">
                          <label
                            className="col-md-3 control-label"
                            htmlFor="example-text-input"
                          >
                            {this.state.ConstantValueOfCreateSub.MalzemeTanimText}
                          </label>
                          <div className="col-md-9">
                            <input
                              type="text"
                              id="MalzemeTanim_TR"
                              name="MalzemeTanim_TR"
                              className="form-control"
                              onChange={this.handleChange}
                              value={this.state.MalzemeTanim_TR}
                              required
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label
                            className="col-md-3 control-label"
                            htmlFor="example-text-input"
                          >
                            {this.state.ConstantValueOfCreateSub.TeslimSekliText}
                          </label>
                          <div className="col-md-9">
                            <select
                              id="DeliveryMethod_TR"
                              name="DeliveryMethod_TR"
                              className="form-control"
                              placeholder="Teslim Şekli"
                              onChange={this.handleChange}
                              value={this.state.DeliveryMethod_TR}
                              required
                            >
                              <option value="Birimsiz" selected>{this.state.ConstantValueOfCreateSub.BirimSelectText}</option>
                                <option value="Ex-Works">Ex-Works</option>
                                <option value="İzocam Dilovası Tesisi Teslim">İzocam Dilovası Tesisi Teslim</option>
                                <option value="İzocam Gebkim Tesisi Teslim">İzocam Gebkim Tesisi Teslim</option>
                                <option value="İzocam Eskişehir Teslimi Teslim">İzocam Eskişehir Teslimi Teslim</option>
                                <option value="İzocam Tarsus Tesisi Teslim">İzocam Tarsus Tesisi Teslim</option>
                                <option value="Genel Müdürlük Merkezi">Genel Müdürlük Merkezi</option>
                            </select>
                          </div>
                        </div>
                        <div className="form-group">
                          <label
                            className="col-md-3 control-label"
                            htmlFor="example-text-input"
                          >
                            {this.state.ConstantValueOfCreateSub.OdemeSekliText}
                          </label>
                          <div className="col-md-9">
                            <select
                              id="PayMethod_TR"
                              name="PayMethod_TR"
                              className="form-control"
                              placeholder="Ödeme Şekli"
                              onChange={this.handleChange}
                              value={this.state.value}
                              required
                            > 
                            <option value="Birimsiz" selected>
                                {this.state.ConstantValueOfCreateSub.BirimSelectText}
                              </option>
                                {paymentOptions}
                            </select>
                          </div>
                        </div>
                        {/* <div className="form-group">
                        <label
                          className="col-md-3 control-label"
                          htmlFor="example-select"
                        >
                          Birim
                        </label>
                        <div className="col-md-9">
                          <input
                            type="text"
                            id="Birim_TR"
                            name="Birim_TR"
                            className="form-control"
                            placeholder="Text"
                            onChange={this.handleChange}
                            value={this.state.value}
                            required
                          />
                        </div>
                      </div> */}
                        {/* <div className="form-group">
                          <label
                            className="col-md-3 control-label"
                            htmlFor="example-text-input"
                          >
                            {this.state.ConstantValueOfCreateSub.ValorText}
                          </label>
                          <div className="col-md-9">
                            <select
                              id="Valor_TR"
                              name="Valor_TR"
                              className="form-control"
                              placeholder="Valör"
                              onChange={this.handleChange}
                              value={this.state.value}
                              required
                            >
                              <option value="Birimsiz" selected>
                                {this.state.ConstantValueOfCreateSub.BirimSelectText}
                              </option>
                                {paymentOptions}
                            </select>
                          </div>
                        </div> */}
                      </fieldset>
                    </div>
                    <div className="col-md-6">
                      <fieldset>
                        <legend>{this.state.ConstantValueOfCreateSub.IngilizceText}</legend>
                        <div className="form-group">
                          <label
                            className="col-md-3 control-label"
                            htmlFor="example-text-input"
                          >
                            {this.state.ConstantValueOfCreateSub.MalzemeTanimText}
                          </label>
                          <div className="col-md-9">
                            <input
                              type="text"
                              id="MalzemeTanim_EN"
                              name="MalzemeTanim_EN"
                              className="form-control"
                              placeholder={this.state.MalzemeTanim_EN}
                              onChange={this.handleChange}
                              value={this.state.MalzemeTanim_EN}
                              required
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label
                            className="col-md-3 control-label"
                            htmlFor="example-text-input"
                          >
                            {this.state.ConstantValueOfCreateSub.TeslimSekliText}
                          </label>
                          <div className="col-md-9">
                            <select
                              id="DeliveryMethod_EN"
                              name="DeliveryMethod_EN"
                              className="form-control"
                              placeholder="Teslim Şekli"
                              onChange={this.handleChange}
                              value={this.state.DeliveryMethod_EN}
                              required
                            >
                                <option value="Birimsiz" selected>
                                {this.state.ConstantValueOfCreateSub.BirimSelectText}
                              </option>
                              <option value="Ex-Works">Ex-Works</option>
                                <option value="İzocam Dilovası Tesisi Teslim">İzocam Dilovası Tesisi Teslim</option>
                                <option value="İzocam Gebkim Tesisi Teslim">İzocam Gebkim Tesisi Teslim</option>
                                <option value="İzocam Eskişehir Teslimi Teslim">İzocam Eskişehir Teslimi Teslim</option>
                                <option value="İzocam Tarsus Tesisi Teslim">İzocam Tarsus Tesisi Teslim</option>
                                <option value="Genel Müdürlük Merkezi">Genel Müdürlük Merkezi</option>
                              {/* {productionAresOptions} */}
                            </select>
                          </div>
                        </div>
                        <div className="form-group">
                          <label
                            className="col-md-3 control-label"
                            htmlFor="example-text-input"
                          >
                            {this.state.ConstantValueOfCreateSub.OdemeSekliText}
                          </label>
                          <div className="col-md-9">
                            <select
                              id="PayMethod_EN"
                              name="PayMethod_EN"
                              className="form-control"
                              placeholder="Ödeme Şekli"
                              onChange={this.handleChange}
                              value={this.state.PayMethod_EN}
                              required
                            >
                              <option value="Birimsiz" selected>
                                {this.state.ConstantValueOfCreateSub.BirimSelectText}
                              </option>
                                  {paymentOptions}
                            </select>
                          </div>
                        </div>
                        {/* <div className="form-group">
                        <label
                          className="col-md-3 control-label"
                          htmlFor="example-select"
                        >
                          Birim
                        </label>
                        <div className="col-md-9">
                          <input
                            type="text"
                            id="Birim_EN"
                            name="Birim_EN"
                            className="form-control"
                            placeholder="Text"
                            onChange={this.handleChange}
                            value={this.state.value}
                            required
                          />
                        </div>
                      </div> */}
                        {/* <div className="form-group">
                          <label
                            className="col-md-3 control-label"
                            htmlFor="example-text-input"
                          >
                            {this.state.ConstantValueOfCreateSub.ValorText}
                          </label>
                          <div className="col-md-9">
                            <select
                              id="Valor_EN"
                              name="Valor_EN"
                              className="form-control"
                              placeholder="Valör"
                              onChange={this.handleChange}
                              value={this.state.Valor_EN}
                              required
                            >
                              <option value="Birimsiz" selected>
                                {this.state.ConstantValueOfCreateSub.BirimSelectText}
                              </option>
                              {paymentOptions}
                            </select>
                          </div>
                        </div> */}
                      </fieldset>
                    </div>
                  </fieldset>

                  <div className="form-group form-actions">
                    <div className="col-md-9 col-md-offset-9">
                      <button
                        type="button"
                        className="btn btn-warning"
                        onClick={this.createChildElement}
                      >
                        <i className="fa fa-plus"></i> {this.state.ConstantValueOfCreateSub.CreateButtonText}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            {/* <ChildElementList id={this.state.projectId} /> */}
            <div className="block full">
              <div className="block-title">
                <h2>
                  <strong>{this.state.ConstantValueOfCreateSub.ListHeadText}</strong>{" "}
                </h2>
              </div>
              <div className="table-responsive">
                <div
                  id="example-datatable_wrapper"
                  className="dataTables_wrapper form-inline no-footer"
                >
                  {this.state.message === null ? (
                    <MDBDataTable striped hover data={dataChilList} />
                  ) : (
                    <p className="alert alert-danger">{this.state.ConstantValueOfCreateSub.ListCountZeroErrorMessage}</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}