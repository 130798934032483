import React, { Component } from "react";
import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import swal from "sweetalert2";
import { _getIzocamUsers } from "../../../api";
export default class IzocamUserList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userList: [],
      LanguageId: localStorage.getItem("LanguageId"),
      message: "",
      status: false,
      ConstantValueIzocamUserListPage: {
        HeadText: "",
        EditTextDT: "",
        MemberTextDT: "",
        EmailTextDT: "",
        StatusTextDT: "",
        ActiveTextDT: "",
        PassiveTextDT: "",
        ListCountZeroErrorMessage: ""
      }
    };
    this.getIzocamUsers = this.getIzocamUsers.bind(this);
    this.ConstantValueIzocamUserList = this.ConstantValueIzocamUserList.bind(
      this
    );
  }

  componentDidMount() {
    this.getIzocamUsers();
    this.ConstantValueIzocamUserList();
  }

  ConstantValueIzocamUserList() {
    this.state.LanguageId === "1"
      ? this.setState({
          ConstantValueIzocamUserListPage: {
            HeadText: "İzocam Kullanıcıları",
            EditTextDT: "Düzenle",
            EditButtonTextDT: "Düzenle",
            MemberTextDT: "Kullanıcı",
            EmailTextDT: "Email",
            StatusTextDT: "Durum",
            ActiveTextDT: "Aktif",
            PassiveTextDT: "Pasif",
            ListCountZeroErrorMessage:
              "Sistemde Kayıtlı Kullanıcı Bulunmamaktadır"
          }
        })
      : this.setState({
          ConstantValueIzocamUserListPage: {
            HeadText: "Izocam Users",
            EditTextDT: "Edit",
            EditButtonTextDT: "Edit",
            MemberTextDT: "User",
            EmailTextDT: "Email",
            StatusTextDT: "Status",
            ActiveTextDT: "Active",
            PassiveTextDT: "Passive",
            ListCountZeroErrorMessage:
              "There are no registered users in the system"
          }
        });
  }
  getIzocamUsers() {
    _getIzocamUsers(this.state.LanguageId).then(response => {
      this.setState({
        userList:
          response.data.data !== null && response.data.data !== undefined
            ? response.data.data
            : [],
        message: response.data.message,
        status:
          response.data.data !== null && response.data.data !== undefined
            ? true
            : false
      });
      console.log(this.state.status);
      console.log(this.state.userList);
    });
  }
  render() {
    const data = {
      columns: [
        {
          label: this.state.ConstantValueIzocamUserListPage.EditTextDT,
          field: "detail",
          sort: "asc",
          width: 150
        },
        {
          label: this.state.ConstantValueIzocamUserListPage.MemberTextDT,
          field: "Name",
          sort: "asc",
          width: 270
        },
        {
          label: this.state.ConstantValueIzocamUserListPage.EmailTextDT,
          field: "email",
          sort: "asc",
          width: 200
        },
        {
          label: this.state.ConstantValueIzocamUserListPage.StatusTextDT,
          field: "isActive",
          sort: "asc",
          width: 200
        }
      ],
      rows:
        this.state.status === true
          ? this.state.userList.map(data => {
              return {
                detail: (
                  <Link
                    // {`edit-company/${data.companyId}`}/izocam-user-update/:id/memberType/:memberTypeId
                    to={`izocam-user-update/${data.memberId}/memberType/${1}`}
                    className="btn btn-warning"
                  >
                    {
                      this.state.ConstantValueIzocamUserListPage
                        .EditButtonTextDT
                    }
                  </Link>
                ),
                Name: data.name,
                email: data.email,
                isActive:
                  data.isActive === true
                    ? this.state.ConstantValueIzocamUserListPage.ActiveTextDT
                    : this.state.ConstantValueIzocamUserListPage.PassiveTextDT
              };
            })
          : this.state.ConstantValueIzocamUserListPage.ListCountZeroErrorMessage
    };
    return (
      <div className="row">
        <div className="col-md-12">
          <div className="block full">
            <div className="block-title">
              <h2>
                <strong>
                  {this.state.ConstantValueIzocamUserListPage.HeadText}
                </strong>{" "}
              </h2>
            </div>

            <div className="table-responsive">
              {this.state.message === null ? (
                <MDBDataTable striped hover data={data} />
              ) : (
                <p className="alert alert-warning">{this.state.ConstantValueIzocamUserListPage.ListCountZeroErrorMessage}</p>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
