import React, { Component } from "react";
import { Link, Route, Redirect, Switch } from "react-router-dom";
import requireAuth from "./requireAuth";
import NavigationBar from "./Common/NavigationBar";
import SideBar from "./Common/SideBar";
import { IntlProvider } from "react-intl";
import { addLocaleData } from "react-intl";
import tr from "react-intl/locale-data/tr";
import Loader from "./Common/Loader";
import DesignGuide from "./Template/DesignGuide";
import AddCompanyUser from "./izocam/user/addCompanyUser";
import AddIzocamUser from "./izocam/user/addIzocamUser";
import AddCompany from "./izocam/company/addCompany";
// import AddProject from './izocam/project/addProject';
import CompanyList from "./izocam/company/companyList";
import ProjectListForIzocam from "./izocam/project/projectListForIzocam/projectListForIzocam";
import ProjectDetail from "./izocam/project/projectDetail/projectDetail";
import CreateChildElement from "./izocam/project/createChildElement/createChildElement";
import BidProcedureForCompany from "./company/bidProcedure/bidProcedureForCompany";
import LiveProjectListForCompany from "./company/liveProject/liveProjectListForCompany";
import LiveProjectDetailForCompany from "./company/liveProject/liveprojectDetailForCompany/liveProjectDetailForCompany";
import BidProcedureProjectDetailForCompany from "./company/bidProcedure/bidProcedureProjectForCompany/bidProcedureProjectDetailForCompany";
import ProjectInvitation from "./izocam/project/projectInvitation/projectInvitation";
import BidProcedureReports from "./izocam/project/reports/bidProcedureReports";
import LiveProjectReports from "./izocam/project/reports/liveProjectReports";
import CreateProject from "./izocam/project/createProject/createProject";
import LiveProjectListForIzocam from "./izocam/project/projectListForIzocam/liveProjectListForIzocam";
import Helper from "./izocam/project/helper";
import ProfilUpdate from "./company/profilUpdate";
import UpdateProject from "./izocam/project/updateProject/updateProject";
import IzocamUserList from "./izocam/user/izocamUserList";
import ManagerMemberUpdate from "./izocam/user/managerMemberUpdate";
import CompanyUserList from "./izocam/user/companyUserList";
import UpdateChilElement from "./izocam/project/projectChildElementList/updateChilElement";
import UpdateCompany from "./izocam/company/updateCompany";
import MaterialList from "./izocam/Material/matrialList";
import MaterialCreate from "./izocam/Material/materialCreate";
import MaterialEdit from "./izocam/Material/materialEdit";
addLocaleData([...tr]);

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyId: localStorage.getItem("CompanyId")
    };
  }
  componentDidMount() {
    document.title = "IZOCAM";
  }
  render() {
    return (
      <IntlProvider locale="tr">
        <div id="page-wrapper">
          <Loader />
          <div
            id="page-container"
            className="sidebar-partial sidebar-visible-lg sidebar-no-animations"
          >
            <SideBar {...this.props} />
            <div id="main-container">
              <NavigationBar />
              <Switch>
                <Route path="design-guide" component={DesignGuide} />
                <Route path="/create-company-user" component={AddCompanyUser} />
                <Route path="/create-izocam-user" component={AddIzocamUser} />
                <Route path="/create-company" component={AddCompany} />
                <Route path="/create-project" component={CreateProject} />
                <Route path="/companies" component={CompanyList} />
                <Route
                  path="/project-list-for-izocam"
                  component={ProjectListForIzocam}
                />
                <Route
                  path="/bid-procedure-project-detail/:id/projectType/:projectTypeId"
                  component={ProjectDetail}
                />
                <Route
                  path="/create-project-alt-element/:id/projectType/:projectTypeId"
                  component={CreateChildElement}
                />
                <Route
                  path="/project-list-for-company"
                  component={BidProcedureForCompany}
                />
                <Route
                  path="/live-project-list-for-company"
                  component={LiveProjectListForCompany}
                />
                <Route
                  path="/go-to-live-procedure-detail/:id"
                  component={LiveProjectDetailForCompany}
                />
                <Route
                  path="/go-to-bid-procedure-detail/:id"
                  component={BidProcedureProjectDetailForCompany}
                />
                <Route
                  path="/project-invitation/:id/projectType/:projectTypeId"
                  component={ProjectInvitation}
                />
                <Route
                  path="/go-to-bid-proc-project-reports/:id/projectType/:projectTypeId"
                  component={BidProcedureReports}
                />
                <Route
                  path="/go-to-live-project-reports/:id/projectType/:projectTypeId"
                  component={LiveProjectReports}
                />
                <Route
                  path="/live-project-list-for-izocam"
                  component={LiveProjectListForIzocam}
                />
                <Route path="/profile-update" component={ProfilUpdate} />
                <Route
                  path="/project-update/:projectId/projectType/:projectTypeId"
                  component={UpdateProject}
                />
                <Route path="/izocam-user" component={IzocamUserList} />
                <Route
                  path="/izocam-user-update/:id/memberType/:memberTypeId"
                  component={ManagerMemberUpdate}
                />
                <Route path="/company-user" component={CompanyUserList} />
                <Route
                  path="/edit-childElement/:childId"
                  component={UpdateChilElement}
                />
                <Route path="/edit-company/:id" component={UpdateCompany} />
                <Route path="/helper" component={Helper} />

                <Route path="/edit-material/:id" component={MaterialEdit} />
                <Route path="/internal-materials" component={MaterialList} />
                <Route path="/internal-materials-create" component={MaterialCreate} />
              

                {this.state.companyId === "1" ? (
                  <Redirect from="/" to="/project-list-for-izocam" />
                ) : (
                  <Redirect from="/" to="/project-list-for-company" />
                )}
             
              </Switch>
            </div>
            <footer className="clearfix"></footer>
          </div>
          <Link to="/" id="to-top">
            <i className="fa fa-angle-double-up"></i>
          </Link>
        </div>
      </IntlProvider>
    );
  }
}

export default requireAuth(Main);
