import React, { Component } from "react";
import { _getChildelementListForProjectWithById } from "../../../../api";
import { MDBDataTable } from "mdbreact";
import Moment from "react-moment";
export default class ChildElementList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projectId: this.props.id,
      childElementListForProject: [],
      LanguageId: localStorage.getItem("LanguageId"),
      message: "",
      ConstantValueLiveChildList: {
        HeadText: "Alt Element Listesi",
        MalzemeTanimTextDT: "Malzeme Tanım",
        MiktarTextDT: "Miktar",
        BirimTextDT: "Birim",
        TerminTextDT: "Termin",
        TeslimSekliTextDT: "Teslim Şekli",
        OdemeSekliTextDT:"Ödeme Şekli",
        ValorTextDT: "Valör",
        ListCountErrorMessage: "Alt Element Bilgisi Bulunmamaktadır",
        PeinhDT:"Miktar Birimi"
      }
    };
  }
  componentDidMount() {
    this.getChildelementListForProjectWithById();
  }

  ConstantValueLiveChild() {
    if(this.state.LanguageId === undefined ||this.state.LanguageId === null){
      this.setState({LanguageId : "1"});
    }
    console.log("Current Language: ",this.state.LanguageId);
    this.state.LanguageId === "1"
      ? this.setState({
          ConstantValueLiveChildList: {
            HeadText: "Alt Element Listesi",
            MalzemeTanimTextDT: "Malzeme Tanım",
            MiktarTextDT: "Miktar",
            BirimTextDT: "Birim",
            TerminTextDT: "Termin",
            TeslimSekliTextDT: "Teslim Şekli",
            OdemeSekliTextDT: "Ödeme Şekli",
            ValorTextDT: "Valör",
            ListCountErrorMessage: "Alt Element Bilgisi Bulunmamaktadır",
            PeinhDT:"Miktar Birimi"
          }
        })
      : this.setState({
          ConstantValueLiveChildList: {
            HeadText: "Child Element List",
            MalzemeTanimTextDT: "Metarial Description",
            MiktarTextDT: "Quantity",
            BirimTextDT: "Unit",
            TerminTextDT: "Termin",
            TeslimSekliTextDT: "Delievery Method",
            OdemeSekliTextDT: "Pay Method",
            ValorTextDT: "Valor",
            ListCountErrorMessage: "No Sub-Element Information Available",
            PeinhDT:"Quantity Unit"
          }
        });
  }
  getChildelementListForProjectWithById() {
    const id = this.state.projectId;
    _getChildelementListForProjectWithById(id, this.state.LanguageId).then(
      response => {
       // console.log(response.data.data);
        this.setState({
          childElementListForProject:
            response.data.data === undefined ? null : response.data.data,
          message: response.data.message
        });
      }
    );
  }

  render() {
 
    const dataChilList = {
      columns: [
        {
          label: this.state.ConstantValueLiveChildList.MalzemeTanimTextDT,
          field: "malzemeTanim",
          sort: "asc",
          width: 150
        },
        {
          label: this.state.ConstantValueLiveChildList.MiktarTextDT,
          field: "miktar",
          sort: "asc",
          width: 200
        },
        {
          label: this.state.ConstantValueLiveChildList.PeinhDT,
          field: "peinh",
          sort: "asc",
          width: 220
        },
        {
          label: this.state.ConstantValueLiveChildList.BirimTextDT,
          field: "birim",
          sort: "asc",
          width: 200
        },
        {
          label: this.state.ConstantValueLiveChildList.TerminTextDT,
          field: "termin",
          sort: "asc",
          width: 200
        },
        {
          label: this.state.ConstantValueLiveChildList.TeslimSekliTextDT,
          field: "deliveryMethod",
          sort: "asc",
          width: 100
        },
        {
          label: this.state.ConstantValueLiveChildList.OdemeSekliTextDT,
          field: "payMethod",
          sort: "asc",
          width: 100
        }
        // {
        //   label: this.state.ConstantValueLiveChildList.ValorTextDT,
        //   field: "valor",
        //   sort: "asc",
        //   width: 100
        // }
      ],
      rows:
        this.state.childElementListForProject !== null &&
        this.state.childElementListForProject !== undefined
          ? this.state.childElementListForProject.map(data => {
           
              return {
                malzemeTanim: data.malzemeTanim,
                miktar: data.miktar,
                peinh:data.peinh,
                birim: data.birim,
                termin: (
                  <Moment format="YYYY-MM-DD HH:mm">{data.termin}</Moment>
                ),
                deliveryMethod: data.deliveryMethod,
                payMethod: data.payMethod
                //valor: data.valor
              };
            })
          : this.state.ConstantValueLiveChildList.ListCountErrorMessage
    };
    return (
      <div className="col-md-12">
        <div className="block full">
          <div className="block-title">
            <h2>
              <strong>{this.state.ConstantValueLiveChildList.HeadText}</strong>{" "}
            </h2>
          </div>
          <div className="table-responsive">
            <div
              id="example-datatable_wrapper"
              className="dataTables_wrapper form-inline no-footer"
            >
              {this.state.message === null ? (
                <MDBDataTable striped hover data={dataChilList} />
              ) : (
                <p className="alert alert-danger">
                  {this.state.ConstantValueLiveChildList.ListCountErrorMessage}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
