import React, { Component } from "react";
import GeneralInformation from "../generalInformation";
import InvitedList from "../invitedList";
import { Link } from "react-router-dom";
export default class ProjectDetailGeneralInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projectId: this.props.id,
      projectType: this.props.projectType,
      HeadText: "",
      BackButtonText: "",
      GeneralInfText: "",
      LanguageId: localStorage.getItem("LanguageId")
    };
  }

  componentDidMount() {
    this.state.LanguageId === "1"
      ? this.setState({ HeadText: "İhale Detayı", BackButtonText: "Geri", GeneralInfText: "İhale Genel Bilgiler" })
      : this.setState({ HeadText: "Tender Detail", BackButtonText: "Back", GeneralInfText: "Tender General Information" });
  }
  render() {
    return (
      <div className="block">
        <div className="block-title">
          {this.state.projectType === "1" ? (
            <Link
              to="/project-list-for-izocam"
              className="btn btn-info ml-2 mb-2 mt-2"
            >
             {this.state.BackButtonText}
            </Link>
          ) : (
            <Link
              to="/live-project-list-for-izocam"
              className="btn btn-info ml-2 mb-2 mt-2"
            >
              {this.state.BackButtonText}
            </Link>
          )}
          <h2>
            <strong>{this.state.HeadText}</strong>{" "}
          </h2>
        </div>

        <form className="form-horizontal form-bordered">
          <fieldset>
            <legend>{this.state.GeneralInfText}</legend>
            <GeneralInformation
              id={this.state.projectId}
              projectType={this.state.projectType}
            />

            <InvitedList id={this.state.projectId} projectType={this.state.projectType}/>
          </fieldset>
        </form>
      </div>
    );
  }
}
