import React, { Component } from "react";
import GeneralInformationForCompany from "./generalInformation/generalInformationForCompany";
import ChildElementForCompany from "./chilElementListForCompany/childElementForCompany";

export default class BidProcedureProjectDetailForCompany extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projectId: this.props.match.params.id
    };
  }
  componentDidMount(){
    console.log(this.state.projectId);
}
  render() {
    return (
    <div id="page-content">
        <div className="row">
            <div className="col-md-12">
                <GeneralInformationForCompany id={this.state.projectId}/>
            </div>
        </div>
        <div className="row">
        <div className="col-md-12">
            <ChildElementForCompany id={this.state.projectId}/>
        </div>
        </div>
    </div>
     ) }
}
