import React, { Component } from 'react';
import { connect } from "react-redux";

class Loader extends Component {

  render() {
    return (
      <div>
        {this.props.loader ?
          <div className="preloader themed-background">
            <h1 className="push-top-bottom text-light text-center"><strong>Izocam</strong>Ihale Portalı</h1>
            <div className="inner">
              <h3 className="text-light visible-lt-ie10"><strong>Izocam...</strong></h3>
              <div className="preloader-spinner hidden-lt-ie10"></div>
            </div>
          </div>
          : null}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    loader: state.elements.loader
  }
};

export default connect(mapStateToProps)(Loader);