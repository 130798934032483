import React, { Component } from 'react'
import LiveProjectGeneralInformation from './liveProjectDetailComp/liveProjectGeneralInformation';
import ChildElementList from '../../../izocam/project/projectChildElementList/ChildElementList';
export default class LiveProjectDetailForCompany extends Component {
    constructor(props) {
        super(props);
        this.state={
            projectId: this.props.match.params.id,
        };
    }
    componentDidMount(){
        //console.log(this.state.projectId);
    }
    render() {
        return (
          <div id="page-content">
            <div className="row">
              <LiveProjectGeneralInformation  id={this.state.projectId} />
            </div>
            {/* Genel Bilgiler Ve Teklif Verme*/}
            <div className="row">
          <ChildElementList id={this.state.projectId} />
            </div> {/*Alt element Listesi*/}
          </div>
        );
    }
}
