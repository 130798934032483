import React, { Component } from "react";
import { Link } from "react-router-dom";
import { _getMyCompanyInvitedBidProcedureProjectList } from "../../../api";
import { MDBDataTable } from "mdbreact";
import Moment from "react-moment";
export default class BidProcedureForCompany extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyId: localStorage.getItem("CompanyId"),
      LanguageId: localStorage.getItem("LanguageId"),
      projectTypeId: 1,
      myCompanyProjectList: [],
      message: "",
      nonMessage:"",
      IhalelerHeadText:"",
      GoToDetailText:"",
      ProjectNameText:"",
      StartDateText:"",
      EndDateText:"",
      StatusText:""
    };
  }
  componentDidMount() {
    if(this.state.LanguageId === "1"){
      this.setState({
        nonMessage: "Davet Edildiğiniz Bir Talep Listesi Bulunmamaktır.",
        IhalelerHeadText: "İhaleler",
        GoToDetailText: "Ayrıntıya Git",
        ProjectNameText: "İhale Adı",
        StartDateText: "Başlangıç Tarihi",
        EndDateText: "Bitiş Tarihi",
        StatusText: "Durum"
      });
    }
    else{
      this.setState({
        nonMessage: "You are not invited to a list of requests.",
        IhalelerHeadText: "Tenders",
        GoToDetailText: "Go To Detail",
        ProjectNameText: "Tender Name",
        StartDateText: "Start Date",
        EndDateText: "End Date",
        StatusText: "Status"
      });
    }
    this.getMyCompanyInvitedBidProcedureProjectList();
  }

  getMyCompanyInvitedBidProcedureProjectList() {
    const obj = {
      CompanyId: this.state.companyId,
      LanguageId: this.state.LanguageId,
      ProjectTypeId: parseInt(this.state.projectTypeId)
    };
    _getMyCompanyInvitedBidProcedureProjectList(obj).then(response => {
      this.setState({
        myCompanyProjectList:
          response.data.data === undefined ? null : response.data.data,
        message: response.data.message
      });
   console.log(response.data);
    });
  }
  render() {
    const data = {
      columns: [
        {
          label: this.state.GoToDetailText,
          field: "detail",
          sort: "asc",
          width: 150
        },
        {
          label: this.state.ProjectNameText,
          field: "name",
          sort: "asc",
          width: 270
        },
        {
          label: this.state.StartDateText,
          field: "startDate",
          sort: "asc",
          width: 200
        },
        {
          label: this.state.EndDateText,
          field: "endDate",
          sort: "asc",
          width: 200
        },
        {
          label: this.state.StatusText,
          field: "status",
          sort: "asc",
          width: 100
        }
      ],
      rows:
        this.state.myCompanyProjectList !== undefined &&
        this.state.myCompanyProjectList !== null
          ? this.state.myCompanyProjectList.map(item => {
              return {
                detail: (
                  <Link
                    to={`/go-to-bid-procedure-detail/${item.projectId}`}
                    className="btn btn-warning"
                  >
                    {this.state.GoToDetailText}
                  </Link>
                ),
                name: item.name,
                startDate: (
                  <Moment format="YYYY/MM/DD HH:mm">{item.startDate}</Moment>
                ),
                endDate: <Moment format="YYYY/MM/DD HH:mm">{item.endDate}</Moment>,
                status: item.status === false ? "Devam Ediyor" : "Tamamlanmış"
              };
            })
          : "No Result No"
    };
    return (
      <div id="page-content">
        <div className="row">
          <div className="col-md-12">
            <div className="block full">
              <div className="block-title">
                <h2>
                  <strong>{this.state.IhalelerHeadText}</strong>{" "}
                </h2>
              </div>
              <div className="table-responsive">
                <div
                  id="example-datatable_wrapper"
                  className="dataTables_wrapper form-inline no-footer"
                >
      
                    {this.state.message === null ? (
                      <MDBDataTable striped hover data={data} />
                    ) : (
                      <p className="alert alert-warning">{this.state.nonMessage}</p>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
