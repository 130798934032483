import React, { Component } from "react";
import { _getMyProfileInformation, _updateProfile } from "../../api";
import swal from "sweetalert2";

export default class ProfilUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profilInformation: [],
      MemberId: localStorage.getItem("UserId"),
      LanguageId: localStorage.getItem("LanguageId"),
      message: "",
      OldPassword:"",
      Password:"",
      TCKN:"",
      TelNumber:"",
      AgainPassword:"",
      OldPasswordControl:"",
      CompanyTitle:"",
      CompanyAddress:"",
      TaxPlace:"",
      TaxNo:"",
      HeadText:"",
      GeneralInformationText:"",
      TelNumberText:"",
      TCKNText:"",
      OldSifreText:"",
      SifreText:"",
      SifreTekrarText:"",
      UpdateButtonText:"",
      TaxNoText:"",
      TaxPlaceText:"",
      TaxNoPlaceHolder:"",
      TaxPlacePlaceHolder:"",
      CompanyAddressText:"",
      CompanyAddressPlaceHolder:"",
      CompanyTitleText:"",
      CompanTitlePlaceHolder:""
    };
    this.handleChange = this.handleChange.bind(this);
    this.onUpdateProfile=this.onUpdateProfile.bind(this);
  }
  handleChange(e) {
    let change = {};
    change[e.target.name] = e.target.value;
    this.setState(change);
   // console.log(e.target.name + ": " + e.target.value);
  }
componentDidMount(){
  this.state.LanguageId === "1"
    ? this.setState({
        HeadText: "Profil Bilgilerini Güncelle",
        GeneralInformationText: "Genel Bilgiler",
        TelNumberText: "Telefon Numarası",
        TCKNText: "TCKN",
        OldSifreText: "Eski Şifre",
        SifreText: "Şifre",
        SifreTekrarText: "Şifre Tekrar",
        UpdateButtonText: "Güncelle",
        TaxNoText:"Vergi Numarası",
        TaxPlaceText:"Vergi Dairesi",
        CompanyAddressText:"Firma Adresi",
        CompanyTitleText:"Firma Ünvan"
      })
    : this.setState({
        HeadText: "Update Profile Information",
        GeneralInformationText: "General Information",
        TelNumberText: "Tel Number",
        TCKNText: "ID",
        OldSifreText: "Old Password",
        SifreText: "Password",
        SifreTekrarText: "Pasword Again",
        UpdateButtonText: "Update",
        TaxNoText:"Tax No",
        TaxPlaceText:"Tax Place",
        CompanyAddressText:"Company Address",
        CompanyTitleText:"Company Title",
      });

    this.getMyProfilInformation();
}
  getMyProfilInformation() {
    _getMyProfileInformation(this.state.MemberId, this.state.LanguageId).then(
      response => {
        this.setState({
          OldPassword:response.data.data.password,
          Password:response.data.data.password,
          TCKN:response.data.data.tckn,
          TelNumber:response.data.data.telNumber,
          message: response.data.message,
          CompanyAddress :response.data.data.companyAddress,
          CompanyTitle : response.data.data.companyTitle,
          TaxPlace :response.data.data.taxPlace,
          TaxNo:response.data.data.taxNo
        });
      // console.log(response.data.data);
      }
    );
  }
  onUpdateProfile(){

     if(String(this.state.OldPassword) === String(this.state.OldPasswordControl)){
          if(this.state.Password === this.state.AgainPassword){
const obj = {
    OldPassword:this.state.OldPassword,
    TCKN:this.state.TCKN,
    Password:this.state.Password,
    TelNumber:this.state.TelNumber,
    MemberId:this.state.MemberId,
    LanguageId:this.state.LanguageId,
    CompanyAddress:this.state.CompanyAddress,
    TaxNo:this.state.TaxNo,
    TaxPlace:this.state.TaxPlace
}

_updateProfile(obj).then(response=>{
swal(response.data.message);
this.getMyProfilInformation();
});
this.props.history.push("/profile-update");

}
else{
    if(this.state.LanguageId ==="1"){
        swal("Şifreler Uyuşmuyor");
    }
    else{
        swal("Passwords do not match");
    }
}
}
else{
    if(this.state.LanguageId ==="1"){
        swal("Eski Şifreniz Doğru değildir.");
    }
    else{
        swal("Your old password is not correct.");
    }

}
  }
  render() {
    return (
      <div className="row">
        <div className="col-md-12">
          <div className="block">
            <div className="block-title">
              <div className="block-options pull-right"></div>
              <h2>
    <strong>{this.state.HeadText}</strong>{" "}
              </h2>
            </div>

            <form
              className="form-horizontal form-bordered"
              name="loginForm"
              onSubmit={this.onCreateCompany}
            >
              <fieldset>
                <legend>{this.state.GeneralInformationText}</legend>
                <div className="col-md-6">
                  <div className="form-group">
                    <label
                      className="col-md-3 control-label"
                      htmlFor="example-text-input"
                    >
                      {this.state.TelNumberText}
                    </label>
                    <div className="col-md-9">
                      <input
                        type="number"
                        id="TelNumber"
                        name="TelNumber"
                        className="form-control"
                        placeholder={this.state.TelNumber}
                        onChange={this.handleChange}
                        value={this.state.value}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      className="col-md-3 control-label"
                      htmlFor="example-text-input"
                    >
                     {this.state.TCKNText}
                    </label>
                    <div className="col-md-9">
                      <input
                        type="text"
                        id="TCKN"
                        name="TCKN"
                        className="form-control"
                        placeholder={this.state.TCKN}
                        onChange={this.handleChange}
                        value={this.state.value}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      className="col-md-3 control-label"
                      htmlFor="example-text-input"
                    >
                    {this.state.OldSifreText}
                    </label>
                    <div className="col-md-9">
                      <input
                        type="text"
                        id="OldPasswordControl"
                        name="OldPasswordControl"
                        className="form-control"
                        placeholder="Text"
                        onChange={this.handleChange}
                        value={this.state.value}
                      />
                    </div>
                  </div>

                  <div className="form-group">
                    <label
                      className="col-md-3 control-label"
                      htmlFor="example-select"
                    >
                      {this.state.SifreText}
                    </label>
                    <div className="col-md-9">
                    <input
                        type="text"
                        id="Password"
                        name="Password"
                        className="form-control"
                        placeholder="password"
                        onChange={this.handleChange}
                        value={this.state.value}
                      />
                    
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      className="col-md-3 control-label"
                      htmlFor="example-select"
                    >
                      {this.state.SifreTekrarText}
                    </label>
                    <div className="col-md-9">
                    <input
                        type="text"
                        id="AgainPassword"
                        name="AgainPassword"
                        className="form-control"
                        placeholder="password"
                        onChange={this.handleChange}
                        value={this.state.value}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      className="col-md-3 control-label"
                      htmlFor="example-select"
                    >
                      {this.state.CompanyAddressText}
                    </label>
                    <div className="col-md-9">
                    <textarea
                        type="text"
                        id="CompanyAddress"
                        name="CompanyAddress"
                        className="form-control"
                        placeholder={this.state.CompanyAddress}
                        onChange={this.handleChange}
                        value={this.state.value}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      className="col-md-3 control-label"
                      htmlFor="example-select"
                    >
                      {this.state.CompanyTitleText}
                    </label>
                    <div className="col-md-9">
                    <input
                        type="text"
                        id="CompanyTitle"
                        name="CompanyTitle"
                        className="form-control"
                        placeholder={this.state.CompanyTitle}
                        onChange={this.handleChange}
                        value={this.state.value}
                        disabled = {true}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      className="col-md-3 control-label"
                      htmlFor="example-select"
                    >
                      {this.state.TaxPlaceText}
                    </label>
                    <div className="col-md-9">
                    <input
                        type="text"
                        id="TaxPlace"
                        name="TaxPlace"
                        className="form-control"
                        placeholder={this.state.TaxPlace}
                        onChange={this.handleChange}
                        value={this.state.value}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      className="col-md-3 control-label"
                      htmlFor="example-select"
                    >
                      {this.state.TaxNoText}
                    </label>
                    <div className="col-md-9">
                    <input
                        type="text"
                        id="TaxNo"
                        name="TaxNo"
                        className="form-control"
                        placeholder={this.state.TaxNo}
                        onChange={this.handleChange}
                        value={this.state.value}
                      />
                    </div>
                  </div>
                </div>
              </fieldset>
              <div className="form-group form-actions">
                <div className="col-md-9 col-md-offset-9">
                  <button
                    type="button"
                    className="btn  btn-warning"
                    id="createCompany"
                    onClick={this.onUpdateProfile}
                  >
                    <i className="fa fa-plus"></i> {this.state.UpdateButtonText}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
