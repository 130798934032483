import React, { Component } from 'react';

export class DesignGuide extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<main className="main">
				<div className="animated">
					<div className="page-heading mb-3">
						<div className="p-4">
							<h2 className="page-title">Figopara Design Guide</h2>
							<div className="text-white-50">
								Figopara platformunda kullanınan html elementleri ve kullanım standartları
							</div>
						</div>
					</div>
					<div className="container-fluid">
						<div className="px-2">
							<div className="bs-docs-section">
								<h1 className="font-weight-lighter">Butonlar</h1>
								<div className="elements">
									<div className="form-group mb-3 row">
										<div className="col-1">
											<button className="btn ml-2 btn-outline-danger mr-3 w-100 ">Sil</button>
										</div>
										<div className="col-11 pt-2">
											<span>Silme, iptal etme veya kaldırma gibi durumlarda kullanılan buton tipidir</span>
										</div>
									</div>
									<div className="form-group mb-3 row">
										<div className="col-1">
											<button className="btn ml-2 btn-info mr-3 w-100 ">Oluştur</button>
										</div>
										<div className="col-11 pt-2">
											<span>Yeni bir içerik oluşturma gibi durumlarda kullanılan buton tipidir</span>
										</div>
									</div>
									<div className="form-group mb-3 row">
										<div className="col-1">
											<button className="btn ml-2 btn-info mr-3 w-100 ">Güncelle</button>
										</div>
										<div className="col-11 pt-2">
											<span>
												İçeriklerde yapılan değişiklerin kaydedilmesi gibi durumlarda kullanılan buton tipidir
											</span>
										</div>
									</div>
									<div className="form-group mb-3 row">
										<div className="col-1">
											<button className="btn ml-2 btn-light mr-3 w-100 ">Filitrele</button>
										</div>
										<div className="col-11 pt-2">
											<span>İçerik arama, filitreleme gibi durumlarda kullanılan buton tipidir</span>
										</div>
									</div>
									<div className="form-group mb-3 row">
										<div className="col-1">
											<button className="btn ml-2 btn-outline-info mr-3 w-100 ">Düzenle</button>
										</div>
										<div className="col-11 pt-2">
											<span>
												Herhangi bir içeriği güncellemesinden sonra yapılan değişikliği kaydetmek için
												kullanılan buton tipidir
											</span>
										</div>
									</div>
									<div className="form-group mb-3 row">
										<div className="col-1">
											<button className="btn ml-2 btn-outline-secondary mr-3 w-100 ">Görüntüle</button>
										</div>
										<div className="col-11 pt-2">
											<span>Veri görüntüleme ve detay gibi durumlarda kullanılan buton tipidir</span>
										</div>
									</div>

									<div className="form-group mb-3 row">
										<div className="col-1">
											<button className="btn ml-2 btn-secondary mr-3 w-100 ">İndir </button>
										</div>
										<div className="col-11 pt-2">
											<span>Dosya indirme gibi durumlarda kullanılan buton tipidir</span>
										</div>
									</div>
									<div className="form-group mb-3 row">
										<div className="col-1">
											<button className="btn ml-2 btn-light mr-3 w-100 ">Geri </button>
										</div>
										<div className="col-11 pt-2">
											<span>Bir önceki sayfaya gitme gibi durumlarda kullanılan buton tipidir</span>
										</div>
									</div>

									<h1 className="font-weight-lighter mb-4">Tüm Butonlar</h1>
									<button className="btn ml-2 btn-primary">Button</button>
									<button className="btn ml-2 btn-secondary">Button</button>
									<button className="btn ml-2 btn-success">Button</button>
									<button className="btn ml-2 btn-info">Button</button>
									<button className="btn ml-2 btn-warning">Button</button>
									<button className="btn ml-2 btn-danger">Button</button>
									<button className="btn ml-2 btn-light">Button</button>
									<button className="btn ml-2 btn-dark">Button</button>
									<br />
									<br />
									<button className="btn ml-2 btn-outline-primary">Button</button>
									<button className="btn ml-2 btn-outline-secondary">Button</button>
									<button className="btn ml-2 btn-outline-success">Button</button>
									<button className="btn ml-2 btn-outline-info">Button</button>
									<button className="btn ml-2 btn-outline-warning">Button</button>
									<button className="btn ml-2 btn-outline-danger">Button</button>
									<button className="btn ml-2 btn-outline-light">Button</button>
									<button className="btn ml-2 btn-outline-dark">Button</button>
									<br />
									<br />
									<h1 className="font-weight-lighter mb-4">Buton Boyutları</h1>
									<button className="btn ml-2 btn-outline-primary btn-sm">Button</button>
									<button className="btn ml-2 btn-outline-secondary">Button</button>
									<button className="btn ml-2 btn-outline-success btn-lg">Button</button>
								</div>
							</div>
							<div className="bs-docs-section">
								<h1 className="font-weight-lighter">Satırlar</h1>
								<div className="elements">
									<div className="px-3 pb-3 d-none d-md-block">
										<div className="row align-items-center text-muted">
											<div className="col-3 col-md text-truncate">Label</div>
											<div className="col-3 col-md text-truncate">Label</div>
											<div className="col-3 col-md text-truncate">Label</div>
											<div className="col-3 col-md text-truncate" />
										</div>
									</div>
									<div className="grid-elements">
										<div className="p-3 bg-white rounded mb-3 shadow-sm">
											<div className="row align-items-center">
												<div className="col-12 col-md-3 text-truncate mb-2 mb-md-0">
													<small className="d-block text-muted d-md-none">Unvan</small>
													RUNSCOPE CORP.
												</div>
												<div className="col-12 col-md-3 text-truncate">
													<small className="d-block text-muted d-md-none">VKN/TCKN</small>
													1562592949
												</div>
												<div className="col-6 col-md-3 text-truncate">
													<small className="d-block text-muted d-md-none">Kayıt Tarihi</small>
													2019-07-08
												</div>
												<div className="col-6 col-md-3 text-truncate text-right">
													<button className="btn ml-2 btn-outline-info mx-2">Düzenle</button>
													<button className="btn ml-2 btn-outline-danger mx-2">Sil</button>
												</div>
											</div>
										</div>

										<div className="p-3 bg-white rounded mb-3 shadow-sm">
											<div className="row align-items-center">
												<div className="col-12 col-md-3 text-truncate mb-2 mb-md-0">
													<small className="d-block text-muted d-md-none">Unvan</small>
													RUNSCOPE CORP.
												</div>
												<div className="col-12 col-md-3 text-truncate">
													<small className="d-block text-muted d-md-none">VKN/TCKN</small>
													1562592949
												</div>
												<div className="col-6 col-md-3 text-truncate">
													<small className="d-block text-muted d-md-none">Kayıt Tarihi</small>
													2019-07-08
												</div>
												<div className="col-6 col-md-3 text-truncate text-right">
													<button className="btn ml-2 btn-outline-info mx-2">Düzenle</button>
													<button className="btn ml-2 btn-outline-danger mx-2">Sil</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="bs-docs-section">
								<h1 className="font-weight-lighter">Tipografi</h1>
								<div className="page-heading my-3 mx-0">
									<div className="p-4">
										<h2 className="page-title">Sayfa Başlığı</h2>
										<div className="text-white-50">Sayfa Açıklaması</div>
									</div>
								</div>
								<div className="elements">
									<h3 className="py-3 text-primary">Sayfa İç Başlık</h3>
									<label className="d-block font-weight-bold">İnput label font tipi</label>
									<small className="text-muted d-block">Label Font Tipi</small>
									<p className="mb-3">Satır içi yazı tipi</p>
									<p>
										Climb leg rub face on everything give attitude nap all day for under the bed. Chase mice
										attack feet but rub face on everything hopped up on goofballs. Ipsum available at Cat
										ipsum
									</p>
								</div>
								<h1 className="font-weight-lighter mt-5">Genel font kullanımları</h1>
								<div className="row mt-1">
									<div className="col-lg-4">
										<div className="bs-component">
											<h1>Heading 1</h1>
											<h2>Heading 2</h2>
											<h3>Heading 3</h3>
											<h4>Heading 4</h4>
											<h5>Heading 5</h5>
											<h6>Heading 6</h6>
											<h3>
												Heading<small className="text-muted">with muted text</small>
											</h3>
											<p className="lead">
												Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.
											</p>
										</div>
									</div>
									<div className="col-lg-4">
										<div className="bs-component">
											<h2>Example body text</h2>
											<p>
												Nullam quis risus eget <a href="/">urna mollis ornare</a> vel eu leo. Cum sociis natoque
												penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nullam id dolor id
												nibh ultricies vehicula.
											</p>
											<p>
												<small>This line of text is meant to be treated as fine print.</small>
											</p>
											<p>
												The following is <strong>rendered as bold text</strong>.
											</p>
											<p>
												The following is <em>rendered as italicized text</em>.
											</p>
											<p>
												An abbreviation of the word attribute is <abbr title="attribute">attr</abbr>.
											</p>
										</div>
									</div>
									<div className="col-lg-4">
										<div className="bs-component">
											<h2>Emphasis classes</h2>
											<p className="text-muted">
												Fusce dapibus, tellus ac cursus commodo, tortor mauris nibh.
											</p>
											<p className="text-primary">Nullam id dolor id nibh ultricies vehicula ut id elit.</p>
											<p className="text-secondary">
												Pellentesque ornare sem lacinia quam venenatis vestibulum.
											</p>
											<p className="text-warning">Etiam porta sem malesuada magna mollis euismod.</p>
											<p className="text-danger">Donec ullamcorper nulla non metus auctor fringilla.</p>
											<p className="text-success">
												Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
											</p>
											<p className="text-info">
												Maecenas sed diam eget risus varius blandit sit amet non magna.
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</main>
		);
	}
}

export default DesignGuide;
