import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import faBuilding from "@fortawesome/fontawesome-free-solid/faBuilding";
import { connect } from "react-redux";
import avatar from "../../styles/img/logofooter.png";
import { _getMyPageList } from "../../api";

class SideBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageList: [],
      LanguageId: localStorage.getItem("LanguageId"),
      IhalePanelText: ""
    };
    this.GetMyPageList = this.GetMyPageList.bind(this);
  }

  componentDidMount() {
    this.state.LanguageId === "1"
      ? this.setState({ IhalePanelText: "İhale Paneli" })
      : this.setState({ IhalePanelText: "Tenders Panel" });
    this.GetMyPageList();
  }
  goTo(e) {
    this.props.history.push(e);
  }
  GetMyPageList() {
    _getMyPageList()
      .then(response => {
        this.setState({ pageList: response.data });
        console.log(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  }

  render() {
    var style = {
      float: "left"
    };
    var style2 = {
      marginLeft: "22%"
    };
    return (
      <div>
        <div id="sidebar-alt">
          <div id="sidebar-alt-scroll">
            <div className="sidebar-content"></div>
          </div>
        </div>
        <div id="sidebar">
          <div id="sidebar-scroll">
            <div className="sidebar-content">
              <img style={style2} src={avatar} alt="" />
              <div className="sidebar-section sidebar-user clearfix sidebar-nav-mini-hide">
                <div style={style} className="sidebar-user-name">
                  {this.state.IhalePanelText}
                </div>
              </div>
            </div>
            <ul className="sidebar-nav">
              {this.state.pageList.map(data => (
                <li className="nav-item d-block">
                  <Link
                    className="nav-link d-block px-4 py-2"
                    id={data.slug}
                    key={data.slug}
                    to={"/" + data.slug}
                  >
                    <FontAwesomeIcon
                      className="spinner-grow-sm "
                      icon={faBuilding}
                      fixedWidth
                    />
                    {data.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user
});

export default withRouter(connect(mapStateToProps)(SideBar));
