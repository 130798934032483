import React, { Component } from "react";
import swal from "sweetalert2";
import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import {
    _ProductionAres,
    _accountListWithWerks,
    _materialSet
  } from "../../../api";
export default class MaterialCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
          werksList: [],
          meinsList:[],
          malGrubuList:[],
          Werks:"1101",
          Matkl:"",
          Name:"",
          SystemDescription:"",
          MaterialId:0,
          Meins:"",
          buttonDisabled:false,
          ConstantValues: {
            HeadMaterialsText: "",
            BirimSelectText:"",
            BirimText:"",
            NameText:"",
            NamePlaceHolder:"",
            WerksText:"",
            MatklText:"Hesap",
            createInternalMaterialText:"",
          },
          LanguageId: localStorage.getItem("LanguageId"),
          sapButtonDisable : false,
        };
        this.ConstantValuesThisPage = this.ConstantValuesThisPage.bind(this);
        this.WerksList = this.WerksList.bind(this);
        this.AccountList = this.AccountList.bind(this);
        this.handleChange  = this.handleChange.bind(this);
        this.createInternalMaterial = this.createInternalMaterial.bind(this);
    }

    componentDidMount(){
        this.ConstantValuesThisPage()
        this.WerksList();
        this.AccountList(this.state.Werks);
    }

    ConstantValuesThisPage() {
        if(this.state.LanguageId === "1"){
            this.setState({
                ConstantValues: {
                    HeadMaterialsText: "Malzeme Ekle",
                    BirimSelectText:"Seçiniz",
                    BirimText:"Birim",
                    NameText:"Malzeme Adı",
                    NamePlaceHolder:"Malzeme Adı Giriniz",
                    WerksText:"Üretim Yeri",
                    MatklText:"Hesap",
                    createInternalMaterialText:"Ekle"
                }
              });
        }
        else {
            this.setState({
                ConstantValues: {
                    HeadMaterialsText: "Material Create",
                    BirimSelectText:"Select",
                    BirimText:"Unit",
                    NameText:"Material Name",
                    NamePlaceHolder:"Material Name Enter",
                    WerksText:"Üretim Yeri",
                    MatklText:"Account",
                    createInternalMaterialText:"Ekle"
                }
              });
        }
    }

    WerksList(){
        _ProductionAres(this.state.LanguageId).then(res=>{     
            const optionsData = res.data.data.map(d => ({
              "value" : d.name,
              "label" : d.name
            }));
            this.setState({werksList:optionsData});   
        })
    }

AccountList(werks){
  
    _accountListWithWerks(werks).then(res=>{
        const optionsData2 = res.data.data.data.map(d => ({
          "value" : d.matkl,
          "label" : d.matkl
        }));
        this.setState({malGrubuList:optionsData2});
        console.log("AccountList:",this.state.malGrubuList);
    });
}
handleChange(e) {
    let change = {};
    change[e.target.name] = e.target.value;
    this.setState(change);
    if(e.target.name === "Name"){
        this.setState({SystemDescription:e.target.value});
    }
    if(e.target.name === "Werks"){
        console.log("Werks:",e.target.value);
        this.AccountList(e.target.value)
    }
  }

  cancelCourse = () => { 
    this.myFormRef.reset();
    this.setState({
        meinsList:[],
        malGrubuList:[],
        Werks:"",
        Matkl:"",
        Name:"",
        SystemDescription:"",
        Meins:""});
  }


createInternalMaterial(){
this.setState({buttonDisabled:true});
var status = true;
if(this.state.Werks == "" )
{
  status = false;
}
if(this.state.Meins == ""){
status = false;
}
if(this.state.Matkl == ""){
status = false;
}
if( this.state.Name == ""){
status = false
}


if(status === false){
  swal("Lütfen Bilgileri Eksiksiz Giriniz");
}
else {
  const obj = {
    MaterialId:this.state.MaterialId,
    Name:this.state.Name,
    SystemDescription:this.state.SystemDescription,
    IsSapMaterial:false,
    Meins:this.state.Meins,
    Matkl:this.state.Matkl,
    Maktx:this.state.Name,
    Werks:this.state.Werks
};
this.cancelCourse();
_materialSet(obj).then(res=>{
swal(res.data.message);

   });
}
this.setState({buttonDisabled:false});


  }
    render() {
        let werksOptions = this.state.werksList.map(v => (
            <option value={v.value}>{v.label}</option>
          ));
          let matklOptions = this.state.malGrubuList.map(v => (
            <option value={v.value}>{v.label}</option>
          ));

        return (
            <div className="row">
            <div className="col-md-12">
              <div className="block">
                <div className="block-title">
                  <div className="block-options pull-right"></div>
                  <h2>
                    <strong>{this.state.ConstantValues.HeadMaterialsText}</strong>{" "}
                  </h2>
                </div>
    
                <form ref={(el) => this.myFormRef = el} className="form-horizontal form-bordered needs-validation">

                <div className="form-group">
                    <label
                      className="col-md-3 control-label"
                      htmlFor="example-text-input"
                    >
                      {this.state.ConstantValues.NameText}
                    </label>
                    <div className="col-md-9">
                      <input
                        type="text"
                        id="Name"
                        name="Name"
                        className="form-control"
                        placeholder={
                          this.state.ConstantValues.NamePlaceHolder
                        }
                        onChange={this.handleChange}
                        value={this.state.value}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                        <label
                          className="col-md-3 control-label"
                          htmlFor="example-text-input"
                        >
                          {this.state.ConstantValues.WerksText}
                        </label>
                        <div className="col-md-9">
                         
                          <select
                            id="Werks"
                            name="Werks"
                            className="form-control"
                            placeholder="Werks"
                            onChange={this.handleChange}
                            value={this.state.Werks}
                            required
                          >
                            {/* <option value="ADT" selected>
                              {this.state.ConstantValues.BirimSelectText}
                            </option> */}
                            {werksOptions}
                          </select>
                        </div>
                      </div>
   <div className="form-group">
                        <label
                          className="col-md-3 control-label"
                          htmlFor="example-text-input"
                        >
                          {this.state.ConstantValues.MatklText}
                        </label>
                        <div className="col-md-9">
                         
                          <select
                            id="Matkl"
                            name="Matkl"
                            className="form-control"
                            placeholder="Matkl"
                            onChange={this.handleChange}
                            value={this.state.Matkl}
                            required
                          >
                            {matklOptions}
                          </select>
                        </div>
                      </div>
<div className="form-group">
                        <label
                          className="col-md-3 control-label"
                          htmlFor="example-text-input"
                        >
                          {this.state.ConstantValues.BirimText}
                        </label>
                        <div className="col-md-9">
                         
                          <select
                            id="Meins"
                            name="Meins"
                            className="form-control"
                            placeholder="Meins"
                            onChange={this.handleChange}
                            value={this.state.Meins}
                            required
                          >
                            <option value="ADT" selected>
                              {this.state.ConstantValues.BirimSelectText}
                            </option>
                            <option value="M3">M3</option>
                            <option value="GR">GR</option>
                            <option value="M2">M2</option>
                            <option value="L">L</option>
                            <option value="PAK">PAK</option>
                            <option value="TKM">TKM</option>
                            <option value="ADT">ADT</option>
                            <option value="KG">KG</option>
                            <option value="M">M</option>
                          </select>
                        </div>
                      </div>
                      <div className="form-group form-actions">
                    <div className="col-md-9 col-md-offset-11">
                      <button
                        type="button"
                        className="btn btn-warning"
                        onClick={this.createInternalMaterial}
                        disabled={this.state.buttonDisabled}
                      >
                        <i className="fa fa-plus"></i> {this.state.ConstantValues.createInternalMaterialText}
                      </button>
                    </div>
                  </div>
            </form>
              </div>
            </div>
          </div>  
        );
      }
}