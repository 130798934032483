import React, { Component } from "react";
import {
  _getInvitedListForProject,
  _getIsProjectStart,
  _deleteProjectInvitation
} from "../../../../api";
import { MDBDataTable } from "mdbreact";
import swal from "sweetalert2";
import { Button } from "react-bootstrap";

export default class InvitedList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projectId: this.props.id,
      projectType: this.props.projectType,
      invitedList: [],
      message: "",
      CompanyNameText: "",
      CompanyCodeText: "",
      DeleteText: "",
      DeleteButtonText: "",
      LanguageId: localStorage.getItem("LanguageId"),
      InvitedListHead: "",
      IsProjectStart: false,
      DeletedErrorMessage1: "",
      DeletedErrorMessage2: "",
      SilButtonText: "",
      VazgecButtonText: ""
    };
    this.constantValueSet = this.constantValueSet.bind(this);
    this.DeleteProjectInvitations = this.DeleteProjectInvitations.bind(this);
  }

  componentDidMount() {
    this.getInvitedListForProject();
    this.constantValueSet();
  }

  getInvitedListForProject() {
    const id = this.state.projectId;
    _getInvitedListForProject(id).then(response => {
      this.setState({
        invitedList:
          response.data.data === undefined ? null : response.data.data,
        message: response.data.message
      });
    });

    _getIsProjectStart(id).then(response => {
      //console.log(response)
      this.setState({ IsProjectStart: response.data });
    });
  }

  constantValueSet() {
    this.state.LanguageId === "1"
      ? this.setState({
          CompanyNameText: "Firma Adı",
          CompanyCodeText: "Firma Kodu",
          DeleteText: "Sil",
          DeleteButtonText: "Sil",
          InvitedListHead: "İhaleye Davet Edilenler",
          DeletedErrorMessage1: "Silmek İstediğinize Emin Misiniz?",
          DeletedErrorMessage2:
            "İhale başlamıştır, eğer silerseniz firmanın tüm teklifleride silinecektir. Kabul Ediyor musunuz?",
          SilButtonText: "Sil",
          VazgecButtonText: "Vazgeç"
        })
      : this.setState({
          CompanyNameText: "Company Name",
          CompanyCodeText: "Company Code",
          DeleteText: "Delete",
          DeleteButtonText: "Delete",
          InvitedListHead: "Invited to Tender",
          DeletedErrorMessage1: "Are you sure this item deleted?",
          DeletedErrorMessage2:
            "The tender has started, if you delete all the offers of the company will be deleted. Do you accept?",
          SilButtonText: "Delete",
          VazgecButtonText: "Cancel"
        });
  }

  DeleteProjectInvitations(ex) {
    if (!this.state.IsProjectStart) {
      swal({
        title: "",
        text: this.state.DeletedErrorMessage1,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: this.state.SilButtonText,
        cancelButtonText: this.state.VazgecButtonText,
        closeOnConfirm: false,
        closeOnCancel: false,
        allowOutsideClick: false
      }).then(result => {
        if (result.value) {
          _deleteProjectInvitation(
            this.state.projectId,
            ex,
            this.state.projectType,
            this.state.IsProjectStart,
            this.state.LanguageId
          ).then(response => {
            swal(response.data.message);
            this.getInvitedListForProject();
          });
        }
      });
    } else {
      swal({
        title: "",
        text: this.state.DeletedErrorMessage2,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: this.state.SilButtonText,
        cancelButtonText: this.state.VazgecButtonText,
        closeOnConfirm: false,
        closeOnCancel: false,
        allowOutsideClick: false
      }).then(result => {
        if (result.value) {
          _deleteProjectInvitation(
            this.state.projectId,
            ex,
            this.state.projectType,
            this.state.IsProjectStart,
            this.state.LanguageId
          ).then(response => {
            swal(response.data.message);
            this.getInvitedListForProject();
          });
        }
      });
    }
  }
  render() {
    // var style1 = {
    //   width: "58px"
    // };
    // var style2 = {
    //   width: "89px"
    // };
    const data = {
      columns: [
        {
          label: this.state.CompanyNameText,
          field: "companyName",
          sort: "asc",
          width: 150
        },
        {
          label: this.state.CompanyCodeText,
          field: "companySAPCode",
          sort: "asc",
          width: 270
        },
        {
          label: this.state.DeleteText,
          field: "Delete",
          sort: "asc",
          width: 270
        }
      ],
      rows:
        this.state.invitedList !== null && this.state.invitedList !== undefined
          ? this.state.invitedList.map(data => {
              return {
                companyName: data.companyName,
                companySAPCode: data.companySAPCode,
                Delete: (
                  <Button
                    className="btn btn-danger"
                    id={data.companyId}
                    onClick={() => this.DeleteProjectInvitations(data.companyId)}
                  >
                    {this.state.DeleteButtonText}
                  </Button>
                )
              };
            })
          : "No Result"
    };
    return (
      <div className="col-md-6">
        <fieldset>
          <legend>{this.state.InvitedListHead}</legend>
          <div className="table-responsive">
            <div
              id="example-datatable_wrapper"
              className="dataTables_wrapper form-inline no-footer"
            >
              {this.state.message === null ? (
                <MDBDataTable striped hover data={data} />
              ) : localStorage.getItem("LanguageId") === "1" ? (
                <p className="alert alert-danger">
                  İhaleye şu an davetli firma bulunmamaktadır.
                </p>
              ) : (
                <p className="alert alert-danger">
                  Guest List is currently unavailable
                </p>
              )}
              {/* <MDBDataTable striped hover data={data} /> */}
              {/* <table
                id="example-datatable"
                className="table table-vcenter table-condensed table-bordered dataTable no-footer"
                role="grid"
                aria-describedby="example-datatable_info"
              >
                <thead>
                  <tr role="row">
                    <th className="text-center sorting_asc" style={style1}>
                      Firma Adı
                    </th>
                    <th className="text-center sorting_disabled" style={style2}>
                      SAP KODU
                    </th>

                  </tr>
                </thead>
                <tbody>
                  {
                    (this.state.message == null)?(this.state.invitedList.map(data=>(
                      <tr role="row" className="even">
                      <td className="text-center sorting_1">{data.companyName}</td>
                      <td>{data.companySAPCode}</td>
                    </tr>
                  ))):(<td rowSpan="2">{this.state.message}</td>)
                  }

                </tbody>
              </table> */}
            </div>
          </div>
        </fieldset>
      </div>
    );
  }
}
