import React, { Component } from "react";
import { _getCompanyList, _createCompanyUser } from "../../../api";
import swal from "sweetalert2";
import Select from 'react-select';
export default class AddCompanyUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyList: [],
      Name: "",
      CompanyId: 0,
      TelNumber: "",
      Email: "",
      Password: "",
      Username: "",
      IsActive: "",
      LanguageId: localStorage.getItem("LanguageId"),
      ConstantValues: {
        HeadCreateMemeber: "",
        MemberInformationText: "",
        FullNameText: "",
        FullNamePlaceHolder: "",
        TelNumberText: "",
        TelNumberPlaceHolder: "",
        EmailText: "",
        EmailPlaceHolder: "",
        UsernameText: "",
        UsernamePlaceHolder: "",
        PasswordText: "",
        PasswprdPlaceHolder: "",
        CompanyText: "",
        CompanySelectText: "",
        CreateButtonText: "",
        CreateErrorMessageForCompany: ""
      }
    };
    this.GetCompanyList = this.GetCompanyList.bind(this);
    this.addCompanyUser = this.addCompanyUser.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.ConstantValuesThisPage = this.ConstantValuesThisPage.bind(this);
  }

  componentDidMount() {
    this.GetCompanyList();
    this.ConstantValuesThisPage();
  }

  ConstantValuesThisPage() {
    this.state.LanguageId === "1"
      ? this.setState({
          ConstantValues: {
            HeadCreateMemeber: "Kullanıcı Ekle",
            MemberInformationText: "Kullanıcı Bilgileri",
            FullNameText: "Ad Soyad",
            FullNamePlaceHolder: "Ad Soyad Giriniz",
            TelNumberText: "Telefon Numarası",
            TelNumberPlaceHolder: "Telefon Numarası Giriniz",
            EmailText: "Email",
            EmailPlaceHolder: "Email Giriniz",
            UsernameText: "Kullanıcı Adı",
            UsernamePlaceHolder: "Kullanıcı Adı Giriniz",
            PasswordText: "Şifre",
            PasswprdPlaceHolder: "Şifre Giriniz",
            CompanyText: "Firma",
            CompanySelectText: "Firma Seçiniz",
            CreateButtonText: "Ekle",
            CreateErrorMessageForCompany: "Lütfen Bir Firma Seçiniz"
          }
        })
      : this.setState({
          ConstantValues: {
            HeadCreateMemeber: "Create Member",
            MemberInformationText: "Member Informations",
            FullNameText: "Full Name",
            FullNamePlaceHolder: "Enter Full Name",
            TelNumberText: "Telephone",
            TelNumberPlaceHolder: "Enter Telephone Number",
            EmailText: "Email",
            EmailPlaceHolder: "Enter Email Address",
            UsernameText: "Username",
            UsernamePlaceHolder: "Enter Username",
            PasswordText: "Password",
            PasswprdPlaceHolder: "Enter Password",
            CompanyText: "Company",
            CompanySelectText: "Select Company",
            CreateButtonText: "Create",
            CreateErrorMessageForCompany: "Please Select a Company"
          }
        });
  }
  handleChange(e) {
    let change = {};
    change[e.target.name] = e.target.value;
    this.setState(change);
  }
  addCompanyUser() {
    if (this.state.CompanyId === 0) {
      swal(this.state.ConstantValues.CreateErrorMessageForCompany);
    } else {
      const obj = {
        Name: this.state.Name,
        CompanyId: this.state.CompanyId,
        TelNumber: this.state.TelNumber,
        Email: this.state.Email,
        Password: this.state.Password,
        Username: this.state.Username,
        IsActive: this.state.IsActive,
        LanguageId: this.state.LanguageId
      };

      _createCompanyUser(obj).then(response => {
        swal(response.data.message);
        this.props.history.push("/create-company-user");
      });
    }
  }

  GetCompanyList() {
    _getCompanyList().then(response => {
   console.log("firmalar: ",response);
      const optionsData = response.data.data.map(d => ({
        "value" : d.companyId,
        "label" : d.companyName
      }));
      let companyOptions=optionsData.map(v=>(
        // <option value={v.value}>{v.label}</option>
        {value:v.value,label:v.label}
      ));
      this.setState({ companyList: companyOptions });
    });
  }
  handleChangeCompany = (selectedOption) => {
     this.setState({CompanyId: selectedOption.value });
    console.log(`Option selected:`, selectedOption);
  }
  render() {
    const { selectedOption } = this.state;
    return (
      <div className="row">
        <div className="col-md-12">
          <div className="block">
            <div className="block-title">
              <div className="block-options pull-right"></div>
              <h2>
                <strong>{this.state.ConstantValues.HeadCreateMemeber}</strong>{" "}
              </h2>
            </div>

            <form
              className="form-horizontal form-bordered"
              id="createCompanyUser"
            >
              <fieldset>
                <legend>
                  {this.state.ConstantValues.MemberInformationText}
                </legend>
                <div className="col-md-6">
                  <div className="form-group">
                    <label
                      className="col-md-3 control-label"
                      htmlFor="example-text-input"
                    >
                      {this.state.ConstantValues.FullNameText}
                    </label>
                    <div className="col-md-9">
                      <input
                        type="text"
                        id="Name"
                        name="Name"
                        className="form-control"
                        placeholder={
                          this.state.ConstantValues.FullNamePlaceHolder
                        }
                        onChange={this.handleChange}
                        value={this.state.value}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      className="col-md-3 control-label"
                      htmlFor="example-text-input"
                    >
                      {this.state.ConstantValues.TelNumberText}
                    </label>
                    <div className="col-md-9">
                      <input
                        type="number"
                        id="TelNumber"
                        name="TelNumber"
                        className="form-control"
                        placeholder={
                          this.state.ConstantValues.TelNumberPlaceHolder
                        }
                        onChange={this.handleChange}
                        value={this.state.value}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      className="col-md-3 control-label"
                      htmlFor="example-text-input"
                    >
                      {this.state.ConstantValues.EmailText}
                    </label>
                    <div className="col-md-9">
                      <input
                        type="text"
                        id="Email"
                        name="Email"
                        className="form-control"
                        placeholder={this.state.ConstantValues.EmailPlaceHolder}
                        onChange={this.handleChange}
                        value={this.state.value}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label
                      className="col-md-3 control-label"
                      htmlFor="example-text-input"
                    >
                      {this.state.ConstantValues.UsernameText}
                    </label>
                    <div className="col-md-9">
                      <input
                        type="text"
                        id="Username"
                        name="Username"
                        className="form-control"
                        placeholder={
                          this.state.ConstantValues.UsernamePlaceHolder
                        }
                        onChange={this.handleChange}
                        value={this.state.value}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      className="col-md-3 control-label"
                      htmlFor="example-text-input"
                    >
                      {this.state.ConstantValues.PasswordText}
                    </label>
                    <div className="col-md-9">
                      <input
                        type="text"
                        id="Password"
                        name="Password"
                        className="form-control"
                        placeholder={
                          this.state.ConstantValues.PasswprdPlaceHolder
                        }
                        onChange={this.handleChange}
                        value={this.state.value}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      className="col-md-3 control-label"
                      htmlFor="example-select"
                    >
                      {this.state.ConstantValues.CompanyText}
                    </label>
                    <div className="col-md-9">
                      {/* <select
                        id="CompanyId"
                        name="CompanyId"
                        className="form-control"
                        size="1"
                        required
                        onChange={this.handleChange}
                        value={this.state.value}
                      >
                        <option value="0">
                          {this.state.ConstantValues.CompanySelectText}
                        </option>
                        {this.state.companyList.map(data => (
                          <option value={data.companyId}>
                            {data.companyName}
                          </option>
                        ))}
                      </select> */}
                           <Select
                        id="CompanyId"
                        name="CompanyId"
                        className="form-control"
                        value={selectedOption}
                        onChange={this.handleChangeCompany}
                        options={this.state.companyList}
      />
                    </div>
                  </div>
                </div>
              </fieldset>

              <div className="form-group form-actions">
                <div className="col-md-9 col-md-offset-9">
                  <button
                    type="button"
                    className="btn btn-warning"
                    onClick={this.addCompanyUser}
                  >
                    <i className="fa fa-plus"></i>
                    {this.state.ConstantValues.CreateButtonText}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
