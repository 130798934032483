import React, { Component } from "react";
import ProjectDetailGeneralInformation from "./projectDetailGeneralInformation/projectDetailGeneralInformation";
import ChildElementList from "../projectChildElementList/ChildElementList";
import ChildElementListIzocam from "../projectChildElementList/childElementListIzocam";

export default class ProjectDetail extends Component {
    constructor(props) {
        super(props);
        this.state={
            projectId: this.props.match.params.id,
            projectType:this.props.match.params.projectTypeId,
        };
    }
    componentDidMount(){
    
    }
  render() {
    return (
        <div id="page-content">
      <div className="row">
        <div className="col-md-12">
         <ProjectDetailGeneralInformation  id={this.state.projectId} projectType={this.state.projectType}/>
        </div>
      </div>
      <div className="row">
      <div className="col-md-12">
        <ChildElementListIzocam id={this.state.projectId}  projectType={this.state.projectType}/>
      </div>
      </div>
      </div>
    );
  }
}
