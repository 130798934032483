import React, { Component } from "react";
import swal from "sweetalert2";
import {  _getCompanyList } from "../../../api";

import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
export default class CompanyList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyList: [],
      ConstantValues: {
        HeadCompaniesText: "",
        EditButtonText: "",
        EditText: "",
        CompanyNameText: "",
        StatusText: "",
        ListErrorMessageText:"",
        ListErrorMessageForDT:"",
        SuccessCompanySapSyncMessage :"",
        ErrorCompanySapSyncMessage:"",
        CompanySapCodeText:"",
        IsInformationCompleteText:"",
        IsInformationNonCompleteText:"",
        IsInformationText:"",
        GetCompanySapText:"",
      },
      LanguageId: localStorage.getItem("LanguageId"),
      sapButtonDisable : false,
    };
    this.getAllCompany = this.getAllCompany.bind(this);
    this.ConstantValuesThisPage = this.ConstantValuesThisPage.bind(this);
    //this.getSapSyncCompany = this.getSapSyncCompany.bind(this);
  }
  componentDidMount() {
    this.getAllCompany();
    this.ConstantValuesThisPage();
  }

  ConstantValuesThisPage() {
    this.state.LanguageId === "1"
      ? this.setState({
          ConstantValues: {
            HeadCompaniesText: "Firmalar",
            EditButtonText: "Düzenle",
            EditText: "Düzenle",
            CompanyNameText: "Firma Adı",
            StatusText: "Durum",
            ListErrorMessageText:"Sistemde Kayıtlı Firma yok",
            ListErrorMessageForDT:"Listelenecek Kayıt Yok",
            SuccessCompanySapSyncMessage:"Firmalar Sisteme Başarıyla Aktarıldı.",
            ErrorCompanySapSyncMessage:"Firmalar Sisteme Aktarılırken Bir Hata Meydana Geldi.",
            CompanySapCodeText:"Firma SAP Kodu",
            IsInformationCompleteText:"Bilgileri Tamam",
            IsInformationNonCompleteText:"Bilgileri Tamam Değil",
            IsInformationText:"Firma Bilgileri",
          }
        })
      : this.setState({
          ConstantValues: {
            HeadCompaniesText: "Companies",
            EditButtonText: "Edit",
            EditText: "Edit",
            CompanyNameText: "Company Name",
            StatusText: "Status",
            ListErrorMessageText:"No Registered Companies in the System",
            ListErrorMessageForDT:"No records to list",
            SuccessCompanySapSyncMessage:"Firms Successfully Transferred to the System.",
            ErrorCompanySapSyncMessage:"An Error Occurred While Transferring Companies To The System.",
            CompanySapCodeText:"Company SAP Code",
            IsInformationCompleteText:"Information Complete",
            IsInformationNonCompleteText:"Information not OK",
            IsInformationText:"Company Information",
          }
        });
  }
  getAllCompany() {
    _getCompanyList().then(response => {
      if (response.data.data !== null && response.data.data !== undefined) {
 //console.log(response.data.data);
        this.setState({
          companyList: response.data.data,
          message: response.data.message
        });
        //console.log(response.data.data);
        //console.log(this.state.companyList);
      } else {
        swal(response.data.message);
      }
    console.log(this.state.companyList);
    });

  }

// getSapSyncCompany(){
//   this.setState({sapButtonDisable:true});
//   _companySapSync().then(response=> {
//     console.log("Firma Sap Senkron Response: ",response);
//     // if (response == 1) {
//     //   swal(this.state.SuccessCompanySapSyncMessage)
//     //   this.setState({sapButtonDisable:false});
//     //   this.getAllCompany();
//     // } else {
//     //   swal(this.state.ErrorCompanySapSyncMessage);
//     // }
//   });
// }

  render() {
    const data = {
      columns: [
        {
          label: this.state.ConstantValues.EditText,
          field: "detail",
          sort: "asc",
          width: 150
        },
        {
          label: this.state.ConstantValues.CompanyNameText,
          field: "companyName",
          sort: "asc",
          width: 270
        },
        {
          label: this.state.ConstantValues.CompanySapCodeText,
          field: "companySapCode",
          sort: "asc",
          width: 200
        },
        {
          label: this.state.ConstantValues.StatusText,
          field: "isActive",
          sort: "asc",
          width: 200
        },
        {
          label: this.state.ConstantValues.IsInformationText,
          field: "isInformationComplate",
          sort: "asc",
          width: 200
        }
      ],
      rows:
      
        this.state.companyList.length > 0 &&
        this.state.companyList !== null &&
        this.state.companyList !== undefined
          ? this.state.companyList.map(data => {
              return {
                detail: (
                  <Link
                    key="key"
                    to={`edit-company/${data.companyId}`}
                    className="btn  btn-warning"
                  >
                    {this.state.ConstantValues.EditButtonText}
                  </Link>
                ),
                companyName: data.companyName,
companySapCode:data.companySAPCode,
isActive: data.companyStatus === true ? "Aktif" : "Pasif",
isInformationComplate:data.isInformationComplate === true?
this.state.ConstantValues.IsInformationCompleteText
:
this.state.ConstantValues.IsInformationNonCompleteText,
              };
            })
          : this.state.ConstantValues.ListErrorMessageText
    };
    return (
      <div className="row">
        <div className="col-md-12">
          <div className="block full">
            <div className="block-title">
              <h2>
                <strong>{this.state.ConstantValues.HeadCompaniesText}</strong>{" "}
              </h2>
            </div>

            <div className="table-responsive">
              {this.state.message === null ? (
                 <MDBDataTable entriesOptions={[5,10, 20, 25]} scrollX striped hover data={data} />
                ) : (
              <p className="alert alert-warning">{this.state.ConstantValues.ListErrorMessageForDT}</p>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
