import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import {
  _getChildelementListForProjectWithById,
  _getMyAllBidTheProject,
  _getChildElementName
} from "../../../../../api";
import ChildElementBidForModal from "./childElementBidForModal";
import { MDBDataTable } from "mdbreact";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import print from "print-js";
export default class ChildElementForCompany extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projectId: this.props.id,
      modalShow: false,
      childElementListForProject: [],
      childElementId: "",
      CompanyId: localStorage.getItem("CompanyId"),
      MemberId: localStorage.getItem("UserId"),
      LanguageId: localStorage.getItem("LanguageId"),
      message: "",
      BirimText: "",
      ValörText: "",
      DetailText: "",
      DetailButtonText: "",
      MalzemeTanimText: "",
      TerminText: "",
      PayMethodText: "",
      DelieveryMethodText: "",
      TeklifVerButtonText: "",
      TeklifErrorMessageText: "",
      MiktarText: "",
      AltElementListText: "",
      AltElementTeklifText: "",
      KapatText: "",
      GeriText: "",
      TeklifDokumuText: "",
      ExportPDFText: "",
      PdfProductText: "",
      PdfBidDateText: "",
      PdfBidText: "",
      ChildElementName: "",
      PeinhText:""
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.exportToPdf = this.exportToPdf.bind(this);
  }

  handleShow = e => {
    this.setState({
      modalShow: true,
      childElementId: e.target.id
    });

    _getChildElementName(e.target.id, this.state.LanguageId).then(response => {
      //console.info(response.data.data)
      this.setState({ ChildElementName: response.data.data });
    });
  };
  handleClose = () => {
    this.setState({ modalShow: false });
  };

  componentDidMount() {
    if (this.state.LanguageId === "1") {
      this.setState({
        BirimText: "Birim",
        ValörText: "Valör",
        DetailText: "Ayrıntıya Git",
        DetailButtonText: "",
        MalzemeTanimText: "Malzeme Tanım",
        TerminText: "Termin",
        PayMethodText: "Ödeme Şekli",
        DelieveryMethodText: "Teslim Şekli",
        TeklifVerButtonText: "Teklif Ver",
        TeklifErrorMessageText: "Şu An Teklif Verilemez",
        MiktarText: "Miktar",
        AltElementListText: "Alt Element Listesi",
        AltElementTeklifText: "Alt Elemente Teklif Ver",
        KapatText: "Kapat",
        GeriText: "Geri",
        TeklifDokumuText: "Teklif Dökümü Al",
        ExportPDFText: "PDF'e Aktar",
        PdfProductText: "",
        PdfBidDateText: "",
        PdfBidText: "",
        PeinhText:"Miktar Birimi"
      });
    } else {
      this.setState({
        BirimText: "Unit",
        ValörText: "Valor",
        DetailText: "Go To Detail",
        DetailButtonText: "",
        MalzemeTanimText: "Material Description",
        TerminText: "Termin",
        PayMethodText: "Payment method",
        DelieveryMethodText: "Delivery Method",
        TeklifVerButtonText: "Give Offer",
        TeklifErrorMessageText: "Currently No Bid",
        MiktarText: "Quantity",
        AltElementListText: "Sub Element List",
        AltElementTeklifText: "Make Offer",
        KapatText: "Close",
        GeriText: "Back",
        TeklifDokumuText: "My Offers",
        ExportPDFText: "Export to PDF",
        PdfProductText: "Product",
        PdfBidDateText: "Bid Date",
        PdfBidText: "Bid (Valor)",
        PeinhText:"Quantity Unit"
      });
    }
    this.getChildelementListForProjectWithById();
  }

  getChildelementListForProjectWithById() {
    const id = this.state.projectId;
    _getChildelementListForProjectWithById(id, this.state.LanguageId).then(
      response => {
        this.setState({
          childElementListForProject:
            response.data.data === undefined ? null : response.data.data,
          message:
            response.data.message === undefined || null
              ? null
              : response.data.message
        });
      }
    );
  }
  exportToPdf() {
    _getMyAllBidTheProject(
      this.state.CompanyId,
      this.state.MemberId,
      this.state.projectId,
      this.state.LanguageId
    ).then(response => {
      print({
        printable: response.data.data.projectProductBid,
        properties: [
          { field: "product", displayName: this.state.PdfProductText },
          { field: "bidDate", displayName: this.state.PdfBidDateText },
          { field: "bid", displayName: this.state.PdfBidText }
        ],
        type: "json",
        gridHeaderStyle: "color: red;  border: 2px solid #3971A5;",
        gridStyle: "border: 2px solid #3971A5;",
        header: `<h3 class="custom-h3">${response.data.data.projectName}</h3>`
      });
    });
  }
  render() {
    const data = {
      columns: [
        {
          label: this.state.DetailText,
          field: "detail",
          sort: "asc",
          width: 150
        },
        {
          label: this.state.MalzemeTanimText,
          field: "malzemeTanim",
          sort: "asc",
          width: 270
        },
        {
          label: this.state.MiktarText,
          field: "miktar",
          sort: "asc",
          width: 200
        },
        {
          label: this.state.PeinhText,
          field: "peinh",
          sort: "asc",
          width: 200
        },
        {
          label: this.state.BirimText,
          field: "birim",
          sort: "asc",
          width: 200
        },
        {
          label: this.state.TerminText,
          field: "termin",
          sort: "asc",
          width: 100
        },
        {
          label: this.state.DelieveryMethodText,
          field: "deliveryMethod",
          sort: "asc",
          width: 100
        },
        {
          label: this.state.PayMethodText,
          field: "payMethod",
          sort: "asc",
          width: 100
        }
        // {
        //   label: this.state.ValörText,
        //   field: "valor",
        //   sort: "asc",
        //   width: 100
        // }
      ],
      rows:
        this.state.childElementListForProject !== undefined &&
        this.state.childElementListForProject !== null
          ? this.state.childElementListForProject.map(data => {
              return {
                detail:
                  data.isStarted === true && data.isEnd === false ? (
                    <button
                      type="button"
                      className="btn  btn-success"
                      id={data.projectAltElementId}
                      onClick={this.handleShow}
                      key={data.projectAltElementId}
                    >
                      {this.state.TeklifVerButtonText}
                    </button>
                  ) : (
                    this.state.TeklifErrorMessageText
                  ),
                malzemeTanim: data.malzemeTanim,
                miktar: data.miktar,
                peinh: data.peinh === null ||data.peinh === undefined ? "Belirtilmemiş":data.peinh,
                birim: data.birim,
                termin: <Moment format="YYYY/MM/DD">{data.termin}</Moment>,
                deliveryMethod: data.deliveryMethod,
                payMethod: data.payMethod
                //valor: data.valor
              };
            })
          : "No Result"
    };
    return (
      <div className="block full">
        <div className="block-title">
          {/* <Link
            to="/project-list-for-company"
            width="25px"
            className="btn btn-info"
          >
            {this.state.GeriText}
          </Link> */}
          <h2>
            <strong>{this.state.AltElementListText}</strong>{" "}
          </h2>
        </div>
        <div className="table-responsive">
          <div
            id="example-datatable_wrapper"
            className="dataTables_wrapper form-inline no-footer"
          >
            {this.state.message === null ? (
              <MDBDataTable striped hover data={data} />
            ) : (
              <p>{this.state.message}</p>
            )}
            {/* <table
          //     id="example-datatable"
          //     className="table table-vcenter table-condensed table-bordered dataTable no-footer"
          //     role="grid"
          //     aria-describedby="example-datatable_info"
          //   >
          //     <thead>
          //       <tr role="row">
          //         <th className="text-center sorting_disabled">Teklif Ver</th>
          //         <th className="text-center sorting_disabled">
          //           Malzeme Tanım
          //         </th>
          //         <th className="sorting">Miktar</th>
          //         <th className="text-center sorting_disabled">Birim</th>
          //         <th className="text-center sorting_disabled">Termin</th>
          //         <th className="text-center sorting_disabled">Teslim Şekli</th>
          //         <th className="text-center sorting_disabled">Ödeme Şekli</th>
          //         <th className="text-center sorting_disabled">Valör</th>
          //       </tr>
          //     </thead>
          //     <tbody>
          //       {this.state.childElementListForProject != null ? (
          //         this.state.childElementListForProject.map(data => (
          //           <tr className="even">
          //             <td>
          //               <button
          //                 type="button"
          //                 className="btn  btn-success"
          //                 id={data.projectAltElementId}
          //                 onClick={this.handleShow}
          //                 key={data.projectAltElementId}
          //               >
          //                 Teklif Ver
          //               </button>
          //             </td>
          //             <td>{data.malzemeTanim}</td>
          //             <td>{data.miktar}</td>
          //             <td>{data.birim}</td>
          //             <td>{data.termin}</td>
          //             <td>{data.deliveryMethod}</td>
          //             <td>{data.payMethod}</td>
          //             <td>{data.valor}</td>
          //           </tr>
          //         ))
          //       ) : (
          //         <td>No Result</td>
          //       )}
          //     </tbody>
          //   </table> */}
          </div>
        </div>
        <Modal show={this.state.modalShow} onHide={this.handleClose} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>{this.state.ChildElementName}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ChildElementBidForModal
              id={this.state.childElementId}
              projectId={this.state.projectId}
            />
          </Modal.Body>
          <Modal.Footer>
            <button
              className="ml-auto"
              onClick={this.handleClose}
              variant="link"
            >
              {this.state.KapatText}
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
