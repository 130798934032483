import React, { Component } from "react";
import Moment from "react-moment";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import moment from "moment";
import { _updateProjectEndDate } from "../../../../api";
import swal from "sweetalert2";
export default class UpdateTimeModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ProjectId: this.props.projectId,
      EndDate: this.props.endDate,
      UpdateEndDate: 0,
      moment: moment(),
      name: this.props.name,
      LanguageId: localStorage.getItem("LanguageId"),
      UpdateErrorMessage: "",
      ConstantValuesOfExtendTime: {
        EndDateText: "",
        UpdateButtonText: "",
        SelectEndDateText: ""
      }
    };
    this.handleChangeDateStart = this.handleChangeDateStart.bind(this);
    this.UpdateTime = this.UpdateTime.bind(this);
    this.ConstantValuesExtendTime = this.ConstantValuesExtendTime.bind(this);
  }

  componentDidMount() {
    // console.log("Süre uzatma proje Id'si: ", this.state.ProjectId);
    this.state.LanguageId === "1"
      ? this.setState({ UpdateErrorMessage: "Lütfen Bir Tarih Seçiniz" })
      : this.setState({ UpdateErrorMessage: "Please select a date" });

      this.ConstantValuesExtendTime();
  }
  ConstantValuesExtendTime() {
    this.state.LanguageId === "1"
      ? this.setState({
          ConstantValuesOfExtendTime: {
            EndDateText: "İhale Bitiş Tarihi",
            UpdateButtonText: "Uzat",
            SelectEndDateText: "Bitiş Tarihi Seçiniz"
          }
        })
      : this.setState({
          ConstantValuesOfExtendTime: {
            EndDateText: "Tender End Date",
            UpdateButtonText: "Update",
            SelectEndDateText: "Select End Date"
          }
        });
  }
  handleChangeDateStart = date => {
    this.setState({
      UpdateEndDate: date._d
    });
    console.log(date._d);
  };
  UpdateTime() {
    if (this.state.UpdateEndDate !== 0) {
      const obj = {
        ProjectId: this.state.ProjectId,
        EndDate: this.state.UpdateEndDate,
        LanguageId: this.state.LanguageId
      };
      _updateProjectEndDate(obj).then(response => {
        swal(response.data.message);
      });
    } else {
      swal(this.state.UpdateErrorMessage);
    }
  }
  render() {
    return (
      <div>
        <fieldset>
          <legend>{this.state.name}</legend>
          <div className="form-group">
            <label
              className="col-md-3 control-label"
              htmlFor="example-text-input"
            >
              {this.state.ConstantValuesOfExtendTime.EndDateText} :
            </label>
            <label
              className="col-md-3 control-label"
              htmlFor="example-text-input"
            >
              <Moment format="YYYY-MM-DD HH:mm">{this.state.EndDate}</Moment>
            </label>
          </div>
          <div className="form-group">
            <label
              className="col-md-3 control-label"
              htmlFor="example-text-input"
            >
              {this.state.ConstantValuesOfExtendTime.SelectEndDateText}
            </label>
            <div className="col-md-6">
              <Datetime
                className="form-control"
                value={this.state.UpdateEndDate}
                onChange={this.handleChangeDateStart}
              />
            </div>
          </div>
          <div className="form-group">
            <div className="col-md-3">
              <button className="btn btn-warning" onClick={this.UpdateTime}>
                {this.state.ConstantValuesOfExtendTime.UpdateButtonText}
              </button>
            </div>
          </div>
        </fieldset>
      </div>
    );
  }
}
