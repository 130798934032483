import React, { Component } from "react";
import swal from "sweetalert2";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import {
  _updateAltElementServices,
  _getChildElementInformation,
  _PaymentMethod,
  _ProductionAres,
  _Materials
} from "../../../../api";
export default class UpdateChilElement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      childId: this.props.match.params.childId,
      ProjectId: "",
      MalzemeTanim_TR: "",
      MalzemeTanim_EN: "",
      DeliveryMethod_TR: "",
      DeliveryMethod_EN: "",
      PayMethod_TR: "",
      PayMethod_EN: "",
      Valor_TR: "",
      Valor_EN: "",
      Miktar: "",
      Termin: "",
      Birim_TR: "",
      Birim_EN: "",
      Birim: "",
      productionAres: 0,
      materialId: "",
      material: "",
      PaymentCode:"",
      MaterialUnitCode:"",
      materias: [],
      paymentOptions: [],
      productionAresOptions: [],
      materialOptions: [],
      LanguageId: localStorage.getItem("LanguageId"),
      date: new Date(),
      MalzemeTanim_TRHolder: "",
      MalzemeTanim_ENHolder: "",
      Peinh:"",
      ConstantValueOfEditSub: {
        HeadText: "",
        BackButtonText: "",
        UpdateButtonText: "",
        MiktarText: "",
        MiktarPlaceHolder: "",
        BirimText: "",
        BirimSelectText: "",
        TerminText: "",
        DetailHeadText: "",
        TurkceText: "",
        IngilizceText: "",
        MalzemeTanimText: "",
        TeslimSekliText: "",
        OdemeSekliText: "",
        ValorText: "",
        CreateButtonText: "",
        ListHeadText: "",
        UpdateTextDT: "",
        ListCountZeroErrorMessage:"",
        UretimYeri:"",
        Malzeme:"",
        PeinhPlaceHolder:"",
        PeinhText:""
      }
    };
    this.handleChange = this.handleChange.bind(this);
    this.updateChildElement = this.updateChildElement.bind(this);
    this.ConstantValueOfEditSubComponent = this.ConstantValueOfEditSubComponent.bind(this);
    this.productionAres=this.productionAres.bind(this);
    this.paymentMethod=this.paymentMethod.bind(this);
    this.materials=this.materials.bind(this);
  }

  ConstantValueOfEditSubComponent() {
    this.state.LanguageId === "1"
      ? this.setState({
        ConstantValueOfEditSub: {
            HeadText: "Alt Element Güncelleme",
            BackButtonText: "Geri",
            UpdateButtonText: "Güncelle",
            MiktarText: "Miktar",
            MiktarPlaceHolder: "Miktar Giriniz",
            BirimText: "Birim",
            BirimSelectText: "Seçiniz",
            TerminText: "Termin",
            DetailHeadText: "Alt Element Detay Bilgiler",
            TurkceText: "Türkçe",
            IngilizceText: "İngilizce",
            MalzemeTanimText: "Malzeme Tanım",
            TeslimSekliText: "Teslim Şekli",
            OdemeSekliText: "Ödeme Şekli",
            ValorText: "Valör",
            CreateButtonText: "Ekle",
            ListHeadText: "Alt Element Listesi",
            ListCountZeroErrorMessage:"Listelenecek Alt Element Bilgisi Bulunamamıştır",
            UretimYeri:"Üretim Yeri",
            Malzeme:"Malzeme",
            PeinhPlaceHolder:"Miktar Birimi",
            PeinhText:"Miktar Birimi"
          }
        })
      : this.setState({
        ConstantValueOfEditSub: {
            HeadText: "Creating a child element",
            BackButtonText: "Back",
            UpdateButtonText: "Update",
            MiktarText: "Quantity",
            MiktarPlaceHolder: "Enter Quantity",
            BirimText: "Unit",
            BirimSelectText: "Select",
            TerminText: "Termin",
            DetailHeadText: "Child Element Detail Information",
            TurkceText: "Turkish",
            IngilizceText: "English",
            MalzemeTanimText: "Material Defination",
            TeslimSekliText: "Delievery Method",
            OdemeSekliText: "Pay Method",
            ValorText: "Valor",
            CreateButtonText: "Crate",
            ListHeadText: "Child Element List",
            ListCountZeroErrorMessage:"No Sub-Element Information To Be Listed Found",
            UretimYeri:"Production Area",
            Malzeme:"Material",
            PeinhPlaceHolder:"Quantity Unit",
            PeinhText:"Quantity Unit"
          }
        });
  }
  handleChange(e) {
    let change = {};
    change[e.target.name] = e.target.value;
    this.setState(change);
  //  console.log(e.target.name + ": " + e.target.value);

  if (e.target.name === "MalzemeTanim_TR") {
    this.setState({ MalzemeTanim_EN: e.target.value });
  }
  if (e.target.name === "DeliveryMethod_TR") {
    var index = e.nativeEvent.target.selectedIndex;
    this.setState({ DeliveryMethod_EN: e.nativeEvent.target[index].text });
    this.setState({ DeliveryMethod_EN:  e.target.value});
  }
  if (e.target.name === "PayMethod_TR") {
    var index = e.nativeEvent.target.selectedIndex;
    this.setState({ PayMethod_TR: e.nativeEvent.target[index].text });
    this.setState({ PayMethod_EN: e.nativeEvent.target[index].text });
    this.setState({ PayMethod_EN: e.target.value });
  }
  if (e.target.name === "Valor_TR") {
    var index = e.nativeEvent.target.selectedIndex;
    this.setState({ Valor_TR:  e.nativeEvent.target[index].text });
    this.setState({ Valor_EN:  e.target.value});
   
  }
  if(e.target.name=="material")
  {
    this.setState({material:e.target.value});
    this.setState({materialId:e.target.value});
    var index = e.nativeEvent.target.selectedIndex;
    this.setState({MalzemeTanim_TR: e.nativeEvent.target[index].text});
    this.setState({MalzemeTanim_EN: e.nativeEvent.target[index].text});
    this.setState({ Birim:this.state.materias.find(x=>x.key==e.target.value).value });
  }
  if(e.target.name=="productionAres")
  {this.setState({PaymentCode: e.target.value});
    this.setState({ DeliveryMethod_TR: e.target.value });
    this.setState({ DeliveryMethod_EN: e.target.value });
    this.setState({productionAres:e.target.value})
     this.materials(e.target.value)
  }
  }
  onChange = date => this.setState({ date });
  componentDidMount() {
    this.paymentMethod();
    this.productionAres();
    this.getChildElementInformation();
    this.ConstantValueOfEditSubComponent();
   
  }
  getChildElementInformation() {
    _getChildElementInformation(this.state.childId, this.state.LanguageId).then(
      response => {
        this.setState({
          MalzemeTanim_TR: response.data.data.malzemeTanim_TR,
          MalzemeTanim_EN: response.data.data.malzemeTanim_EN,
          DeliveryMethod_TR: response.data.data.deliveryMethod_TR,
          DeliveryMethod_EN: response.data.data.deliveryMethod_EN,
          PayMethod_TR: response.data.data.payMethod_TR,
          PayMethod_EN: response.data.data.payMethod_EN,
          Valor_TR: response.data.data.valor_TR,
          Valor_EN: response.data.data.valor_EN,
          Miktar: response.data.data.miktar,
          date: response.data.data.termin,
          Birim_TR: response.data.data.birim_TR,
          Birim_EN: response.data.data.birim_EN,
          Birim: response.data.data.birim_TR,
          ProjectId: response.data.data.projectId,
          MaterialCode :response.data.data.materialCode,
          MaterialUnitCode :response.data.data.materialUnitCode,
          PaymentCode :response.data.data.paymentCode,
          productionAres :response.data.data.productAreaCode,
          materialId: response.data.data.materialId,
          Peinh:response.data.data.peinh === null || response.data.data.peinh === undefined ? 1:parseInt(response.data.data.peinh)
        });
        this.materials(this.state.productionAres);
        console.log('gelen data' +response.data.data);
      }
    );
  }

  updateChildElement() {
    const obj = {
      Id: this.state.childId,
      ProjectId: this.state.projectId,
      MalzemeTanim_TR: this.state.MalzemeTanim_TR,
      MalzemeTanim_EN: this.state.MalzemeTanim_EN,
      DeliveryMethod_TR: this.state.DeliveryMethod_TR,
      DeliveryMethod_EN: this.state.DeliveryMethod_EN,
      PayMethod_TR: this.state.PayMethod_TR,
      PayMethod_EN: this.state.PayMethod_EN,
      Valor_TR: this.state.Valor_TR,
      Valor_EN: this.state.Valor_EN,
      Miktar: this.state.Miktar,
      Termin: this.state.date,
      Birim_TR: this.state.Birim_TR,
      Birim_EN: this.state.Birim_TR,
      LanguageId: this.state.LanguageId,
      MaterialCode :this.state.MaterialCode,
      materialId:this.state.materialId,
      MaterialUnitCode :this.state.MaterialUnitCode,
      PaymentCode :this.state.PaymentCode,
      ProductAreaCode :this.state.productionAres,
      Peinh:this.state.Peinh
    };
    //console.log(obj);

    _updateAltElementServices(obj).then(response => {
      swal(response.data.message);
    });
    this.getChildElementInformation();
  }
  validation = currentDate => {
    var d = new Date();
    return currentDate.isAfter(d.setDate(d.getDate() - 1));
    //currentDate.isAfter(addDays(new Date(),1))
  };

  // getComponent() {
  //   return <ChildElementList id={this.state.projectId} />;
  // }
  handleChangeDateStart = date => {
    this.setState({
      date: date._d
    });
    //console.log(date._d);
  };
    /* -------------naci olut---------------------      */
    paymentMethod()
    {
      _PaymentMethod(this.state.LanguageId).then(res=>{
        const optionsData = res.data.data.map(d => ({
          "value" : d.text1,
          "label" : d.text1
        }))
      this.setState({paymentOptions:optionsData});
      })
    }
    productionAres()
    {
      _ProductionAres(this.state.LanguageId).then(res=>{     
        const optionsData = res.data.data.map(d => ({
          "value" : d.name,
          "label" : d.name
        }))
        this.setState({productionAresOptions:optionsData});
      })
    }
    materials(productionAreCode)
    { 
      this.setState({materialOptions:[]});
      if(productionAreCode !="Birimsiz")
      {
        _Materials(this.state.LanguageId,productionAreCode).then(res=>{
          const optionsData = res.data.data.map(d => ({
            "value" : d.id,
            "label" : d.name
          }))
          let materialsOptions=optionsData.map(v=>(
            <option value={v.value}>{v.label}</option>
          ));
          this.setState({materialOptions:materialsOptions});
          const materialData = res.data.data.map(d => ({
            "key" : d.id,
            "value" : d.meins
          }));
          this.setState({materias:materialData});
        })
      }
     
    }
      /* -------------naci olut---------------------      */
  render() {
    let paymentOptions=this.state.paymentOptions.map(v=>(
      <option value={v.value}>{v.label}</option>
    ));
    let productionAresOptions = this.state.productionAresOptions.map(v => (
      <option value={v.value}>{v.label}</option>
    ));
    return (
      <div id="page-content">
        <div className="page-container">
          <div className="row">
            <div className="col-md-12">
              <div className="block">
                <div className="block-title">
                  <h2>
                    <strong>{this.state.ConstantValueOfEditSub.HeadText}</strong>
                  </h2>

                  <form className="form-horizontal form-bordered needs-validation">
                    <fieldset>
                      <legend>{this.state.ProjectName}</legend>
                      <div className="col-md-8">
                      <div className="form-group">
                          <label
                            className="col-md-3 control-label"
                            htmlFor="example-text-input"
                          >
                            {this.state.ConstantValueOfEditSub.UretimYeri}
                          </label>
                          <div className="col-md-9">
                          <select
                              id="productionAres"
                              name="productionAres"
                              className="form-control"
                              placeholder="Üretim Yerleri"
                              onChange={this.handleChange }
                              value={this.state.productionAres}
                              required
                             
                            >
                            <option value={this.state.productionAres} selected>
                                  {this.state.productionAres}
                                </option>
                              {productionAresOptions}
                            </select>
                          </div>
                        </div>
                        <div className="form-group">
                          <label
                            className="col-md-3 control-label"
                            htmlFor="example-text-input"
                          >
                            {this.state.ConstantValueOfEditSub.Malzeme}
                          </label>
                          <div className="col-md-9">
                          <select
                               id="material"
                               name="material"
                               className="form-control"
                               placeholder="Malzemeler"
                              onChange={this.handleChange }
                              value={this.state.value}
                              required
                             
                            >
                              <option value={this.state.materialId} selected>
                                  {this.state.MalzemeTanim_TR}
                                </option>
                              {this.state.materialOptions}
                            </select>
                          </div>
                        </div>
                 
                        <div className="form-group">
                          <label
                            className="col-md-3 control-label"
                            htmlFor="example-text-input"
                          >
                            {this.state.ConstantValueOfEditSub.MiktarText}
                          </label>
                          <div className="col-md-9">
                            <input
                              type="text"
                              id="Miktar"
                              name="Miktar"
                              className="form-control"
                              placeholder={this.state.Miktar}
                              onChange={this.handleChange}
                              value={this.state.value}
                              required
                            />
                          </div>
                        </div>
                        <div className="form-group">
                        <label
                          className="col-md-3 control-label"
                          htmlFor="example-text-input"
                        >
                          {this.state.ConstantValueOfEditSub.PeinhText}
                        </label>
                        <div className="col-md-9">
                          <input
                            type="number"
                            id="Peinh"
                            name="Peinh"
                            className="form-control"
                            min={1}
                            placeholder={this.state.Peinh}
                            onChange={this.handleChange}
                            value={this.state.value}
                            required
                          />
                        </div>
                      </div>
                        <div className="form-group">
                          <label
                            className="col-md-3 control-label"
                            htmlFor="example-text-input"
                          >
                            {this.state.ConstantValueOfEditSub.BirimText}
                          </label>
                          <div className="col-md-9">
                            {/* <input
                              type="text"
                              id="Birim"
                              name="Birim"
                              className="form-control"
                              placeholder="Birim"
                              onChange={this.handleChange}
                              value={this.state.value}
                              required
                            /> */}
                            <select
                              id="Birim"
                              name="Birim_TR"
                              className="form-control"
                              placeholder="Birim_TR"
                              onChange={this.handleChange}
                              value={this.state.Birim}
                              required
                            >
                              <option value={this.state.Birim_TR} selected>
                                {this.state.Birim_TR}
                              </option>
                              <option value="M3">M3</option>
                            <option value="GR">GR</option>
                            <option value="M2">M2</option>
                            <option value="L">L</option>
                            <option value="PAK">PAK</option>
                            <option value="TKM">TKM</option>
                            <option value="ADT">ADT</option>
                            <option value="KG">KG</option>
                            <option value="M">M</option>
                              {/* <option value="Metrekare" hidden={this.state.Birim_TR === "Metrekare" ? true:false}>Metrekare</option>
                              <option value="Metreküp" hidden={this.state.Birim_TR === "Metreküp" ? true:false}>Metreküp</option>
                              <option value="Ton" hidden={this.state.Birim_TR === "Ton" ? true:false}>Ton</option>
                              <option value="Kg" hidden={this.state.Birim_TR === "Kg" ? true:false}>Kg</option>
                              <option value="Adet" hidden={this.state.Birim_TR === "Adet" ? true:false}>Adet</option>
                              <option value="Metre" hidden={this.state.Birim_TR === "Metre" ? true:false}>Metre</option>
                              <option value="Kutu" hidden={this.state.Birim_TR === "Kutu" ? true:false}>Kutu</option>
                              <option value="%" hidden={this.state.Birim_TR === "%" ? true:false}>%</option>
                              <option value="Gram" hidden={this.state.Birim_TR === "Gram" ? true:false}>Gram</option> */}
                            </select>
                          </div>
                        </div>
                        <div className="form-group">
                          <label
                            className="col-md-3 control-label"
                            htmlFor="example-text-input"
                          >
                            {this.state.ConstantValueOfEditSub.TerminText}
                          </label>
                          <div className="col-md-9">
                            {/* <input
                              type="date"
                              id="Termin"
                              name="Termin"
                              className="form-control file"
                              placeholder="Termin"
                              onChange={this.handleChange}
                              value={this.state.value}
                              required
                            /> */}
                            <Datetime
                              id="Termin"
                              name="Termin"
                              selected={this.state.date}
                              className="form-control"
                              timeFormat={"HH:mm:ss"}
                              value={this.state.date}
                              onChange={this.handleChangeDateStart}
                              isValidDate={this.validation}
                            />
                          </div>
                        </div>
                      </div>
                    </fieldset>

                    <fieldset>
                      <legend>{this.state.ConstantValueOfEditSub.DetailHeadText}</legend>
                      <div className="col-md-6">
                        <fieldset>
                          <legend>{this.state.ConstantValueOfEditSub.TurkceText}</legend>
                          <div className="form-group">
                            <label
                              className="col-md-3 control-label"
                              htmlFor="example-text-input"
                            >{this.state.ConstantValueOfEditSub.MalzemeTanimText}
                            </label>
                            <div className="col-md-9">
                              <input
                                type="text"
                                id="MalzemeTanim_TR"
                                name="MalzemeTanim_TR"
                                className="form-control"
                                placeholder={this.state.MalzemeTanim_TR}
                                onChange={this.handleChange}
                                value={this.state.MalzemeTanim_TR}
                                required
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <label
                              className="col-md-3 control-label"
                              htmlFor="example-text-input"
                            >
                              {this.state.ConstantValueOfEditSub.TeslimSekliText}
                            </label>
                            <div className="col-md-9">
                              <select
                                id="DeliveryMethod_TR"
                                name="DeliveryMethod_TR"
                                className="form-control"
                                placeholder="Teslim Şekli"
                                onChange={this.handleChange}
                                value={this.state.value}
                                required
                              >
                                <option
                                  value={this.state.DeliveryMethod_TR}
                                  selected
                                >
                                  {this.state.DeliveryMethod_TR}
                                </option>
                                {/* {productionAresOptions} */}
                                <option value="Ex-Works">Ex-Works</option>
                                <option value="İzocam Dilovası Tesisi Teslim">İzocam Dilovası Tesisi Teslim</option>
                                <option value="İzocam Gebkim Tesisi Teslim">İzocam Gebkim Tesisi Teslim</option>
                                <option value="İzocam Eskişehir Teslimi Teslim">İzocam Eskişehir Teslimi Teslim</option>
                                <option value="İzocam Tarsus Tesisi Teslim">İzocam Tarsus Tesisi Teslim</option>
                                <option value="Genel Müdürlük Merkezi">Genel Müdürlük Merkezi</option>
                              </select>
                            </div>
                          </div>
                          <div className="form-group">
                            <label
                              className="col-md-3 control-label"
                              htmlFor="example-text-input"
                            >
                              {this.state.ConstantValueOfEditSub.OdemeSekliText}
                            </label>
                            <div className="col-md-9">
                              <select
                                id="PayMethod_TR"
                                name="PayMethod_TR"
                                className="form-control"
                                placeholder="Ödeme Şekli"
                                onChange={this.handleChange}
                                value={this.state.value}
                                required
                              >
                                <option
                                  value={this.state.PayMethod_TR}
                                  selected
                                >
                                  {this.state.PayMethod_TR}
                                </option>
                                {paymentOptions}
                              </select>
                            </div>
                          </div>
                          {/* <div className="form-group">
                            <label
                              className="col-md-3 control-label"
                              htmlFor="example-text-input"
                            >
                              {this.state.ConstantValueOfEditSub.ValorText}
                            </label>
                            <div className="col-md-9">
                              <select
                                id="Valor_TR"
                                name="Valor_TR"
                                className="form-control"
                                placeholder="Valör"
                                onChange={this.handleChange}
                                value={this.state.value}
                                required
                              >
                                <option value={this.state.Valor_TR} selected>
                                  {this.state.Valor_TR}
                                </option>
                                {paymentOptions}
                              </select>
                            </div>
                          </div> */}
                        </fieldset>
                      </div>
                      <div className="col-md-6">
                        <fieldset>
                          <legend>{this.state.ConstantValueOfEditSub.IngilizceText}</legend>
                          <div className="form-group">
                            <label
                              className="col-md-3 control-label"
                              htmlFor="example-text-input"
                            >
                              {this.state.ConstantValueOfEditSub.MalzemeTanimText}
                            </label>
                            <div className="col-md-9">
                              <input
                                type="text"
                                id="MalzemeTanim_EN"
                                name="MalzemeTanim_EN"
                                className="form-control"
                                placeholder={this.state.MalzemeTanim_TR}
                                onChange={this.handleChange}
                                value={this.state.MalzemeTanim_EN}
                                required
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <label
                              className="col-md-3 control-label"
                              htmlFor="example-text-input"
                            >
                              {this.state.ConstantValueOfEditSub.TeslimSekliText}
                            </label>
                            <div className="col-md-9">
                              <select
                                id="DeliveryMethod_EN"
                                name="DeliveryMethod_EN"
                                className="form-control"
                                placeholder="Teslim Şekli"
                                onChange={this.handleChange}
                                value={this.state.DeliveryMethod_EN}
                                required
                              >
                                <option
                                  value={this.state.DeliveryMethod_EN}
                                  selected
                                >
                                  {this.state.DeliveryMethod_EN}
                                  </option>
                                  {/* {productionAresOptions} */}
                                  <option value="Ex-Works">Ex-Works</option>
                                <option value="İzocam Dilovası Tesisi Teslim">İzocam Dilovası Tesisi Teslim</option>
                                <option value="İzocam Gebkim Tesisi Teslim">İzocam Gebkim Tesisi Teslim</option>
                                <option value="İzocam Eskişehir Teslimi Teslim">İzocam Eskişehir Teslimi Teslim</option>
                                <option value="İzocam Tarsus Tesisi Teslim">İzocam Tarsus Tesisi Teslim</option>
                                <option value="Genel Müdürlük Merkezi">Genel Müdürlük Merkezi</option>
                              </select>
                            </div>
                          </div>
                          <div className="form-group">
                            <label
                              className="col-md-3 control-label"
                              htmlFor="example-text-input"
                            >
                              {this.state.ConstantValueOfEditSub.OdemeSekliText}
                            </label>
                            <div className="col-md-9">
                              <select
                                id="PayMethod_EN"
                                name="PayMethod_EN"
                                className="form-control"
                                placeholder="Ödeme Şekli"
                                onChange={this.handleChange}
                                value={this.state.value}
                                required
                              >
                                <option
                                  value={this.state.PayMethod_EN}
                                  selected
                                >
                                  {this.state.PayMethod_EN}
                                </option> 
                                {paymentOptions}
                              </select>
                            </div>
                          </div>
                          {/* <div className="form-group">
                            <label
                              className="col-md-3 control-label"
                              htmlFor="example-text-input"
                            >
                              {this.state.ConstantValueOfEditSub.ValorText}
                            </label>
                            <div className="col-md-9">
                              <select
                                id="Valor_EN"
                                name="Valor_EN"
                                className="form-control"
                                placeholder="Valör"
                                onChange={this.handleChange}
                                value={this.state.Valor_EN}
                                required
                              >
                                <option value={this.state.Valor_TR} selected>
                                  {this.state.Valor_TR}
                                </option>
                              {paymentOptions}</select>
                            </div>
                          </div> */}
                        </fieldset>
                      </div>
                    </fieldset>

                    <div className="form-group form-actions">
                      <div className="col-md-9 col-md-offset-9">
                        <button
                          type="button"
                          className="btn btn-warning"
                          onClick={this.updateChildElement}
                        >
                          <i className="fa fa-plus"></i> {this.state.ConstantValueOfEditSub.UpdateButtonText}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
