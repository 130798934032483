import React, { Component } from "react";
import { _getBidderListForProject } from "../../../../../api";
import { MDBDataTable } from "mdbreact";
import { Link } from "react-router-dom";
export default class BidProcedureGeneralTeklifs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projectId: this.props.id,
      projectTypeId: this.props.projectTypeId,
      bidderList: [],
      message: ""
    };
  }
  componentDidMount() {
    this.getBidderList();
   // console.log("asdasd");
  }

  getBidderList() {
    _getBidderListForProject(this.state.projectId).then(response => {
      this.setState({
        bidderList:
          response.data.data === undefined || response.data.data === null
            ? null
            : response.data.data,
        message: response.data.message
      });
    //  console.log(response.data.data);
    });
  }
  render() {
    const data = {
      columns: [
        {
          label: "Firma İsmi",
          field: "companyName",
          sort: "asc",
          width: 150
        },
        {
          label: "Kullanıcı",
          field: "companyUser",
          sort: "asc",
          width: 270
        },
        {
          label: "Teklif Saati",
          field: "bidDate",
          sort: "asc",
          width: 200
        },
        {
          label: "Teklif",
          field: "bid",
          sort: "asc",
          width: 200
        },
        {
          label: "Ek Gider",
          field: "ekGider",
          sort: "asc",
          width: 100
        }
      ],
      rows: this.state.bidderList
    };
    return (
      <div className="col-md-12">
        <div className="block full">
          <div className="block-title">
            <Link
              className="btn btn-info"
              to={`/bid-procedure-project-detail/${this.state.projectId}/projectType/${this.state.projectTypeId}`}
              key="id"
            >
              Geri
            </Link>
            <h2>
              <strong>İhaleye Gelen Teklifler</strong>{" "}
            </h2>
          </div>

          <div className="table-responsive">
            {this.state.message === null && this.state.bidderList !== null ? (
              <MDBDataTable striped hover data={data} />
            ) : (
              <p className="alert alert-warning">Listelenecek Kayıt Yok</p>
            )}
            {/* <MDBDataTable striped hover data={data} /> */}
            {/* <table
              id="example-datatable"
              className="table table-vcenter table-condensed table-bordered dataTable no-footer"
            >
              <thead>
                <tr>
                  <th
                    className="text-center sorting_disabled"

                  >
                    Firma adı
                  </th>
                  <th className="text-center sorting_disabled">
                    Firma Kullanıcısı
                  </th>
                  <th
                    className="sorting"

                  >
                    Teklif Saati
                  </th>
                  <th
                    className="text-center sorting_disabled"

                  >
                    Teklif
                  </th>
                  <th
                    className="text-center sorting_disabled"
                  >
                    Ek Gider
                  </th>
                </tr>
              </thead>
              <tbody>
               {(this.state.message == null)?(
                   this.state.bidderList.map(data => (
                    <tr className="even">
                      <td>{data.companyName}</td>
                      <td>{data.companyUser}</td>
                      <td>{data.bidDate}</td>
                      <td>{data.bid}</td>
                      <td>{data.ekGider}</td>
                    </tr>))
               ):(
                 <tr>
                   <td></td>
                   <td></td>
                   <td>{this.state.message}</td>
                   <td></td>
                   <td></td>
                 </tr>
               )}
              </tbody>
            </table> */}
          </div>
        </div>
      </div>
    );
  }
}
