import React, { Component } from "react";
import { _getCompanyList, _inviteCompanyForProject } from "../../../../api";
import swal from "sweetalert2";
import { MDBDataTable } from "mdbreact";
import { Link } from "react-router-dom";
// import Moment from "react-moment";

export default class ProjectInvitation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projectId: this.props.match.params.id,
      projectType: this.props.match.params.projectTypeId,
      LanguageId: localStorage.getItem("LanguageId"),
      companyList: [],
      message: "",
      companyId: "",
      EkGider: "",
      ConstantValuesOfInvited: {
        BackButtonText: "",
        HeadText: "",
        InviteTextDT: "",
        InviteButtonTextDT: "",
        EkGiderTextDT: "",
        EkGiderPlaceHolder: "",
        CompanyNameTextDT: "",
        SAPCodeText: ""
      }
    };
    this.handleChange = this.handleChange.bind(this);
    this.inviteCompany = this.inviteCompany.bind(this);
    this.ConstantValuesInvited = this.ConstantValuesInvited.bind(this);
  }

  handleChange(e) {
    let change = {};
    change[e.target.name] = e.target.value;
    this.setState(change);
   // console.log(e.target.name + ": " + e.target.value);
  }
  componentDidMount() {
    this.ConstantValuesInvited();
    this.getCompanyList();

  }

  getCompanyList() {
    _getCompanyList().then(response => {
      this.setState({
        companyList: response.data.data,
        message: response.data.message
      });
    });
  }

  inviteCompany(e) {
    const obj = {
      ProjectId: this.state.projectId,
      CompanyId: e.target.id,
      EkGider: this.state.EkGider,
      LanguageId: localStorage.getItem("LanguageId")
    };
    _inviteCompanyForProject(obj).then(response => {
      swal(response.data.message);
    });
  }

  ConstantValuesInvited() {
    this.state.LanguageId === "1"
      ? this.setState({
          ConstantValuesOfInvited: {
            BackButtonText: "Geri",
            HeadText: "Firmalar",
            InviteTextDT: "Davet Et",
            InviteButtonTextDT: "Davet Et",
            EkGiderTextDT: "Ek Gider",
            EkGiderPlaceHolder: "Ek Gider Giriniz",
            CompanyNameTextDT: "Firma Adı",
            SAPCodeText: "Firma SAP Kodu"
          }
        })
      : this.setState({
          ConstantValuesOfInvited: {
            BackButtonText: "Back",
            HeadText: "Companies",
            InviteTextDT: "Invite",
            InviteButtonTextDT: "Invite",
            EkGiderTextDT: "Additional Expense",
            EkGiderPlaceHolder: "Enter Additional Expenses",
            CompanyNameTextDT: "Company Name",
            SAPCodeText: "Company SAP Code"
          }
        });
  }

  render() {
    const data = {
      columns: [
        {
          label: this.state.ConstantValuesOfInvited.InviteTextDT,
          field: "detail",
          sort: "asc",
          width: 150
        },
        {
          label: this.state.ConstantValuesOfInvited.EkGiderTextDT,
          field: "ekGider",
          sort: "asc",
          width: 270
        },
        {
          label: this.state.ConstantValuesOfInvited.CompanyNameTextDT,
          field: "companyName",
          sort: "asc",
          width: 270
        },
        {
          label: this.state.ConstantValuesOfInvited.SAPCodeText,
          field: "companySAPCode",
          sort: "asc",
          width: 270
        }
      ],
      rows: this.state.companyList.map(data => {
        return {
          detail: (
            <button
              type="button"
              id={data.companyId}
              className="btn btn-warning"
              onClick={this.inviteCompany}
            >
              {this.state.ConstantValuesOfInvited.InviteButtonTextDT}
            </button>
          ),
          ekGider: (
            <input
              type="number"
              className="form-control"
              placeholder={this.state.ConstantValuesOfInvited.EkGiderPlaceHolder}
              name="EkGider"
              onChange={this.handleChange}
              value={this.state.value}
            />
          ),
          companyName: data.companyName,
          companySAPCode: data.companySAPCode
        };
      })
    };
    return (
      <div id="page-content">
        <div className="row">
          <div className="col-md-12">
            <div className="block full">
              <div className="block-title">
                {this.state.projectType === "1" ? (
                  <Link to="/project-list-for-izocam" className="btn btn-info">
                    {this.state.ConstantValuesOfInvited.BackButtonText}
                  </Link>
                ) : (
                  <Link
                    to="/live-project-list-for-izocam"
                    className="btn btn-info"
                  >
                    {this.state.ConstantValuesOfInvited.BackButtonText}
                  </Link>
                )}
                <h2>
                <strong>{this.state.ConstantValuesOfInvited.HeadText}</strong>
                </h2>
              </div>
              <div className="table-responsive">
                {this.state.message === null ? (
                  <MDBDataTable striped hover data={data} />
                ) : (
                  <p className="alert alert-danger">{this.state.message}</p>
                )}
                {/* <table
                  id="example-datatable"
                  className="table table-vcenter table-condensed table-bordered dataTable no-footer"
                >
                  <thead>
                    <tr role="row">
                      <th className="text-center sorting_asc" style={style2}>
                        Davet Et
                      </th>
                      <th className="sorting" style={style}>
                        Ek Gider
                      </th>
                      <th
                        className="text-center sorting_disabled"
                        style={style}
                      >
                        Firma Adı
                      </th>
                      <th
                        className="sorting"
                        rowSpan="1"
                        colSpan="1"
                        style={style}
                      >
                        Firma SAP Kodu
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.message == null ? (
                      this.state.companyList.map(data => (
                        <tr role="row" className="even">
                          <td className="text-center sorting_1">
                            <button
                              type="button"
                              id={data.companyId}
                              className="btn btn-warning"
                              onClick={this.inviteCompany}
                            >
                              Davet Et
                            </button>
                          </td>
                          <td>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Ek Gider"
                              name="EkGider"
                              onChange={this.handleChange}
                              value={this.state.value}
                            />
                          </td>
                          <td>{data.companyName}</td>
                          <td>{data.companySAPCode}</td>
                        </tr>
                      ))
                    ) : (
                      <td rowSpan="4">{this.state.message}</td>
                    )}
                  </tbody>
                </table> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
