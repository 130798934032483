import React, { Component } from "react";
import CanvasJSReact from "../../../canvasjs.react";
import {
  _getBidderListForProjectNew,
  _getBidderListForChart
} from "../../../api";
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
export default class Helper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataPoints: [],
      currency: "TRY",
      bidderList: [],
      projectId: this.props.id,
      currencCode: "",
      isProjectStart: this.props.started,
      isProjectEnd: false,
      BidComparisonTextChart: "",
      BaseExchangeCurrencyTextChar: "",
      TeklifDateTextChart: "",
      LanguageId: localStorage.getItem("LanguageId")
    };
    this.ConstantValuesThisChart = this.ConstantValuesThisChart.bind(this)
  }
  componentDidMount() {
    this.interval = setInterval(() => {
      this.getBidderList();
    }, 15000);
    this.ConstantValuesThisChart();
  }

  ConstantValuesThisChart() {
    this.state.LanguageId === "1"
      ? this.setState({
          BidComparisonTextChart: "Teklif Karşılaştırması",
          BaseExchangeCurrencyTextChar: "Baz Alınan Kur",
          TeklifDateTextChart: "Teklif Saati"
        })
      : this.setState({
          BidComparisonTextChart: "Bid Comparison",
          BaseExchangeCurrencyTextChar: "Base Exchange Currency",
          TeklifDateTextChart: "Bid Hour"
        });
  }

  getBidderList() {
    _getBidderListForChart(this.state.projectId)
      .then(response => {
        this.setState({
          bidderList:
            response.data.data === undefined
              ? null
              : response.data.data.bidders,
          currency: response.data.data.projectCurrency,
          currencCode:
            response.data.data.projectCurrency === "TRY"
              ? "₺"
              : response.data.data.projectCurrency === "USD"
              ? "$"
              : "€",
          isProjectStart: response.data.data.isProjectEnd,
          isProjectEnd: response.data.data.isProjectEnd
        });
      })
      .catch(error => {
        console.log(error);
      });
  }

  render() {
    // const options = {
    //   animationEnabled: true,
    //   exportEnabled: true,
    //   theme: "light2", //"light1", "dark1", "dark2"
    //   title: {
    //     text: `Baz Alınan Kur ${this.state.currency}`
    //   },
    //   data: [
    //     {
    //       type: "column", //change type to bar, line, area, pie, etc
    //       //indexLabel: "{y}", //Shows y value on all Data Points
    //       animationEnabled: true,
    //       exportEnabled: true,
    //       indexLabelFontColor: "#5A5757",
    //       indexLabelPlacement: "outside",
    //       dataPoints:
    //         this.state.bidderList !== undefined ||
    //         this.state.bidderList !== null ||
    //         this.state.bidderList.length > 0
    //           ? this.state.bidderList.map(data => {
    //               //console.log(data);
    //               return {
    //                 label: data.companyName,
    //                 y: data.newTeklif
    //               };
    //             })
    //           : "Teklif Yok"
    //     }
    //   ]
    // };
    const options = {
      theme: "dark1",
      animationEnabled: true,
      exportEnabled: true,
      title: {
        text: this.state.BidComparisonTextChart
      },
      axisY: {
        title: `${this.state.BaseExchangeCurrencyTextChar} ${this.state.currency}`,
        prefix: this.state.currencCode,
        includeZero: false,
        crosshair: {
          enabled: true,
          snapToDataPoint: true
        }
      },
      axisX: {
        title: this.state.TeklifDateTextChart,
        crosshair: {
          enabled: true,
          snapToDataPoint: true
        }
      },
      data: [
        {
          type: "scatter",
          xValueFormatString: "HH:mm:TT",
          yValueFormatString: `${this.state.currencCode}#,##0.00`,
          markerSize: 10,
          toolTipContent: "{label}: {y}",
          dataPoints:
            this.state.bidderList !== undefined ||
            this.state.bidderList !== null ||
            this.state.bidderList.length > 0
              ? this.state.bidderList.map(data => {
                  //console.log(data);
                  return {
                    x: new Date(data.teklifDate),
                    label: data.companyName,
                    y: data.teklif
                  };
                })
              : "Teklif Yok"
        }
      ]
    };
    return (
      <div id="page-content">
        <div className="row">
          <div className="col-md-12">
            <CanvasJSChart options={options} />
          </div>
        </div>
      </div>
    );
  }
}
