import React, { Component } from "react";
import BidProcedureGeneralTeklifs from "./bidprocedureReportsSubComp/bidProcedureGeneralTeklifs";
import BidProcedureChildElementTeklif from "./bidprocedureReportsSubComp/bidProcedureChildElementTeklif";
export default class BidProcedureReports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projectId: this.props.match.params.id,
      projectType: this.props.match.params.projectTypeId,
      LanguageId: localStorage.getItem("LanguageId"),
      disabledForPdf: false
    };
  }

  render() {
    return (
      <div className="page-content">
        {/* <div className="row">
          <BidProcedureGeneralTeklifs id={this.state.projectId} projectTypeId={this.state.projectType}/>
         
        </div> */}
        <div className="row">
          <BidProcedureChildElementTeklif id={this.state.projectId}/>
        </div>
      </div>
    );
  }
}
