import React, { Component } from "react";
import {
  // _getBidderListForProject,
  _getBidderListForProjectNew,
  _updateCompanyExportStatus,
  _exportToExcelFile,
  _sendMail,
  _getProjctEndDate,
  _getExportPdf,
  _getProjectOtherInformation
} from "../../../../api";
import { MDBDataTable } from "mdbreact";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import XLSX from "xlsx";
import $ from "jquery";
import swal from "sweetalert2";
import Countdown from "react-countdown-now";
import Helper from "../helper";
import print from "print-js";

export default class LiveProjectReports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabledForExcelButton: false,
      projectId: this.props.match.params.id,
      projectTypeId: this.props.match.params.projectTypeId,
      bidderList: [],
      message: "",
      bidderListForExcel: [],
      bidderListForCompany: [],
      companyBid: [],
      bidDetail: [],
      nonBidCount:[],
      winText: "",
      lostText: "",
      endDate: "",
      isProjectStarted: false,
      endDateMessage: "",
      LanguageId: localStorage.getItem("LanguageId"),
      disabledForPdf: false,
      ConstantValueLiveReport: {
        RemainingTimeText: "",
        BackButtonText: "",
        MailSendButtonText: "",
        CompanyNameTextDT: "",
        MemberTextDT: "",
        TeklifDateTextDT: "",
        OldBidTextDT: "",
        NewTeklifTextDT: "",
        TotalTeklifTextDT: "",
        SelectTextDT: "",
        MarkAsWonButtonTextDT: "",
        TenderEndMessageText: "",
        NonMarkAsWonButtonTextDT:"",
        ListCountZeroErrorMessage:"",
        ProjectNameText:"",
        ProjectNumberText:""
      },
      ProjectName :"",
      ProjectNumber:""
    };
    this.onSelect = this.onSelect.bind(this);
    this.exportPdf = this.exportPdf.bind(this);
    this.ConstantValuesLiveReportPage = this.ConstantValuesLiveReportPage.bind(this);
    this.getProjectOtherInformation = this.getProjectOtherInformation.bind(this);
  }
  componentDidMount() {
    //console.clear();
    // _getBidderListForProjectNew(this.state.projectId).then(response => {

    //   console.log("Datamız: ",response.data.data);
    //   console.log("Mesajımız: ",response.data.message)
    // });
    if (localStorage.getItem("LanguageId") === "1") {
      this.setState({ winText: "Kazandı", lostText: "Kaybetti" });
    } else {
      this.setState({ winText: "Winner", lostText: "Loser" });
    }

    this.interval = setInterval(() => {
      this.getBidderList();
    }, 1000);

    this.getProjctEndDate();
this.ConstantValuesLiveReportPage();
this.getProjectOtherInformation();
    // this.getBidderList();
  }

  ConstantValuesLiveReportPage() {
    this.state.LanguageId === "1"
      ? this.setState({
          ConstantValueLiveReport: {
            RemainingTimeText: "Kalan Zaman",
            BackButtonText: "Geri",
            MailSendButtonText: "Mail Gönder",
            CompanyNameTextDT: "Firma İsmi",
            MemberTextDT: "Kullanıcı",
            TeklifDateTextDT: "Telif Saati",
            OldBidTextDT: "Eski Teklif",
            NewTeklifTextDT: "Yeni Teklif",
            TotalTeklifTextDT: "Toplam",
            SelectTextDT: "Seç",
            MarkAsWonButtonTextDT: "Kazandı Olarak İşaretle",
            TenderEndMessageText: "İhale Bitmiştir.",
            NonMarkAsWonButtonTextDT:"Kaybetti Olarak İşaretle",
            ListCountZeroErrorMessage:"Şu An Teklif Veren Bulunmamıştır. Lütfen Tedarikçilerin Teklif Vermesini Bekleyiniz.",
            ProjectNameText:"İhale Adı",
            ProjectNumberText:"İhale Numarası"
          }
        })
      : this.setState({
          ConstantValueLiveReport: {
            RemainingTimeText: "Remaining Time",
            BackButtonText: "Back",
            MailSendButtonText: "Send Mail",
            CompanyNameTextDT: "Company Name",
            MemberTextDT: "Member",
            TeklifDateTextDT: "Bid Hour",
            OldBidTextDT: "Old Bid",
            NewTeklifTextDT: "New Teklif",
            TotalTeklifTextDT: "Total",
            SelectTextDT: "Select",
            MarkAsWonButtonTextDT: "Mark as Won",
            TenderEndMessageText: "Tender Is End",
            NonMarkAsWonButtonTextDT:"Mark As Lost",
            ListCountZeroErrorMessage:"There are currently no Bidders. Please wait for the firms to bid.",
            ProjectNameText:"Tender Name",
            ProjectNumberText:"Tender Number"
          }
        });
  }
  getProjctEndDate() {
    _getProjctEndDate(this.state.projectId, this.state.LanguageId)
      .then(response => {
        this.setState({
          isProjectStarted: response.data.isProjectStarted,
          endDateMessage: response.data.message
        });
        if (response.data.isProjectStarted === true) {
          var date = new Date(response.data.data.endDate);
          var timestamp = date.getTime();
          this.setState({
            disabledForBidElement: false,
            endDate: timestamp
          });
          this.interval = setInterval(() => {
            var nowDate = Date.now();
            var difference = timestamp - nowDate;
            var hede = parseInt(difference / 1000);
            // console.log("hede eski: ",hede);
            if (hede <= 120 && hede > 0) {
              _getProjctEndDate(
                this.state.projectId,
                this.state.LanguageId
              ).then(response => {
                date = new Date(response.data.data.endDate);
                timestamp = date.getTime();
                nowDate = Date.now();
                difference = timestamp - nowDate;
                hede = parseInt(difference / 1000);
                this.setState({
                  disabledForBidElement: false,
                  endDate: timestamp
                });
                // console.log("hede yeni:  ",hede);
              });
            }
            if (hede === 0) {
              this.setState({
                disabledForBidElement: true,
                endDateMessage: "İhale bitmiştir"
              });
              setTimeout(function() {
                //your code to be executed after 1,5 second
              }, 3500);
              window.location.href = `/go-to-live-project-reports/${this.state.projectId}/projectType/${this.state.projectTypeId}`;
            }
          }, 1000);
        } else {
          this.setState({ disabledForBidElement: true });
        }
      })
      .catch(error => {
        console.log(error);
      });
  }
  LanguageTable() {
    const lang = {
      sDecimal: ",",
      sEmptyTable: "Tabloda herhangi bir veri mevcut değil",
      sInfo:
        "_TOTAL_ kayıttan _START_ - _END_ arasındaki kayıtlar gösteriliyor",
      sInfoEmpty: "Kayıt yok",
      sInfoFiltered: "(_MAX_ kayıt içerisinden bulunan)",
      sInfoPostFix: "",
      sInfoThousands: ".",
      sLengthMenu: "Sayfada _MENU_ kayıt göster",
      sLoadingRecords: "Yükleniyor...",
      sProcessing: "İşleniyor...",
      sSearch: "Ara:",
      sZeroRecords: "Eşleşen kayıt bulunamadı",
      oPaginate: {
        sFirst: "İlk",
        sLast: "Son",
        sNext: "Sonraki",
        sPrevious: "Önceki"
      },
      oAria: {
        sSortAscending: ": artan sütun sıralamasını aktifleştir",
        sSortDescending: ": azalan sütun sıralamasını aktifleştir"
      },
      select: {
        rows: {
          _: "%d kayıt seçildi",
          "0": "",
          "1": "1 kayıt seçildi"
        }
      }
    };
    return lang;
  }

  getProjectOtherInformation(){
    _getProjectOtherInformation(this.state.projectId)
    .then(response => {
this.setState({
ProjectName:response.data.data,
ProjectNumber : response.data.message
});

    });
  }

  getBidderList() {
    _getBidderListForProjectNew(this.state.projectId)
      .then(response => {
        this.setState({
          bidderList:
            response.data.data === undefined ? null : response.data.data,
          message: response.data.message
        });
      })
      .catch(error => {
        console.log(error);
      });
  }
  componentWillUnmountMount() {
    clearInterval(this.interval);
  }
  componentDidCatch() {}
  onSelect(e) {
    var id = e.target.id;
    var state = e.target.check;

    const obj = {
      id: id,
      state: state
    };
  }

  onClickConfirm(e) {
    _updateCompanyExportStatus(this.state.projectId, e, true);
  }
  onClickDecline(e) {
    _updateCompanyExportStatus(this.state.projectId, e, false);
  }

  exportToExcelFile() {
    _exportToExcelFile(this.state.projectId, localStorage.getItem("LanguageId"))
      .then(response => {
        this.setState({
          bidderListForExcel: response.data.data,
          bidderListForCompany: response.data.data.company,
          companyBid: response.data.data.companyBid,
 
        });
        //console.log(response.data.data);
      })
      .catch(error => {
        console.log(error);
      });
  }
  exportToExcel() {
    this.setState({ disabledForExcelButton: true });
    _exportToExcelFile(this.state.projectId, localStorage.getItem("LanguageId"))
      .then(response => {
        this.setState({
          bidderListForExcel: response.data.data,
          bidderListForCompany: response.data.data.company,
          companyBid: response.data.data.companyBid,
          bidDetail: response.data.data.companyBid.bidDetail,
          nonBidCount:response.data.data.nonCount
        });
        var downloadLink;
        var dataType = "application/vnd.ms-excel";
        var tableSelect1 = document.getElementById("tbl1");
        var tableSelect2 = document.getElementById("tbl2");
        var tableSelect3 = document.getElementById("tbl3");
        var tableHTML =
          '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8"></head><body>' +
          tableSelect1.outerHTML.replace(/ /g, "%20") +
          tableSelect2.outerHTML.replace(/ /g, "%20") +
          tableSelect3.outerHTML.replace(/ /g, "%20") +
          "</body></html>";

        // Specify file name
        var filename = `${this.state.companyBid.product}.xls`;

        // Create download link element
        downloadLink = document.createElement("a");

        document.body.appendChild(downloadLink);

        if (navigator.msSaveOrOpenBlob) {
          var blob = new Blob(["\ufeff", tableHTML], {
            type: dataType
          });
          navigator.msSaveOrOpenBlob(blob, filename);
        } else {
          // Create a link to the file
          downloadLink.href = "data:" + dataType + ", " + tableHTML;

          // Setting the file name
          downloadLink.download = filename;

          //triggering the function
          downloadLink.click();
          this.setState({ disabledForExcelButton: false });
        }
      })
      .catch(error => {
        console.log(error);
      });
  }
  sendMail() {
    if (
      this.state.bidderListForExcel !== null &&
      this.state.bidderListForExcel !== undefined
    ) {
      _sendMail(this.state.projectId, localStorage.getItem("LanguageId"))
        .then(response => {
          swal(response.data.message);
        })
        .catch(error => {
          console.log(error);
        });
    } else {
      if (localStorage.getItem("LanguageId") === "1") {
        swal("Mail Göndermek İçin Rapor Mevcut Değildir.");
      } else {
        swal("No reports to send mail.");
      }
    }
  }
  exportPdf() {
    this.setState({ disabledForPdf: true });
    _getExportPdf(this.state.projectId, this.state.LanguageId).then(
      response => {
        print({
          printable: response.data.data,
          properties: [
            { field: "companyName", displayName: "Firma Adı" },
            { field: "companyUser", displayName: "Kullanıcı" },
            { field: "bidDate", displayName: "Teklif Tarihi" },
            { field: "baseBid", displayName: "Firma Kurundan" },
            { field: "bid", displayName: "İhale Kurundan" }
          ],
          type: "json",
          gridHeaderStyle: "color: red;  border: 2px solid #3971A5;",
          gridStyle: "border: 2px solid #3971A5;",
          header: `<h3 class="custom-h3">İhaleye Gelen Bütün Teklifler</h3>`
        });
      }
    );
    this.setState({ disabledForPdf: false });
  }
  render() {
    const data = {
      columns: [
        {
          label: this.state.ConstantValueLiveReport.CompanyNameTextDT,
          field: "companyName",
          sort: "asc",
          width: 150
        },
        {
          label: this.state.ConstantValueLiveReport.MemberTextDT,
          field: "companyUser",
          sort: "asc",
          width: 270
        },
        {
          label: this.state.ConstantValueLiveReport.TeklifDateTextDT,
          field: "bidDate",
          sort: "asc",
          width: 200
        },
        {
          label: this.state.ConstantValueLiveReport.OldBidTextDT,
          field: "oldTeklif",
          sort: "asc",
          width: 200
        },
        {
          label: this.state.ConstantValueLiveReport.NewTeklifTextDT,
          field: "newTeklif",
          sort: "asc",
          width: 100
        },
        {
          label: this.state.ConstantValueLiveReport.TotalTeklifTextDT,
          field: "total",
          sort: "asc",
          width: 100
        },
        {
          label: this.state.ConstantValueLiveReport.SelectTextDT,
          field: "sec",
          sort: "asc",
          width: 100
        }
      ],
      rows:
        this.state.bidderList !== undefined && this.state.bidderList !== null
          ? this.state.bidderList.map(data => {
              //console.log(data);
              return {
                companyName: data.companyName,
                companyUser: data.companyUser,
                bidDate: (
                  <Moment format="YYYY-MM-DD HH:mm">{data.teklifDate}</Moment>
                ),
                oldTeklif: data.oldTeklif,
                newTeklif: data.newTeklif,
                total: data.total,
                sec:
                  data.isChecked === true ? (
                    <button
                      className="btn btn-danger"
                      onClick={e => this.onClickDecline(data.memberId)}
                    >
                      {this.state.ConstantValueLiveReport.NonMarkAsWonButtonTextDT}
                    </button>
                  ) : (
                    <button
                      className="btn btn-success"
                      onClick={e => this.onClickConfirm(data.memberId)}
                    >
                      {this.state.ConstantValueLiveReport.MarkAsWonButtonTextDT}
                    </button>
                  )
              };
            })
          : this.state.ConstantValueLiveReport.ListCountZeroErrorMessage
    };
    var disp = {
      display: "none"
    };

    return (
      <div className="col-md-12">
        <div className="col-md-6">
          <div className="form-group">
            <label
              className="col-md-3 control-label"
              htmlFor="example-text-input"
            >
              {this.state.ConstantValueLiveReport.RemainingTimeText}
            </label>

            <label
              className="col-md-3 control-label"
              htmlFor="example-text-input"
            >
              <div className="col-md-5"></div>
              {this.state.endDateMessage === null ? (
                this.state.isProjectStarted === true ? (
                  <b>
                    {this.state.endDate !== null ||
                    this.state.endDate !== undefined ||
                    this.state.endDate !== "" ? (
                      <Countdown date={this.state.endDate} />
                    ) : (
                      <Countdown></Countdown>
                    )}
                  </b>
                ) : (
                  this.state.endDateMessage
                )
              ) : (
                this.state.endDateMessage
              )}
            </label>
          </div>
        </div>
        <div className="block full">
          <div className="block-title">
            <div className="row">
            <Link
              className="btn  btn-info"
              to={`/bid-procedure-project-detail/${this.state.projectId}/projectType/${this.state.projectTypeId}`}
              key="id"
              onClick={e => {
                this.clearInterval();
              }}
            >
              {this.state.ConstantValueLiveReport.BackButtonText}
            </Link>
            <button
              type="button"
              onClick={e => this.exportPdf()}
              className="btn btn-warning"
              disabled={this.state.disabledForPdf}
            >
              Export PDF
            </button>
            <button
              type="button"
              onClick={e => this.exportToExcel()}
              className="btn btn-warning"
              disabled={this.state.disabledForExcelButton}
            >
              Export To Excel
            </button>
            <span></span>
            <button
              className="btn btn-info"
              type="button"
              onClick={e => this.sendMail()}
            >
              {this.state.ConstantValueLiveReport.MailSendButtonText}
            </button>
            <div className="col-md-12">
           <div className="col-md-3">

           <label><strong>{this.state.ConstantValueLiveReport.ProjectNameText}</strong></label> : <span><strong>{this.state.ProjectName}</strong></span>
           </div>
           <div className="col-md-3">
           <label><strong>{this.state.ConstantValueLiveReport.ProjectNumberText}</strong></label> : <span><strong>{this.state.ProjectNumber}</strong></span>
      </div>
           </div>
</div>

          </div>



          <div className="table-responsive">
            {this.state.message === null ? (
              <MDBDataTable
                striped
                hover
                data={data}
                className="grey lighten-4"
              />
            ) : (
              <p className="alert alert-warning">{this.state.ConstantValueLiveReport.ListCountZeroErrorMessage}</p>
            )}
          </div>
        </div>
        <div id="page-content">
          <div className="row">
            <Helper
              id={this.state.projectId}
              started={this.state.isProjectStarted}
            />
          </div>
        </div>
        <div style={disp}>
          <div>
            <table
              border="1"
              style={{
                "border-spacing": "0",
                "border-collapse": "collapse",
                "font-size": "16px"
              }}
              id="tbl1"
            >
              <tr>
                <td style={{ height: "120px" }}>
                  <img
                    src={"https://www.demircode.com/Images/izocam-logo.png"}
                    alt=""
                  />
                </td>
                <td
                  style={{
                    "text-align": "center",
                    "font-size": "30px",
                    "vertical-align": "top"
                  }}
                  colSpan={this.state.bidderListForCompany.length * 2 + 1}
                >
                  <b>
                    TEKLİF KARŞILAŞTIRMA FORMU
                    <br />({this.state.bidderListForExcel.montOfYear})
                  </b>
                </td>
              </tr>
            </table>
            <table
              border="1"
              style={{
                "border-spacing": "0",
                "border-collapse": "collapse",
                "font-size": "16px"
              }}
              id="tbl2"
            >
              <tr>
                <td>Sif No :</td>
                <td>Firma</td>
                {this.state.bidderListForCompany.map(item => {
                  return (
                    <td colSpan="2" style={{ "text-align": "center" }}>
                      <b>{item.companyName}</b>
                    </td>
                  );
                })}
              </tr>
              <tr>
                <td>Tarih : {this.state.bidderListForExcel.dateForToday}</td>
                <td>Adres</td>
                {this.state.bidderListForCompany.map(item => {
                  return (
                    <td colSpan="2" style={{ "text-align": "center" }}>
                      {item.companyAdres}
                    </td>
                  );
                })}
              </tr>
              <tr>
                <td>Talep Eden Kısım</td>
                <td>Telefon</td>
                {this.state.bidderListForCompany.map(item => {
                  return (
                    <td colSpan="2" style={{ "text-align": "center" }}>
                      {item.userTelNo}
                    </td>
                  );
                })}
              </tr>
              <tr>
                <td>Doldurulacak</td>
                <td>Sorumlu Kişi</td>
                {this.state.bidderListForCompany.map(item => {
                  return (
                    <td colSpan="2" style={{ "text-align": "center" }}>
                      {item.companyUser}
                    </td>
                  );
                })}
              </tr>
              <tr>
                <td></td>
                <td></td>
                {this.state.bidderListForCompany.map(item => {
                  return (
                    <React.Fragment>
                      <td style={{ "text-align": "center" }}>CIF</td>
                      <td style={{ "text-align": "center" }}>DDP</td>
                    </React.Fragment>
                  );
                })}
              </tr>
              <tr>
                <td>MALZEME</td>
                <td style={{ "text-align": "center" }}>
                  <b>
                    MİKTAR
                    <br />
                    (MT)
                  </b>
                </td>
                {this.state.bidderListForCompany.map(item => {
                  return (
                    <React.Fragment>
                      <td style={{ "text-align": "center" }}>
                        Birim Fiyat
                        <br />({this.state.bidderListForExcel.projectCurrency} /
                        MT)
                      </td>
                      <td style={{ "text-align": "center" }}>
                        Birim Fiyat
                        <br />({this.state.bidderListForExcel.projectCurrency} /
                        MT)
                      </td>
                    </React.Fragment>
                  );
                })}
              </tr>
              <tr>
                <td>{this.state.companyBid.product}</td>
                <td style={{ "text-align": "center" }}>MT. Gir</td>
                {this.state.bidDetail.map(item => {
                  return (
                    <React.Fragment>
                      <td style={{ "text-align": "center" }}>{item.bid}</td>
                      <td style={{ "text-align": "center" }}>
                        <b>{item.total}</b>
                      </td>
                    </React.Fragment>
                  );
                })}
                  {this.state.nonBidCount.map(data => {
                          return (
                            <React.Fragment>
                              <td style={{ "text-align": "center" }}>-</td>
                              <td style={{ "text-align": "center" }}>-</td>
                            </React.Fragment>
                          );
                        })}
              </tr>
              <tr>
                <td></td>
                <td></td>
                {this.state.bidderListForCompany.map(item => {
                  return (
                    <React.Fragment>
                      <td></td>
                    </React.Fragment>
                  );
                })}
                {this.state.bidderListForCompany.map(item => {
                  return (
                    <React.Fragment>
                      <td></td>
                    </React.Fragment>
                  );
                })}
              </tr>
              <tr>
                <td></td>
                <td></td>
                {this.state.bidderListForCompany.map(item => {
                  return (
                    <React.Fragment>
                      <td></td>
                    </React.Fragment>
                  );
                })}
                {this.state.bidderListForCompany.map(item => {
                  return (
                    <React.Fragment>
                      <td></td>
                    </React.Fragment>
                  );
                })}
              </tr>
              <tr>
                <td>Genel Toplam : </td>
                <td>MT. Toplam</td>
                {this.state.bidDetail.map(item => {
                  return (
                    <React.Fragment>
                      <td style={{ "text-align": "center" }}>{item.bid}</td>
                      <td style={{ "text-align": "center" }}>
                        <b>{item.total}</b>
                      </td>
                    </React.Fragment>
                  );
                })}
                  {this.state.nonBidCount.map(data => {
                          return (
                            <React.Fragment>
                              <td style={{ "text-align": "center" }}>-</td>
                              <td style={{ "text-align": "center" }}>-</td>
                            </React.Fragment>
                          );
                        })}
              </tr>
            </table>
            <table
              width="1000"
              border="1"
              style={{
                "border-spacing": "0",
                "border-collapse": "collapse",
                "font-size": "16px"
              }}
              id="tbl3"
            >
              <tr>
                <td>Teslim Tarihi : </td>
                <td></td>
                {/* {this.state.bidderListForCompany.map(item => {
                  return <td></td>;
                })} */}
                {this.state.bidderListForCompany.map(item => {
                  return (
                    <React.Fragment>
                      <td></td>
                    </React.Fragment>
                  );
                })}
                {this.state.bidderListForCompany.map(item => {
                  return (
                    <React.Fragment>
                      <td></td>
                    </React.Fragment>
                  );
                })}
              </tr>
              <tr>
                <td>Ödeme Şartları :</td>
                <td></td>
                {/* {this.state.bidderListForCompany.map(item => {
                  return <td></td>;
                })} */}
                {this.state.bidderListForCompany.map(item => {
                  return (
                    <React.Fragment>
                      <td></td>
                    </React.Fragment>
                  );
                })}
                {this.state.bidderListForCompany.map(item => {
                  return (
                    <React.Fragment>
                      <td></td>
                    </React.Fragment>
                  );
                })}
              </tr>
              <tr>
                <td
                  style={{
                    height: "120px",
                    "vertical-align": "top",
                    padding: "10px"
                  }}
                  colSpan={this.state.bidderListForCompany.length * 2 + 2}
                >
                  Açıklama Gelecek
                </td>
              </tr>
              <tr>
                <td colSpan={this.state.bidderListForCompany.length * 2 + 2}>
                  <b>NOT : YILLIK ALIM TUTARI "FİYAT GELECEK" ÜZERİNDEDİR.</b>
                </td>
              </tr>
              <tr>
                <td colSpan={this.state.bidderListForCompany.length * 2 + 2}>
                  ARAŞTIRMAYI YAPAN :
                </td>
              </tr>
              <tr>
                <td colSpan={this.state.bidderListForCompany.length * 2 + 2}>
                  İsim :<br />
                  <br />
                  İmza :<br />
                  <br />
                  Tarih : {this.state.bidderListForExcel.dateForToday}
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    );
  }
}
