import React, { Component } from "react";
import { Link } from "react-router-dom";
import { _getBidProcedureProjectListForIzocam } from "../../../../api";
import swal from "sweetalert2";
import { MDBDataTable } from "mdbreact";
import Moment from "react-moment";
export default class ProjectListForIzocam extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bidProjectList: [],
      message: "",
      LanguageId: localStorage.getItem("LanguageId"),
      projectTypeId: 1,
      ProjectStatus: "1",
      ConstantValuesBidList: {
        ProjectStatusLabelText: "",
        ProjectStatusContiunesText: "",
        ProjectStatusCompletedText: "",
        ProjectStatusAllText: "",
        ProjectHeaderText: "",
        GoToDetailTextDT: "",
        GoToDetailButtonTextDT: "",
        InviteTextDT: "",
        InviteButtonTextDT: "",
        CreateChildElementTextDT: "",
        CreateChildElementButtonTextDT: "",
        TenderNameTextDT: "",
        TenderStatusTextDT: "",
        TenderStartDateDT: "",
        TenderEndDateDT: "",
        BidListErrorMessage: "",
        createdUserTextDT: "",
        CreaterNullMessage: ""
      }
    };
    this.handleChange = this.handleChange.bind(this);
    this.ConstantValuesBidListPage = this.ConstantValuesBidListPage.bind(this);
  }

  componentDidMount() {
    this.getBidProcedureProjectListForIzocam();
    this.ConstantValuesBidListPage();
  }

  ConstantValuesBidListPage() {
    this.state.LanguageId === "1"
      ? this.setState({
          ConstantValuesBidList: {
            ProjectStatusLabelText: "Ihale Durumu",
            ProjectStatusContiunesText: "Devam Ediyor",
            ProjectStatusCompletedText: "Tamamlanmış",
            ProjectStatusAllText: "Tümü",
            ProjectHeaderText: "İhaleler",
            GoToDetailTextDT: "Ayrıntıya Git",
            GoToDetailButtonTextDT: "Ayrıntıya Git",
            InviteTextDT: "Davet Et",
            InviteButtonTextDT: "Davet",
            CreateChildElementTextDT: "Alt Element Ekle",
            CreateChildElementButtonTextDT: "Alt Element Ekle",
            TenderNameTextDT: "İhale Adı",
            TenderStatusTextDT: "Durum",
            TenderStartDateDT: "Başlangıç Tarihi",
            TenderEndDateDT: "Bitiş Tarihi",
            BidListErrorMessage:
              "Bu Duruma Ait İhale Bulunamamıştır. Lütfen İhale Durumuny Değiştiriniz",
            createdUserTextDT: "Oluşturan",
            CreaterNullMessage: "Belirtilmemiş"
          }
        })
      : this.setState({
          ConstantValuesBidList: {
            ProjectStatusLabelText: "Tender Status",
            ProjectStatusContiunesText: "Contiunes",
            ProjectStatusCompletedText: "Completed",
            ProjectStatusAllText: "All",
            ProjectHeaderText: "Tenders",
            GoToDetailTextDT: "Go To Detail",
            GoToDetailButtonTextDT: "Go To Detail",
            InviteTextDT: "Invite",
            InviteButtonTextDT: "Invite",
            CreateChildElementTextDT: "Create Sub Element",
            CreateChildElementButtonTextDT: "Create Sub Element",
            TenderNameTextDT: "Tender Name",
            TenderStatusTextDT: "Status",
            TenderStartDateDT: "Start Date",
            TenderEndDateDT: "End Date",
            BidListErrorMessage:
              "There is no tender for this situation. Please Change Tender Status",
            createdUserTextDT: "Creater",
            CreaterNullMessage: "Unspecified"
          }
        });
  }

  handleChange(e) {
    let change = {};
    change[e.target.name] = e.target.value;
    this.setState(change);
    //console.log(e.target.name + ": " + e.target.value);
    _getBidProcedureProjectListForIzocam(e.target.value).then(response => {
      this.setState({
        bidProjectList: response.data.data,
        message: response.data.message
      });
      //console.log(response.data.data);
    });
  }
  getBidProcedureProjectListForIzocam() {
    _getBidProcedureProjectListForIzocam(this.state.ProjectStatus).then(
      response => {
        if (response.data.data !== null && response.data.data !== undefined) {
          this.setState({
            bidProjectList: response.data.data,
            message: response.data.message
          });
           console.log(response.data.data);
        } else {
          swal(response.data.message);
        }
      }
    );
  }
  render() {
    const data = {
      columns: [
        {
          label: this.state.ConstantValuesBidList.GoToDetailTextDT,
          field: "detail",
          sort: "asc",
          width: 150
        },
        {
          label: this.state.ConstantValuesBidList.InviteTextDT,
          field: "invite",
          sort: "asc",
          width: 270
        },
        {
          label: this.state.ConstantValuesBidList.CreateChildElementTextDT,
          field: "childElement",
          sort: "asc",
          width: 200
        },
        {
          label: this.state.ConstantValuesBidList.TenderNameTextDT,
          field: "name",
          sort: "asc",
          width: 120
        },
        {
          label: this.state.ConstantValuesBidList.TenderStartDateDT,
          field: "startDate",
          sort: "asc",
          width: 100
        },
        {
          label: this.state.ConstantValuesBidList.TenderEndDateDT,
          field: "endDate",
          sort: "asc",
          width: 100
        },
        {
          label: "İhale No",
          field: "projectNumber",
          sort: "asc",
          width: 100
        },
        {
          label: "Sap No",
          field: "sapNumber",
          sort: "asc",
          width: 100
        },
        {
          label: this.state.ConstantValuesBidList.TenderStatusTextDT,
          field: "status",
          sort: "asc",
          width: 100
        },
        {
          label: this.state.ConstantValuesBidList.createdUserTextDT,
          field: "createdUser",
          sort: "asc",
          width: 100
        }
      ],
      rows:
        this.state.bidProjectList !== null &&
        this.state.bidProjectList !== undefined &&
        this.state.bidProjectList.length > 0
          ? this.state.bidProjectList.map(data => {
              return {
                detail: (
                  <Link
                    className="btn btn-warning"
                    to={`/bid-procedure-project-detail/${data.id}/projectType/${this.state.projectTypeId}`}
                    key="id"
                  >
                    {this.state.ConstantValuesBidList.GoToDetailButtonTextDT}
                  </Link>
                ),
                invite: (
                  <Link
                    className="btn  btn-warning"
                    to={`/project-invitation/${data.id}/projectType/${this.state.projectTypeId}`}
                    key={data.id}
                  >
                    {this.state.ConstantValuesBidList.InviteButtonTextDT}
                  </Link>
                ),
                childElement: (
                  <Link
                    className="btn  btn-warning"
                    to={`/create-project-alt-element/${data.id}/projectType/${this.state.projectTypeId}`}
                    key={data.id}
                  >
                    {
                      this.state.ConstantValuesBidList
                        .CreateChildElementButtonTextDT
                    }
                  </Link>
                ),
                name: data.name,
                startDate: (
                  <Moment format="YYYY-MM-DD">{data.startDate}</Moment>
                ),
                endDate: <Moment format="YYYY-MM-DD">{data.endDate}</Moment>,
                projectnumber :data.projectNumber,
                sapNumber:data.sapNumber,
                status: data.projectStatus,
                createdUser:
                  data.createdUser === "" || data.createdUser === null || data.createdUser === undefined
                    ? this.state.ConstantValuesBidList.CreaterNullMessage
                    : data.createdUser
              };
            })
          : this.state.ConstantValuesBidList.BidListErrorMessage
    };

    return (
      <div className="row">
        <div className="col-md-12">
          <div className="block full">
            <div className="form-group">
              <div className="col-md-3">
                <label
                  className="col-md-3 control-label"
                  htmlFor="example-text-input"
                >
                  {this.state.ConstantValuesBidList.ProjectStatusLabelText}
                </label>
              </div>
              <div className="col-md-9">
                <select
                  className="form-form-control-lg"
                  id="ProjectStatus"
                  name="ProjectStatus"
                  onChange={this.handleChange}
                  value={this.state.value}
                >
                  <option value="1">
                    {
                      this.state.ConstantValuesBidList
                        .ProjectStatusContiunesText
                    }
                  </option>
                  <option value="2">
                    {
                      this.state.ConstantValuesBidList
                        .ProjectStatusCompletedText
                    }
                  </option>
                  <option value="3">
                    {this.state.ConstantValuesBidList.ProjectStatusAllText}
                  </option>
                </select>
              </div>
            </div>
            <div className="block-title">
              <h2>
                <strong>
                  {this.state.ConstantValuesBidList.ProjectHeaderText}
                </strong>
              </h2>
            </div>

            <div className="table-responsive">
              {this.state.message === null ? (
                <MDBDataTable striped hover data={data} />
              ) : (
                <p className="alert alert-danger">{this.state.message}</p>
              )}
              {/* <table
                id="example-datatable"
                className="table table-vcenter table-condensed table-bordered dataTable no-footer"
                role="grid"
                aria-describedby="example-datatable_info"
              >
                <thead>
                  <tr role="row">
                    <th
                      className="text-center sorting_asc"
                      aria-controls="example-datatable"
                      aria-sort="ascending"
                    >
                      Ayrıntıya Git
                    </th>
                    <th>
                      Davet Et
                    </th>
                    <th>Alt Element Ekle</th>
                    <th className="text-center sorting_disabled">İhale Adı</th>
                    <th
                      className="sorting"
                      aria-controls="example-datatable"
                      aria-label="Client: activate to sort column ascending"
                    >
                      Başlangıç Tarihi
                    </th>
                    <th
                      className="sorting"
                      aria-controls="example-datatable"
                      aria-label="Client: activate to sort column ascending"
                    >
                      Bitiş Tarihi
                    </th>
                    <th
                      className="sorting"
                      aria-controls="example-datatable"
                      aria-label="Client: activate to sort column ascending"
                    >
                      İhale Durumu
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.bidProjectList.map(data => (
                    <tr className="even">
                      <td className="text-center sorting_1">
                        <Link
                          className="btn  btn-warning"
                          to={`bid-procedure-project-detail/${data.id}`}
                          key="id"
                        >
                          Detaya Git
                        </Link>
                      </td>
                      <td className="text-center sorting_1">
                        <Link
                          className="btn  btn-warning"
                          to={`project-invitation/${data.id}`}
                          key={data.id}
                        >
                          Davet Et
                        </Link>
                      </td>
                      <td className="text-center sorting_1">
                        <Link
                          className="btn  btn-warning"
                          to={`create-project-alt-element/${data.id}`}
                          key={data.id}
                        >
                          Alt Element Ekle
                        </Link>
                      </td>

                      <td>{data.name}</td>
                      <td>{data.startDate}</td>
                      <td>{data.endDate}</td>
                      <td>{data.projectStatus}</td>
                    </tr>
                  ))}
                </tbody>
              </table> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
