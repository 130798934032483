import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  _getGeneralInformationForProject,
  _createBidForTheProject,
  _getMyAllBidTheProject
} from "../../../../../api";
import swal from "sweetalert2";
import Moment from "react-moment";
import print from "print-js";

export default class GeneralInformationForCompany extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projectId: this.props.id,
      projectGeneralInformation: [],
      message: "",
      CompanyId: localStorage.getItem("CompanyId"),
      LanguageId: localStorage.getItem("LanguageId"),
      MemberId: localStorage.getItem("UserId"),
      Bid: "",
      ProjeDetayiText: "",
      ProjextGeneralnformationText: "",
      GeriButtonText: "",
      StartDateText: "",
      EndDateText: "",
      ProjectTypeText: "",
      CurrencyText: "",
      TeknikSartnameText: "",
      DescriptionText: "",
      DownloadText: "",
      ExportPDFText: "",
      ExportPDFLabelText: "",
      PdfProductText: "",
      PdfBidDateText: "",
      PdfBidText: "",
      disabledPdfButton:false
    };
    this.handleChange = this.handleChange.bind(this);
    this.createBidForTheProject = this.createBidForTheProject.bind(this);
    this.exportToPdfBidProc = this.exportToPdfBidProc.bind(this);
  }

  componentDidMount() {
    if (this.state.LanguageId === "1") {
      this.setState({
        ProjeDetayiText: "İhale Detayı",
        ProjextGeneralnformationText: "İhale Genel Bilgiler",
        GeriButtonText: "Geri",
        StartDateText: "Başlangıç Tarihi",
        EndDateText: "Bitiş Tarihi",
        ProjectTypeText: "İhale Türü",
        CurrencyText: "Döviz Tipi",
        TeknikSartnameText: "Teknik Şartname",
        DescriptionText: "Açıklama",
        DownloadText: "İndir",
        ExportPDFText: "PDF' e Aktar",
        ExportPDFLabelText: "Tüm Tekliflerim",
        PdfProductText: "",
        PdfBidDateText: "",
        PdfBidText: ""
      });
    } else {
      this.setState({
        ProjeDetayiText: "Tender Details",
        ProjextGeneralnformationText: "General Information",
        GeriButtonText: "Back",
        StartDateText: "Start Date",
        EndDateText: "End Date",
        ProjectTypeText: "Tender Type",
        CurrencyText: "Currency Type",
        TeknikSartnameText: "Technical specifications",
        DescriptionText: "Description",
        DownloadText: "Download",
        ExportPDFText: "Export to PDF",
        ExportPDFLabelText: "All My Offers",
        PdfProductText: "Product",
        PdfBidDateText: "Bid Date",
        PdfBidText: "Bid (Valor)"
      });
    }
    this.getGeneralInformationForProject();
  }
  handleChange(e) {
    let change = {};
    change[e.target.name] = e.target.value;
    this.setState(change);
    //console.log(e.target.name + ": " + e.target.value);
  }

  getGeneralInformationForProject() {
    const id = this.state.projectId;
    _getGeneralInformationForProject(id).then(response => {
      this.setState({
        projectGeneralInformation: response.data.data,
        message: response.data.message
      });
      // console.log(response.data.data)
    });
  }

  createBidForTheProject() {
    const obj = {
      ProjectId: this.state.projectId,
      CompanyId: this.state.CompanyId,
      LanguageId: this.state.LanguageId,
      MemberId: this.state.MemberId,
      Bid: this.state.Bid
    };
    _createBidForTheProject(obj).then(response => {
      swal(response.data.message);
      this.getGeneralInformationForProject();
    });
  }
  downloadBase64File(pdf) {
    if (pdf !== null) {
      const linkSource = `data:application/pdf;base64,${pdf}`;
      const downloadLink = document.createElement("a");
      const fileName = "sartname.pdf";

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    } else {
      localStorage.getItem("LanguageId") === "1"
        ? swal("Sistemde ihalenin teknik şartnamesi mevcut değildir.")
        : swal(
            "Technical specifications of the tender are not available on the site."
          );
    }
  }
  exportToPdfBidProc() {
    this.setState({ disabledPdfButton :true});
    _getMyAllBidTheProject(
      this.state.CompanyId,
      this.state.MemberId,
      this.state.projectId,
      this.state.LanguageId
    ).then(response => {
      //console.log(response.data.data);
      print({
        printable: response.data.data.projectProductBid,
        properties: [
          { field: "product", displayName: this.state.LanguageId === "1"? "Ürün":"Product" },
          {field :"quantity",displayName:this.state.LanguageId === "1" ? "Adet":"Quantity"},
          { field: "bidDate", displayName: this.state.LanguageId === "1"? "Teklif Tarihi":"Bid Date" },
          { field: "bid", displayName: this.state.LanguageId === "1"? "Teklif (Valör)":"Bid (Valor)"}
        ],
        type: "json",
        gridHeaderStyle: "color: red;  border: 2px solid #3971A5;",
        gridStyle: "border: 2px solid #3971A5;",
        header: `<h3 class="custom-h3">${response.data.data.projectName}</h3>`
      });
      this.setState({ disabledPdfButton: false });
    });
  }
  render() {
    var style1 = {
      width: "90%"
    };
    return (
      <div className="block">
        <div className="block-title">
          <Link
            to="/project-list-for-company"
            width="25px"
            className="btn btn-info"
          >
            {this.state.GeriButtonText}
          </Link>
          <h2>
            <strong>{this.state.ProjeDetayiText}</strong>
          </h2>
        </div>
        <form className="form-horizontal form-bordered">
          <fieldset>
            <legend>{this.state.ProjextGeneralnformationText}</legend>
            <div className="col-md-6">
              <div className="form-group">
                <label
                  className="col-md-3 control-label"
                  htmlFor="example-text-input"
                >
                  {this.state.StartDateText}
                </label>
                <div className="col-md-9">
                  <label
                    className="col-md-8 control-label"
                    type="date"
                    id="example-text-input"
                  >
                    {this.state.projectGeneralInformation !== null &&
                    this.state.projectGeneralInformation !== undefined ? (
                      <Moment format="YYYY-MM-DD HH:mm">
                        {this.state.projectGeneralInformation.startDate}
                      </Moment>
                    ) : (
                      "No Result"
                    )}
                  </label>
                </div>
              </div>
              <div className="form-group">
                <label
                  className="col-md-3 control-label"
                  htmlFor="example-text-input"
                >
                  {this.state.EndDateText}
                </label>
                <div className="col-md-9">
                  <label
                    className="col-md-8 control-label"
                    type="date"
                    id="example-text-input"
                  >
                    {this.state.projectGeneralInformation !== null &&
                    this.state.projectGeneralInformation !== undefined ? (
                      <Moment format="YYYY-MM-DD HH:mm">
                        {this.state.projectGeneralInformation.endDate}
                      </Moment>
                    ) : (
                      "No Result"
                    )}
                    {/* {this.state.projectGeneralInformation.endDate} */}
                  </label>
                </div>
              </div>
              <div className="form-group">
                <label
                  className="col-md-3 control-label"
                  htmlFor="example-select"
                >
                  {this.state.ProjectTypeText}
                </label>
                <div className="col-md-9">
                  <label
                    className="col-md-3 control-label"
                    type="date"
                    id="example-text-input"
                  >
                    {this.state.projectGeneralInformation.projectType}
                  </label>
                </div>
              </div>
              <div className="form-group">
                <label
                  className="col-md-3 control-label"
                  htmlFor="example-select"
                >
                  {this.state.CurrencyText}
                </label>
                <div className="col-md-9">
                  <label
                    className="col-md-3 control-label"
                    type="date"
                    id="example-text-input"
                  >
                    {this.state.projectGeneralInformation.currency}
                  </label>
                </div>
              </div>
              <div className="form-group">
                <label
                  className="col-md-3 control-label"
                  htmlFor="example-select"
                >
                  {this.state.TeknikSartnameText}
                </label>
                <div className="col-md-9">
                  <button
                    type="button"
                    onClick={e =>
                      this.downloadBase64File(
                        this.state.projectGeneralInformation.teknikSartname
                      )
                    }
                    className="btn btn-warning"
                  >
                    {this.state.DownloadText}
                  </button>
                </div>
              </div>
              <div className="form-group">
                <label
                  className="col-md-3 control-label"
                  htmlFor="example-select"
                >
                  {this.state.ExportPDFLabelText}
                </label>
                <div className="col-md-9">
                  <button
                    type="button"
                    className="btn btn-info"
                    onClick={this.exportToPdfBidProc}
                    disabled={this.state.disabledPdfButton}
                  >
                    {this.state.ExportPDFText}
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label
                  className="col-md-3 control-label"
                  htmlFor="example-text-input"
                >
                  {this.state.DescriptionText}
                </label>
                <div className="col-md-9">
                  <textarea
                    className="col-md-3 control-label"
                    style={style1}
                    rows="7"
                    disabled
                    id="example-text-input"
                    value={this.state.projectGeneralInformation.longDescription}
                  ></textarea>
                </div>
              </div>
              {/* <fieldset>
                <legend>Teklif Vermek İçin</legend>
                <div className="form-group">
                  <label
                    className="col-md-3 control-label"
                    htmlFor="example-text-input"
                  >
                    Teklif Ver
                  </label>
                  <div className="col-md-6">
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Teklif Ver"
                      name="Bid"
                      id="Bid"
                      onChange={this.handleChange}
                      value={this.state.value}
                    />
                  </div>
                  <div className="col-md-3">
                    <button
                      type="button"
                      className="btn btn-warning"
                      onClick={this.createBidForTheProject}
                    >
                      Teklif ver
                    </button>
                  </div>
                </div>
              </fieldset> */}
            </div>
          </fieldset>
        </form>
      </div>
    );
  }
}
