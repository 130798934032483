import axios from "axios";
import store from "./store";
import { _errCall, _toggleLoader } from "./redux/actions";
/*

<httpProtocol>
      <customHeaders>
        <add name="Access-Control-Allow-Origin" value="*" />
        <add name="Access-Control-Allow-Methods" value="*" />
        <add name="Access-Control-Allow-Headers" value="*" />
        <add name="Access-Control-Allow-Credentials" value="true" />
      </customHeaders>
    </httpProtocol>

 */
/*** CONSTANTS ***/
//"https://localhost:44391/api/page";
// let ROOT_URL = "";

// let ROOT_URL_COMPANY = "/api/api/company";
// let ROOT_URL_PAGE = "/api/api/page";
// let ROOT_URL_IZOCAM = "/api/api/izocam";
// let ROOT_URL_LOGIN = "/api/api/login";

let ROOT_URL_COMPANY = "https://ihaleportalapi.demircode.com/api/company";
let ROOT_URL_PAGE = "https://ihaleportalapi.demircode.com/api/page";
let ROOT_URL_IZOCAM = "https://ihaleportalapi.demircode.com/api/izocam";
let ROOT_URL_LOGIN = "https://ihaleportalapi.demircode.com/api/login";
let ROOT_URL_COMMON = "https://ihaleportalapi.demircode.com/api/common";

// let ROOT_URL_COMPANY = "http://ihaleapi.izocam.com.tr/api/company";
// let ROOT_URL_PAGE = "http://ihaleapi.izocam.com.tr/api/page";
// let ROOT_URL_IZOCAM = "http://ihaleapi.izocam.com.tr/api/izocam";
// let ROOT_URL_LOGIN = "http://ihaleapi.izocam.com.tr/api/login";
// let ROOT_URL_COMMON = "http://ihaleapi.izocam.com.tr/api/common";

// let ROOT_URL_COMPANY = "https://ihaleapi.azurewebsites.net/api/company";
// let ROOT_URL_PAGE = "https://ihaleapi.azurewebsites.net/api/page";
// let ROOT_URL_IZOCAM = "https://ihaleapi.azurewebsites.net/api/izocam";
// let ROOT_URL_LOGIN = "https://ihaleapi.azurewebsites.net/api/login";
//

/*** SUBSCRIPTION SERVICES ***/
/*** SERVICES ***/

export async function postUserLogin(credentials) {
  const config = { timeout: 60000 };
  const url = `${ROOT_URL_LOGIN}/be-login`;
  return await axios.post(url, credentials, config);
}

export async function _getMyPageList() {
  const Token = getToken();
  const MemberId = getUserId();
  const LanguageId = getLanguageId();
  const url = `${ROOT_URL_PAGE}/my-page-list/${MemberId}?LanguageId=${LanguageId}`;
  const config = { timeout: 60000, headers: { Token: Token } };
  return await axios.get(url, config).then(handleSuccess, handleError);
}
export async function _getCompanyList() {
  const Token = getToken();
  const LanguageId = getLanguageId();
  const url = `${ROOT_URL_IZOCAM}/get-all-company/${LanguageId}`;
  const config = { timeout: 60000, headers: { Token: Token }};
  return await axios.get(url, config).then(handleSuccess, handleError);
}

export async function _createCompany(data) {
  const Token = getToken();
  const config = { timeout: 60000, headers: { Token: Token } };
  const url = `${ROOT_URL_IZOCAM}/create-company`;
  return axios.post(url, data, config);
}
export async function _createIzocamUser(data) {
  const Token = getToken();
  const config = { timeout: 60000, headers: { Token: Token } };
  const url = `${ROOT_URL_IZOCAM}/create-izocam-user`;
  return axios.post(url, data, config);
}
export async function _createCompanyUser(data) {
  const Token = getToken();
  const config = { timeout: 60000, headers: { Token: Token } };
  const url = `${ROOT_URL_IZOCAM}/create-company-user`;
  return axios.post(url, data, config);
}

export async function _postFilesExample(data) {
  const Token = getToken();
  const config = { timeout: 60000, headers: { Token: Token } };
  const url = `${ROOT_URL_IZOCAM}/post-filess`;
  //const url = `${ROOT_URL_IZOCAM}/post-files`;
  return axios.post(url, data, config);
}

export async function _getBidProcedureProjectListForIzocam(ProjectStatus) {
  const Token = getToken();
  const LanguageId = getLanguageId();
  const url = `${ROOT_URL_IZOCAM}/get-bid-procedures-list?LanguageId=${LanguageId}&ProjectStatus=${ProjectStatus}`;
  const config = { timeout: 60000, headers: { Token: Token } };
  return await axios.get(url, config).then(handleSuccess, handleError);
}

export async function _getGeneralInformationForProject(id) {
  const Token = getToken();
  const LanguageId = getLanguageId();
  const url = `${ROOT_URL_IZOCAM}/get-project-general-information?ProjectId=${id}&LanguageId=${LanguageId}`;
  const config = { timeout: 60000, headers: { Token: Token } };
  return await axios.get(url, config).then(handleSuccess, handleError);
}

//Canlı ihale rapor için gelen teklifler
export async function _getBidderListForProject(id) {
  const Token = getToken();
  const LanguageId = getLanguageId();
  const url = `${ROOT_URL_IZOCAM}/project-bidders-list?ProjectId=${id}&LanguageId=${LanguageId}`;
  const config = { timeout: 60000, headers: { Token: Token } };
  return await axios.get(url, config).then(handleSuccess, handleError);
}

export async function _getBidderListForProjectNew(id) {
  const Token = getToken();
  const LanguageId = getLanguageId();
  const url = `${ROOT_URL_IZOCAM}/project-bidders-list-new?ProjectId=${id}&LanguageId=${LanguageId}`;
  const config = { timeout: 60000, headers: { Token: Token } };
  return await axios.get(url, config).then(handleSuccess, handleError);
}

export async function _getProjectOtherInformation(ProjectId) {
  const Token = getToken();
  const LanguageId = getLanguageId();
  const url = `${ROOT_URL_IZOCAM}/project/other-information?ProjectId=${ProjectId}&LanguageId=${LanguageId}`;
  const config = { timeout: 60000, headers: { Token: Token } };
  return await axios.get(url, config).then(handleSuccess, handleError);
}

export function _updateCompanyExportStatus(id, memberId, status) {
  const Token = getToken();
  const url = `${ROOT_URL_IZOCAM}/update-project-export-status?ProjectId=${id}&MemberId=${memberId}&Status=${status}`;
  const config = { timeout: 60000, headers: { Token: Token } };
  axios.post(url, config);
}

export async function _getInvitedListForProject(id) {
  const Token = getToken();
  const LanguageId = getLanguageId();
  const url = `${ROOT_URL_IZOCAM}/project-invited-list?ProjectId=${id}&LanguageId=${LanguageId}`;
  const config = { timeout: 60000, headers: { Token: Token } };
  return await axios.get(url, config).then(handleSuccess, handleError);
}

export async function _deleteProjectInvitation(
  ProjectId,
  CompanyId,
  ProjectType,
  IsProjectStart,
  LanguageId
) {
  const Token = getToken();
  const config = { timeout: 60000, headers: { Token: Token } };
  const url = `${ROOT_URL_IZOCAM}/delete-project-invitation?ProjectId=${ProjectId}&CompanyId=${CompanyId}&ProjectType=${ProjectType}&IsProjectStart=${IsProjectStart}&LanguageId=${LanguageId}`;
  return axios.get(url, config);
}

export async function _deleteChildElement(
  ProjectId,
  AltElementId,
  ProjectType,
  IsProjectStart,
  LanguageId
) {
  const Token = getToken();
  const config = { timeout: 60000, headers: { Token: Token } };
  const url = `${ROOT_URL_IZOCAM}/delete-project-child-element?ProjectId=${ProjectId}&AltElementId=${AltElementId}&ProjectType=${ProjectType}&IsProjectStart=${IsProjectStart}&LanguageId=${LanguageId}`;
  return axios.get(url, config);
}


export async function _getIsProjectStart(ProjectId) {
  const Token = getToken();
  const url = `${ROOT_URL_IZOCAM}/is-project-start?ProjectId=${ProjectId}`;
  const config = { timeout: 60000, headers: { Token: Token } };
  return await axios.get(url, config).then(handleSuccess, handleError);
}

export async function _getChildelementListForProjectWithById(id, LanguageId) {
  const Token = getToken();
  //const LanguageId = getLanguageId();
  const url = `${ROOT_URL_IZOCAM}/project-child-list?ProjectId=${id}&LanguageId=${LanguageId}`;
  const config = { timeout: 60000, headers: { Token: Token } };
  return await axios.get(url, config).then(handleSuccess, handleError);
}
export async function _getProjectName(id) {
  const Token = getToken();
  const LanguageId = getLanguageId();
  const url = `${ROOT_URL_IZOCAM}/get-project-name?ProjectId=${id}&LanguageId=${LanguageId}`;
  const config = { timeout: 60000, headers: { Token: Token } };
  return await axios.get(url, config).then(handleSuccess, handleError);
}

export async function _createChildElement(data) {
  const Token = getToken();
  const config = { timeout: 60000, headers: { Token: Token } };
  const url = `${ROOT_URL_IZOCAM}/create-project-child`;
  return axios.post(url, data, config);
}

export async function _getMyCompanyInvitedBidProcedureProjectList(data) {
  const Token = getToken();
  const config = { timeout: 60000, headers: { Token: Token } };
  const url = `${ROOT_URL_COMPANY}/my-company-project-list`;
  return axios.post(url, data, config);
}
export async function _createBidForTheProject(data) {
  const Token = getToken();
  const config = { timeout: 60000, headers: { Token: Token } };
  const url = `${ROOT_URL_COMPANY}/bid-for-the-project`;
  return axios.post(url, data, config);
}

export async function _bidChildElement(data) {
  const Token = getToken();
  const config = { timeout: 60000, headers: { Token: Token } };
  const url = `${ROOT_URL_COMPANY}/bid-for-the-project-child-element`;
  return axios.post(url, data, config);
}

export async function _inviteCompanyForProject(data) {
  const Token = getToken();
  const config = { timeout: 60000, headers: { Token: Token } };
  const url = `${ROOT_URL_COMPANY}/invite-company-to-project`;
  return axios.post(url, data, config);
}

export async function _getMyLastBid(ProjectId, LanguageId, MemberId) {
  const Token = getToken();
  const url = `${ROOT_URL_COMPANY}/my-last-bid-for-project?ProjectId=${ProjectId}&LanguageId=${LanguageId}&MemberId=${MemberId}`;
  const config = { timeout: 60000, headers: { Token: Token } };
  return await axios.get(url, config).then(handleSuccess, handleError);
}

export async function _getMyAllBidTheProject(CompanyId,MemberId,ProjectId,LanguageId) {
  const Token = getToken();
  const url = `${ROOT_URL_COMPANY}/my-all-bid-the-bid-project?CompanyId=${CompanyId}&MemberId=${MemberId}&ProjectId=${ProjectId}&LanguageId=${LanguageId}`;
  const config = { timeout: 60000, headers: { Token: Token } };
  return await axios.get(url, config).then(handleSuccess, handleError);
}
export async function _giveBidForTheLiveProject(data) {
  const Token = getToken();
  const config = { timeout: 60000, headers: { Token: Token } };
  const url = `${ROOT_URL_COMPANY}/give-bid-for-the-live-project`;
  return axios.post(url, data, config);
}

export async function _getCheckNumberofOffers(ProjectId, MemberId) {
  const Token = getToken();
  const url = `${ROOT_URL_COMPANY}/check-number-of-offers?MemberId=${MemberId}&ProjectId=${ProjectId}`;
  const config = { timeout: 60000, headers: { Token: Token } };
  return await axios.get(url, config).then(handleSuccess, handleError);
}

export async function _getProjctEndDate(ProjectId, LanguageId, MemberId) {
  const Token = getToken();
  const url = `${ROOT_URL_COMPANY}/project-end-date?ProjectId=${ProjectId}&LanguageId=${LanguageId}`;
  const config = { timeout: 60000, headers: { Token: Token } };
  return await axios.get(url, config).then(handleSuccess, handleError);
}
export async function _getLiveProjectDetail(ProjectId, LanguageId, MemberId) {
  const Token = getToken();
  const url = `${ROOT_URL_COMPANY}/live-project-detail?ProjectId=${ProjectId}&LanguageId=${LanguageId}`;
  const config = { timeout: 60000, headers: { Token: Token } };
  return await axios.get(url, config).then(handleSuccess, handleError);
}

export async function _createProject(data) {
  const Token = getToken();
  const config = { timeout: 60000, headers: { Token: Token } };
  const url = `${ROOT_URL_IZOCAM}/create-project`;
  return axios.post(url, data, config);
}

//get-live-project-list
export async function _getLiveProjectListForIzocam(LanguageId, projectStatus) {
  const Token = getToken();
  const url = `${ROOT_URL_IZOCAM}/get-live-project-list/${LanguageId}?ProjectStatus=${projectStatus}`;
  const config = { timeout: 60000, headers: { Token: Token } };
  return await axios.get(url, config).then(handleSuccess, handleError);
}

//project-child-element-list-for-report

export async function _getProjectChildelementListForReport(
  ProjectId,
  LanguageId
) {
  const Token = getToken();
  const url = `${ROOT_URL_IZOCAM}/project-child-element-list-for-report?ProjectId=${ProjectId}&LanguageId=${LanguageId}`;
  const config = { timeout: 60000, headers: { Token: Token } };
  return await axios.get(url, config).then(handleSuccess, handleError);
}

//project-child-element-bidders-list
export async function _getBiddersListForProjectChildElement(
  ProjectChildElementId,
  LanguageId
) {
  const Token = getToken();
  const url = `${ROOT_URL_IZOCAM}/project-child-element-bidders-list?ProjectChildElementId=${ProjectChildElementId}&LanguageId=${LanguageId}`;
  const config = { timeout: 60000, headers: { Token: Token } };
  return await axios.get(url, config).then(handleSuccess, handleError);
}

export async function _getMyProfileInformation(MemberId, LanguageId) {
  const Token = getToken();
  const url = `${ROOT_URL_COMPANY}/get-my-profile-information?MemberId=${MemberId}&LanguageId=${LanguageId}`;
  const config = { timeout: 60000, headers: { Token: Token } };
  return await axios.get(url, config).then(handleSuccess, handleError);
}

export async function _updateProfile(data) {
  const Token = getToken();
  const config = { timeout: 60000, headers: { Token: Token } };
  const url = `${ROOT_URL_COMPANY}/update-my-profile`;
  return axios.post(url, data, config);
}

export async function _exportToExcelFile(ProjectId, LanguageId) {
  const Token = getToken();
  const url = `${ROOT_URL_IZOCAM}/excel-export-new?ProjectId=${ProjectId}&LanguageId=${LanguageId}`;
  const config = { timeout: 60000, headers: { Token: Token } };
  return await axios.get(url, config).then(handleSuccess, handleError);
}

//Güncelleme bilgisi çekmek için
export async function _getProjectInformation(ProjectId, LanguageId) {
  const Token = getToken();
  const url = `${ROOT_URL_IZOCAM}/get-project-information?ProjectId=${ProjectId}&LanguageId=${LanguageId}`;
  const config = { timeout: 60000, headers: { Token: Token } };
  return await axios.get(url, config).then(handleSuccess, handleError);
}

//güncellemek için
export async function _updateProjectInfprmation(data) {
  const Token = getToken();
  const config = { timeout: 60000, headers: { Token: Token } };
  const url = `${ROOT_URL_IZOCAM}/update-project-information`;
  return axios.post(url, data, config);
}

export async function _updateProjectEndDate(data) {
  const Token = getToken();
  const config = { timeout: 60000, headers: { Token: Token } };
  const url = `${ROOT_URL_IZOCAM}/update-project-end-date`;
  return axios.post(url, data, config);
}

export async function _sendMail(ProjectId, LanguageId) {
  const Token = getToken();
  const url = `${ROOT_URL_IZOCAM}/send-mail-for-the-project?ProjectId=${ProjectId}&LanguageId=${LanguageId}`;
  const config = { timeout: 60000, headers: { Token: Token } };
  return await axios.get(url, config).then(handleSuccess, handleError);
}

//get-my-last-bid-for-child-element
export async function _getMyLastBidForTheChildElement(
  ProjectChildElementId,
  MemberId,
  LanguageId
) {
  const Token = getToken();
  const url = `${ROOT_URL_COMPANY}/get-my-last-bid-for-child-element?ProjectChildElementId=${ProjectChildElementId}&MemberId=${MemberId}&LanguageId=${LanguageId}`;
  const config = { timeout: 60000, headers: { Token: Token } };
  return await axios.get(url, config).then(handleSuccess, handleError);
}

export async function _getIzocamUsers(LanguageId) {
  const Token = getToken();
  const url = `${ROOT_URL_IZOCAM}/get-all-izocam-user?LanguageId=${LanguageId}`;
  const config = { timeout: 60000, headers: { Token: Token } };
  return await axios.get(url, config).then(handleSuccess, handleError);
}

export async function _getUserInformationForManager(MemberId, LanguageId) {
  const Token = getToken();
  const url = `${ROOT_URL_IZOCAM}/get-user-information-for-manager?MemberId=${MemberId}&LanguageId=${LanguageId}`;
  const config = { timeout: 60000, headers: { Token: Token } };
  return await axios.get(url, config).then(handleSuccess, handleError);
}

export async function _managerUpdateUser(data, LanguageId) {
  const Token = getToken();
  const config = { timeout: 60000, headers: { Token: Token } };
  const url = `${ROOT_URL_IZOCAM}/manager-user-update?LanguageId=${LanguageId}`;
  return axios.post(url, data, config);
}
export async function _getCompanyUsers(LanguageId) {
  const Token = getToken();
  const url = `${ROOT_URL_IZOCAM}/get-all-company-user?LanguageId=${LanguageId}`;
  const config = { timeout: 60000, headers: { Token: Token } };
  return await axios.get(url, config).then(handleSuccess, handleError);
}

export async function _getChildElementInformation(ChildElementId, LanguageId) {
  const Token = getToken();
  const url = `${ROOT_URL_IZOCAM}/child-element-information?ChildElementId=${ChildElementId}&LanguageId=${LanguageId}`;
  const config = { timeout: 60000, headers: { Token: Token } };
  return await axios.get(url, config).then(handleSuccess, handleError);
}

export async function _updateAltElementServices(data) {
  const Token = getToken();
  const config = { timeout: 60000, headers: { Token: Token } };
  const url = `${ROOT_URL_IZOCAM}/update-child-element`;
  return axios.post(url, data, config);
}

//get-company-information
export async function _getCompanyInformation(CompanyId, LanguageId) {
  const Token = getToken();
  const url = `${ROOT_URL_IZOCAM}/get-company-information?CompanyId=${CompanyId}&LanguageId=${LanguageId}`;
  const config = { timeout: 60000, headers: { Token: Token } };
  return await axios.get(url, config).then(handleSuccess, handleError);
}

export async function _updateCompanyWithById(data) {
  const Token = getToken();
  const config = { timeout: 60000, headers: { Token: Token } };
  const url = `${ROOT_URL_IZOCAM}/update-company`;
  return axios.post(url, data, config);
}

export async function _updatProjectInf(data) {
  const Token = getToken();
  const config = { timeout: 60000, headers: { Token: Token } };
  const url = `${ROOT_URL_IZOCAM}/update-project-information`;
  return axios.post(url, data, config);
}

export async function _getBidderListForChart(ProjectId) {
  const Token = getToken();
  const config = { timeout: 60000, headers: { Token: Token } };
  const url = `${ROOT_URL_IZOCAM}/bidder-list-for-chart?ProjectId=${ProjectId}`;
  return axios.get(url, config).then(handleSuccess, handleError);
}

export async function _getExcelReportForChild(ProjectId) {
  const Token = getToken();
  const config = { timeout: 600000, headers: { Token: Token } };
  const url = `${ROOT_URL_IZOCAM}/excel-report-bid-procedure?ProjectId=${ProjectId}`;
  return axios.get(url, config).then(handleSuccess, handleError);
}

export async function _getExportPdf(ProjectId, LanguageId) {
  const Token = getToken();
  const config = { timeout: 600000, headers: { Token: Token } };
  const url = `${ROOT_URL_IZOCAM}/export-to-excel-file?ProjectId=${ProjectId}&LanguageId=${LanguageId}`;
  return axios.get(url, config).then(handleSuccess, handleError);
}

export async function _getExportPdfForChild(ProjectId) {
  const Token = getToken();
  const config = { timeout: 600000, headers: { Token: Token } };
  const url = `${ROOT_URL_IZOCAM}/pdf-report-for-child?ProjectId=${ProjectId}`;
  return axios.get(url, config).then(handleSuccess, handleError);
}

export async function _getExportPdfForChildWithById(
  ProjectId,
  ProjectAltElementId
) {
  const Token = getToken();
  const config = { timeout: 600000, headers: { Token: Token } };
  const url = `${ROOT_URL_IZOCAM}/get-pdf-export-child-with-byId?ProjectId=${ProjectId}&ProjectAltElementId=${ProjectAltElementId}`;
  return axios.get(url, config).then(handleSuccess, handleError);
}

export async function _ProjectClone(data) {
  const Token = getToken();
  const config = { timeout: 60000, headers: { Token: Token } };
  const url = `${ROOT_URL_IZOCAM}/project-clone`;
  return axios.post(url, data, config);
}

export async function _getChildElementName(ProjectAltElementId,LanguageId) {
         const Token = getToken();
         const config = { timeout: 600000, headers: { Token: Token } };
         const url = `${ROOT_URL_COMPANY}/child-element-name?ChildElementId=${ProjectAltElementId}&LanguageId=${LanguageId}`;
         return axios.get(url, config).then(handleSuccess, handleError);
}

export async function _updateCurrencyRates() {
  const Token = getToken();
  const config = { timeout: 600000, headers: { Token: Token } };
  const url = `${ROOT_URL_IZOCAM}/currency/update`;
  return axios.get(url, config).then(handleSuccess, handleError);
}

/*** naci olut ****/

export async function _PaymentMethod(LanguageId) {
  const Token = getToken();
  //const LanguageId = getLanguageId();
  const url = `${ROOT_URL_COMMON}/PaymentMethod?LanguageId=${LanguageId}`;
  const config = { timeout: 60000, headers: { Token: Token } };
  return await axios.get(url, config).then(handleSuccess, handleError);
}
export async function _ProductionAres(LanguageId) {
  const Token = getToken();
  //const LanguageId = getLanguageId();
  const url = `${ROOT_URL_COMMON}/ProductionAres?LanguageId=${LanguageId}`;
  const config = { timeout: 60000, headers: { Token: Token } };
  return await axios.get(url, config).then(handleSuccess, handleError);
}
export async function _accountList(LanguageId) {
  const Token = getToken();
  //const LanguageId = getLanguageId();
  const url = `${ROOT_URL_COMMON}/AccountList?LanguageId=${LanguageId}`;
  const config = { timeout: 60000, headers: { Token: Token } };
  return await axios.get(url, config).then(handleSuccess, handleError);
}
export async function _Materials(LanguageId,productionAreCode) {
  const Token = getToken();
  //const LanguageId = getLanguageId();
  const url = `${ROOT_URL_COMMON}/Materials?LanguageId=${LanguageId}&productionAreCode=${productionAreCode}`;
  const config = { timeout: 60000, headers: { Token: Token } };
  return await axios.get(url, config).then(handleSuccess, handleError);
}

export async function _ProjectBuyGroups(LanguageId) {
  const Token = getToken();
  //const LanguageId = getLanguageId();
  const url = `${ROOT_URL_COMMON}/ProjectBuyGroups?LanguageId=${LanguageId}`;
  const config = { timeout: 60000, headers: { Token: Token } };
  return await axios.get(url, config).then(handleSuccess, handleError);
}
export async function _SaveProjectSapSync(data)
{
  const Token = getToken();
  const config = { timeout: 600000, headers: { Token: Token } };
  const url = `${ROOT_URL_COMMON}/SaveProjectSapSync`;
  return axios.post(url,data,config);
}
export async function _SaveCompanyBidSapSync(data)
{
  const Token = getToken();
  const config = { timeout: 600000, headers: { Token: Token } };
  const url = `${ROOT_URL_COMMON}/SaveCompanyBidSapSync`;
  return axios.post(url,data,config);
}
export async function _syncStatus(LanguageId,projectId) {
  const Token = getToken();
  //const LanguageId = getLanguageId();
   const url = `${ROOT_URL_COMMON}/SyncStatus?LanguageId=${LanguageId}&ProjeId=${projectId}`;
  //const url = `${ROOT_URL_COMMON}/GetApi`;
  const config = { timeout: 60000, headers: { Token: Token } };
  return await axios.get(url, config).then(handleSuccess, handleError);
}
export async function _mainSet(data)
{
  const Token = getToken();
  const config = { timeout: 600000, headers: { Token: Token } };
  const url = `${ROOT_URL_COMMON}/mainSet`;
  return axios.post(url,data,config);
}

export async function _materialList()
{
  const Token = getToken();
  //const LanguageId = getLanguageId();
   const url = `${ROOT_URL_COMMON}/material/list`;
  //const url = `${ROOT_URL_COMMON}/GetApi`;
  const config = { timeout: 60000, headers: { Token: Token } };
  return await axios.get(url, config).then(handleSuccess, handleError);
}
export async function _accountListWithWerks(Werks)
{
  const Token = getToken();
  //const LanguageId = getLanguageId();
   const url = `${ROOT_URL_COMMON}/account/list/${Werks}`;
  //const url = `${ROOT_URL_COMMON}/GetApi`;
  const config = { timeout: 60000, headers: { Token: Token } };
  return await axios.get(url, config).then(handleSuccess, handleError);
}
export async function _materialSet(data)
{
  const Token = getToken();
  const config = { timeout: 600000, headers: { Token: Token } };
  const url = `${ROOT_URL_COMMON}/material/save`;
  return axios.post(url,data,config);
}

export async function _materialDetail(MaterialId,LanguageId)
{
  const Token = getToken();
  //const LanguageId = getLanguageId();
   const url = `${ROOT_URL_COMMON}/material/detail/${MaterialId}/language/${LanguageId}`;
  //const url = `${ROOT_URL_COMMON}/GetApi`;
  const config = { timeout: 60000, headers: { Token: Token } };
  return await axios.get(url, config).then(handleSuccess, handleError);
}

export async function _IsMyFirstBid(ProjectId,LanguageId,CompanyId,MemberId)
{
 // project/live/{ProjectId}/langauge/{LanguageId}/company/{CompanyId}/member/{MemberId}
  
  const Token = getToken();
  //const LanguageId = getLanguageId();
   const url = `${ROOT_URL_COMPANY}/project/live/${ProjectId}/langauge/${LanguageId}/company/${CompanyId}/member/${MemberId}`;
  //const url = `${ROOT_URL_COMMON}/GetApi`;
  const config = { timeout: 60000, headers: { Token: Token } };
  return await axios.get(url, config).then(handleSuccess, handleError);
}


/*** naci olut ****/

/*** HELPER FUNCTIONS ****/
function getToken() {
  store.dispatch(_toggleLoader(true));
  return localStorage.getItem("Token");
}
function getUserId() {
  store.dispatch(_toggleLoader(true));
  return localStorage.getItem("UserId");
}
function getLanguageId() {
  store.dispatch(_toggleLoader(true));
  return localStorage.getItem("LanguageId");
}
function getCompanyId() {
  store.dispatch(_toggleLoader(true));
  return localStorage.getItem("CompanyId");
}

function handleSuccess(response) {
  store.dispatch(_toggleLoader(false));
  return { data: response.data };
}

function handleError(err) {
  store.dispatch(_toggleLoader(false));
  if (!err.status && !err.response) {
    return Promise.reject({
      FriendlyMessage: "İnternet bağlantısında problem"
    });
  }
  const status = err.response.status;
  const error = err.response.data;
  if (status === 400 || status === 403) {
    // swal({
    //   title: error.Title,
    //   text: error.FriendlyMessage,
    //   type: "error",
    //   confirmButtonText: "Tamam"
    // });
    return Promise.reject({
      code: error.Code,
      title: error.Title,
      message: error.FriendlyMessage
    });
  } else if (status === 401 || status === 503) {
    store.dispatch(_errCall());
    return Promise.reject({
      code: status
    });
  }
}
