import React, { Component } from "react";
import {
  _getBiddersListForProjectChildElement,
  _getExcelReportForChild,
  _getExportPdfForChildWithById
} from "../../../../../api";
import { MDBDataTable } from "mdbreact";
import Moment from "react-moment";
import print from "print-js";
export default class ChildElementBidListForReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ProjectAltElementId: this.props.id,
      ProjectId: this.props.projectId,
      LanguageId: localStorage.getItem("LanguageId"),
      bidListForChildElement: [],
      message: "",
      disabledForPdf: false,
      ConsntantValueChildReport: {
        HeadText: "",
        CompanyNameTextDT: "",
        BidTextDT: "",
        BidDateTextDT: "",
        ListCountErrorMessage: ""
      }
    };
    this.exportPdf = this.exportPdf.bind(this);
    this.ConstantValueChildElementReport = this.ConstantValueChildElementReport.bind(
      this
    );
  }

  componentDidMount() {
    this.getBiddersListForProjectChildElement();
    this.ConstantValueChildElementReport();
  }

  ConstantValueChildElementReport() {
    this.state.LanguageId === "1"
      ? this.setState({
          ConsntantValueChildReport: {
            HeadText: "Alt Elemente Gelen Teklifler",
            CompanyNameTextDT: "Firma Adı",
            BidTextDT: "Teklif",
            BidDateTextDT: "Teklif Tarihi",
            ListCountErrorMessage: "Henüz Teklif Veren Firma Yok"
          }
        })
      : this.setState({
          ConsntantValueChildReport: {
            HeadText: "Sub-Element Offers",
            CompanyNameTextDT: "Company Name",
            BidTextDT: "Bid",
            BidDateTextDT: "Bid Date",
            ListCountErrorMessage: "No Bidding Companies Yet"
          }
        });
  }

  exportPdf() {
    this.setState({ disabledForPdf: true });
    _getExportPdfForChildWithById(
      this.state.ProjectId,
      this.state.ProjectAltElementId
    ).then(response => {
      print({
        printable: response.data,
        properties: [
          { field: "product", displayName: "Ürün" },
          { field: "companyName", displayName: "Firma Adı" },
          { field: "companyUser", displayName: "Kullanıcı" },
          { field: "bidDate", displayName: "Teklif Tarihi" },
          { field: "baseBid", displayName: "Firma Kurundan" },
          { field: "bid", displayName: "İhale Kurundan" }
        ],
        type: "json",
        gridHeaderStyle: "color: red;  border: 2px solid #3971A5;",
        gridStyle: "border: 2px solid #3971A5;",
        header: `<h3 class="custom-h3">Alt Elemente Gelen Bütün Teklifler</h3>`
      });
    });
    this.setState({ disabledForPdf: false });
  }
  getBiddersListForProjectChildElement() {
    _getBiddersListForProjectChildElement(
      this.state.ProjectAltElementId,
      this.state.LanguageId
    ).then(response => {
      this.setState({
        bidListForChildElement:
          response.data.data === undefined ? null : response.data.data,
        message: response.data.message
      });
    });
  }
  render() {
    const dataChild = {
      columns: [
        {
          label: this.state.ConsntantValueChildReport.CompanyNameTextDT,
          field: "companyName",
          sort: "asc",
          width: 150
        },
        {
          label: this.state.ConsntantValueChildReport.BidTextDT,
          field: "bid",
          sort: "asc",
          width: 200
        },
        {
          label: this.state.ConsntantValueChildReport.BidDateTextDT,
          field: "bidDate",
          sort: "asc",
          width: 270
        }
      ],
      rows:
        this.state.bidListForChildElement !== null &&
        this.state.bidListForChildElement !== undefined
          ? this.state.bidListForChildElement.map(data => {
              return {
                companyName: data.companyName,
                bid: data.bid,
                bidDate: (
                  <Moment format="YYYY-MM-DD HH:mm">{data.bidDate}</Moment>
                )
              };
            })
          : this.state.ConsntantValueChildReport.ListCountErrorMessage
    };
    return (
      <div className="col-md-12">
        <div className="block full">
          <div className="block-title">
            <h2>
              <strong>{this.state.ConsntantValueChildReport.HeadText}</strong>{" "}
            </h2>
            <button
              type="button"
              onClick={e => this.exportPdf()}
              className="btn btn-warning"
              disabled={this.state.disabledForPdf}
            >
              Export PDF
            </button>
          </div>

          <div className="table-responsive">
            {this.state.message === null && this.state.bidderList !== null ? (
              <MDBDataTable striped hover data={dataChild} />
            ) : (
              <p className="alert alert-warning">{this.state.ConsntantValueChildReport.ListCountErrorMessage}</p>
            )}
            {/* <table
              id="example-datatable"
              className="table table-vcenter table-condensed table-bordered dataTable no-footer"
            >
              <thead>
                <tr>
                  <th>Firma Adı</th>
                  <th className="text-center sorting_disabled">
                    Teklif Tarihi
                  </th>
                  <th>Teklif</th>
                </tr>
              </thead>
              <tbody>
                {this.state.message == null ? (
                  this.state.bidListForChildElement.map(data => (
                    <tr>
                      <td>{data.companyName}</td>
                      <td>{data.bidDate}</td>
                      <td>{data.bid}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td></td>
                    <td>{this.state.message}</td>
                    <td></td>
                  </tr>
                )}
              </tbody>
            </table> */}
          </div>
        </div>
      </div>
    );
  }
}
