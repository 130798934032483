import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../redux/actions/index";
import avatar from "../styles/img/izocam-logo-png-transparent.png";
import swal from "sweetalert2";
import "./../styles/login.css";
export class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      credentials: {
        Username: "",
        Password: "",
        LanguageId: 1
      }
    };

    this.onUserLogin = this.onUserLogin.bind(this);
  }

  onInputChange = value => {
    this.setState(prevState => ({
      credentials: { ...prevState.credentials, [value.id]: value.value }
    }));
  };

  onUserLogin(e) {
    e.preventDefault();
    if (this.state.credentials.LanguageId === 0) {
      swal("Please Select Language");
    } else {
      this.props._signin(this.state.credentials, () => {
        if(localStorage.getItem("CompanyId")==="1"){
          this.props.history.push("/project-list-for-izocam");
        }
        else{
          this.props.history.push("/project-list-for-company");
        }
      });
    }
  }
  componentDidMount() {
    document.title = "IZOCAM";
  }
  render() {
    var stylesLogo = {
      marginLeft: "33%"
    };
    return (
      <section
        id="login"
        className="bg-light d-flex align-items-center justify-content-center "
      >
        <div className="authentication-bg"></div>  
        <div className="container">
          <div className="card-login">
          <div className="splash-page bg-white p-3 rounded">
            <img  src={avatar} alt="" />
            <div className="mt-3">
              <div>{this.props.errorMessage}</div>
              <form
                name="loginForm"
                onSubmit={this.onUserLogin}
                className="form-horizontal"
              >
                <div className="form-group">
                  <label className="control-label" htmlFor="username">
                    Kullanıcı Adı
                  </label>
                  <div className="input-field">
                    <input
                      id="Username"
                      className="form-control"
                      type="text"
                      placeholder="Kullanıcı Adınız"
                      required
                      onChange={event => this.onInputChange(event.target)}
                      value={this.state.credentials.UserName}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label" htmlFor="password">
                    Şifre
                  </label>
                  <div className="input-field">
                    <input
                      id="Password"
                      className="form-control"
                      type="password"
                      placeholder="************"
                      required
                      onChange={event => this.onInputChange(event.target)}
                      value={this.state.credentials.Password}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label" htmlFor="password">
                    Dil Seçiniz
                  </label>
                  <div className="input-field">
                    <select
                      id="LanguageId"
                      className="form-control"
                      required
                      onChange={event => this.onInputChange(event.target)}
                      value={this.state.credentials.LanguageId}
                    >
                      <option value="0">Select Language</option>
                      <option value="1">Türkçe</option>
                      <option value="2">English</option>
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 text-right">
                    <input
                      type="submit"
                      value="Giriş Yap"
                      className="btn btn-primary"
                    />
                  </div>
                </div>
              </form>
            </div></div>
          </div>
        </div>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return { errorMessage: state.auth.errorMessage };
}

export default connect(
  mapStateToProps,
  actions
)(Login);
