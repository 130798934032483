import React, { Component } from "react";
import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import swal from "sweetalert2";
import { _getCompanyUsers } from "../../../api";
export default class CompanyUserList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userList: [],
      LanguageId: localStorage.getItem("LanguageId"),
      message: "",
      status: false,
      ConstantValuesCompanyList: {
        HeadText: "",
        EditTextDT: "",
        MemberTextDT: "",
        CompanyTextDT: "",
        StatusTextDT: "",
        ActiveTextDT: "",
        PassiveTextDT: "",
        ListCountZeroErrorMessage: ""
      }
    };
    this.getCompanyUsers = this.getCompanyUsers.bind(this);
    this.ConstantValueCompanyList = this.ConstantValueCompanyList.bind(this);
  }

  componentDidMount() {
    this.getCompanyUsers();
    this.ConstantValueCompanyList();
  }
  ConstantValueCompanyList() {
    this.state.LanguageId === "1"
      ? this.setState({
        ConstantValuesCompanyList: {
            HeadText: "Firmalar",
            EditTextDT: "Düzenle",
            EditButtonTextDT: "Düzenle",
            MemberTextDT: "Kullanıcı",
            CompanyTextDT: "Firma",
            StatusTextDT: "Durum",
            ActiveTextDT: "Aktif",
            PassiveTextDT: "Pasif",
            ListCountZeroErrorMessage:
              "Sistemde Kayıtlı Firma Bulunmamaktadır"
          }
        })
      : this.setState({
        ConstantValuesCompanyList: {
            HeadText: "Companies",
            EditTextDT: "Edit",
            EditButtonTextDT: "Edit",
            MemberTextDT: "User",
            CompanyTextDT: "Company",
            StatusTextDT: "Status",
            ActiveTextDT: "Active",
            PassiveTextDT: "Passive",
            ListCountZeroErrorMessage:
              "There are no registered company in the system"
          }
        });
  }
  getCompanyUsers() {
    _getCompanyUsers(this.state.LanguageId).then(response => {
      this.setState({
        userList:
          response.data.data !== null && response.data.data !== undefined
            ? response.data.data
            : [],
        message: response.data.message,
        status:
          response.data.data !== null && response.data.data !== undefined
            ? true
            : false
      });
    });
  }
  render() {
    const data = {
      columns: [
        {
          label: this.state.ConstantValuesCompanyList.EditTextDT,
          field: "detail",
          sort: "asc",
          width: 150
        },
        {
          label: this.state.ConstantValuesCompanyList.MemberTextDT,
          field: "Name",
          sort: "asc",
          width: 270
        },
        {
          label: this.state.ConstantValuesCompanyList.CompanyTextDT,
          field: "company",
          sort: "asc",
          width: 200
        },
        {
          label: this.state.ConstantValuesCompanyList.StatusTextDT,
          field: "isActive",
          sort: "asc",
          width: 200
        }
      ],
      rows:
        this.state.status === true
          ? this.state.userList.map(data => {
              return {
                detail: (
                  <Link
                    // {`edit-company/${data.companyId}`}/izocam-user-update/:id/memberType/:memberTypeId
                    to={`izocam-user-update/${data.memberId}/memberType/${2}`}
                    className="btn btn-warning"
                  >
                    {this.state.ConstantValuesCompanyList.EditButtonTextDT}
                  </Link>
                ),
                Name: data.name,
                company: data.company,
                isActive: data.isActive === true ? this.state.ConstantValuesCompanyList.ActiveTextDT : this.state.ConstantValuesCompanyList.PassiveTextDT
              };
            })
          : this.state.ConstantValuesCompanyList.ListCountZeroErrorMessage
    };
    return (
      <div className="row">
        <div className="col-md-12">
          <div className="block full">
            <div className="block-title">
              <h2>
                <strong>{this.state.ConstantValuesCompanyList.HeadText}</strong>{" "}
              </h2>
            </div>

            <div className="table-responsive">
              {this.state.message === null ? (
                <MDBDataTable striped hover data={data} />
              ) : (
                <p className="alert alert-warning">{this.state.ConstantValuesCompanyList.ListCountZeroErrorMessage}</p>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
