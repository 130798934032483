import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import {
  _getProjectChildelementListForReport,
  _getExcelReportForChild,
  _getExportPdfForChild
} from "../../../../../api";
import ChildElementBidListForReport from "../childElementBidListForReport/childElementBidListForReport";
import { MDBDataTable } from "mdbreact";
import print from "print-js";
import { isThisHour } from "date-fns";
// import Moment from "react-moment";
export default class BidProcedureChildElementTeklif extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projectId: this.props.id,
      modalShow: false,
      childElementListForReport: [],
      childElementId: "",
      LanguageId: localStorage.getItem("LanguageId"),
      message: "",
      excelForChild: [],
      disabledForExcelButton: false,
      bidderList: [],
      bidderListForExcel: [],
      bidderListForCompany: [],
      companyBid: [],
      allSum: [],
      product: [],
      NonCount: [],
      disabledForPdf: false,
      ConsntantValueBidReport: {
        GoToReportText: "",
        GorButtonText: "",
        ElementNameText: "",
        HeadText: "",
        BidListErrorMessage: "",
        CloseButtonTextModal:"",
        HeadTextModal:""
      }
    };
    this.exportPdf = this.exportPdf.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.exportToExcel = this.exportToExcel.bind(this);
    this.ConstantValueOfBidReport = this.ConstantValueOfBidReport.bind(this);
  }

  componentDidMount() {
    this.getProjectChildelementListForReport();
    this.getExcelExportChild();
    this.ConstantValueOfBidReport();
  }

  getExcelExportChild() {
    _getExcelReportForChild(this.state.projectId).then(response => {
      this.setState({
        excelForChild:
          response.data.data !== null && response.data.data !== undefined
            ? response.data.data
            : null
      });
    });
  }
  ConstantValueOfBidReport() {
    this.state.LanguageId === "1"
      ? this.setState({
          ConsntantValueBidReport: {
            GoToReportText: "Ayrıntıya Git",
            GorButtonText: "Gör",
            ElementNameText: "Alt Element Adı",
            HeadText: "Alt Element Teklifleri",
            BidListErrorMessage: "Henüz Teklif Veren Firma Yok",
            CloseButtonTextModal:"Kapat",
            HeadTextModal:"Alt Element'e Verilen Teklifler"
          }
        })
      : this.setState({
          ConsntantValueBidReport: {
            GoToReportText: "Go To Detail",
            GorButtonText: "See",
            ElementNameText: "Child Element Name",
            HeadText: "Sub-Element Offers",
            BidListErrorMessage: "No Bidding Companies Yet",
            CloseButtonTextModal:"Close",
            HeadTextModal:"Bids to the Lower Element"
          }
        });
  }
  getProjectChildelementListForReport() {
    _getProjectChildelementListForReport(
      this.state.projectId,
      this.state.LanguageId
    ).then(response => {
      this.setState({
        childElementListForReport:
          response.data.data === undefined ? null : response.data.data,
        message: response.data.message
      });
    });
  }
  handleShow = e => {
    this.setState({ modalShow: true, childElementId: e.target.id });
  };
  handleClose = () => {
    this.setState({ modalShow: false });
  };
  exportToExcel() {
    this.setState({ disabledForExcelButton: true });
    _getExcelReportForChild(this.state.projectId).then(response => {
      this.setState({
        bidderListForExcel: response.data.data,
        bidderListForCompany: response.data.data.company,
        companyBid: response.data.data.companyBid,
        allSum: response.data.data.companyAllSum
      });
      var downloadLink;
      var dataType = "application/vnd.ms-excel";
      var tableSelect1 = document.getElementById("tbl1");
      var tableSelect2 = document.getElementById("tbl2");
      var tableSelect3 = document.getElementById("tbl3");
      var tableHTML =
        '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8"></head><body>' +
        tableSelect1.outerHTML.replace(/ /g, "%20") +
        tableSelect2.outerHTML.replace(/ /g, "%20") +
        tableSelect3.outerHTML.replace(/ /g, "%20") +
        "</body></html>";

      // Specify file name
      var filename = `${this.state.bidderListForExcel.projectName}.xls`;

      // Create download link element
      downloadLink = document.createElement("a");

      document.body.appendChild(downloadLink);

      if (navigator.msSaveOrOpenBlob) {
        var blob = new Blob(["\ufeff", tableHTML], {
          type: dataType
        });
        navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        // Create a link to the file
        downloadLink.href = "data:" + dataType + ", " + tableHTML;

        // Setting the file name
        downloadLink.download = filename;

        //triggering the function
        downloadLink.click();
        this.setState({ disabledForExcelButton: false });
      }
    });
  }
  exportPdf() {
    this.setState({ disabledForPdf: true });
    _getExportPdfForChild(this.state.projectId).then(response => {
      print({
        printable: response.data,
        properties: [
          { field: "product", displayName: "Ürün" },
          { field: "companyName", displayName: "Firma Adı" },
          { field: "companyUser", displayName: "Kullanıcı" },
          { field: "bidDate", displayName: "Teklif Tarihi" },
          { field: "baseBid", displayName: "Firma Kurundan" },
          { field: "bid", displayName: "İhale Kurundan" }
        ],
        type: "json",
        gridHeaderStyle: "color: red;  border: 2px solid #3971A5;",
        gridStyle: "border: 2px solid #3971A5;",
        header: `<h3 class="custom-h3">İhaleye gelen Bütün Teklifler</h3>`
      });
    });
    this.setState({ disabledForPdf: false });
  }
  render() {
    var disp = {
      display: "none"
    };
    const data = {
      columns: [
        {
          label: this.state.ConsntantValueBidReport.GoToReportText,
          field: "detail",
          sort: "asc",
          width: 150
        },
        {
          label: this.state.ConsntantValueBidReport.ElementNameText,
          field: "name",
          sort: "asc",
          width: 270
        }
      ],
      rows:
        this.state.childElementListForReport !== null
          ? this.state.childElementListForReport.map(data => {
              return {
                detail: (
                  <button
                    className="btn btn-warning teklifVer"
                    id={data.projeAltElementId}
                    type="button"
                    onClick={this.handleShow}
                  >
                    {this.state.ConsntantValueBidReport.GorButtonText}
                  </button>
                ),
                name: data.projectAltElementName
              };
            })
          : this.state.ConsntantValueBidReport.BidListErrorMessage
    };
    return (
      <div className="col-md-12">
        <div className="block full">
          <div className="block-title">
            <h2>
              <strong>{this.state.ConsntantValueBidReport.HeadText}</strong>{" "}
            </h2>
            <div className="col-md-6">
              <div className="col-md-3">
                <button
                  type="button"
                  onClick={e => this.exportToExcel()}
                  className="btn btn-warning"
                  disabled={this.state.disabledForExcelButton}
                >
                  Export To Excel
                </button>
              </div>
              <div className="col-md-3">
                <button
                  type="button"
                  onClick={e => this.exportPdf()}
                  className="btn btn-warning"
                  disabled={this.state.disabledForPdf}
                >
                  Export PDF
                </button>
              </div>
            </div>
          </div>

          <div className="table-responsive">
            {this.state.message === null ? (
              <MDBDataTable striped hover data={data} />
            ) : (
              <p className="alert alert-warning">
                {this.state.ConsntantValueBidReport.BidListErrorMessage}
              </p>
            )}
            {/* <table
              id="example-datatable"
              className="table table-vcenter table-condensed table-bordered dataTable no-footer"
            >
              <thead>
                <tr>
                  <th>Gör</th>
                  <th className="text-center sorting_disabled">
                    Alt Element Adı
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.state.message == null ? (
                  this.state.childElementListForReport.map(data => (
                    <tr className="even">
                      <td>
                        <button
                          className="btn btn-warning teklifVer"
                          id={data.projeAltElementId}
                          type="button"
                          onClick={this.handleShow}
                        >
                          Gör
                        </button>
                      </td>
                      <td>{data.projectAltElementName}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>{this.state.message}</td>
                    <td></td>
                  </tr>
                )}
              </tbody>
            </table> */}
          </div>
          <div style={disp}>
            <div>
              <table
                border="1"
                style={{
                  "border-spacing": "0",
                  "border-collapse": "collapse",
                  "font-size": "16px"
                }}
                id="tbl1"
              >
                <tr>
                  <td style={{ height: "120px" }}>
                    <img
                      src={"https://www.demircode.com/Images/izocam-logo.png"}
                      alt=""
                    />
                  </td>
                  <td
                    style={{
                      "text-align": "center",
                      "font-size": "30px",
                      "vertical-align": "top"
                    }}
                    colSpan={this.state.bidderListForCompany.length * 2 + 1}
                  >
                    <b>
                      TEKLİF KARŞILAŞTIRMA FORMU
                      <br />({this.state.bidderListForExcel.montOfYear})
                    </b>
                  </td>
                </tr>
              </table>
              <table
                border="1"
                style={{
                  "border-spacing": "0",
                  "border-collapse": "collapse",
                  "font-size": "16px"
                }}
                id="tbl2"
              >
                <tr>
                  <td>Sif No :</td>
                  <td>Firma</td>
                  {this.state.bidderListForCompany.map(item => {
                    return (
                      <td colSpan="2" style={{ "text-align": "center" }}>
                        <b>{item.companyName}</b>
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <td>Tarih : {this.state.bidderListForExcel.dateForToday}</td>
                  <td>Adres</td>
                  {this.state.bidderListForCompany.map(item => {
                    return (
                      <td colSpan="2" style={{ "text-align": "center" }}>
                        {item.companyAdres}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <td>Talep Eden Kısım</td>
                  <td>Telefon</td>
                  {this.state.bidderListForCompany.map(item => {
                    return (
                      <td colSpan="2" style={{ "text-align": "center" }}>
                        {item.userTelNo}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <td>Doldurulacak</td>
                  <td>Sorumlu Kişi</td>
                  {this.state.bidderListForCompany.map(item => {
                    return (
                      <td colSpan="2" style={{ "text-align": "center" }}>
                        {item.companyUser}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  {this.state.bidderListForCompany.map(item => {
                    return (
                      <React.Fragment>
                        <td style={{ "text-align": "center" }}>CIF</td>
                        <td style={{ "text-align": "center" }}>DDP</td>
                      </React.Fragment>
                    );
                  })}
                </tr>
                <tr>
                  <td>MALZEME</td>
                  <td style={{ "text-align": "center" }}>
                    <b>
                      MİKTAR
                      <br />
                      (MT)
                    </b>
                  </td>
                  {this.state.bidderListForCompany.map(item => {
                    return (
                      <React.Fragment>
                        <td style={{ "text-align": "center" }}>
                          Birim Fiyat
                          <br />({
                            this.state.bidderListForExcel.projectCurrency
                          }{" "}
                          / MT)
                        </td>
                        <td style={{ "text-align": "center" }}>
                          Birim Fiyat
                          <br />({
                            this.state.bidderListForExcel.projectCurrency
                          }{" "}
                          / MT)
                        </td>
                      </React.Fragment>
                    );
                  })}
                </tr>
                {this.state.companyBid.map(item => {
                  return (
                    <React.Fragment>
                      <tr>
                        <td>{item.product}</td>
                        <td style={{ "text-align": "center" }}>
                          {item.piece} - {item.birim}
                        </td>
                        {item.bidDetail.map(data => {
                          return (
                            <React.Fragment>
                              <td style={{ "text-align": "center" }}>
                                {data.bid === "0 TRY"
                                  ? "-"
                                  : data.bid === "0 USD"
                                  ? "-"
                                  : data.bid === "0 EUR"
                                  ? "-"
                                  : data.bid === null
                                  ? "-"
                                  : data.bid}
                              </td>
                              <td style={{ "text-align": "center" }}>
                                <b>
                                  {data.total === "0 TRY"
                                    ? "-"
                                    : data.total === "0 USD"
                                    ? "-"
                                    : data.total === "0 EUR"
                                    ? "-"
                                    : data.total === null
                                    ? "-"
                                    : data.total}
                                </b>
                              </td>
                            </React.Fragment>
                          );
                        })}
                        {item.nonBidCount.map(data => {
                          return (
                            <React.Fragment>
                              <td style={{ "text-align": "center" }}>-</td>
                              <td style={{ textAlign: "center" }}>-</td>
                            </React.Fragment>
                          );
                        })}
                      </tr>
                    </React.Fragment>
                  );
                })}
                <tr>
                  <td></td>
                  <td></td>
                  {this.state.bidderListForCompany.map(item => {
                    return (
                      <React.Fragment>
                        <td></td>
                      </React.Fragment>
                    );
                  })}
                  {this.state.bidderListForCompany.map(item => {
                    return (
                      <React.Fragment>
                        <td></td>
                      </React.Fragment>
                    );
                  })}
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  {this.state.bidderListForCompany.map(item => {
                    return (
                      <React.Fragment>
                        <td></td>
                      </React.Fragment>
                    );
                  })}
                  {this.state.bidderListForCompany.map(item => {
                    return (
                      <React.Fragment>
                        <td></td>
                      </React.Fragment>
                    );
                  })}
                </tr>
                <tr>
                  <td>Genel Toplam : </td>
                  <td></td>
                  {this.state.allSum.map(item => {
                    return (
                      <React.Fragment>
                        {item.bid === "-" ? (
                          <td style={{ "text-align": "center" }}>{item.bid}</td>
                        ) : item.bid === "0 TRY" ? (
                          <td style={{ "text-align": "center" }}>-</td>
                        ) : item.bid === "0 USD" ? (
                          "0"
                        ) : item.bid === "0 EUR" ? (
                          <td style={{ "text-align": "center" }}>-</td>
                        ) : (
                          <td style={{ "text-align": "center" }}>
                            {item.bid}{" "}
                            {this.state.bidderListForExcel.projectCurrency}
                          </td>
                        )}

                        {item.total === "-" ? (
                          <td style={{ "text-align": "center" }}>
                            {item.total}
                          </td>
                        ) : item.total === "0 TRY" ? (
                          <td style={{ "text-align": "center" }}>-</td>
                        ) : item.total === "0 USD" ? (
                          "0"
                        ) : item.total === "0 EUR" ? (
                          <td style={{ "text-align": "center" }}>-</td>
                        ) : (
                          <td style={{ "text-align": "center" }}>
                            <b>
                              {item.total}{" "}
                              {this.state.bidderListForExcel.projectCurrency}
                            </b>
                          </td>
                        )}
                        {/* <td style={{ "text-align": "center" }}>
                          <b>
                            {item.total}{" "}
                            {this.state.bidderListForExcel.projectCurrency}
                          </b>
                        </td> */}
                      </React.Fragment>
                    );
                  })}
                    {/* {this.state.bidDetail.nonBidCount.map(data => {
                      return (
                        <React.Fragment>
                          <td  style={{ "text-align": "center" }}>-</td>
                          <td style={{textAlign:"center"}}>-</td>
                        </React.Fragment>
                      );
                    })} */}
                </tr>
              </table>
              <table
                width="1000"
                border="1"
                style={{
                  "border-spacing": "0",
                  "border-collapse": "collapse",
                  "font-size": "16px"
                }}
                id="tbl3"
              >
                <tr>
                  <td>Teslim Tarihi : </td>
                  <td></td>
                  {/* {this.state.bidderListForCompany.map(item => {
                  return <td></td>;
                })} */}
                  {this.state.bidderListForCompany.map(item => {
                    return (
                      <React.Fragment>
                        <td></td>
                      </React.Fragment>
                    );
                  })}
                  {this.state.bidderListForCompany.map(item => {
                    return (
                      <React.Fragment>
                        <td></td>
                      </React.Fragment>
                    );
                  })}
                </tr>
                <tr>
                  <td>Ödeme Şartları :</td>
                  <td></td>
                  {/* {this.state.bidderListForCompany.map(item => {
                  return <td></td>;
                })} */}
                  {this.state.bidderListForCompany.map(item => {
                    return (
                      <React.Fragment>
                        <td></td>
                      </React.Fragment>
                    );
                  })}
                  {this.state.bidderListForCompany.map(item => {
                    return (
                      <React.Fragment>
                        <td></td>
                      </React.Fragment>
                    );
                  })}
                </tr>
                <tr>
                  <td
                    style={{
                      height: "120px",
                      "vertical-align": "top",
                      padding: "10px"
                    }}
                    colSpan={this.state.bidderListForCompany.length * 2 + 2}
                  >
                    Açıklama Gelecek
                  </td>
                </tr>
                <tr>
                  <td colSpan={this.state.bidderListForCompany.length * 2 + 2}>
                    <b>NOT : YILLIK ALIM TUTARI "FİYAT GELECEK" ÜZERİNDEDİR.</b>
                  </td>
                </tr>
                <tr>
                  <td colSpan={this.state.bidderListForCompany.length * 2 + 2}>
                    ARAŞTIRMAYI YAPAN :
                  </td>
                </tr>
                <tr>
                  <td colSpan={this.state.bidderListForCompany.length * 2 + 2}>
                    İsim :<br />
                    <br />
                    İmza :<br />
                    <br />
                    Tarih : {this.state.bidderListForExcel.dateForToday}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <Modal show={this.state.modalShow} onHide={this.handleClose} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>{this.state.ConsntantValueBidReport.HeadTextModal}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ChildElementBidListForReport
              id={this.state.childElementId}
              projectId={this.state.projectId}
            />
          </Modal.Body>
          <Modal.Footer>
            <button
              className="ml-auto"
              onClick={this.handleClose}
              variant="link"
            >
              {this.state.ConsntantValueBidReport.CloseButtonTextModal}
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
