import React, { Component } from "react";
import swal from "sweetalert2";
import { _createIzocamUser } from "../../../api";

export default class AddIzocamUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Name: "",
      Username: "",
      Email: "",
      Password: "",
      TelNumber: "",
      IsActive: "",
      LanguageId: localStorage.getItem("LanguageId"),
      ConstantValuesIzocamUser: {
        CreateIzocamUserHeadText: "",
        MembersInformationText: "",
        FullNameText: "",
        FullNamePlaceHolder: "",
        TelephoneText: "",
        TelephonePlaceHolder: "",
        PasswordText: "",
        PasswordPlaceHolder: "",
        StatusText: "",
        ActiveText: "",
        PassiveText: "",
        EmailText: "",
        EmailPlaceHolder: "",
        UsernameText: "",
        UsernamePlaceHolder: "",
        CreateButtonText: "",
        SelecText: ""
      }
    };
    this.handleChange = this.handleChange.bind(this);
    this.createIzocamUser = this.createIzocamUser.bind(this);
    this.ConstantValuesCreateIzocamUserPage = this.ConstantValuesCreateIzocamUserPage.bind(
      this
    );
  }

  handleChange(e) {
    let change = {};
    change[e.target.name] = e.target.value;
    this.setState(change);
  }
  componentDidMount() {
    this.ConstantValuesCreateIzocamUserPage();
  }
  ConstantValuesCreateIzocamUserPage() {
    this.state.LanguageId === "1"
      ? this.setState({
          ConstantValuesIzocamUser: {
            CreateIzocamUserHeadText: "İzocam Kullanıcısı Ekle",
            MembersInformationText: "Kullanıcı Bilgileri",
            FullNameText: "Ad Soyad",
            FullNamePlaceHolder: "Ad Soyad Giriniz",
            TelephoneText: "Telefon",
            TelephonePlaceHolder: "Telefon Numarası Giriniz",
            PasswordText: "Şifre",
            PasswordPlaceHolder: "Bir Şifre Giriniz",
            StatusText: "Durum",
            ActiveText: "Aktif",
            PassiveText: "Pasif",
            EmailText: "Email",
            EmailPlaceHolder: "Email adresi giriniz",
            UsernameText: "Kullanıcı Adı",
            UsernamePlaceHolder: "örn ad.soyad ",
            CreateButtonText: "Ekle",
            SelecText: "Seçiniz"
          }
        })
      : this.setState({
          ConstantValuesIzocamUser: {
            CreateIzocamUserHeadText: "Create Izocam User",
            MembersInformationText: "Member Information",
            FullNameText: "Full Name",
            FullNamePlaceHolder: "Enter Full Name",
            TelephoneText: "Telephone",
            TelephonePlaceHolder: "enter Tel Number",
            PasswordText: "Password",
            PasswordPlaceHolder: "Enter a Password",
            StatusText: "Status",
            ActiveText: "Active",
            PassiveText: "Passive",
            EmailText: "Email",
            EmailPlaceHolder: "Enter Email Address",
            UsernameText: "User Name",
            UsernamePlaceHolder: "example: name.surname",
            CreateButtonText: "Create",
            SelecText: "Select"
          }
        });
  }

  createIzocamUser() {
    const obj = {
      Name: this.state.Name,
      Username: this.state.Username,
      Email: this.state.Email,
      Password: this.state.Password,
      TelNumber: this.state.TelNumber,
      IsActive: true,
      LanguageId: this.state.LanguageId
    };

    _createIzocamUser(obj).then(response => {
      swal(response.data.message);
      document.getElementById("createIzocamUser").reset();
    });
  }
  render() {
    return (
      <div className="row">
        <div className="col-md-12">
          <div className="block">
            <div className="block-title">
              <div className="block-options pull-right"></div>
              <h2>
                <strong>
                  {this.state.ConstantValuesIzocamUser.CreateIzocamUserHeadText}
                </strong>{" "}
              </h2>
            </div>

            <form
              className="form-horizontal form-bordered"
              id="createIzocamUser"
            >
              <fieldset>
                <legend>
                  {this.state.ConstantValuesIzocamUser.MembersInformationText}
                </legend>
                <div className="col-md-6">
                  <div className="form-group">
                    <label
                      className="col-md-3 control-label"
                      htmlFor="example-text-input"
                    >
                      {this.state.ConstantValuesIzocamUser.FullNameText}
                    </label>
                    <div className="col-md-9">
                      <input
                        type="text"
                        id="Name"
                        name="Name"
                        className="form-control"
                        placeholder={
                          this.state.ConstantValuesIzocamUser
                            .MembersInformationText
                        }
                        onChange={this.handleChange}
                        value={this.state.value}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      className="col-md-3 control-label"
                      htmlFor="example-text-input"
                    >
                      {this.state.ConstantValuesIzocamUser.TelephoneText}
                    </label>
                    <div className="col-md-9">
                      <input
                        type="number"
                        id="TelNumber"
                        name="TelNumber"
                        className="form-control"
                        placeholder={
                          this.state.ConstantValuesIzocamUser
                            .TelephonePlaceHolder
                        }
                        onChange={this.handleChange}
                        value={this.state.value}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      className="col-md-3 control-label"
                      htmlFor="example-text-input"
                    >
                      {this.state.ConstantValuesIzocamUser.PasswordText}
                    </label>
                    <div className="col-md-9">
                      <input
                        type="text"
                        id="Password"
                        name="Password"
                        className="form-control"
                        placeholder={
                          this.state.ConstantValuesIzocamUser
                            .PasswordPlaceHolder
                        }
                        onChange={this.handleChange}
                        value={this.state.value}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      className="col-md-3 control-label"
                      htmlFor="example-select"
                    >
                      {this.state.ConstantValuesIzocamUser.StatusText}
                    </label>
                    <div className="col-md-9">
                      <select
                        id="IsActive"
                        name="IsActive"
                        className="form-control"
                        size="1"
                        onChange={this.handleChange}
                        value={this.state.value}
                      >
                        <option value="1">
                          {this.state.ConstantValuesIzocamUser.SelecText}
                        </option>
                        <option value="1">
                          {this.state.ConstantValuesIzocamUser.ActiveText}
                        </option>
                        <option value="0">
                          {this.state.ConstantValuesIzocamUser.PassiveText}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label
                      className="col-md-3 control-label"
                      htmlFor="example-text-input"
                    >
                      {this.state.ConstantValuesIzocamUser.EmailText}
                    </label>
                    <div className="col-md-9">
                      <input
                        type="text"
                        id="Email"
                        name="Email"
                        className="form-control"
                        placeholder={
                          this.state.ConstantValuesIzocamUser.EmailPlaceHolder
                        }
                        onChange={this.handleChange}
                        value={this.state.value}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      className="col-md-3 control-label"
                      htmlFor="example-text-input"
                    >
                      {this.state.ConstantValuesIzocamUser.UsernameText}
                    </label>
                    <div className="col-md-9">
                      <input
                        type="text"
                        id="Username"
                        name="Username"
                        className="form-control"
                        placeholder={
                          this.state.ConstantValuesIzocamUser
                            .UsernamePlaceHolder
                        }
                        onChange={this.handleChange}
                        value={this.state.value}
                      />
                    </div>
                  </div>
                  {/* <div className="form-group">
                    <label
                      className="col-md-3 control-label"
                      htmlFor="example-select"
                    >
                      Şifre Tekrar*
                    </label>
                    <div className="col-md-9">
                      <input
                        type="text"
                        id="example-text-input"
                        name="example-text-input"
                        className="form-control"
                        placeholder="Text"
                      />
                    </div>
                  </div> */}
                </div>
              </fieldset>

              <div className="form-group form-actions">
                <div className="col-md-9 col-md-offset-9">
                  <button
                    type="button"
                    className="btn btn-warning"
                    onClick={this.createIzocamUser}
                  >
                    <i className="fa fa-plus"></i>{" "}
                    {this.state.ConstantValuesIzocamUser.CreateButtonText}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
