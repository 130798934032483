import {AUTH_USER, AUTH_ERROR, TOGGLE_LOADER} from './types';
import * as service from '../../api.js'

export const _signin = (formProps, callback) => async dispatch => { //why callback. recheck tut on udemy
  try {
    const response = await service.postUserLogin(formProps);
    console.log(response)
    dispatch({type: AUTH_USER, payload: response.data.token});
    localStorage.setItem('Token', response.data.apiKey);
    localStorage.setItem('UserId', response.data.memberId);
    localStorage.setItem('CompanyId',response.data.companyId);
    localStorage.setItem('LanguageId',response.data.languageId);
    localStorage.setItem('Username',response.data.username);
    localStorage.setItem('Email',response.data.email);
    callback();
  } catch (e) {
    dispatch({type: AUTH_ERROR, payload: 'Invalid login credentials'});
  }
};

export const _signout = () => {
  localStorage.removeItem('Token');
  localStorage.removeItem('UserId');
  localStorage.removeItem("CompanyId");
  localStorage.removeItem("LanguageId");
  localStorage.removeItem("Username");
  localStorage.removeItem("Email");

  return {
    type: AUTH_USER,
    payload: ''
  };
};

export const _errCall = () => {
  localStorage.removeItem('Token');
    localStorage.removeItem("UserId");
    localStorage.removeItem("CompanyId");
    localStorage.removeItem("LanguageId");
    localStorage.removeItem("Username");
    localStorage.removeItem("Email");

  return {
    type: AUTH_ERROR,
    payload: 'Invalid token'
  };
};

export const _toggleLoader = (value) => {
  return {
    type: TOGGLE_LOADER,
    payload: value
  };
};