import React, { Component } from "react";
import swal from "sweetalert2";
import { _materialList } from "../../../api";
import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
export default class MaterialList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      materialList: [],
      ConstantValues: {
        HeadCompaniesText: "",
        EditButtonText: "",
        EditText: "",
        MaterialNameText: "",
        MaterialWerksText: "",
        MaterialMeinsText:"",
        MaterialAccountText:"",
        ListErrorMessageText:"",
        ListErrorMessageForDT:"",
      },
      LanguageId: localStorage.getItem("LanguageId"),
      ListStatus:false
    };
    this.getAlMaterials = this.getAlMaterials.bind(this);
    this.ConstantValuesThisPage = this.ConstantValuesThisPage.bind(this);
  }
  componentDidMount() {
    this.getAlMaterials();
    this.ConstantValuesThisPage();
  }

  ConstantValuesThisPage() {
    this.state.LanguageId === "1"
      ? this.setState({
          ConstantValues: {
            HeadMaterialsText: "Malzemeler",
            EditButtonText: "Düzenle",
            EditText: "Düzenle",
            MaterialName: "Malzeme Adı",
            StatusText: "Durum",
            ListErrorMessageText:"Sistemde Kayıtlı Material yok",
            ListErrorMessageForDT:"Listelenecek Kayıt Yok",
            MaterialNameText: "Malzeme Adı",
            MaterialWerksText: "Üretim Yeri",
            MaterialMeinsText:"Birim",
            MaterialAccountText:"Hesap",
          }
        })
      : this.setState({
          ConstantValues: {
            HeadMaterialsText: "Materials",
            EditButtonText: "Edit",
            EditText: "Edit",
            MaterialName: "Material Name",
            StatusText: "Status",
            ListErrorMessageText:"No Registered Materials in the System",
            ListErrorMessageForDT:"No records to list",
            MaterialNameText: "Material Name",
            MaterialWerksText: "Production Area",
            MaterialMeinsText:"Unit",
            MaterialAccountText:"Account",
          }
        });
  }
  getAlMaterials(){
    _materialList().then(response => {
      console.log(response.data.data);
         if(response.data.status){
           this.setState({ListStatus:true});
         }
          this.setState({
            materialList: response.data.data,
            message: response.data.message
          });
          console.log(response.data.data);
        
      });
  }

  render() {
    const data = {
      columns: [
        {
          label: this.state.ConstantValues.EditText,
          field: "detail",
          sort: "asc",
          width: 150
        },
        {
          label: this.state.ConstantValues.MaterialName,
          field: "materialName",
          sort: "asc",
          width: 270
        },
        {
          label: this.state.ConstantValues.MaterialMeinsText,
          field: "meins",
          sort: "asc",
          width: 270
        },
        {
          label: this.state.ConstantValues.MaterialWerksText,
          field: "werks",
          sort: "asc",
          width: 270
        },
        {
          label: this.state.ConstantValues.MaterialAccountText,
          field: "matkl",
          sort: "asc",
          width: 270
        },

      ],
      rows:
        this.state.ListStatus &&
        this.state.materialList !== null &&
        this.state.materialList !== undefined
          ? this.state.materialList.map(data => {
              return {
                detail: (
                  <Link
                    key="key"
                    to={`edit-material/${data.materialId}`}
                    className="btn btn-warning"
                  >
                    {this.state.ConstantValues.EditButtonText}
                  </Link>
                ),
                materialName: data.name,
                meins: data.meins,
                werks:data.werks,
                matkl:data.matkl
                //miktar: data.companyStatus === true ? "Aktif" : "Pasif"
              };
            })
          : this.state.ConstantValues.ListErrorMessageText
    };
    return (
      <div className="row">
        <div className="col-md-12">
          <div className="block full">
            <div className="block-title">
              <h2>
                <strong>{this.state.ConstantValues.HeadMaterialsText}</strong>{" "}
              </h2>
            </div>

            <div className="table-responsive">
              {this.state.message === null ? (
                <MDBDataTable striped hover data={data} />
              ) : (
              <p className="alert alert-warning">{this.state.ConstantValues.ListErrorMessageForDT}</p>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
