import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  _getMyLastBid,
  _giveBidForTheLiveProject,
  _getProjctEndDate,
  _getLiveProjectDetail,
  _IsMyFirstBid, _getCheckNumberofOffers
} from "../../../../../api";
import Countdown from "react-countdown-now";
import swal from "sweetalert2";

export default class LiveProjectGeneralInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      result:true,
      projectId: this.props.id,
      memberId: localStorage.getItem("UserId"),
      LanguageId: localStorage.getItem("LanguageId"),
      CompanyId: localStorage.getItem("CompanyId"),
      detailGeneralInformation: [],
      lastBid: 0,
      lastBidMessage: "",
      IsMinBid: false,
      lastTime: "",
      Bid: 0,
      endDate: "",
      isProjectStarted: false,
      endDateMessage: "",
      disabledForBidElement: false,
      projectCurrency: "",
      Currency: null,
      BidBaseCurrency: null,
      isMyFirstBid : true,
      firstBidDescription:"",
      clickCount:0,
      ConstantValueLiveDetail: {
        HeadText: "",
        BackButtonText: "",
        LastBidText: "",
        SpecificationsText: "",
        CurrencyText: "",
        RemainTimeText: "",
        GiveOfferText: "",
        CurrencyText2: "",
        TeklifVerButtonText: "",
        DescriptionText: "",
        TeklifVerPlaceHolder:"",
        DownloadButtonText:"",
        GeneralInfText:"",
        BidSuccessText:"",
        BidErrorText:"",
      }
    };
    this.handleChange = this.handleChange.bind(this);
    this.giveBidForTheLiveProject = this.giveBidForTheLiveProject.bind(this);
    this.handleChangeCurrency = this.handleChangeCurrency.bind(this);
    //  this.downloadBase64File = this.downloadBase64File.bind(this);
    this.ConstantValueLive = this.ConstantValueLive.bind(this);
    this.isMyFirstBid = this.isMyFirstBid.bind(this);
    this.giveBid = this.giveBid.bind(this);
  }

  ConstantValueLive() {
    this.state.LanguageId === "1"
      ? this.setState({
          ConstantValueLiveDetail: {
            HeadText: "İhale Detayı",
            BackButtonText: "Geri",
            LastBidText: "Son Teklifiniz: ",
            SpecificationsText: "Şartname",
            CurrencyText: "Para Birimi",
            RemainTimeText: "Kalan Zaman",
            GiveOfferText: "Teklif Ver",
            CurrencyText2: "Kur",
            TeklifVerButtonText: "Teklif Ver",
            DescriptionText: "Açıklama",
            TeklifVerPlaceHolder:"Teklif",
            DownloadButtonText:"İndir",
            GeneralInfText:"İhale Genel Bilgiler",
            BidSuccessText:"Teklifiniz Uygundur",
            BidErrorText:"Teklifiniz Uygun Değildir"
          }
        })
      : this.setState({
          ConstantValueLiveDetail: {
            HeadText: "Tender Detail",
            BackButtonText: "Geri",
            LastBidText: "Your Last Offer: ",
            SpecificationsText: "Specification",
            CurrencyText: "Currency Unit",
            RemainTimeText: "Remaining Time",
            GiveOfferText: "Give Offer",
            CurrencyText2: "Currency",
            TeklifVerButtonText: "Give Offer",
            DescriptionText: "Description",
            TeklifVerPlaceHolder:"Bid",
            DownloadButtonText:"Download",
            GeneralInfText:"Tender General Information",
            BidSuccessText:"Your offer is suitable",
            BidErrorText:"Your offer is not eligible"
          }
        });
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      this.getMyLastBid();
    }, 1000);
    //this.getMyLastBid();
    // this.interval2 = setInterval(() => {
    //   this.getProjctEndDate();
    // }, 1000);
    this.getProjctEndDate();
    this.getLiveProjectDetail();
    this.ConstantValueLive();
    this.isMyFirstBid();
  }

  isMyFirstBid(){
    const projectId = this.state.projectId;
    const LanguageId = this.state.LanguageId;
    const memberId = this.state.memberId;
    const companyId = this.state.CompanyId;
    _IsMyFirstBid(projectId,LanguageId,companyId,memberId).then(response => {

      console.log("İlk Teklifim mi?: ",response);
      if(response.data.status === true){
        this.setState({
          isMyFirstBid:response.data.isMyFirstBid,
          firstBidDescription:response.data.firstBidDescription
        });
      }
    });

  }
  componentWillUnmount() {
    clearInterval(this.interval);
    clearInterval(this.interval2);
  }
  handleChangeCurrency(e) {
    this.setState({ Currency: e.target.value });
  }
  getMyLastBid() {
    const projectIdForBid = this.state.projectId;
    const LanguageIdForBid = this.state.LanguageId;
    const MemberIdForBid = this.state.memberId;
    _getMyLastBid(projectIdForBid, LanguageIdForBid, MemberIdForBid)
      .then(response => {
        this.setState({
          lastBidMessage: response.data.message,
          IsMinBid: response.data.isMinBid,
          BidBaseCurrency: response.data.bidBaseCurrency
        });
        if (response.data.data.bid > 0) {
          this.setState({
            lastBid: response.data.data.bid,
            lastBidMessage: response.data.message,
            IsMinBid: response.data.isMinBid,
            BidBaseCurrency: response.data.bidBaseCurrency
          });
          // console.log("SınTeklif: ", response.data.data.bid);
          // console.log("En düşük teklif Mi?: ", response.data.isMinBid);
        }
      })
      .catch(error => {
        console.log(error);
      });
  }
  handleChange(e) {
    let change = {};
    change[e.target.name] = parseFloat(e.target.value);
    this.setState(change);
    // console.log(e.target.name + ": " + e.target.value);
  }

  giveBid(){
    _getCheckNumberofOffers(this.state.projectId, this.state.memberId)
        .then(response => {
          const result = response.data;
          this.setState({ result });
          if (!result) {
            swal("Açık artırmaya 5'ten fazla teklif veremezsiniz!")
           // swal({
             // title: "Uyarı",
             // text: "Açık artırmaya 5'ten fazla teklif veremezsiniz!",
             // icon: "warning",
             // button: "Tamam",
            // });
            return;
          }

          //console.log("İlk Teklif Uyarısı: ",this.state.firstBidDescription);
          //console.log("Bu Benim İlk Teklifim Mi? ",this.state.isMyFirstBid);
          if(this.state.isMyFirstBid){
            var alertMessage = this.state.firstBidDescription;
            var r = window.confirm(alertMessage);
            if (r === true) {
              //alert("Tamam Uayrı görüldü Teklifi yolla.");
              this.giveBidForTheLiveProject();
              this.setState({
                isMyFirstBid:false
              });
            }
          }
          else {
            //alert("İlk teklif Değil Uyarıya Gerek Yok");
            this.giveBidForTheLiveProject();
          }
        })
        .catch(error => {
          console.log(error);
        });
  }
  giveBidForTheLiveProject() {

    if (parseFloat(this.state.Bid) > 0) {

      if (this.state.Bid > this.state.lastBid && this.state.lastBid !== 0) {
        //Teklif son tekliften büyükse ve son teklif mevcut ise
        this.state.LanguageId === "1"
          ? swal("Teklifiniz son tekliften büyük olamaz!")
          : swal("Your bid cannot be greater than the last offer!");
      } else if (
        this.state.lastBid / this.state.Bid >= 2 &&
        this.state.lastBid / this.state.Bid !== 1
      ) {
        if (
          this.state.Currency !== this.state.BidBaseCurrency &&
          this.state.lastBid !== 0
        ) {
          var message = "";
          this.state.LanguageId === "1"
            ? (message =
                "Lütfen İlk Teklifinizde Kullandığınız Para Birimini Seçiniz.")
            : (message =
                "Please select the currency you used in your first offer.");
          swal(message);
        } else {
          this.myFunction();
        }
      } else {
        if (
          this.state.Currency !== this.state.BidBaseCurrency &&
          this.state.lastBid !== 0
        ) {
          var message = "";
          this.state.LanguageId === "1"
            ? (message =
                "Lütfen ilk teklifinizde kullandığınız para birimini seçin.")
            : (message =
                "Please select the currency you used in your first offer.");
          swal(message);
        } else if (this.state.lastBid !== 0) {
          const obj = {
            ProjectId: this.state.projectId,
            CompanyId: this.state.CompanyId,
            LanguageId: this.state.LanguageId,
            MemberId: this.state.memberId,
            Bid: this.state.Bid,
            Currency: this.state.Currency
          };
          this.setState({ BidBaseCurrency: obj.Currency });
          _giveBidForTheLiveProject(obj).then(response => {
            swal(response.data.message);
          });
        } else {
          const obj = {
            ProjectId: this.state.projectId,
            CompanyId: this.state.CompanyId,
            LanguageId: this.state.LanguageId,
            MemberId: this.state.memberId,
            Bid: this.state.Bid,
            Currency: this.state.Currency
          };
          var tbid = this.state.Bid;
          var lBid = this.state.lastBid * 0.01;

          this.setState({ BidBaseCurrency: obj.Currency });
          _giveBidForTheLiveProject(obj).then(response => {
            swal(response.data.message);
          });
        }
      }
    }
    else {
      if (parseInt(this.state.LanguageId) === 1) {
        swal("Lütfen Bir Teklif Giriniz");
      } else {
        swal("Please Enter Bid");
      }
    }
  }

  myFunction() {
    var alertMessage = "";
    var rate = parseInt(this.state.lastBid / this.state.Bid);
    if (this.state.LanguageId === "1") {
      alertMessage = `Teklifiniz bir önceki tekliften ${rate}  kat küçüktür. Teklif vermek istediğinize emin misiniz?`;
    } else {
      alertMessage = `Your bid from the previous offer ${rate} floor is small. Are you sure you want to bid?`;
    }
    var r = window.confirm(alertMessage);
    if (r === true) {
      if (
        this.state.Currency !== this.state.BidBaseCurrency &&
        this.state.lastBid !== 0
      ) {
        // console.log(this.state.Currency);
        // console.log(this.state.BidBaseCurrency);
      } else {
        const obj = {
          ProjectId: this.state.projectId,
          CompanyId: this.state.CompanyId,
          LanguageId: this.state.LanguageId,
          MemberId: this.state.memberId,
          Bid: this.state.Bid,
          Currency: this.state.Currency
        };

        var tbid = this.state.Bid;
        var lBid = this.state.lastBid * 0.01;
        // console.log("Teklif: ", tbid);
        // console.log("Son Teklif: ", this.state.Bid);
        //console.log("Son Teklif yüzdesi: ", lBid);
        // console.log("Arasındaki Fark: ", this.state.lastBid - tbid);
        if (this.state.lastBid - tbid >= lBid) {
          //  console.log("Teklif verilebilir: ", this.state.lastBid - tbid);

          this.setState({ BidBaseCurrency: obj.Currency });
          _giveBidForTheLiveProject(obj).then(response => {
            swal(response.data.message);
          });
        } else {
          this.state.LanguageId === "1"
            ? swal(
                "Vermek istediğiniz teklif son teklifinizden minimum %1 oranında düşebilirsiniz."
              )
            : swal(
                "The bid you wish to bid can be reduced by a minimum of 1% from your last bid."
              );
        }
      }
    }
  }

  getLiveProjectDetail() {
    _getLiveProjectDetail(this.state.projectId, this.state.LanguageId)
      .then(response => {
        this.setState({
          detailGeneralInformation: response.data.data,
          Currency: response.data.data.currency
        });
        // console.log(response.data.data);
      })

      .catch(error => {
        console.log(error);
      });
  }
  getProjctEndDate() {
    _getProjctEndDate(this.state.projectId, this.state.LanguageId)
      .then(response => {
        this.setState({
          isProjectStarted: response.data.isProjectStarted,
          endDateMessage: response.data.message
        });
        if (response.data.isProjectStarted === true) {
          var date = new Date(response.data.data.endDate);
          var timestamp = date.getTime();
          this.setState({
            disabledForBidElement: false,
            endDate: timestamp
          });
          this.interval = setInterval(() => {
            var nowDate = Date.now();
            var difference = timestamp - nowDate;
            var hede = parseInt(difference / 1000);
            // console.log("hede eski: ",hede);
            if (hede <= 120 && hede > 0) {
              _getProjctEndDate(
                this.state.projectId,
                this.state.LanguageId
              ).then(response => {
                date = new Date(response.data.data.endDate);
                timestamp = date.getTime();
                nowDate = Date.now();
                difference = timestamp - nowDate;
                hede = parseInt(difference / 1000);
                this.setState({
                  disabledForBidElement: false,
                  endDate: timestamp
                });

                // console.log("hede yeni:  ",hede);
              });
            }
            if (hede === 0) {
              this.setState({
                disabledForBidElement: true,
                endDateMessage: "İhale sona ermiştir"
              });
              setTimeout(function() {
                //your code to be executed after 1,5 second
              }, 3500);
              window.location.href = `/go-to-live-procedure-detail/${this.state.projectId}`;
            }
          }, 1000);
        } else {
          this.setState({ disabledForBidElement: true });
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  // download(pdf) {
  //   this.downloadBase64File(pdf);
  // }

  downloadBase64File(pdf) {
    if (pdf !== null) {
      const linkSource = `data:application/pdf;base64,${pdf}`;
      const downloadLink = document.createElement("a");
      const fileName = "sartname.pdf";

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    } else {
      localStorage.getItem("LanguageId") === "1"
        ? swal("Sistemde ihalenin teknik şartnamesi mevcut değildir.")
        : swal(
            "Technical specifications of the tender are not available on the site."
          );
    }
  }

  render() {

    var style = {
      width: "90%"
    };
    return (
      <div className="col-md-12">
        <div className="block">
          <div className="block-title">
            <Link to="/live-project-list-for-company" className="btn btn-info">
              Geri
            </Link>
            <h2>
              <strong>{this.state.ConstantValueLiveDetail.HeadText}</strong>{" "}
            </h2>
          </div>
          <form className="form-horizontal form-bordered">
            <fieldset>
              <legend>{this.state.ConstantValueLiveDetail.GeneralInfText}</legend>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="col-md-3 control-label"
                    htmlFor="example-text-input"
                  >
                    {this.state.ConstantValueLiveDetail.LastBidText}
                  </label>
                  <div className="col-md-9">
                    <label
                      className="col-md-8 control-label"
                      type="date"
                      id="example-text-input"
                    >
                      {this.state.lastBidMessage == null ? (
                        <b>
                          {this.state.lastBid}
                          <span> {this.state.BidBaseCurrency}</span>
                        </b>
                      ) : (
                        this.state.lastBidMessage
                      )}
                      {this.state.IsMinBid === false ? (
                        <i className="fas fa-times"><b>{this.state.ConstantValueLiveDetail.BidErrorText}</b></i>
                      ) : (
                        <i className="fas fa-check"><b>{this.state.ConstantValueLiveDetail.BidSuccessText}</b></i>
                      )}
                    </label>
                  </div>"
                </div>
              </div>
              <div className="form-group">
                <div className="col-md-9">
                  <label
                    className="col-md-3 control-label"
                    type="date"
                    id="example-text-input"
                  >
                    {this.state.ConstantValueLiveDetail.SpecificationsText}
                  </label>
                  <div className="col-md-3">
                    <button
                      type="button"
                      onClick={e =>
                        this.downloadBase64File(
                          this.state.detailGeneralInformation.teknikSartname
                        )
                      }
                      className="btn btn-warning mt-2"
                    >
                      {this.state.ConstantValueLiveDetail.DownloadButtonText}
                    </button>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="col-md-9">
                  <label
                    className="col-md-1 control-label"
                    htmlFor="example-text-input"
                  >
                    {this.state.ConstantValueLiveDetail.CurrencyText}
                  </label>
                  <div className="col-md-3">
                    <label
                      className="col-md-3 control-label"
                      type="date"
                      id="example-text-input"
                    >
                      {this.state.detailGeneralInformation.currency}
                    </label>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="col-md-3 control-label"
                    htmlFor="example-text-input"
                  >
                    {this.state.ConstantValueLiveDetail.RemainTimeText}
                  </label>

                  <label
                    className="col-md-3 control-label"
                    htmlFor="example-text-input"
                  >
                    {this.state.endDateMessage === null ? (
                      this.state.isProjectStarted === true ? (
                        <b>
                          <Countdown date={this.state.endDate} />{" "}
                        </b>
                      ) : (
                        this.state.endDateMessage
                      )
                    ) : (
                      this.state.endDateMessage
                    )}
                  </label>
                </div>
                <fieldset>
                  <div className="form-group">
                    <div className="col-md-3">
                      <label
                        className="col-md-3 control-label"
                        htmlFor="example-text-input"
                      >
                        {this.state.ConstantValueLiveDetail.GiveOfferText}

                      </label>
                      <input
                        type="number"
                        className="form-control"
                        name="Bid"
                        placeholder={this.state.ConstantValueLiveDetail.TeklifVerPlaceHolder}
                        onChange={this.handleChange}
                        value={this.state.value}
                        disabled={this.state.disabledForBidElement}
                      />
                    </div>
                    <div className="col-md-3 mt-4">
                      <label
                        className="col-md-3 control-label"
                        htmlFor="example-text-input"
                      >
                        {this.state.ConstantValueLiveDetail.CurrencyText2}
                      </label>
                      <select
                        disabled={this.state.disabledForBidElement}
                        className="form-control"
                        name="Currency"
                        id="Currency"
                        onChange={this.handleChangeCurrency}
                        value={this.state.value}
                      >
                        <option
                          value={this.state.detailGeneralInformation.currency}
                        >
                          {this.state.detailGeneralInformation.currency}
                        </option>
                        <option
                          className="visible"
                          hidden={
                            this.state.detailGeneralInformation.currency ===
                            "TRY"
                              ? true
                              : false
                          }
                          value="TRY"
                        >
                          TRY
                        </option>
                        <option
                          hidden={
                            this.state.detailGeneralInformation.currency ===
                            "USD"
                              ? true
                              : false
                          }
                          value="USD"
                        >
                          USD
                        </option>
                        <option
                          hidden={
                            this.state.detailGeneralInformation.currency ===
                            "EUR"
                              ? true
                              : false
                          }
                          value="EUR"
                        >
                          EUR
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <button

                      disabled={this.state.disabledForBidElement || !this.state.result }
                      type="button"
                      className="btn btn-warning"
                      onClick={this.giveBid}
                    >
                      {this.state.ConstantValueLiveDetail.TeklifVerButtonText}
                    </button>
                  </div>
                </fieldset>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="col-md-3 control-label"
                    htmlFor="example-text-input"
                  >
                    {this.state.ConstantValueLiveDetail.DescriptionText}
                  </label>
                  <div className="col-md-9">
                    <textarea
                      className="col-md-3 control-label"
                      style={style}
                      rows="7"
                      value={this.state.detailGeneralInformation.description}
                      disabled
                      id="example-text-input"
                    ></textarea>
                  </div>
                </div>
              </div>
            </fieldset>
          </form>
        </div>
      </div>
    );
  }
}
